import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { removeEmptyFields } from "utils/functions";
import { teamCreateSchema } from "models/teamModel";
import { createTeam, getAllTeams } from "services/apiAdmin/teamApi";
import { getAllUsers, getSuperadminUsers } from "services/apiUser/userApi";

import createTeamView from "./CreateView";
import { translate } from "utils/translate";
const newTeam = {
	title: "",
	leaderId: "",
	parentTeamId: "",
	specialistId: ""
}

const newTeamState = {
	title: "",
	leaderId: "",
	parentTeamId: "",
	specialistId: ""
}

let _isMounted = false

async function onSubmit(props) {
	let body = removeEmptyFields({ ...props.team }, ["leaderId", "parentTeamId", "specialistId"])

	let result = await createTeam(body)
	// Show confirmation message
	if (result.success && true && _isMounted) {

		props.setCreateSuccess(true)
		setTimeout(function () {
			if (_isMounted) {
				props.setCreateSuccess(false)
				props.history.push('/admin/team/index')
			}
		}, 1500);
	} else {
		// Show error message
		if (result) {
			let message = result.message
			if (!message) {
				message = "Algo salió mal, inténtelo nuevamente"
			}
			props.setValidationMessage(message)
			props.setCreateError(true)
		}
	}
}

async function getTeams(props) {
	let result = await getAllTeams();
	if (_isMounted && result.success) {
		props.setTeamList(result.data)
	}
	props.changeLoadingTeamList(false)
}

async function getUserList(props) {
	let result = await getAllUsers();
	if (_isMounted && result.success) {
		props.setUserList(result.data)
	}
	props.changeLoadingUserList(false)
	props.changeLoadingSpecialistList(false)
}

async function getSuperadminList(props) {
	let result = await getSuperadminUsers();
	if (_isMounted && result.success) {
		props.setSuperadminList(result.data)
	}
	props.changeLoadingUserList(false)
	props.changeLoadingSpecialistList(false)
}

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{}
	),
	withState("team", "setTeam", { ...newTeam }),
	withState("teamState", "setTeamState", { ...newTeamState }),
	withState("teamList", "setTeamList", []),
	withState("userList", "setUserList", []),
	withState("superadminList", "setSuperadminList", []),
	withState("createSuccess", "setCreateSuccess", false),
	withState("createError", "setCreateError", false),
	withState("validationMessage", "setValidationMessage", ""),
	withState("isLoadingSave", "setIsLoadingSave", false),
	withState("loadingUserList", "changeLoadingUserList", false),
	withState("loadingSpecialistList", "changeLoadingSpecialistList", false),
	withState("loadingTeamList", "changeLoadingTeamList", false),
	withHandlers({
		onFieldChange: (props) => (field, value) => {
			props.team[field] = value
			let isValid = validateProperty(teamCreateSchema, props.team, field).isValid
			if (isValid) {
				props.teamState[field] = "success"
			} else {
				props.teamState[field] = "error"
			}
			props.setTeamState(props.teamState)
			props.setTeam(props.team)
		},
		onUserListGet: (props) => async () => {
			getUserList(props)
		},
		onTeamCreate: (props) => async () => {
			props.setIsLoadingSave(true)

			let validation = validateObject(teamCreateSchema, props.team)
		
			let val = true;
			for(let x =0; x < props.teamList.length; x++){
				let name_space = props.teamList[x].title.split(" ").join("");
				let name_space_n = props.team.title.split(" ").join("");
				if(name_space == name_space_n){
					val=false;
				}
			}
			if (validation.isValid && val == true) {
				await onSubmit(props)
			} else {
				if(val == false){
					let message = translate("team_name")
					props.setValidationMessage(message)
					props.setCreateError(true)
				}
				if(validation.isValid && val == true){
					props.teamState[0]= "error"
				props.setTeamState(props.teamState)
				}else{
					let field
					for (field in props.team) {
						if (validation.errors[field]) {
							props.teamState[field] = "error"
						}
					}
					props.setTeamState(props.teamState)
				}
				
			}
			props.setIsLoadingSave(false)
		}
	}),
	lifecycle({
		componentDidMount() {
			_isMounted = true
			this.props.setTeam({ ...newTeam })
			this.props.setTeamState({ ...newTeamState })
			this.props.setCreateSuccess(false)

			getTeams(this.props)
			getUserList(this.props)
		},
		componentWillUnmount() {
			_isMounted = false
		},
		UNSAFE_componentWillUpdate(nextProps) {
			//If loading changes
			if (this.props.loadingUserList !== nextProps.loadingUserList && nextProps.loadingUserList) {
				getUserList(this.props)
			}

			if (this.props.loadingSpecialistList !== nextProps.loadingSpecialistList && nextProps.loadingSpecialistList) {
				//getUserList(this.props)
				getSuperadminList(this.props)
			}

			if (this.props.loadingTeamList !== nextProps.loadingTeamList && nextProps.loadingTeamList) {
				getTeams(this.props)
			}
		}
	})
)(createTeamView);
