import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Explore from "@material-ui/icons/Explore";

import DashboardIcon from "@material-ui/icons/Dashboard";
// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";


import Add from "@material-ui/icons/Add";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { CircularProgress, Select, MenuItem, Grid } from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
import TextField from '@material-ui/core/TextField';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from "components/CustomButtons/Button.js";
import { getUserLocale } from "utils/translate";
//functions
import { formatDate, filterCaseInsensitive } from "utils/functions";
import { CommitmentStatus } from "components/Constants";
import { getCommitmentColor } from "utils/dataTypeFormatter";
import { translate } from "utils/translate"


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function ReactTables(props) {
  const classes = useStyles();

  let dataTableInfo = props.commitmentList.map((prop) => {
    let colorStatus = getCommitmentColor(prop.status)
    return {
      id: prop.id,
      createdAt: formatDate(prop.when,getUserLocale()),
      comentary: prop.comentary,
      createdBy: `${prop.firstName} ${prop.lastName} (${prop.email})`,
      status: CommitmentStatus[`${prop.status}`],
      color: colorStatus,
    }
  })

  let breadcrumbViews = [
    {
      name: translate("routes_home_label"),
      url: "/admin/dashboard",
      icon: DashboardIcon
    },
    {
      name: translate("routes_commitment_label"),
      url: "/admin/commitment/internal",
      icon: Explore
    }
  ]

  return (
    <>
      <Breadcrumbs views={breadcrumbViews} />
      <Heading
        textAlign="center"
        title={translate("commitment_tracking_tooltip")}
      />
      <GridContainer>
        <GridItem xs={12}>
        <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                <Explore />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{translate("commitment")}</h4>
              </CardHeader>
              <CardBody>
                <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <p style={{ fontWeight: 500, textAlign: "center" }}>{translate("commitment_title_")} </p>
                          <h4 className={classes.cardIconTitle} style={{textAlign: "center" }}>{props.commitment.title}</h4>
                          <h4 className={classes.cardIconTitle}></h4>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          <p style={{ fontWeight: 500, textAlign: "left" }}>{translate("wig")}: </p>
                          <h4 className={classes.cardIconTitle}>{props.wig}</h4>
                          <h4 className={classes.cardIconTitle}></h4>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <p style={{ fontWeight: 500, textAlign: "left" }}>{translate("commitment_for_when_input")} </p>
                          <TextField
                            value={props.commitment.when}
                            disabled = "true"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <h4 className={classes.cardIconTitle}></h4>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <p style={{ fontWeight: 500, textAlign: "left" }}>{translate("commitment_responsable_label")} </p>
                            <TextField
                            value={props.commitment.firstName + " " + props.commitment.lastName} 
                            disabled = "true"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <p style={{ fontWeight: 500, textAlign: "left" }}>{translate("commitment_clear_path_label")} </p>
                            <h4 className={classes.cardIconTitle}>{props.commitment.hasDependency}</h4>
                            <h4 className={classes.cardIconTitle}></h4>
                        </GridItem>
        
                        <GridItem xs={12} sm={6} md={6}>
                        <p style={{ fontWeight: 500, textAlign: "left" }}>{translate("commitment_autocomplete_input")} </p>
                        <TextField
                            value={props.clearpath.firstName + " " + props.clearpath.lastName} 
                            disabled = "true"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                  </GridItem>
                </GridContainer>
                </form>
              </CardBody>
            </Card>

        </GridItem>

        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Explore />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{translate("commitment_tracking_tooltip")}</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={dataTableInfo}
                filterable
                defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                columns={[
                  {
                    Header: translate("table_header_date"),
                    accessor: "createdAt"
                  },
                  {
                    Header: translate("const_commentary"),
                    accessor: "comentary"
                  },
                  {
                    Header: translate("table_header_who"),
                    accessor: "createdBy"
                  },
                  {
                    Header: translate("table_header_status"),
                    accessor: "status",
                    getProps: (state, rowInfo) => {
                      return {
                        style: {
                          color: rowInfo.row._original.color
                        },
                      };
                    }
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                minRows={0}
                showPaginationBottom={false}
                className="-striped -highlight noActions"
                previousText={translate("table_previous_text")}
                nextText={translate("table_next_text")}
                loadingText={translate("table_loading_text")}
                noDataText={translate("table_not_found_text")}
                pageText={translate("table_page_text")}
                ofText={translate("table_of_text")}
                rowsText={translate("table_rows_text")}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
