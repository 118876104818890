/*eslint-disable*/
import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';

import PropTypes from "prop-types";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { CircularProgress, Select, MenuItem, Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from "components/Snackbar/Snackbar.js";
import GridContainer from "components/Grid/GridContainer.js";

// icons
import GroupAdd from "@material-ui/icons/GroupAdd";
import Group from "@material-ui/icons/Group";
import Close from "@material-ui/icons/Close";
import AddAlert from "@material-ui/icons/AddAlert";

// funciones
import { verifyPermission } from "utils/functions";
import { translate } from "utils/translate"
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import Button from "components/CustomButtons/Button.js";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";


var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links, teamMembers } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {user}
        {headerLinks}
        {links}
        {teamMembers}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      team: props.team,
      roleId: props.roleId,
      ...this.getCollapseStates(props.routes)
    };
  }
  mainPanel = React.createRef();

  /*
  alert = () => {
    let alertMessage = (null)
    if (props.alertVisible) {
      alertMessage = (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={'¿Está seguro que desea eliminar el Equipo?'}
          //onConfirm={() => props.onTeamDelete(props.alertId)}
          //onCancel={() => props.onModalClosed()}
          confirmBtnCssClass={(props.alertLoading ? classes.disabled : "") + " " + classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText="Sí, deseo eliminarlo"
          cancelBtnText="Cancelar"
          showCancel
          disabled={props.alertLoading}
        >
          {
            //getTeam(props.alertId)
          }
          {props.alertLoading ?
            <GridContainer justify="center">
              <Grid>
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
            </GridContainer>
            :
            null
          }
        </SweetAlert>
      )
    }
    return (alertMessage)
  }

  */



  async alertWithInput(id) {
    await this.props.changeAlertId(id)
    await this.props.changeAlertVisible(true)
    //window.location.href = `/admin/dashboard`;
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map(prop => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (routes[i].hasOwnProperty("lookup")) {
        let lookUp = routes[i].lookup.find(function (look) {
          return window.location.href.indexOf(look) > -1
        })
        if (lookUp !== undefined) {
          return true
        }
      } else if (window.location.href.toLowerCase().indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = routesName => {
    let lookUp = routesName.find(function (look) {
      return window.location.href.indexOf(look) > -1
    })

    return lookUp !== undefined ? "active" : "";
  };
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    const { classes, color, rtlActive } = this.props;
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            )
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive
          });
        const caret =
          classes.caret +
          " " +
          cx({
            [classes.caretRTL]: rtlActive
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive
          });
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                    <prop.icon className={itemIcon} />
                  )
              ) : (
                  <span className={collapseItemMini}>
                    {rtlActive ? prop.rtlMini : prop.mini}
                  </span>
                )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (this.state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.lookup)
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(prop.lookup)
        });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive
        });

      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                  <prop.icon className={itemIcon} />
                )
            ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
            <ListItemText
              primary={rtlActive ? prop.rtlName : prop.name}
              disableTypography={true}
              classes={{ primary: classes.largeName }}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };
  render() {
    const {
      classes,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
      signOut,
      memberList,
      roleId,
      team
    } = this.props;
    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive
      });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive
      });
    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: rtlActive
      });
    const collapseItemMini =
      classes.collapseItemMini +
      " " +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive
      });
    const photo =
      classes.photo +
      " " +
      cx({
        [classes.photoRTL]: rtlActive
      });

    const memberPhoto = classes.memberPhoto

    const getUser = (id) => {
      let user = this.props.memberList.find(function (user) {
        return user.userId === id
      })
      return `${user.firstName} ${user.lastName}`
    }

    let alert = () => {
      let alertMessage = (null)
      if (this.props.alertVisible) {
        alertMessage = (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title={translate("alert_remove_from_team_title")}
            onConfirm={() => this.props.onUserDelete(this.props.alertId)}
            onCancel={() => this.props.onModalClosed()}
            confirmBtnCssClass={(this.props.alertLoading ? classes.disabled : "") + " " + classes.button + " " + classes.success}
            cancelBtnCssClass={classes.button + " " + classes.danger}
            confirmBtnText={translate("alert_remove_from_team_confirmText")}
            cancelBtnText={translate("alert_remove_from_team_cancelText")}
            showCancel
            disabled={this.props.alertLoading}
          >
            {
              `${getUser(this.props.alertId)} ${translate("alert_remove_from_team_conjunction")} ${this.props.team.title}`
            }
            {this.props.alertLoading ?
              <GridContainer justify="center">
                <Grid>
                  <div className={classes.circularProgress}>
                    <CircularProgress color="inherit" />
                  </div>
                </Grid>
              </GridContainer>
              :
              null
            }
          </SweetAlert>
        )
      }
      return (alertMessage)
    }

    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    var user = (
      <div className={userWrapperClass}>
        <div className={photo}>
          <img src={userInfo.picture} className={classes.avatarImg} alt="..." />
        </div>
        <List className={classes.list}>
          <ListItem className={classes.item + " " + classes.userItem}>
            <NavLink
              to={"#"}
              className={classes.itemLink + " " + classes.userCollapseButton}
              onClick={() => this.openCollapse("openAvatar")}
            >
              <ListItemText
                primary={userInfo.name}
                disableTypography={true}
                className={itemText + " " + classes.userItemText}
              />
            </NavLink>
          </ListItem>
        </List>
      </div>
    );
    var links = (
      <div className={userWrapperClass}>
        <List className={classes.list}>{this.createLinks(routes)}</List>
      </div>
    );

    let teamMembers = (
      <List className={classes.list}>
        <ListItem
          className={classes.listItem}
          key={1}
        >
          <ListItemAvatar>
            {verifyPermission(roleId, team.dataRoleId, "teamMember", "read.write.all") ?

              <NavLink to={`/admin/members`} >
                <Tooltip title={translate("sidebar_add_member_label")}>
                  <Button
                    justIcon
                    round
                    simple
                    color="white"
                  >
                    <GroupAdd className={classes.icons} />
                  </Button>
                </Tooltip>
              </NavLink>
              :
              <Button
                justIcon
                round
                disabled
                simple
                color="white"
              >
                <Group className={classes.icons} />
              </Button>
            }
          </ListItemAvatar>
          
          <ListItemText
          //aqui empieza la lista de miembros
            primary={translate("sidebar_myteam_label")}
            disableTypography={true}
            className={itemText + " " + classes.userItemText}
          />
        </ListItem>
        {memberList.map((prop, key) => {
          return (
            <ListItem
              className={classes.listItem}
              key={key + 1}
            >
              <ListItemAvatar>
                <div className={memberPhoto}>
                  <img src={prop.picture} className={classes.avatarImg} alt="..." />
                </div>
              </ListItemAvatar>
              <ListItemText
                primary={`${prop.firstName} ${prop.lastName}`}
                secondary={translate(`const_datarole_${prop.dataRoleId}`)}
                classes={{ primary: classes.primaryUserItemText, secondary: classes.secondaryUserItemText }}
                className={itemText + " " + classes.userItemText}
              />
              {verifyPermission(this.props.roleId, this.props.team.dataRoleId, "team", "read.write.all") ?
                <ListItemSecondaryAction>
                  <Tooltip title={translate("sidebar_remove_user_label")}>
                    <IconButton style={{ fontSize: 40 }} aria-label="Delete" className={classes.deleteIcon} onClick={() => { this.alertWithInput(prop.userId) }}>
                      <Close />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
                :
                null
              }
            </ListItem>
          )
        })}
      </List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    var brand = (
      <div className={logoClasses}>
        <a
          target="_blank"
          className={logoMini}
        >
          <img src={logo} alt="logo" className={classes.img} />
        </a>
        <a
          target="_blank"
          className={logoNormal}
        >
           <a className={classes.logoTextUpdate}>{logoText}</a>
        </a>
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div ref={this.mainPanel}>
        {alert()}
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={<AdminNavbarLinks signOut={signOut} rtlActive={rtlActive} />}
              links={links}
              teamMembers={teamMembers}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
              teamMembers={teamMembers}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Snackbar
          place="br"
          color="success"
          icon={AddAlert}
          message={translate("user_deleted")}
          open={this.props.deleteSuccess}
          closeNotification={() => this.props.changeDeleteSuccess(false)}
          close
        />
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={this.props.validationMessage}
          open={this.props.deleteError}
          closeNotification={() => this.props.changeDeleteError(false)}
          close
        />
      </div >
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue"
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue", "green"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
    "primary"
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object
};

export default withStyles(sidebarStyle)(Sidebar);
