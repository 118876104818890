import React , { useState, useEffect } from "react";

// react component for creating dynamic tables
import ReactTable from "react-table";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer  } from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";


//dependy react-datePicker
import DatePicker , {registerLocale} from "react-datepicker";
import es from "date-fns/locale/es";
import eu from "date-fns/locale/en-US";
import "react-datepicker/dist/react-datepicker.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CircularProgress, Select, MenuItem, Grid } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Snackbar from "components/Snackbar/Snackbar.js";

// @material-ui/icons
import Explore from "@material-ui/icons/Explore";
import Add from "@material-ui/icons/Add";
import TrackChanges from "@material-ui/icons/TrackChanges";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Close from "@material-ui/icons/Close";
import AddAlert from "@material-ui/icons/AddAlert";

import DashboardIcon from "@material-ui/icons/Dashboard";
// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Days,DaysE } from "components/Constants";
import Launch from "@material-ui/icons/Launch";
import { getUserLocale } from "utils/translate";
import styles from "assets/jss/material-dashboard-pro-react/views/Commitment/internalStyle.js";

import Chart from 'react-apexcharts'

//functions
import { getWeeksNumberBetweenDates, formatDate, verifyPermission, filterCaseInsensitive} from "utils/functions"
import { getCommitmentColor } from "utils/dataTypeFormatter"

import { DataRoles } from "components/Constants";
import { translate } from "utils/translate";

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(styles);

function weekDayHeader(props) {
  if(getUserLocale() === "en-US"){
    return (<div style={{ textAlign: "center" }}>{DaysE[`${props.date.getDay()}`]}</div>)
  }else {
    return (<div style={{ textAlign: "center" }}>{Days[`${props.date.getDay()}`]}</div>)
  }

}

let currentTeamValue
function handleTeamChange(props) {
  let previousTeamValue = currentTeamValue
  currentTeamValue = props.teamId

  if (previousTeamValue !== currentTeamValue) {
    props.onTeamIdChange()
  }
}

export default function InternalCommitment(props) {
  const [selectFecha, setSelectFecha ] = useState(new Date(props.initialDate))  
  const [longitudArray, setLongitudArray ] = useState(0);

  const getData = (valor) => {
    let nuevoValor = valor + longitudArray;
    setLongitudArray(nuevoValor);
  };

  useEffect(() => {
    getData(1);
  }, [props.commitmentList]);

  registerLocale("es", es)
  registerLocale("eu", eu)

  const classes = useStyles();

  const selectedEvent = event => {
    // window.location.href = `/admin/accountabilityrecord/team`;
    window.location.href = `/admin/accountabilityrecords/team/${event.teamId}`;
  };

  const eventColors = event => {
    var backgroundColor = "event-";
    event.color
        ? (backgroundColor = backgroundColor + event.color)
        : (backgroundColor = backgroundColor + "default");
    return {
        className: backgroundColor
    };
  };

  const getCommitment = (id) => {
    let commitment = props.commitmentList.find(function (commitment) {
      return commitment.id === id
    })
    return commitment.title
  }

  const commitmentTrackingAlert = () => {
    let alertMessage = (null)
    if (props.trackingCommitmentAlertVisible) {
      alertMessage = (
        <SweetAlert
          style={{ display: "block" }}
          title={translate("alert_commitment_track_title")}
          onConfirm={() => props.onTrackingSave(props.alertId)}
          onCancel={() => props.onModalClosed()}
          confirmBtnCssClass={(props.alertLoading ? classes.disabled : "") + " " + classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={translate("alert_commitment_save_button")}
          cancelBtnText={translate("alert_commitment_cancel_button")}
          showCancel
          disabled={props.alertLoading}
        >
          {getCommitment(props.alertId)}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <CustomInput
                success={props.trackingState.commentary === "success"}
                error={props.trackingState.commentary === "error"}
                labelText={translate("alert_commitment_comment_label")}
                id="inputCommentary"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => {
                    props.onTrackingFieldChange("commentary", event.target.value)
                  },
                  type: "text",
                  value: props.tracking.commentary,
                  endAdornment:
                    props.trackingState["commentary"] === "error" ? (
                      <InputAdornment position="end">
                        <Close className={classes.error} />
                      </InputAdornment>
                    ) : (
                        undefined
                      )
                }}
              />
              <div className={classes.block}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={props.tracking.isDone}
                      onChange={event => props.onTrackingFieldChange("isDone", event.target.checked)}
                      value="isDone"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label={translate("alert_commitment_finished_label")}
                />
                {
                  verifyPermission(props.roleId, props.team.dataRoleId, "commitment", "tracking.close") ?
                    <FormControlLabel
                      control={
                        <Switch
                          checked={props.tracking.isClosed && !props.tracking.isDone}
                          disabled={props.tracking.isDone}
                          onChange={event => props.onTrackingFieldChange("isClosed", event.target.checked)}
                          value="isClosed"
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={translate("alert_commitment_closed_label")}
                    />
                    : null
                }
              </div>
            </GridItem>

          </GridContainer>
          {props.alertLoading ?
            <GridContainer justify="center">
              <Grid>
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
            </GridContainer>
            :
            null
          }
        </SweetAlert >
      )
    }
    return (alertMessage)
  }

  const commitmentDeleteAlert = () => {
    let alertMessage = (null)
    if (props.deleteCommitmentAlertVisible) {
      alertMessage = (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={translate("alert_commitment_delete_title")}
          onConfirm={() => props.onCommitmentDelete(props.alertId)}
          onCancel={() => props.onModalClosed()}
          confirmBtnCssClass={(props.alertLoading ? classes.disabled : "") + " " + classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={translate("alert_commitment_delete_confirm_text")}
          cancelBtnText={translate("alert_commitment_delete_cancel_text")}
          showCancel
          disabled={props.alertLoading}
        >
          {
            getCommitment(props.alertId)
          }
          {props.alertLoading ?
            <GridContainer justify="center">
              <Grid>
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
            </GridContainer>
            :
            null
          }
        </SweetAlert>
      )
    }
    return (alertMessage)
  }

  const alertWithInput = (id, type) => {
    props.changeAlertId(id)
    type === "tracking" ? props.changeTrackingCommitmentAlertVisible(true) : props.changeDeleteCommitmentAlertVisible(true)
  }


  const [open, setOpen] = React.useState(false);

  let dataTableInfo = props.commitmentList.map((prop) => {
    let colorStatus = getCommitmentColor(prop.status)

    let initialDate = new Date(prop.createdAt)
    let lastDate
    // If the status is working get the current date
    if (prop.status === 1) {
      lastDate = new Date()
    } else {
      lastDate = new Date(prop.changedAt)
    }

    return {
      id: prop.id,
      createdAt: formatDate(prop.createdAt,getUserLocale()),
      assignedTo: `${prop.firstName} ${prop.lastName}`,
      commitment: prop.title,
      when: formatDate(prop.when,getUserLocale()),
      duration: getWeeksNumberBetweenDates(initialDate, lastDate),
      status: translate(`const_commitment_status_${prop.status}`),
      color: colorStatus,
      hasDependency: (prop.hasDependency ? translate("commitment_dependency_true") : translate("commitment_dependency_false")),
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          <Tooltip title={translate("commitment_track_tooltip")}>
            <Button
              color={"success"}
              simple
              disabled={props.status === 1}
              className={classes.actionButton}
              onClick={() => { if (prop.status === 1) { alertWithInput(prop.id, "tracking") } }}
            >
              <TrackChanges className={classes.icon} />
            </Button>
          </Tooltip>
         
          {
            verifyPermission(props.roleId, props.team.dataRoleId, "commitment", "tracking.close") ?
              <Tooltip title={translate("commitment_delete_tooltip")}>
                <Button
                  color={"danger"}
                  simple
                  disabled={props.status === 1}
                  className={classes.actionButton}
                  onClick={() => { if (prop.status === 1) { alertWithInput(prop.id, "delete") } }}
                >
                  <Close className={classes.icon} />
                </Button>
              </Tooltip>
              
              :
              null
          }
           <Tooltip title={translate("commitment_tracking_tooltip")}>
          <Button color="primary" onClick={() => props.viewTracking(prop.id)}>
                                <Launch className={classes.icon} />
                            </Button>
          </Tooltip>

        </div>
      )
    }
  })

  let breadcrumbViews = [
    {
      name: translate("routes_home_label"),
      url: "/admin/dashboard",
      icon: DashboardIcon
    },
    {
      name: translate("routes_commitment_label"),
      url: "/admin/commitment/internal",
      icon: Explore
    }
  ]
  let setSelectFechaFunction = (date) =>{
    let formatoCorrespondiente = moment(date).format("YYYY-MM-DDTHH:mm")
    props.setInitialDate(formatoCorrespondiente)
    setSelectFecha(date)
  }

  handleTeamChange(props)

  return (
    <>
      {commitmentTrackingAlert()}
      {commitmentDeleteAlert()}
      <Breadcrumbs views={breadcrumbViews} />
      <Heading
        textAlign="center"
        title={translate("routes_weekly_compass_label")}
      />
      <GridContainer>
        {verifyPermission(props.roleId, props.team.dataRoleId, "commitment", "create")
          ?
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Add />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{translate("commitment_title")}</h4>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              {translate("wig")}
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={props.commitment[`wigId`]}
                              onChange={e => props.onFieldChange(`wigId`, e.target.value)}
                              inputProps={{
                                name: `wigId`,
                                id: `wigId`
                              }}
                            >
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={""}
                                disabled
                              >
                                {translate("commitment_wig_input")}
                              </MenuItem>
                              {props.wigList.map((wig, index) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  style={{ overflowX: "auto" }}
                                  value={wig.id}
                                  key={index}
                                >
                                  {wig.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            success={props.commitmentState.action === "success"}
                            error={props.commitmentState.action === "error"}
                            labelText={translate("commitment_action")+' *'}
                            id="inputAction"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                props.onFieldChange("action", event.target.value)
                                props.onFieldChange("title", event.target.value + (props.commitment.impact === '' ? props.commitment.impact.trim() : ' ' + props.commitment.impact))
                              },
                              type: "text",
                              value: props.commitment.action,
                              endAdornment:
                                props.commitmentState["action"] === "error" ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.error} />
                                  </InputAdornment>
                                ) : (
                                    undefined
                                  )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            success={props.commitmentState.impact === "success"}
                            error={props.commitmentState.impact === "error"}
                            labelText={translate("commitment_impact")+' *'}
                            id="inputImpact"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                props.onFieldChange("impact", event.target.value)
                                //props.onFieldChange("title", props.commitment.action + '' + event.target.value)
                                props.onFieldChange("title", (props.commitment.action === '' ? props.commitment.action.trim()  : props.commitment.action + ' ' )+ event.target.value)
                              },
                              type: "text",
                              value: props.commitment.impact,
                              endAdornment:
                                props.commitmentState["impact"] === "error" ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.error} />
                                  </InputAdornment>
                                ) : (
                                    undefined
                                  )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            disabled={true}
                            labelText={translate("commitment") + '*'}
                            id="inputTitle"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled:true,
                              onChange: event => {
                                props.onFieldChange("title", event.target.value)
                              },
                              type: "text",
                              value: props.commitment.title,
                              endAdornment:
                                props.commitmentState["title"] === "error" ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.error} />
                                  </InputAdornment>
                                ) : (
                                    undefined
                                  )
                            }}
                          />
                        </GridItem>
                        <GridItem xs={1} sm={6} md={6}></GridItem>
                        {/*<GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            success={props.commitmentState.when === "success"}
                            error={props.commitmentState.when === "error"}
                            labelText={translate("commitment_for_when_input") + "*"}
                            id="inputWhen"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: event => {
                                props.onFieldChange("when", event.target.value)
                              },
                              type: "text",
                              value: props.commitment.when,
                              endAdornment:
                                props.commitmentState["when"] === "error" ? (
                                  <InputAdornment position="end">
                                    <Close className={classes.error} />
                                  </InputAdornment>
                                ) : (
                                    undefined
                                  )
                            }}
                          />
                          </GridItem>*/}
                        <GridItem xs={12} sm={6} md={6}>
                        <div>{translate("commitment_for_when_input") + "*"}</div>
                        <DatePicker 
                          className="pickers"
                          selected={selectFecha} 
                          onChange={(date) => setSelectFechaFunction(date)} 
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeCaption={translate("time_hour")}
                          timeIntervals={1}
                          dateFormat={translate("commitment_for_when_input") != "When?"  ?  
                            "dd MMMM, yyyy h:mm aa"
                              : 
                              "MMMM d, yyyy h:mm aa"
                          }
                           
                          locale={translate("commitment_for_when_input") != "When?"  ?  
                            es
                            : 
                            eu
                           }
                        />
                          {/* <TextField
                            id="datetime-local"
                            label={translate("commitment_for_when_input") + "*"}
                            type="datetime-local"
                            defaultValue={props.initialDate}
                            onChange={event => props.setInitialDate(event.target.value)}
                            //onChange={event => props.onFieldChange("hasDependency", event.target.checked)}
                            //value={props.commitment.when}
                            //value = {props.initialDate}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          /> */}
                        </GridItem>
                        
                        <GridItem xs={12} sm={12} md={12}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                              <div className={classes.block}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={props.commitment.hasDependency}
                                      onChange={event => props.onFieldChange("hasDependency", event.target.checked)}
                                      value="hasDependency"
                                      classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar
                                      }}
                                    />
                                  }
                                  classes={{
                                    label: classes.label
                                  }}
                                  label={translate("commitment_clear_path_label")}
                                />
                                <FormControlLabel
                                  control={
                                    <Switch
                                      disabled={!props.commitment.hasDependency}
                                      checked={props.commitment.internalDependency && props.commitment.hasDependency}
                                      onChange={event => props.onFieldChange("internalDependency", event.target.checked)}
                                      value="internalDependency"
                                      classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar
                                      }}
                                    />
                                  }
                                  classes={{
                                    label: classes.label
                                  }}
                                  label={translate("commitment_internal_clear_path_label")}
                                />
                              </div>
                              <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                              >
                                <Autocomplete
                                  loadingText={translate("autocomplete_loading_text")}
                                  clearText={translate("autocomplete_clear_text")}
                                  noOptionsText={translate("autocomplete_no_options_text")}
                                  id="userList"
                                  disabled={!props.commitment.hasDependency}
                                  open={open}
                                  onOpen={() => {
                                    setOpen(true);
                                    props.changeloadingHelperList(true);
                                  }}
                                  onClose={() => {
                                    setOpen(false);
                                    props.changeHelpersList([]);
                                  }}
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      newValue = parseInt(newValue.userId)
                                    }
                                    props.onFieldChange("dependencyUser", newValue)
                                  }}
                                  getOptionSelected={(option, value) => option.userId === value.userId}
                                  getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
                                  options={props.helpersList}
                                  loading={props.loadingHelperList}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={translate("commitment_autocomplete_input")}
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {props.loadingHelperList ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>

                            </GridItem>
                            
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                              {/*
                              <div className={classes.block}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={props.commitment.applyForAllMembers}
                                      onChange={event => props.onFieldChange("applyForAllMembers", event.target.checked)}
                                      value="applyForAllMembers"
                                      classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar
                                      }}
                                    />
                                  }
                                  classes={{
                                    label: classes.label
                                  }}
                                  label={translate("commitment_apllies_all_label")}
                                />
                              </div>
                                */}
                              <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                              >
                                <InputLabel
                                  success={props.commitmentState.title === "success"}
                                  error={props.commitmentState.title === "error"}
                                  htmlFor="simple-select"
                                  className={classes.selectLabel}
                                >
                                  {translate("commitment_responsable_label")}*
                      </InputLabel>
                                <Select
                                  disabled={props.commitment.applyForAllMembers}
                                  MenuProps={{
                                    className: classes.selectMenu
                                  }}
                                  classes={{
                                    select: classes.select
                                  }}
                                  value={props.commitment[`assignedUser`]}
                                  onChange={e => props.onFieldChange(`assignedUser`, e.target.value)}
                                  inputProps={{
                                    name: `assignedUser`,
                                    id: `assignedUser`
                                  }}
                                >
                                  <MenuItem
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value={""}
                                    disabled
                                  >
                                    {translate("commitment_responsable_input")}
                                  </MenuItem>
                                  {props.membersList.map((user, index) => (
                                    <MenuItem
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }}
                                      style={{ overflowX: "auto" }}
                                      value={user.userId}
                                      key={index}
                                    >
                                      {`${user.firstName} ${user.lastName} (${DataRoles[user.dataRoleId]})`}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <div className={classes.formCategory}>
                    <small>*</small> {translate("required_fields")}
                  </div>
                  <div className={classes.center}>
                    {props.isLoadingSave ?
                      <CircularProgress color="inherit" /> :
                      <Button color="primary" onClick={props.onCommitmentSave}>
                        {translate("button_save")}
                      </Button>
                    }
                  </div>
                </form>
              </CardBody>
            </Card>
          </GridItem>
          :
          null
        }
        {
          //* Start of commitment calendar 
          
        }
        <GridItem xs={6}>
        <Card>
          <CardHeader color="primary" icon>
              <CardIcon color="primary">
                  <CalendarTodayIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{translate("commitment_calendar_label")}</h4>
          </CardHeader>
          <CardBody>
              <BigCalendar
                  localizer={localizer}
                  events={props.events}
                  toolbar={false}
                  views={["work_week", "day"]}
                  drilldownView="work_week"
                  defaultView="work_week"
                  min={new Date(2020, 1, 0, 7, 0, 0)}
                  max={new Date(2020, 1, 0, 17, 30, 0)}
                  components={{
                      //toolbar: CustomToolbar,
                      work_week: {
                          header: weekDayHeader
                      }
                  }}
                  step={30}
                  onSelectEvent={event => selectedEvent(event)}
                  eventPropGetter={eventColors}
              />
          </CardBody>
        </Card>

        </GridItem>

        <GridItem xs={6}>
          <Card>
            <CardBody>
              <Chart options = {props.commitmentChart.options} series = {props.commitmentChartSeries} type="pie" width={380} />
            </CardBody>
          </Card>
        </GridItem>
      
        {
          //* Start of commitment tracking table 
        }
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon> 
              <CardIcon color="primary">
                <Explore />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{translate("routes_commitment_label")}</h4>
            </CardHeader>
            <CardBody>
            {longitudArray  < 2 ?
            <GridContainer justify="center">
              <Grid>
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
            </GridContainer>
            :
            <ReactTable
            data={dataTableInfo}
            filterable
            defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
            columns={[
              {
                Header: translate("table_header_date"),
                accessor: "createdAt",
                sortable: false,
                filterable: false
              },
              {
                Header: translate("table_header_responsable"),
                accessor: "assignedTo"
              },
              {
                Header: translate("table_header_commitment"),
                accessor: "commitment",
                sortable: false,
                filterable: false
              },
              {
                Header: translate("table_header_when"),
                accessor: "when",
                sortable: false,
                filterable: false
              },
              {
                Header: translate("table_header_duration"),
                accessor: "duration",
                sortable: false,
                filterable: false
              },
              {
                Header: translate("table_header_status"),
                accessor: "status",
                getProps: (state, rowInfo) => {
                  return {
                    style: {
                      color: rowInfo.row._original.color
                    },
                  };
                }
              },
              {
                Header: translate("table_header_clear"),
                accessor: "hasDependency"
              },
              {
                Header: translate("table_header_actions"),
                accessor: "actions",
                sortable: false,
                filterable: false
              }
            ]}
            defaultPageSize={10}
            showPaginationTop
            minRows={0}
            showPaginationBottom={false}
            className="-striped -highlight"
            previousText={translate("table_previous_text")}
            nextText={translate("table_next_text")}
            loadingText={translate("table_loading_text")}
            noDataText={translate("table_not_found_text")}
            pageText={translate("table_page_text")}
            ofText={translate("table_of_text")}
            rowsText={translate("table_rows_text")}
          />
          }
              
            </CardBody>
          </Card>
          <Snackbar
            place="br"
            color="success"
            icon={AddAlert}
            message={translate("alert_commitment_save_success")}
            open={props.createSuccess}
            closeNotification={() => props.changeCreateSuccess(false)}
            close
          />
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.createError}
            closeNotification={() => props.changeCreateError(false)}
            close
          />

        </GridItem>
      </GridContainer>
    </>
  );
}