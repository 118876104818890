import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { teamAccountabilitySchema } from "models/teamModel";
import { addDays } from "utils/functions"
import { getTeamById, updateTeamAccountability, getAccountabilitySchedule, getUsersByTeam } from "services/apiAdmin/teamApi";
// questions Api
import { getAllQuestions, getAccountabilityRecordById, getAccountabilityParticipantsByRecordId } from "services/apiAdmin/accountabilityApi";
import { setTeamMembersList } from "store/AdminState";

import { verifyPermission } from "utils/functions";

import AccountabilityView from "./recordView";

const colorsArray = [
	"default",
	"green",
	"red",
	"azure",
	"orange",
	"rose",
]

const newTeam = {
	title: "",
	parentTeamId: "",
	day: "",
	time: "7:00:00"
}

const gradesData = {
	'acGrade_0': [],
	'acGrade_1': [],
	'acGrade_2': [],
	'acGrade_3': [],
	'acGrade_4': [],
	'acGrade_5': [],
	'acGrade_6': [],
	'acGrade_7': [],
	'acGrade_8': []
}

const gradesState = {
	'acGrade_0': "success",
	'acGrade_1': "success",
	'acGrade_2': "success",
	'acGrade_3': "success",
	'acGrade_4': "success",
	'acGrade_5': "success",
	'acGrade_6': "success",
	'acGrade_7': "success",
	'acGrade_8': "success"
}

const participantsData = [];

const newTeamState = {
	title: "",
	parentTeamId: "",
	day: "",
	time: ""
}

let _isMounted = false

async function onSubmit(props) {
	let result = await updateTeamAccountability(props.teamId, props.team)
	// Show confirmation message
	if (result && result.success && _isMounted) {
		props.setCreateSuccess(true)
		setTimeout(function () {
			if (_isMounted) {
				props.setCreateSuccess(false)
				//props.history.push('/admin/dashboard')
			}
		}, 1500);
	} else {
		// Show error message
		if (result) {
			let message = result.message
			if (!message) {
				message = "Algo ocurrió mal, inténtalo nuevamente."
			}
			props.setValidationMessage(message)
			props.setCreateError(true)
		}
	}
}

async function getTeam(props, teamId) {
	let result = await getTeamById(teamId)

	if (result.success) {
		if (_isMounted) {
			props.setTeam(result.data)
		}

	}
}

async function getTeamUsers(props, teamId) {
	if (!teamId) {
		return
	}
	let result = await getUsersByTeam(teamId);
	if (_isMounted && result.success) {
		props.setTeamMembersList(result.data)
	}
}

let questionsList;

async function getQuestions() {

	let result = await getAllQuestions();
	if (_isMounted && result.success) {
		questionsList = result.data;
	}
}

let accountabilityRecord;

async function getAccountabilityRecord(id) {

	let result = await getAccountabilityRecordById(id);
	if (_isMounted && result.success) {
		accountabilityRecord = result.data;
	}
}

let accountabilityParticipants;

async function getAccountabilityParticipants(id) {

	let result = await getAccountabilityParticipantsByRecordId(id);
	if (_isMounted && result.success) {
		accountabilityParticipants = result.data;
	}
}

let recordId;


async function getSchedule(props, filter) {
	let result = await getAccountabilitySchedule(filter)

	if (result.success) {
		if (_isMounted) {
			const today = new Date();
			const y = today.getFullYear()
			const m = today.getMonth() + 1
			const wd = today.getDay()

			let events = result.data.map((team) => {
				let eventDate = addDays(today, team.day - wd)
				eventDate = new Date(`${y}-${m}-${String(eventDate.getDate()).padStart(2, '0')} ${team.time}`)
				return {
					teamId: team.teamId,
					title: team.title,
					allDay: false,
					start: eventDate,
					end: new Date(eventDate.getTime() + 30 * 60000),
					color: colorsArray[Math.floor(Math.random() * 6)]
				}
			})
			props.setEvents(events)
		}

	}
}


export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
			teamId: state.admin.teamId,
			roleId: state.admin.roleId,
			team: state.admin.team,
			membersList: state.admin.membersList,
			questionsList: questionsList,
			accountabilityRecord: accountabilityRecord,
			accountabilityParticipants: accountabilityParticipants,
			recordId: recordId
		}),
		{ setTeamMembersList }
	),
	withState("team", "setTeam", { ...newTeam }),
	withState("teamState", "setTeamState", { ...newTeamState }),
	withState("events", "setEvents", []),
	withState("viewAllTeams", "setViewAllTeams", true),
	withState("createSuccess", "setCreateSuccess", false),
	withState("createError", "setCreateError", false),
	withState("validationMessage", "setValidationMessage", ""),
	withState("isLoadingSave", "setIsLoadingSave", false),
	withState("grades", "changeGrades", { ...gradesData }),
	withState("gradesState", "changeGradesState", { ...gradesState }),
	withState("participants", "changeParticipants", participantsData),
	withHandlers({
		onFieldChange: (props) => (field, value) => {
			props.team[field] = value
			let isValid = validateProperty(teamAccountabilitySchema, props.team, field).isValid
			if (isValid) {
				props.teamState[field] = "success"
			} else {
				props.teamState[field] = "error"
			}
			props.setTeamState(props.teamState)
			props.setTeam(props.team)
		},
		onParticipantsChange: (props) => (value) => {
			props.participants.length = 0;

			for (let i = 0, l = value.length; i < l; i += 1) {
				props.participants[i] = value[i];
			};

			props.changeParticipants(props.participants);
		},
		onGradeChange: (props) => (field, value) => {
			props.grades[field][0] = value;
			props.changeGrades(props.grades);
		},
		onNotesChange: (props) => (field, value) => {
			props.grades[field][1] = value;
			if (field === `acGrade_8`) {
				props.grades[field][0] = 'N/A';
			}
			if (value.length < 1) {
				// un comment this if notes are required. 
				// props.gradesState[field] = 'error';
				props.gradesState[field] = 'success';
			} else {
				props.gradesState[field] = 'success';
			}
			props.changeGrades(props.grades);
			props.changeGradesState(props.gradesState);
		},
		onFilterChange: (props) => async (field, value) => {
			props.setViewAllTeams(value)
			getSchedule(props, value)
		},
		onSave: (props) => async () => {
			props.setIsLoadingSave(true)
			let validation = validateObject(teamAccountabilitySchema, props.team)
			if (validation.isValid) {
				await onSubmit(props)
			} else {
				let field
				for (field in props.team) {
					if (validation.errors[field]) {
						props.teamState[field] = "error"
					}
				}
				props.setTeamState(props.teamState)
			}
			props.setIsLoadingSave(false)

			if (verifyPermission(props.roleId, props.team.dataRoleId, "calendar", "read.all")) {
				getSchedule(props, props.viewAllTeams)
			}
		},
		goBack: (props) => async (field, value) => {
			window.location = `/admin/accountabilityrecords/team/${props.teamId}`;
		}

	}),
	lifecycle({
		componentWillMount() {
			getQuestions();
			let recordId = this.props.match.params.id;
			// getRecord(recordId);
			getAccountabilityRecord(recordId);
			getAccountabilityParticipants(recordId);
		},
		componentDidMount() {
			_isMounted = true;

			getTeam(this.props, this.props.teamId)
			getTeamUsers(this.props, this.props.teamId)


			if (verifyPermission(this.props.roleId, this.props.team.dataRoleId, "calendar", "read.all")) {
				getSchedule(this.props, true)
			}

			this.props.setTeamState({ ...newTeamState })
			this.props.setCreateSuccess(false)
			this.props.setViewAllTeams(true)
		},
		UNSAFE_componentWillUpdate(nextState) {
			// Check team changes
			if (nextState.teamId !== this.props.teamId) {
				getTeam(this.props, nextState.teamId)
				getTeamUsers(this.props, this.props.teamId)
			}
		},
		componentWillUnmount() {
			_isMounted = false
		}
	})
)(AccountabilityView);
