import {
    container,
    primaryColor
  } from "assets/jss/material-dashboard-pro-react.js";
  
  const loaderStyle = theme => ({
    wrapper: {
      height: "auto",
      minHeight: "100vh",
      position: "relative",
      top: "0"
    },
    fullPage: {
      position: "relative",
      minHeight: "100vh",
      display: "flex!important",
      margin: "0",
      border: "0",
      color: primaryColor[0],
      alignItems: "center",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: "100%"
    },
    container: {
      ...container,
      zIndex: "4",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "100px"
      }
    },
    progressContainer: {
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 0,
          left: 0
    }
  });
  
  export default loaderStyle;
  