import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { CircularProgress, Grid, MenuItem, Select } from "@material-ui/core";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';

// material-ui icons
import TrendingUp from "@material-ui/icons/TrendingUp";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import TrackChanges from "@material-ui/icons/TrackChanges";
import Add from "@material-ui/icons/PlaylistAdd";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Flag from "@material-ui/icons/Flag";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import TrackingDashboard from "components/TrackingDashboard/TrackingDashboard.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";

import styles from "assets/jss/material-dashboard-pro-react/views/WIG/detailsStyle.js";

//functions
// import { verifyPermission } from "utils/functions";
import { formatPredictiveName, formatWIGName } from "utils/dataTypeFormatter.js";
import { translate, getUserLocale } from "utils/translate";
import { roseCardHeader } from "assets/jss/material-dashboard-pro-react";

import { getWeeksNumberByYear, getDaysOfTheWeek, verifyPermission, getMonthOfYear, getMonthsByYear } from "utils/functions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";


const useStyles = makeStyles(styles);

export default function DetailsView(props) {

  let cont = 0

  let arrayConsistency = []

  const [longitudArray, setLongitudArray] = useState(0);

  const getData = (valor) => {
    if (longitudArray == 2) {
      props.setPredictiveCulminada(true)
      let nuevoValor = valor + 0;
      setLongitudArray(nuevoValor);
    } else {
      let nuevoValor = valor + longitudArray;
      setLongitudArray(nuevoValor);
    }
  };

  useEffect(() => {
    getData(1);
  }, [props.axesDataset.axes]);

  const classes = useStyles();

  function getButtons(team, roleId, type) {
    let buttons = []

    try {
      let show = false
      if (type === "predictive") {
        if (props.ownedTeams.includes(props.team.teamId) || (roleId === 1 || roleId === 2)) {
          buttons.push({ color: "success", icon: TrackChanges, text: translate("const_track"), href: `/admin/${type}/tracking/:id` })
          if (props.Allowedition || (roleId === 1 || roleId === 2)) {
            buttons.push({ color: "primary", icon: Edit, text: translate("edit"), href: `/admin/${type}/edit/:id` },
              { color: "danger", icon: Close, text: translate("delete"), onClick: warningWithConfirmMessage })
          }
        }

      } else {

        if (props.ownedTeams.includes(props.wig.teamId) || (roleId === 1 || roleId === 2)) {
          show = true;
        }

        if (verifyPermission(roleId, team.dataRoleId, type, "track.all") && show === true) {
          buttons.push({ color: "success", icon: TrackChanges, text: translate("const_track"), href: `/admin/${type}/tracking/:id` })
        }

        if (verifyPermission(roleId, team.dataRoleId, type, "read.write.all") && show === true) {
          if (props.Allowedition || (roleId === 1 || roleId === 2)) {
            buttons.push({ color: "primary", icon: Edit, text: translate("edit"), href: `/admin/${type}/edit/:id` },
              { color: "danger", icon: Close, text: translate("delete"), onClick: warningWithConfirmMessage })
          }

        }
      }

      /*
      if (verifyPermission(roleId, team.dataRoleId, type, "track.all") && show === true) {
        buttons.push({ color: "success", icon: TrackChanges, text: "Rastrear", href: `/admin/${type}/tracking/:id` })
      }

      if (verifyPermission(roleId, team.dataRoleId, type, "read.write.all") && show === true) {
        buttons.push({ color: "primary", icon: Edit, text: "Editar", href: `/admin/${type}/edit/:id` },
          { color: "danger", icon: Close, text: "Eliminar", onClick: warningWithConfirmMessage })
      }
      */
    } catch (err) {

    }

    return buttons
  }


  function tableButtoms(id, type) {

    if (props.general)
      return (null);
    let buttons = getButtons(props.team, props.roleId, type)
    let result = buttons.map((prop, key) => {
      return (
        (prop.href !== undefined ?
          <>
            <NavLink
              to={`${prop.href.replace(":id", id)}`}
              key={key}
            >
              {renderButtom(prop, key)}
            </NavLink>
          </>

          :
          renderButtom(prop, key, id, type)
        )
      );
    });
    return (
      <>

        {/* {
          type === "predictive" ?
            <GridItem xs={24} sm={24} md={12}>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  {translate("filterMonths")}
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={props.month}
                  onChange={e => props.onFieldChange("month", e)}
                  inputProps={{
                    name: "selectMonth",
                    id: "selectMonth"
                  }}
                >
                  { }
                  {getMonthOfYear().map((value, index) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                      value={value}
                      key={index}
                    >
                      
                      {value}
                      {console.log(value)}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </GridItem>
            : null
        } */}

        {result.length ? <Table
          tableHead={[
            translate("table_header_actions")
          ]}
          tableData={[[result]]}
          customCellClasses={[classes.center]}
          customClassesForCells={[0]}
          customHeadCellClasses={[
            classes.center
          ]}
          customHeadClassesForCells={[0]}
        /> :
          null}
        <br />
      </>
    )
  }

  const renderButtom = (prop, key, id, type) => {
    return <Tooltip title={prop.text} key={key}>
      <Button
        color={prop.color}
        simple
        className={classes.actionButton}
        key={key}
        onClick={() => { if (prop.onClick) { prop.onClick(id, type) } }}
      >
        <prop.icon className={classes.icon} />
      </Button>
    </Tooltip>
  }

  const getPredictiveName = (id) => {
    let predictive = props.predictiveList.find(function (predictive) {
      return predictive.id === id
    })
    return formatPredictiveName(predictive)
  }

  const warningWithConfirmMessage = (id, type) => {
    props.changeAlertId(id)
    props.changeAlertObjectType(type)
    props.changeAlertType("delete")
    props.changeAlertVisible(true)

  }

  const hideAlert = () => {
    props.changeAlertVisible(false)
  }

  const alert = () => {
    let alertMessage = (null)
    if (props.alertVisible) {
      switch (props.alertType) {
        case "delete":
          alertMessage = (
            <SweetAlert
              warning
              style={{ display: "block" }}
              title={props.alertObjectType === "wig" ? translate("alert_wig_delete_title") : translate("alert_predictive_delete_title")}
              onConfirm={() => props.handleDeleteConfirm(props.alertId, props.alertObjectType)}
              onCancel={() => hideAlert()}
              confirmBtnCssClass={(props.alertLoading ? classes.disabled : "") + " " + classes.button + " " + classes.success}
              cancelBtnCssClass={classes.button + " " + classes.danger}
              confirmBtnText={translate("alert_delete_confirm")}
              cancelBtnText={translate("alert_delete_cancel")}
              showCancel
              disabled={props.alertLoading}
            >
              {
                props.alertObjectType === "wig" ? formatWIGName(props.wig, getUserLocale()) : getPredictiveName(props.alertId)}
              {props.alertLoading ?
                <GridContainer justify="center">
                  <Grid>
                    <div className={classes.circularProgress}>
                      <CircularProgress color="inherit" />
                    </div>
                  </Grid>
                </GridContainer>
                :
                null
              }
            </SweetAlert>
          )
          break;
        case "deleteConfirmation":
          alertMessage = (
            <SweetAlert
              success
              style={{ display: "block" }}
              title="¡Eliminada!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnCssClass={classes.button + " " + classes.success}
            >
              {translate("alert_predictive_delete_success")}
            </SweetAlert>
          )
          break;
        default:
          break;
      }
    }
    return (alertMessage)
  }

  let breadcrumbViews = [
    {
      name: translate("routes_home_label"),
      url: "/admin/dashboard",
      icon: DashboardIcon
    },
    {
      name: translate("wig"),
      url: `/admin/wig/details/${props.wigId}`,
      icon: Flag
    }
  ]



  return (
    <>
      {props.predictiveCulminada ?
        <GridContainer className={classes.progressContainer}>
          <div className={classes.circularProgress}>
            <CircularProgress color="inherit" />
          </div>
        </GridContainer>
        :
        <>
          <Breadcrumbs views={breadcrumbViews} />
          {alert()}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TrackingDashboard title={props.axesDataset.title} name={props.axesDataset.name} icon={Flag}
                description={tableButtoms(props.wigId, "wig")} axes={props.axesDataset.axes} showDetails={true} showDetailsBtn={false} type={"wig"} changeBarColor
                showCumulativeGraph={props.showCumulativeGraph} showAverageGraph={props.showAverageGraph} wig={props.wig}
                changeShowCumulativeGraph={props.changeShowCumulativeGraph} changeShowAverageGraph={props.changeShowAverageGraph} />
            </GridItem>
            {(props.general) ? null :
              <GridItem xs={12}>
                <GridItem xs={12}>
                  <GridContainer justify="flex-end">
                    {(verifyPermission(props.roleId, props.team.dataRoleId, "predictive", "read.write.all"))
                      && props.predictiveList.length < 100 &&
                      ((props.wigparent == true && props.predictivesLead == true)
                        || (props.wigparent == false && props.predictivesTeam == true)
                        || (props.predictivesLead == false && props.predictivesTeam == false)
                        || (props.wigparent == true && props.predictivesTeam == false)) ?
                      <NavLink
                        to={`/admin/predictive/create/${props.wigId}`}
                      >
                        <Tooltip title={translate("predictive_create_tooltip")}>
                          <Button color="primary">
                            <Add className={classes.icons} /> {translate("predictive")}
                          </Button>
                        </Tooltip>
                      </NavLink>
                      :
                      null
                    }

                  </GridContainer>
                </GridItem>
              </GridItem>
            }

            {
              props.predictiveDataset.map((predictive, key) => {
                return (
                  <GridItem xs={12} sm={12} md={12} key={key}>
                    <TrackingDashboard title={predictive.title} name={predictive.name} icon={TrendingUp} type={"predictive"} changeBarColor
                      typePredictive={props.predictiveList[key]} description={tableButtoms(predictive.id, "predictive")} axes={predictive.axes} showDetails={true} showDetailsBtn={false} showInRow={true}
                      consistency={predictive.axes.length == 1 ? props.predictiveList[key].consistency : arrayConsistency} axesNumber={predictive.axes.length} />
                  </GridItem>
                )
              })

            }


          </GridContainer>
        </>
      }

    </>
  );
}
