import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";

import { getHistoryLogsByTeam } from "services/apiAdmin/historyApi";

import HistoryView from "./HistoryView"

let _isMounted = false

async function getHistoryLogs(page) {
	let params = {
		page: page
	}

	let result = await getHistoryLogsByTeam(params)

	if (_isMounted && result.success) {	
		return result
	}
}


export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{}
	),
	withState("historyLogs", "setHistoryLogs", []),
	withState("page", "setPage", 0),
	withHandlers({
		onLastPageReached: () => async (page) => {
			console.log("Page sending", page);
			if (_isMounted) {
				let result = await getHistoryLogs(page)
				return result.data
			}
			return []
		},
	}),
	lifecycle({
		componentDidMount() {
			_isMounted = true
			//getHistoryLogs(this.props)
		}
	})
)(HistoryView);
