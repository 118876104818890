import { combineReducers } from "redux";

import admin from "./AdminState";
import login from "./LoginState";

const appReducer = combineReducers({
	admin,
	login
});

export default (state, action) => {
	if (action.type === 'Login/SIGN_OUT_SUCCESS') {
		 // for all keys defined in your persistConfig(s)
		 localStorage.removeItem('persist:root')
 
		 state = undefined;
	}

	return appReducer(state, action)
}
