import { compose, withState, lifecycle } from "recompose";
import { connect } from "react-redux";
import { getExternalCommitments } from "services/apiAdmin/commitmentApi";

import ExternalView from "./ExternalView"

let _isMounted = false

async function getCommitments(props) {
	let result = await getExternalCommitments()
	if (_isMounted && result.success) {
		props.setCommitmentList(result.data)
	}
}


export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{  }
	),
	withState("commitmentList", "setCommitmentList", []),
	lifecycle({
		componentDidMount() {
			_isMounted = true
			getCommitments(this.props)
		}
	})
)(ExternalView);
