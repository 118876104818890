import { compose, lifecycle, withState, withHandlers } from "recompose";
import { connect } from "react-redux";
import { signOut } from "store/LoginState";

import SidebarView from "./SidebarView";

import { deleteUserFromTeamById, getUsersByTeam } from "services/apiAdmin/teamApi";
import { setTeamMembersList } from "store/AdminState";

let _isMounted = false
async function deleteUserFromTeam(props, id) {
	props.changeAlertLoading(true)

	let result = await deleteUserFromTeamById(props.teamId, id)

	if (result.success) { //result.success		
		props.changeDeleteSuccess(true)
		await getUsersDelete(props)
		setTimeout(function () {
			props.changeDeleteSuccess(false)
		}, 500);
	} else {
		// Show error message
		if (result) {
			let message = result.message
			if (!message) {
				message = "Algo ocurrió mal, inténtalo nuevamente."
			}
			props.changeValidationMessage(message)
			props.changeDeleteError(true)
		}
	}

	props.changeAlertLoading(false)
	props.changeAlertVisible(false)
}

async function getUsersDelete(props){
	let result = await getUsersByTeam(props.teamId)
	if(result.success){
		props.setTeamMembersList(result.data)
	}
}

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
			membersList: state.admin.membersList
		}),
		{ signOut, setTeamMembersList }
	),
	withState("validationMessage", "changeValidationMessage", ""),
	withState("deleteSuccess", "changeDeleteSuccess", false),
	withState("deleteError", "changeDeleteError", false),
	withState("alertVisible", "changeAlertVisible", false),
	withState("alertLoading", "changeAlertLoading", false),
	withState("alertId", "changeAlertId", undefined),
	withHandlers({
		onModalClosed: (props) => () => {
			props.changeAlertLoading(false)
			props.changeAlertVisible(false)
		},
		onUserDelete: (props) => (id) => {
			deleteUserFromTeam(props, id)
			props.changeAlertVisible(false)
			//window.location.href = `/admin/dashboard`;
		},
	}),
	lifecycle({
		async componentDidMount() {
			_isMounted = true
			await getUsersDelete(this.props)
		},
		componentWillUnmount() {
			_isMounted = false
		}
	})
)(SidebarView);
