const userCreate = {
    firstName: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 64
        },
        displayName: "Nombre"
    },
    lastName: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 64
        },
        displayName: "Apellido"
    },
    password: {
        type: "string",
        required: true,
        isPassword: true,
        length: {
            min: 6,
            max: 256
        },
        displayName: "Contraseña"
    },
    email: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 250
        },
        isEmail: true,
        displayName: "Email"
    },
    roleId: {
        type: "integer",
        required: true,
        max: 4,
        min: 1,
        displayName: "Role"
    },
    employeeCode: {
        type: "integer",
        required: false,
        length: {
            min: 5,
            max: 10
        },
        min: 0,
        max: 9999999999,
        displayName: "EmployeeCode"
    }
}

const userUpdate = {
    firstName: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 64
        },
        displayName: "Nombre"
    },
    lastName: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 64
        },
        displayName: "Apellido"
    },
    password: {
        type: "string",
        required: false,
        isPassword: true,
        length: {
            min: 6,
            max: 256
        },
        displayName: "Contraseña"
    },
    roleId: {
        type: "integer",
        required: true,
        max: 4,
        min: 1,
        displayName: "Role"
    },
    employeeCode: {
        type: "integer",
        required: false,
        max: 999999,
        min: 1,
        displayName: "EmployeeCode"
    }
}

export {
    userCreate,
    userUpdate
};
