import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator"
import { wigTrackingSchema } from "models/wigModel"
import { getWIGById, createWIGTracking } from "services/apiAdmin/wigApi"
import { getTrackingListByAxisId } from "services/apiAdmin/trackingApi"
import { getUserLocale } from "utils/translate";
import TrackingView from "./TrackingView";
import { Months, MonthsE } from "components/Constants";
import moment from "moment";


let wigId
let _isMounted = false

const emptyDataTable = { "header": ["loading"], "result": [] }
const newTracking = {
	goalAchived1: "",
	commentary1: "",
	goalAchived2: "",
	commentary2: "",
	month: new Date().getMonth() + 1
}

const newTrackingState = {
	goalAchived1: "",
	commentary1: "",
	goalAchived2: "",
	commentary2: "",
	month: ""
}

const staticProps = [
	"month", "dataTypeId1", "dataTypeId2", "axesNumber"
]

async function onSubmit(props) {
	// Clear extra args
	if (props.tracking.axesNumber !== 2) {
		props.tracking.goalAchived2 = null
	}

	let result = await createWIGTracking(props.tracking, props.wigId)
	// Show confirmation message
	if (result && result.success) {
		let property
		// Clear state
		for (property in props.tracking) {
			if (!staticProps.includes(property)) {
				props.tracking[property] = ""
			}
		}
		props.changeTracking(props.tracking)

		for (property in props.trackingState) {
			props.trackingState[property] = ""
		}
		props.changeTrackingState(props.trackingState)

		props.changeCreateSuccess(true)
		setTimeout(function () {
			props.changeCreateSuccess(false)
		}, 5000);

		getTrackingTables(props, props.wig)
	} else {
		// Show error message
		if (result) {
			let message = result.message
			if (!message) {
				message = "Algo ocurrió mal, inténtalo nuevamente."
			}
			props.changeValidationMessage(message)
			props.changeCreateError(true)
		}
	}
}

async function getWIG(props) {
	let result = await getWIGById(wigId, 0)
	if (result.success) {
		if (_isMounted) {
			result.data.axesNumber = (result.data.x2 === null ? 1 : 2)
			newTracking.axesNumber = result.data.axesNumber
			props.changeTracking(newTracking)
			props.changeWig(result.data)
			if(result.data.year >= 2024 && result.data.startDate && result.data.startDate != null) {
				let meses = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'Octuber', 'November', 'December']
				moment.locale('en');
				let fechaIniciald = moment.utc(result.data.startDate).format('MMMM');
				let coincidenciaInitial = meses.indexOf(fechaIniciald);
				let nuevoObjecto = {
					...props.tracking,
					month: coincidenciaInitial + 1
				}
				props.changeTracking(nuevoObjecto)
			}

			getTrackingTables(props, result.data)
		}

	}
}

async function getTrackingTables(props, wig) {
	let index
	for (index of [1, 2]) {
		if (wig[`axisId${index}`] !== null) {
			let trackingResult = await getTrackingListByAxisId(wig[`axisId${index}`])
			if (trackingResult.success) {
				props[`changeTrackingList${index}`](trackingResult.data)
			}
		}
	}
}

async function getMonths(props) {
	let aux_months = []
	if (getUserLocale() === "en-US") {

		for (let i = 1; i < 13; i++) {
			aux_months.push(MonthsE[i])
		}

	} else {
		for (let i = 1; i < 13; i++) {
			aux_months.push(Months[i])
		}
	}
	props.setMonthsList(aux_months)
}
export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{}
	),
	withState("wigId", "changeWIGId", undefined),
	withState("wig", "changeWig", undefined),
	withState("tracking", "changeTracking", newTracking),
	withState("trackingState", "changeTrackingState", { ...newTrackingState }),
	withState("trackingList1", "changeTrackingList1", emptyDataTable),
	withState("trackingList2", "changeTrackingList2", emptyDataTable),
	withState("createSuccess", "changeCreateSuccess", false),
	withState("createError", "changeCreateError", false),
	withState("validationMessage", "changeValidationMessage", ""),
	withState("isLoadingSave", "changeIsLoadingSave", false),
	withState("monthsList", "setMonthsList", []),
	withHandlers({
		onFieldChange: (props) => (field, event) => {
			if (field == "month") {
				props.tracking[field] = event.target.value + 1
			} else {
				props.tracking[field] = event.target.value
			}

			let isValid = validateProperty(wigTrackingSchema, props.tracking, field).isValid
			if (isValid) {
				props.trackingState[field] = "success"
			} else {
				props.trackingState[field] = "error"
			}
			props.changeTrackingState(props.trackingState)
			props.changeTracking(props.tracking)
		},
		onTrackingSave: (props) => async () => {
			props.changeIsLoadingSave(true)

			let validation = validateObject(wigTrackingSchema, props.tracking)
			if (validation.isValid) {
				await onSubmit(props)
			} else {
				let field
				for (field in props.tracking) {
					if (validation.errors[field]) {
						props.trackingState[field] = "error"
					}
				}
				props.changeTrackingState(props.trackingState)
			}
			props.changeIsLoadingSave(false)
		}
	}),
	lifecycle({
		componentDidMount() {
			wigId = this.props.match.params.id
			this.props.changeWIGId(wigId)
			_isMounted = true
			//getPredictiveList(this.props)
			getWIG(this.props)
			newTracking.goalAchived1 = ""
			newTracking.commentary1 = ""
			newTracking.goalAchived2 = ""
			newTracking.commentary2 = ""
			this.props.changeTracking({ ...newTracking })
			this.props.changeTrackingState({ ...newTrackingState })
			getMonths(this.props)
		}
	})
)(TrackingView);
