import MainDashboard from "views/MainDashboard";

import DetailsWIG from "views/WIG/Details";
import CreateWIG from "views/WIG/Create";
import EditWIG from "views/WIG/Edit";
import TrackingWIG from "views/WIG/Tracking";

import DetailsPredictive from "views/Predictive/Details";
import CreatePredictive from "views/Predictive/Create";
import EditPredictive from "views/Predictive/Edit";
import TrackignPredictive from "views/Predictive/Tracking";

import IndexUser from "views/User/Index";
import CreateUser from "views/User/Create";
import EditUser from "views/User/Edit";
import DownloadUserReport from "views/User/Download";

import IndexTeam from "views/Team/Index";
import CreateTeam from "views/Team/Create";
import EditTeam from "views/Team/Edit";
import AddMember from "views/Team/AddMember";
import Accountability from "views/Accountability";
import AccountabilityRecord from "views/Accountability/RecordCreate";
import AccountabilityMinute from "views/Accountability/Minute"
import AccountabilityRecordsList from "views/Accountability/RecordsList";
import MinuteList from "views/Accountability/MinuteList";
import AccountabilityRecordView from "views/Accountability/RecordView";
import AccountabilityRecordLegacyView from "views/Accountability/RecordViewLegacy";
import ExternalCommitment from "views/Commitment/External";
import InternalCommitment from "views/Commitment/Internal";
import Tracking from "views/Commitment/Tracking";
import CommitmentMinute from "views/Accountability/MinuteCommitment";
import HistoryDashboard from 'views/HistoryDashboard';
import Assign from 'views/Assign';
// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Flag from "@material-ui/icons/Flag";
import Calendar from "@material-ui/icons/CalendarToday";
import Explore from "@material-ui/icons/Explore";
import Person from "@material-ui/icons/Person";
import Group from "@material-ui/icons/Group";
import GroupAdd from "@material-ui/icons/GroupAdd";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import ScheduleIcon from '@material-ui/icons/Schedule';
import HistoryIcon from '@material-ui/icons/History'

//functions
import { verifyPermission } from "utils/functions";
import { translate } from "utils/translate"

function getChildWIGMenu(wigList, roleId, allowedTeams, isParentTeam){
	let yearMenu = []
	let currentYear = new Date().getFullYear()
	let counter = 0;
	//console.log(wigList)
	if (wigList.length>0){
		//Iterate for five years and get wig list from them
		for (let iterateYear = currentYear + 1; iterateYear > currentYear - 4; iterateYear--){
			//console.log(counter)
			if (wigList[counter] && wigList[counter].length>0){
				let wigDetailList = wigList[counter]
				//Verify is a owner team or child team				
				if (wigDetailList.length>0 && !isParentTeam)
					wigDetailList = wigDetailList.filter(wig => !wig.isParentTeam)
				//Verify is a parent team
				if (wigDetailList.length>0 && isParentTeam)
					wigDetailList = wigDetailList.filter(wig => wig.isParentTeam)
				if (wigDetailList.length > 0){
					let detailMenu2 = wigDetailList.map(function (wig, index) {
						return {
							path: `/wig/details/${wig.id}`,
							lookup: [`/wig/details/${wig.id}`, `/wig/edit/${wig.id}`, `/wig/tracking/${wig.id}`],
							name: wig.name,
							mini: `${index + 1}`,
							layout: "/admin"
						}
					})
					let stateName = isParentTeam ? "predictiveYearCollapseParent" : "predictiveYearCollapse"
					yearMenu.push(
						{
							name: iterateYear.toString(),
							lookup: ["wigYear"],
							icon: Calendar,
							collapse: true,
							state: stateName + iterateYear.toString(),
							layout: "/admin",
							views: detailMenu2
						}
					)
				}
			}
			counter++
		}
	}
	return yearMenu
}

function getWIGMenu(wigList, roleId, allowedTeams, isParentTeam) {
	let wigMenu = []
	let yearMenu = getChildWIGMenu(wigList, roleId, allowedTeams, isParentTeam)
	let lookupName = isParentTeam ? "wigParent" : "wig"
	let stateName = isParentTeam ? "predictiveCollapseParent" : "predictiveCollapse"
	let menuName = isParentTeam ? translate("routes_wig_label_parent") : translate("routes_wig_label")
	wigMenu.push(
		{
			name: menuName,
			lookup: [lookupName],
			icon: Flag,
			collapse: true,
			state: stateName,
			layout: "/admin",
			views: yearMenu
		}
	)

	return wigMenu
}

function getAdminMenu(team, roleId) {
	let adminMenu = []
	let childMenu = []

	if (!roleId) {
		return adminMenu
	}

	if (verifyPermission(roleId, team.dataRoleId, "team", "read.all")) {
		childMenu.push({
			path: "/team/index",
			lookup: ["team/index", "team/create", "team/edit"],
			name: translate("routes_team_label"),
			icon: Group,
			component: IndexTeam,
			layout: "/admin"
		},
			{
				path: "/team/create",
				lookup: ["/team"],
				name: translate("routes_team_creation_label"),
				redirect: true,
				component: CreateTeam,
				layout: "/admin"
			},
			{
				path: "/team/edit/:id",
				lookup: ["/team"],
				name: translate("routes_team_edit_label"),
				redirect: true,
				component: EditTeam,
				layout: "/admin"
			})
	}

	if (verifyPermission(roleId, team.dataRoleId, "user", "read.write.all")) {
		childMenu.push({
			path: "/user/index",
			lookup: ["user/index", "user/create", "user/edit"],
			name: translate("routes_user_label"),
			icon: Person,
			component: IndexUser,
			layout: "/admin"
		},
			{
				path: "/user/create",
				lookup: ["/user"],
				name: translate("routes_user_creation_label"),
				redirect: true,
				component: CreateUser,
				layout: "/admin"
			},
			{
				path: "/user/edit/:id",
				lookup: ["/user"],
				name: translate("routes_user_edit_label"),
				redirect: true,
				component: EditUser,
				layout: "/admin"
			},{
				path: "/user/Download",
				lookup: ["/user"],
				name: translate("routes_user_edit_label"),
				redirect: true,
				component: DownloadUserReport,
				layout: "/admin"
			})
	}

	if (verifyPermission(roleId, team.dataRoleId, "team", "read.all")) {
		childMenu.push({
			path: "/history/index",
			lookup: ["history/index"],
			name: translate("routes_log_label"),
			icon: HistoryIcon,
			component: HistoryDashboard,
			layout: "/admin"
		})
	}

	if (childMenu.length) {
		adminMenu.push(
			{
				collapse: true,
				name: translate("routes_admin_label"),
				lookup: ["admin"],
				icon: BusinessCenter,
				state: "adminCollapse",
				views: childMenu
			}
		)
	}

	if (verifyPermission(roleId, team.dataRoleId, "teamMember", "read.write.all")) {
		adminMenu.push({
			path: "/members",
			lookup: ["/members"],
			redirect: true,
			name: translate("routes_team_add_member_label"),
			icon: GroupAdd,
			component: AddMember,
			layout: "/admin"
		})
	}

	return adminMenu
}

function getWIGAndPredictiveMenu(team, roleId) {
	let menu = []
	if (!roleId) {
		return menu
	}

	menu.push({
		path: "/wig/details/:id",
		lookup: ["/wig"],
		name: translate("routes_wig_detail_label"),
		icon: Apps,
		redirect: true,
		component: DetailsWIG,
		layout: "/admin"
	},
		{
			path: "/predictive/details/:id",
			lookup: ["/wig"],
			name: translate("routes_wig_predictive_label"),
			mini: "P",
			component: DetailsPredictive,
			layout: "/admin",
			redirect: true,
		})

	// Edit - Create
	if (verifyPermission(roleId, team.dataRoleId, "wig", "read.write.all")) {
		menu.push({
			path: "/wig/create",
			lookup: ["/wig"],
			name: translate("wig"),
			mini: "M",
			component: CreateWIG,
			layout: "/admin",
			redirect: true,
		},
			{
				path: "/predictive/create/:id",
				lookup: ["/wig"],
				name: translate("predictive"),
				mini: "P",
				component: CreatePredictive,
				layout: "/admin",
				redirect: true,
			},
			{
				path: "/wig/edit/:id",
				lookup: ["/wig"],
				name: translate("routes_wig_edit_label"),
				icon: Apps,
				redirect: true,
				component: EditWIG,
				layout: "/admin"
			},
			{
				path: "/predictive/edit/:id",
				lookup: ["/wig"],
				name: translate("routes_predictive_edit_label"),
				mini: "E",
				component: EditPredictive,
				layout: "/admin",
				redirect: true,
			})
	}

	// Tracking
	if (verifyPermission(roleId, team.dataRoleId, "wig", "track.all")) {
		menu.push({
			path: "/wig/tracking/:id",
			lookup: ["/wig"],
			name: translate("routes_wig_track_label"),
			icon: Apps,
			redirect: true,
			component: TrackingWIG,
			layout: "/admin"
		},
			{
				path: "/predictive/tracking/:id",
				lookup: ["/wig"],
				name: translate("predictives"),
				mini: "P",
				component: TrackignPredictive,
				layout: "/admin",
				redirect: true,
			})
	}

	return menu
}

function getCompassMenu(team, roleId) {
	let compassMenu = []
	let childMenu = []

	if (!roleId) {
		return compassMenu
	}

	childMenu.push({
		path: "/commitment/internal",
		lookup: ["commitment/internal"],
		name: translate("routes_commitment_label"),
		mini: translate("routes_commitment_mini"),
		//icon: GroupWork,
		component: InternalCommitment,
		layout: "/admin"
	},
		{
			path: "/commitment/external",
			lookup: ["commitment/external"],
			name: translate("routes_clear_path_label"),
			//icon: MeetingRoom,
			mini: translate("routes_clear_path_mini"),
			component: ExternalCommitment,
			layout: "/admin"
		}
	);


	childMenu.push({
		path: "/accountabilityrecords/team/:id",
		lookup: ["accountabilityrecords/team"],
		name: translate("routes_wig_sessions_label"),
		mini: translate("routes_wig_session_mini"),
		component: AccountabilityRecordsList,
		layout: "/admin"
	});

	childMenu.push({
		path: "/accountabilityminutes/team/:id",
		lookup: ["accountabilityminutes/team"],
		name: translate("routes_wig_session_minute"),
		mini: translate("routes_wig_minute_mini"),
		component: MinuteList,
		layout: "/admin"
	});
	


	

	//if (verifyPermission(roleId, team.dataRoleId, "teamMember", "read.write.all")) {
		childMenu.push({
			path: "/accountability",
			lookup: ["accountability", "accountability/team"],
			name: translate("routes_wig_session_label"),
			icon: ScheduleIcon,
			component: Accountability,
			layout: "/admin"
		},
			{
				path: "/accountabilityrecord/team/:id",
				lookup: ["accountabilityrecord/team"],
				name: translate("routes_wig_session_record"),
				redirect: true,
				component: AccountabilityRecord,
				layout: "/admin"
			},
			{
				path: "/accountabilityrecord/minute",
				lookup: ["accountabilityrecord/minute"],
				name: 'minute',
				redirect: true,
				component: AccountabilityMinute,
				layout: "/admin"
			},
			{
				path: "/accountabilityminute/team/:id",
				lookup: ["accountabilityminute/team"],
				name: translate("routes_wig_session_minute"),
				redirect: true,
				component: AccountabilityMinute,
				layout: "/admin"
			},
			{
				path: "/accountabilityrecords/team/:id",
				lookup: ["accountabilityrecords/team"],
				name: translate("routes_wig_sessions_label"),
				redirect: true,
				component: AccountabilityRecordsList,
				layout: "/admin"
			},
			{
				path: "/accountabilityrecord/record/:id",
				lookup: ["accountabilityrecord/record"],
				name: translate("routes_wig_session_record"),
				redirect: true,
				component: AccountabilityRecordView,
				layout: "/admin"
			},
			{
				path: "/accountabilityrecordlegacy/team/:id",
				lookup: ["accountabilityrecordlegacy/team"],
				name: translate("routes_wig_session_record"),
				redirect: true,
				component: AccountabilityRecordLegacyView,
				layout: "/admin"
			},
			{
				path: "/accountabilityrecord/minutecommitment/:id",
				lookup: ["accountabilityrecord/minutecommitment"],
				name: translate("routes_wig_session_record"),
				redirect: true,
				component: CommitmentMinute,
				layout: "/admin"
			},
			{
				path: "/commitment/tracking/:id",
				lookup: ["commitment/tracking"],
				name: translate("routes_wig_tracking"),
				redirect: true,
				component: Tracking,
				layout: "/admin"
			},
		

		)
	//}

	


	if (childMenu.length) {
		compassMenu.push(
			{
				lookup: ["compass"],
				name: translate("routes_weekly_compass_label"),
				icon: Explore,
				collapse: true,
				state: "compassCollapse",
				views: childMenu
			}
		)
	}

	return compassMenu
}

export default function getRoutes(props, roleId) {
	//console.log(props.teamId)
	const predictiveMenu = getWIGMenu(props.wigList, roleId, props.allowedTeams, false)
	const predictiveParentMenu = getWIGMenu(props.wigList, roleId, props.allowedTeams, true)
	const adminMenu = getAdminMenu(props.team, roleId)
	const wigMenu = getWIGAndPredictiveMenu(props.team, roleId)
	const compassMenu = getCompassMenu(props.team, roleId)

	const menuRoutes = [
		{
			path: "/dashboard",
			lookup: ["dashboard"],
			name: translate("routes_home_label"),
			icon: DashboardIcon,
			component: MainDashboard,
			layout: "/admin"
		},
		...adminMenu,
		...predictiveMenu,
		...predictiveParentMenu,
		...compassMenu,
		...wigMenu,
	]

	return menuRoutes
}

