import {
    cardTitle,
    dangerColor,
    whiteColor,
    grayColor,
    blackColor,
    hexToRgb,
    primaryColor,
    warningColor,
    successColor,
  } from "assets/jss/material-dashboard-pro-react.js";
  import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
  import buttonGroupStyle from "assets/jss/material-dashboard-pro-react/buttonGroupStyle.js";
  import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle";
  const validationFormsStyle = {
    cardTitle: {
      ...cardTitle,
      color: whiteColor
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    },
    formCategory: {
      marginBottom: "0",
      color: grayColor[0],
      fontSize: "14px",
      padding: "10px 0 10px"
    },
    center: {
      textAlign: "center"
    },
    saveCenter: {
      textAlign: "center",
      height: "55px"
    },
    justifyContentCenter: {
      justifyContent: "center"
    },
    registerButton: {
      float: "right"
    },
    label: {
      cursor: "pointer",
      paddingLeft: "0",
      color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
      fontSize: "14px",
      lineHeight: "1.428571429",
      fontWeight: "400",
      display: "inline-flex"
    },
    mrAuto: {
      marginRight: "auto"
    },
    mlAuto: {
      marginLeft: "auto"
    },
    buttonCriteria: {
      margin: "1rem 1px",
      padding: "14px 30px"
    },
    ...buttonStyle,

  };
  
  export default validationFormsStyle;
  