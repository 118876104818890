import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import jwtDecode from "jwt-decode";
import { signOut } from "store/LoginState";

import AppView from "./App";

export default compose(
	connect(state => ({
		isAuthenticated: state.login.isAuthenticated
	}),
	{ signOut }
	),
	lifecycle({
		componentDidMount() {
			let jwtToken = localStorage.getItem("access_token")
			if (jwtToken) {
				const tokenPayload = jwtDecode(jwtToken)

				const current_time = new Date().getTime() / 1000;
				if (current_time > tokenPayload.exp) {
					this.props.signOut()
				}
			}
		}
	})
)(AppView);
