import { translate } from "utils/translate"

const wigCreateSchema = {
    endDate :{
        // type: "string",
        required: true,
        eng_displayName: "End date",
        spa_displayName: "Fecha final",
    },
    startDate :{
        // type: "string",
        required: true,
        eng_displayName: "Start date",
        spa_displayName: "Fecha inicial",
    },
    verb: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 256
        },
        eng_displayName: "Verb",
        spa_displayName:  "Verbo"
    },
    what: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 256
        },
        eng_displayName: "What?",
        spa_displayName:  "Qué?"
    },
    verbTwo: {
        type: "string",
        required: false,
        length: {
            min: 1,
            max: 256
        },
        displayName: "verbTwo",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.verbTwo === "" || data.verbTwo === undefined || data.verbTwo === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The verb field is obligatory"] : ["El campo Verbo es obligatorio"] : ["The verb field is obligatory"],
        }
        ]
    },
    whatTwo: {
        type: "string",
        required: false,
        length: {
            min: 1,
            max: 256
        },
        displayName: "whatTwo",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.whatTwo === "" || data.whatTwo === undefined || data.whatTwo === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The what field is obligatory"] : ["El campo que es obligatorio"] : ["The what field is obligatory"],
        }
        ]
    },
    // verbTwo: {
    //     type: "string",
    //     required: false,
    //     length: {
    //         min: 1,
    //         max: 256
    //     },
    //     eng_displayName: "Verb Two",
    //     spa_displayName:  "Verbo dos"
    // },
    // whatTwo: {
    //     type: "string",
    //     required: false,
    //     length: {
    //         min: 1,
    //         max: 256
    //     },
    //     eng_displayName: "What two?",
    //     spa_displayName:  "Qué dos?"
    // },
    year: {
        type: "integer",
        required: true,
        eng_displayName: "Year",
        spa_displayName:  "Año"
    },
    description: {
        type: "string",
        required: false,
        length: {
            min: 0,
            max: 256
        },
        eng_displayName: "Description",
        spa_displayName:  "Descripción"
    },
    axesNumber: {
        type: "decimal",
        required: true,
        max: 2,
        min: 1,
        eng_displayName: "Axis Number",
        spa_displayName:  "Número de Ejes"
    },
    x1: {
        type: "decimal",
        required: true,
        max: 9999999999.99,
        min: -9999999999.99,
        eng_displayName: "X1",
        spa_displayName:  "X1"
    },
    y1: {
        type: "decimal",
        required: true,
        max: 9999999999.99,
        min: -9999999999.99,
        eng_displayName: "Y1",
        spa_displayName:  "Y1"
    },
    level1_1: {
        type: "decimal",
        required: true,
        max: 9999999999.99,
        min: -9999999999.99,
        eng_displayName: "Level 1 Axis 1",
        spa_displayName:  "Nivel 1 Eje 1",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.y1 >= data.x1) {
                    isValid = (data.level2_1 > data.level1_1)
                } else {
                    isValid = (data.level2_1 < data.level1_1)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 1 field must be valid for Level 2"] : ["El campo nivel 1 debe válido para la nivel 2"] : ["The Level 1 field must be valid for Level 2"],
           
        }]
    },
    level2_1: {
        type: "decimal",
        required: true,
        max: 9999999999.99,
        min: -9999999999.99,
        eng_displayName: "Level 2 Axis 1",
        spa_displayName:  "Nivel 2 Eje 1",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.y1 >= data.x1) {
                    isValid = (data.level3_1 > data.level2_1)
                } else {
                    isValid = (data.level3_1 < data.level2_1)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 2 field must be valid for Level 3"] : ["El campo nivel 2 debe válido para la nivel 3"] : ["The Level 2 field must be valid for Level 3"],
        }]
    },
    level3_1: {
        type: "decimal",
        required: true,
        max: 9999999999.99,
        min: -9999999999.99,
        eng_displayName: "Level 3 Axis 1",
        spa_displayName:  "Nivel 3 Eje 1",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.y1 >= data.x1) {
                    isValid = (data.y1 >= data.level3_1)
                } else {
                    isValid = (data.y1 <= data.level3_1)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 3 field must be valid for Y"] : ["El campo nivel 3 debe válido para la Y"] : ["The Level 3 field must be valid for Y"],
        }]
    },
    dataTypeId1: {
        type: "integer",
        required: true,
        eng_displayName: "Type Date 1",
        spa_displayName:  "Tipo de Dato 1"
    },
    displayName1: {
        type: "string",
        required: true,
        length: {
            min: 1,
            // max: 20
        },
        spa_displayName: "Nombre de la MCI",
        eng_displayName: "WIG Name"
    },
    x2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        eng_displayName: "X2",
        spa_displayName:  "X2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.x2 === "" || data.x2 === undefined || data.x2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The X field is obligatory"] : ["El campo X es obligatorio"] : ["The X field is obligatory"],
        }
        ]
    },
    y2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        eng_displayName: "Y2",
        spa_displayName:  "Y2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.y2 === "" || data.y2 === undefined || data.y2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Y field is obligatory"] : ["El campo Y es obligatorio"] : ["The Y field is obligatory"],
        }
        ]
    },
    level1_2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        eng_displayName: "Level 1 Axis 2",
        spa_displayName:  "Nivel 1 Eje 2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.level1_2 === "" || data.level1_2 === undefined || data.level1_2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 1 Axis 2 is obligatory"] : ["Nivel 1 Eje 2 es obligatorio"] : ["The Level 1 Axis 2 is obligatory"],
        },
        {
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1 && data.level1_2 !== "" && data.level1_2 !== undefined && data.level1_2 !== null) {
                    if (data.y2 >= data.x2) {
                        isValid = (data.level2_2 > data.level1_2)
                    } else {
                        isValid = (data.level2_2 < data.level1_2)
                    }
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 1 field must be valid for Level 2"] : ["El campo nivel 1 debe válido para la nivel 2"] : ["The Level 1 field must be valid for Level 2"],
        }]
    },
    level2_2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        eng_displayName: "Level 2 Axis 2",
        spa_displayName:  "Nivel 2 Eje 2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.level2_2 === "" || data.level2_2 === undefined || data.level2_2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 2 Axis 2 is obligatory"] : ["Nivel 2 Eje 2 es obligatorio"] : ["The Level 2 Axis 2 is obligatory"],
        },
        {
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1 && data.level2_2 !== "" && data.level2_2 !== undefined && data.level2_2 !== null) {
                    if (data.y2 >= data.x2) {
                        isValid = (data.level3_2 > data.level2_2)
                    } else {
                        isValid = (data.level3_2 < data.level2_2)
                    }
                }
                return isValid
            },
            message:localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 1 field must be valid for Level 2"] : ["El campo nivel 1 debe válido para la nivel 2"] : ["The Level 1 field must be valid for Level 2"],
        }]
    },
    level3_2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        eng_displayName: "Level 3 Axis 2",
        spa_displayName:  "Nivel 3 Eje 2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.level3_2 === "" || data.level3_2 === undefined || data.level3_2 === null)
                }
                return isValid
            },
         message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 3 Axis 2 is obligatory"] : ["Nivel 3 Eje 2 es obligatorio"] : ["The Level 3 Axis 2 is obligatory"],
        },
        {
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1 && data.level2_2 !== "" && data.level2_2 !== undefined && data.level2_2 !== null) {
                    if (data.y2 >= data.x2) {
                        isValid = (data.y2 >= data.level3_2)
                    } else {
                        isValid = (data.y2 <= data.level3_2)
                    }
                }
                return isValid
            },
            message:localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 1 field must be valid for Level 2"] : ["El campo nivel 1 debe válido para la nivel 2"] : ["The Level 1 field must be valid for Level 2"],
        }]
    },
    dataTypeId2: {
        type: "integer",
        required: false,
        eng_displayName: "Data Type 2",
        spa_displayName:  "Tipo de Dato 2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.dataTypeId2 === "" || data.dataTypeId2 === undefined || data.dataTypeId2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Type WIG field is obligatory"] : ["El campo tipo de MCI es obligatorio"] : ["The Type WIG field is obligatory"],
        }
        ]
    },
    displayName2: {
        type: "string",
        required: false,
        length: {
            min: 1,
            // max: 256
        },
        eng_displayName: "Axis 2 Name",
        spa_displayName:  "Nombre Eje 2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.displayName2 === "" || data.displayName2 === undefined || data.displayName2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Name Axis field is obligatory"] : ["El campo Nombre del Eje es obligatorio"] : ["The Name Axis field is obligatory"],
        }
        ]
    }
}

const wigTrackingSchema = {
    month: {
        type: "integer",
        required: true,
        max: 12,
        min: 1,
        displayName: "Mes"
    },
    axesNumber: {
        type: "integer",
        required: true,
        displayName: "Cantidad de ejes"
    },
    goalAchived1: {
        type: "decimal",
        required: true,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta alcanzada 1"
    },
    goalAchived2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta alcanzada 2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.goalAchived2 === "" || data.goalAchived2 === undefined || data.goalAchived2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Goal 2 field is obligatory"] : ["El campo Meta Alcanzada 2 es obligatorio"] : ["The Goal 2 field is obligatory"],
 
        }
        ]
    },
    commentary1: {
        type: "string",
        required: false,
        length: {
            min: 0,
            max: 256
        },
        displayName: "Comentario 1"
    },
    commentary2: {
        type: "string",
        required: false,
        length: {
            min: 0,
            max: 256
        },
        displayName: "Comentario 2"
    }
}

const wigUpdateSchema = {
    verb: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 256
        },
        displayName: "Verbo"
    },
    what: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 256
        },
        displayName: "Qué?"
    },
    description: {
        type: "string",
        required: false,
        length: {
            min: 0,
            max: 256
        },
        displayName: "Descripción"
    },
    x1: {
        type: "decimal",
        required: true,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "X1"
    },
    y1: {
        type: "decimal",
        required: true,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Y1"
    },
    level1_1: {
        type: "decimal",
        required: true,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Nivel 1 Eje 1",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.y1 >= data.x1) {
                    isValid = (data.level2_1 > data.level1_1)
                } else {
                    isValid = (data.level2_1 < data.level1_1)
                }
                return isValid
            },
            message:localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 1 field must be valid for Level 2"] : ["El campo nivel 1 debe válido para la nivel 2"] : ["The Level 1 field must be valid for Level 2"],
        }]
    },
    level2_1: {
        type: "decimal",
        required: true,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Nivel 2 Eje 1",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.y1 >= data.x1) {
                    isValid = (data.level3_1 > data.level2_1)
                } else {
                    isValid = (data.level3_1 < data.level2_1)
                }
                return isValid
            },
            message:localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 2 field must be valid for Level 3"] : ["El campo nivel 2 debe válido para la nivel 3"] : ["The Level 2 field must be valid for Level 3"],
        }]
    },
    level3_1: {
        type: "decimal",
        required: true,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Nivel 3 Eje 1",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.y1 >= data.x1) {
                    isValid = (data.y1 >= data.level3_1)
                } else {
                    isValid = (data.y1 <= data.level3_1)
                }
                return isValid
            },
            message:localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 3 field must be valid for Y"] : ["El campo nivel 3 debe válido para Y"] : ["The Level 3 field must be valid for Y"],
        }]
    },
    dataTypeId1: {
        type: "integer",
        required: true,
        displayName: "Tipo de dato 1"
    },
    displayName1: {
        type: "string",
        required: true,
        length: {
            min: 1,
            // max: 20
        },
        spa_displayName: "Nombre de la MCI",
        eng_displayName: "WIG Name"
    },
    verbTwo: {
        type: "string",
        required: false,
        length: {
            min: 1,
            max: 256
        },
        displayName: "verbTwo",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.verbTwo === "" || data.verbTwo === undefined || data.verbTwo === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The verbTwo field is obligatory"] : ["El campo Verbo es obligatorio"] : ["The verb field is obligatory"],
        }
        ]
    },
    whatTwo: {
        type: "string",
        required: false,
        length: {
            min: 1,
            max: 256
        },
        displayName: "whatTwo",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.whatTwo === "" || data.whatTwo === undefined || data.whatTwo === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The whatTwo field is obligatory"] : ["El campo que es obligatorio"] : ["The verb field is obligatory"],
        }
        ]
    },
    x2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "X2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.x2 === "" || data.x2 === undefined || data.x2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The X field is obligatory"] : ["El campo X es obligatorio"] : ["The X field is obligatory"],
        }
        ]
    },
    y2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Y2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.y2 === "" || data.y2 === undefined || data.y2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Y field is obligatory"] : ["El campo Y es obligatorio"] : ["The Y field is obligatory"],
        }
        ]
    },
    level1_2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Nivel 1 Eje 2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.level1_2 === "" || data.level1_2 === undefined || data.level1_2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 1 Axis 2 is obligatory"] : ["El Nivel 1 Eje 2 es obligatorio"] : ["The Level 1 Axis 2 is obligatory"],
           
        },
        {
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1 && data.level1_2 !== "" && data.level1_2 !== undefined && data.level1_2 !== null) {
                    if (data.y2 >= data.x2) {
                        isValid = (data.level2_2 > data.level1_2)
                    } else {
                        isValid = (data.level2_2 < data.level1_2)
                    }
                }
                return isValid
            },
            message:localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 1 field must be valid Level 2"] : ["El campo Nivel 1 debe válido para Nivel 2"] : ["The Level 1 field must be valid for Level 2"],
        }]
    },
    level2_2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Nivel 2 Eje 2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.level2_2 === "" || data.level2_2 === undefined || data.level2_2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 2 Axis 2 is obligatory"] : ["El Nivel 2 Eje 2 es obligatorio"] : ["The Level 2 Axis 2 is obligatory"],
        },
        {
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1 && data.level2_2 !== "" && data.level2_2 !== undefined && data.level2_2 !== null) {
                    if (data.y2 >= data.x2) {
                        isValid = (data.level3_2 > data.level2_2)
                    } else {
                        isValid = (data.level3_2 < data.level2_2)
                    }
                }
                return isValid
            },
            message:localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 1 field must be valid Level 2"] : ["El campo Nivel 1 debe válido para Nivel 2"] : ["The Level 1 field must be valid for Level 2"],
        }]
    },
    level3_2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Nivel 3 Eje 2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.level3_2 === "" || data.level3_2 === undefined || data.level3_2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 3 Axis 2 is obligatory"] : ["El Nivel 3 Eje 2 es obligatorio"] : ["The Level 3 Axis 2 is obligatory"],
        },
        {
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1 && data.level2_2 !== "" && data.level2_2 !== undefined && data.level2_2 !== null) {
                    if (data.y2 >= data.x2) {
                        isValid = (data.y2 >= data.level3_2)
                    } else {
                        isValid = (data.y2 <= data.level3_2)
                    }
                }
                return isValid
            },
            message:localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Level 1 field must be valid Level 2"] : ["El campo Nivel 1 debe válido para Nivel 2"] : ["The Level 1 field must be valid for Level 2"],
        }]
    },
    dataTypeId2: {
        type: "integer",
        required: false,
        displayName: "Tipo de dato 2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.dataTypeId2 === "" || data.dataTypeId2 === undefined | data.dataTypeId2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Type WIG Field is obligatory"] : ["El campo Tipo de MCI es obligatorio"] : ["The Type WIG field is obligatory"],
           
        }
        ]
    },
    displayName2: {
        type: "string",
        required: false,
        length: {
            min: 1,
            // max: 256
        },
        displayName: "Nombre eje 2",
        customRules: [{
            rule: function (data) {
                let isValid = true
                if (data.axesNumber !== 1) {
                    isValid = !(data.displayName2 === "" || data.displayName2 === undefined || data.displayName2 === null)
                }
                return isValid
            },
            message: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["The Name Axis field is obligatory"] : ["El Campo nombre del Eje es obligatorio"] : ["The Name Axis field is obligatory"],
          
        }
        ]
    }
}

const wigGoalsSchema = {
    // enero
    enero1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Enero nivel 1",
    },
    enero1_level3: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Enero nivel 1",
    },
    enero1_level2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Enero nivel 2",
    },
    enero1_level1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Enero nivel 3",
    },
    // febrero
    febrero1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Febrero",
    },
    febrero1_level3: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Febrero",
    },
    febrero1_level2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Febrero",
    },
    febrero1_level1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Febrero",
    },
    // marzo
    marzo1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Marzo",
    },
    marzo1_level3: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Marzo",
    },
    marzo1_level2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Marzo",
    },
    marzo1_level1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Marzo",
    },
    // abril
    abril1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Abril",
    },
    abril1_level3: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Abril",
    },
    abril1_level2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Abril",
    },
    abril1_level1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Abril",
    },
    // mayo
    mayo1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Mayo",
    },
    mayo1_level3: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Mayo",
    },
    mayo1_level2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Mayo",
    },
    mayo1_level1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Mayo",
    },
    // junio
    junio1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Junio",
    },
    junio1_level3: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Junio",
    },
    junio1_level2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Junio",
    },
    junio1_level1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Junio",
    },
    // julio
    julio1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Julio",
    },
    julio1_level3: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Julio",
    },
    julio1_level2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Julio",
    },
    julio1_level1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Julio",
    },
    // agosto
    agosto1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Agosto",
    },
    agosto1_level3: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Agosto",
    },
    agosto1_level2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Agosto",
    },
    agosto1_level1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Agosto",
    },
    // septiembre
    septiembre1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Septiembre",
    },
    septiembre1_level3: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Septiembre",
    },
    septiembre1_level2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Septiembre",
    },
    septiembre1_level1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Septiembre",
    },
    // octubre
    octubre1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Octubre",
    },
    octubre1_level3: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Octubre",
    },
    octubre1_level2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Octubre",
    },
    octubre1_level1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Octubre",
    },
    // noviembre
    noviembre1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Noviembre",
    },
    noviembre1_level3: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Noviembre",
    },
    noviembre1_level2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Noviembre",
    },
    noviembre1_level1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Noviembre",
    },
    // diciembre
    diciembre1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Diciembre",
    },
    diciembre1_level3: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Diciembre",
    },
    diciembre1_level2: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Diciembre",
    },
    diciembre1_level1: {
        type: "decimal",
        required: false,
        max: 9999999999.99,
        min: -9999999999.99,
        displayName: "Meta trazada Diciembre",
    },
    // axis 2
    // enero
    // enero2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Enero",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.enero2 === "" || data.enero2 === undefined || data.enero2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada enero es obligatorio`
    //     }]
    // },
    // enero2_level3:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Enero",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             console.log(data);
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.enero2_level3 === "" || data.enero2_level3 === undefined || data.enero2_level3 === null)
    //             }
    //             console.log(isValid);
    //             return isValid
    //         },
    //         message: `Meta trazada enero es obligatorio`
    //     }]
    // },
    // enero2_level2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Enero",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.enero2_level2 === "" || data.enero2_level2 === undefined || data.enero2_level2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // enero2_level1:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Enero",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.enero2_level1 === "" || data.enero2_level1 === undefined || data.enero2_level1 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // // febrero
    // febrero2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Febrero",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.febrero2 === "" || data.febrero2 === undefined || data.febrero2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // febrero2_level3:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Febrero",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.febrero2_level3 === "" || data.febrero2_level3 === undefined || data.febrero2_level3 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // febrero2_level2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Febrero",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.febrero2_level2 === "" || data.febrero2_level2 === undefined || data.febrero2_level2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // febrero2_level1:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Febrero",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.febrero2_level1 === "" || data.febrero2_level1 === undefined || data.febrero2_level1 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // // marzo
    // marzo2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Marzo",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.marzo2 === "" || data.marzo2 === undefined || data.marzo2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // marzo2_level3:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Marzo",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.marzo2_level3 === "" || data.marzo2_level3 === undefined || data.marzo2_level3 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // marzo2_level2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Marzo",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.marzo2_level2 === "" || data.marzo2_level2 === undefined || data.marzo2_level2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // marzo2_level1:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Marzo",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.marzo2_level1 === "" || data.marzo2_level1 === undefined || data.marzo2_level1 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // // abril
    // abril2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Abril",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.abril2 === "" || data.abril2 === undefined || data.abril2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // abril2_level3:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Abril",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.abril2_level3 === "" || data.abril2_level3 === undefined || data.abril2_level3 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // abril2_level2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Abril",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.abril2_level2 === "" || data.abril2_level2 === undefined || data.abril2_level2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // abril2_level1:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Abril",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.abril2_level1 === "" || data.abril2_level1 === undefined || data.abril2_level1 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // // mayo
    // mayo2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Mayo",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.mayo2 === "" || data.mayo2 === undefined || data.mayo2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // mayo2_level3:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Mayo",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.mayo2_level3 === "" || data.mayo2_level3 === undefined || data.mayo2_level3 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // mayo2_level2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Mayo",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.mayo2_level2 === "" || data.mayo2_level2 === undefined || data.mayo2_level2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // mayo2_level1:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Mayo",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.dmayo2_level1 === "" || data.mayo2_level1 === undefined || data.mayo2_level1 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // // junio
    // junio2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Junio",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.junio2 === "" || data.junio2 === undefined || data.junio2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // junio2_level3:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Junio",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.junio2_level3 === "" || data.junio2_level3 === undefined || data.junio2_level3 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // junio2_level2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Junio",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.junio2_level2 === "" || data.junio2_level2 === undefined || data.junio2_level2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // junio2_level1:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Junio",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.junio2_level1 === "" || data.junio2_level1 === undefined || data.junio2_level1 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // // julio
    // julio2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Julio",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.julio2 === "" || data.julio2 === undefined || data.julio2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // julio2_level3:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Julio",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.julio2_level3 === "" || data.julio2_level3 === undefined || data.julio2_level3 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // julio2_level2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Julio",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.julio2_level2 === "" || data.julio2_level2 === undefined || data.julio2_level2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // julio2_level1:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Julio",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.julio2_level1 === "" || data.julio2_level1 === undefined || data.julio2_level1 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // // agosto
    // agosto2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Agosto",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.agosto2 === "" || data.agosto2 === undefined || data.agosto2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // agosto2_level3:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Agosto",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.agosto2_level3 === "" || data.agosto2_level3 === undefined || data.agosto2_level3 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // agosto2_level2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Agosto",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.agosto2_level2 === "" || data.agosto2_level2 === undefined || data.agosto2_level2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },    
    // agosto2_level1:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Agosto",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.agosto2_level1 === "" || data.agosto2_level1 === undefined || data.agosto2_level1 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // // septiembre
    // septiembre2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Septiembre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.septiembre2 === "" || data.septiembre2 === undefined || data.septiembre2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // septiembre2_level3:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Septiembre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.septiembre2_level3 === "" || data.septiembre2_level3 === undefined || data.septiembre2_level3 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // septiembre2_level2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Septiembre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.septiembre2_level2 === "" || data.septiembre2_level2 === undefined || data.septiembre2_level2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // septiembre2_level1:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Septiembre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.septiembre2_level1 === "" || data.septiembre2_level1 === undefined || data.septiembre2_level1 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // // octubre
    // octubre2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Octubre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.octubre2 === "" || data.octubre2 === undefined || data.octubre2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // octubre2_level3:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Octubre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.octubre2_level3 === "" || data.octubre2_level3 === undefined || data.octubre2_level3 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // octubre2_level2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Octubre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.octubre2_level2 === "" || data.octubre2_level2 === undefined || data.octubre2_level2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // octubre2_level1:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Octubre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.octubre2_level1 === "" || data.octubre2_level1 === undefined || data.octubre2_level1 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // // noviembre
    // noviembre2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Noviembre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.noviembre2 === "" || data.noviembre2 === undefined || data.noviembre2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // noviembre2_level3:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Noviembre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.noviembre2_level3 === "" || data.noviembre2_level3 === undefined || data.noviembre2_level3 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // noviembre2_level2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Noviembre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.noviembre2_level2 === "" || data.noviembre2_level2 === undefined || data.noviembre2_level2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // noviembre2_level1:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Noviembre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.noviembre2_level1 === "" || data.noviembre2_level1 === undefined || data.noviembre2_level1 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // // diciembre
    // diciembre2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Diciembre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.diciembre2 === "" || data.diciembre2 === undefined || data.diciembre2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // diciembre2_level3:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Diciembre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.diciembre2_level3 === "" || data.diciembre2_level3 === undefined || data.diciembre2_level3 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // diciembre2_level2:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Diciembre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.diciembre2_level2 === "" || data.diciembre2_level2 === undefined || data.diciembre2_level2 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
    // diciembre2_level1:{
    //     type: "decimal",
    //     required: true,
    //     max: 9999999999.99,
    //     min: -9999999999.99,
    //     displayName: "Meta trazada Diciembre",
    //     customRules: [{
    //         rule: function (data) {
    //             let isValid = true
    //             if (data.axesNumber !== 1) {
    //                 isValid = !(data.diciembre2_level1 === "" || data.diciembre2_level1 === undefined || data.diciembre2_level1 === null)
    //             }
    //             return isValid
    //         },
    //         message: `Meta trazada Diciembre es obligatorio`
    //     }]
    // },
}

export {
    wigUpdateSchema,
    wigCreateSchema,
    wigTrackingSchema,
    wigGoalsSchema
};
