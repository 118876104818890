import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { teamUpdateSchema } from "models/teamModel";
import { updateTeam, getTeamById, getAllTeams,getTeamsLeaderByUser,getUsersByTeam } from "services/apiAdmin/teamApi";
import { getAllUsers, getSuperadminUsers } from "services/apiUser/userApi";
import { removeEmptyFields } from "utils/functions";
import {GetWigsFromTeamandLeader, getWIGByTeamIdAndCURRENTYear, copyWIG, inactivateWIG, getLastWIG, CopyWIGAxis} from "services/apiAdmin/wigApi";
import { formatWIGName } from "utils/dataTypeFormatter";
import { getUserLocale } from "utils/translate";
import EditView from "./EditView";
import { getPredictiveByWIGId,UpdatePredictiveWigID,GetPredictivesParent} from "services/apiAdmin/predictiveApi";
import { translate } from "utils/translate";

import { getUser} from "services/apiUser/userApi";
import { deleteUserFromTeamById,addMemberToTeam } from "services/apiAdmin/teamApi";
import { TramRounded } from "@material-ui/icons";

let w_cy=false;
let w_ny= false;
let w_nny= false;
let w_nnny= false;
let l_cy=false;
let l_ny= false;
let l_nny= false;
let l_nnny= false;
const newTeam = {
	title: "",
	parentTeamId: "",
	specialistId: "",
	allowLeaderEdition: false
}

const newTeamState = {
	title: "",
	parentTeamId: "",
	specialistId: "",
	allowLeaderEdition: ""
}

const newLead = {
	userId: "",
	dataRoleId: 3
}

let teamId
let _isMounted = false
let predictivesparent= [];
let parentId= 0;
async function onSubmit(props) {
	let body = removeEmptyFields({ ...props.team }, ["parentTeamId", "specialistId"])

	let result = await updateTeam(props.teamId, body)
	// Show confirmation message
	if (result && result.success && _isMounted) {
		props.setCreateSuccess(true)
		setTimeout(function () {
			if (_isMounted) {
				//props.history.push('/admin/team/index')
			}
		}, 1500);
	} else {
		// Show error message
		if (result) {
			let message = result.message
			if (!message) {
				message = "Algo ocurrió mal, inténtalo nuevamente."
			}

			if (message == 'El equipo ya existe') {
				let message = translate("team_name")
				props.setValidationMessage(message)
			} else {

				props.setValidationMessage(message)
			}
			props.setCreateError(true)
		}
	}
}

async function getTeam(props) {
	let result = await getTeamById(teamId)

	if (result.success) {
		if (_isMounted) {
	
			if (!result.data.parentTeamId) {
				result.data.parentTeamId = ""
			}
			if (!result.data.specialistId) {
				result.data.specialistId = ""
			}
			props.setLastParent(result.data.parentTeamId)
			props.setTeam(result.data)
		}

	}

}

async function getTeams(props) {
	let result = await getAllTeams();
	if (_isMounted && result.success) {
		let list = result.data.filter(function (team) {
			return team.id !== teamId;
		});
		for(let i =0; i < list.length; i++){
			if(list[i].id == props.team.parentTeamId){
				
				props.setTeamLead(list[i].title)
				props.setParentId(props.team.parentTeamId)
				
			}
		}
		props.setTeamList(list)
		props.setParentTeam(list[0])
	
	}
	props.changeLoadingTeamList(false)
}

async function getUsers(props) {
	let result = await getSuperadminUsers();
	if (_isMounted && result.success) {
		props.setUserList(result.data)
	}
	props.changeLoadingSpecialistList(false)
}

async function getUsersL(props) {
	let result = await getAllUsers();
	if (_isMounted && result.success) {
		props.setUserLists(result.data)
	}
	props.changeLoadingUserLists(false)
}

async function getLeader(props){
	
	let result = await getUsersByTeam(teamId)
	if(result.success){
		for(let i =0; i < result.data.length; i++){
			if(result.data[i].dataRoleId == 1){
				let leader= {
					userId: result.data[i].userId,
					dataRoleId: 3
				}
				let result_user = await getUser(result.data[i].userId);
			
				let lead= (result_user.data[0].firstName + " " + result_user.data[0].lastName + ", " + result_user.data[0].email);

				props.setLeader_st(lead);
				props.setLeader(result.data[i].userId)
				props.setLeaderO(leader);
			}
			
		}
	}
	
}
//Get WIG for team and leaderId
async function getWIGs(props){
	let currentYear=new Date().getFullYear();
	let result = await GetWigsFromTeamandLeader(props.teamId,props.leader, currentYear);
	if(result.success){
		
		if(result.data!= undefined){
			
			props.setWigList(result.data)
			if(result.data.length>0){
				w_cy=true;
				props.SetWigsCY(true)
			}
			
		}
		
	}
}

//GET WIGS FOR NEW PARENT
async function getParentWIGList(props) {
	
	
	if (!props.team.parentTeamId) {
		return
	}
	let currentYear = new Date().getFullYear()
	let result = await getWIGByTeamIdAndCURRENTYear(props.team.parentTeamId, currentYear)
	if (result.success) {
		if (_isMounted) 
		{
			let wig
		for (wig of result.data) {
			wig.name = formatWIGName(wig, getUserLocale())
		}
		    if(result.data.length<=0){
				l_cy=true;
			
			}
			props.setParentWIGList(result.data)
		}
	}
}

//COPY WIGS
async function CopyWIGS(props){
	
	for(let i = 0; i < props.WigList.length; i++){
		let resultcopywig = await copyWIG(props.WigList[i].Id,props.WigList[i].ParentWig,props.teamId);
		if(resultcopywig.success){
			//INACTIVATE WIG FOR WIGID
			await InactivateWIG(props,props.WigList[i].Id);
			// GET LAST WIG
			let resultlastwig= await getLastWIG(props.teamId);
			if(resultlastwig.success){
				//COPY AXIS WIG
		
				await CopyWIGAXIS(props,props.WigList[i].Id,resultlastwig.data.Id)
			}
		}
	}

}
//INACTIVATE WIG FOR WIGID
async function InactivateWIG(props,wigId){
	
	let resultinactivatewig = await inactivateWIG(wigId);
	if(resultinactivatewig.success){

	
	}
}
//COPY WIGAXIS
async function CopyWIGAXIS(props,lastwigId,wigId){
	let resultcopywigaxis = await CopyWIGAxis(lastwigId,wigId);
	if(resultcopywigaxis.success){
		await GetPredictives(props,lastwigId,wigId);
	}
}
// GET PREDICITVES TO LASTWIG
async function GetPredictives(props,lastwigId,wigId){
	let result = await getPredictiveByWIGId(lastwigId)
	for(let i =0; i< result.data.length; i++){
		await savePredictiveUpdate(result.data[i].id,wigId)
	}
}

//UPDATE NEW WIGiD TO PREDICTIVES
async function savePredictiveUpdate(predictiveId,wigId){
	let result = await UpdatePredictiveWigID(wigId, predictiveId);
	if(result.success){
		
	}
}

//GET WIGS TO OLD PARENT TEAM
async function GetWigsOldParent(props){
	if (!props.parentId) {
		return
	}
	let currentYear = new Date().getFullYear()
	let result = await getWIGByTeamIdAndCURRENTYear(props.parentId, currentYear)
	if (result.success) {
		if (_isMounted) 
		{
			let wig
		for (wig of result.data) {
			wig.name = formatWIGName(wig, getUserLocale())
		}
		
		
			
			await GetPredictivesList(props,result.data)
			
		}
	}
}

//GET PREDICITVES
async function GetPredictivesList(props,wigList){
	predictivesparent = [];
	for(let i =0; i < wigList.length; i++){
		let result = await GetPredictivesParent(props.leader,props.teamId,wigList[i].id);
		
		if(result.success){
			console.log(result)
			for(let y =0; y< result.message.length;y++){
					result.message[y].WIGId= null;
					predictivesparent.push(result.message[y]);
	
			}

		}
		//await GetPredictivesToParent(props,wigList[i].id);
	}
	if(predictivesparent.length>0){
		props.SetPreCY(true)
	}

	props.setPredictiveList(predictivesparent)
	//console.log(predictivesparent)

}

//Copy PREDICTIVES
async function CopyPredictives(props){


	for(let i =0; i < props.PredictiveList.length; i++){
		if(props.PredictiveList[i].WIGId!= null){
			await savePredictiveUpdate(props.PredictiveList[i].Id,props.PredictiveList[i].WIGId)
		}
	
	}
}

//Get WIG for team and leaderId to next year
async function getWIGs1(props){
	let currentYear=new Date().getFullYear();
	currentYear= currentYear +1;
	let result = await GetWigsFromTeamandLeader(props.teamId,props.leader, currentYear);
	if(result.success){
		
		if(result.data!= undefined){
			
			props.setWigListNY(result.data)
			if(result.data.length>0){
				w_ny=true;
				props.SetWigsNY(true)
			}
			
		}
		
	}
}

//GET WIGS FOR NEW PARENT NEXT YEAR
async function getParentWIGList1(props) {
	
	
	if (!props.team.parentTeamId) {
		return
	}
	let currentYear = new Date().getFullYear();
	currentYear= currentYear + 1;
	let result = await getWIGByTeamIdAndCURRENTYear(props.team.parentTeamId, currentYear)
	if (result.success) {
		if (_isMounted) 
		{
			let wig
		for (wig of result.data) {
			wig.name = formatWIGName(wig, getUserLocale())
		}
		if(result.data.length<=0){
			l_ny=true;
		
		}
			props.setParentWIGList1(result.data)
		}
	}
}

//COPY WIGS NEXT YEAR
async function CopyWIGSNY(props){
	
	for(let i = 0; i < props.WigListNY.length; i++){
		let resultcopywig = await copyWIG(props.WigListNY[i].Id,props.WigListNY[i].ParentWig,props.teamId);
		if(resultcopywig.success){
			//INACTIVATE WIG FOR WIGID
			await InactivateWIG(props,props.WigListNY[i].Id);
			// GET LAST WIG
			let resultlastwig= await getLastWIG(props.teamId);
			if(resultlastwig.success){
				//COPY AXIS WIG
		
				await CopyWIGAXIS(props,props.WigListNY[i].Id,resultlastwig.data.Id)
			}
		}
	}

}


//Get WIG for team and leaderId to next next year
async function getWIGs2(props){
	let currentYear=new Date().getFullYear();
	currentYear= currentYear +2;
	let result = await GetWigsFromTeamandLeader(props.teamId,props.leader, currentYear);
	if(result.success){
		
		if(result.data!= undefined){
			
			props.setWigListNNY(result.data)
			if(result.data.length>0){
				w_nny=true;
				props.SetWigsNNY(true)
			}
			
		}
		
	}
}


//GET WIGS FOR NEW PARENT NEXT YEAR
async function getParentWIGList2(props) {
	
	
	if (!props.team.parentTeamId) {
		return
	}
	let currentYear = new Date().getFullYear();
	currentYear= currentYear + 2;
	let result = await getWIGByTeamIdAndCURRENTYear(props.team.parentTeamId, currentYear)
	if (result.success) {
		if (_isMounted) 
		{
			let wig
		for (wig of result.data) {
			wig.name = formatWIGName(wig, getUserLocale())
		}
		if(result.data.length<=0){
			l_nny=true;
	
		}
			props.setParentWIGList2(result.data)
		}
	}
}

//COPY WIGS NEXT NEXT YEAR
async function CopyWIGSNNY(props){
	
	for(let i = 0; i < props.WigListNNY.length; i++){
		let resultcopywig = await copyWIG(props.WigListNNY[i].Id,props.WigListNNY[i].ParentWig,props.teamId);
		if(resultcopywig.success){
			//INACTIVATE WIG FOR WIGID
			await InactivateWIG(props,props.WigListNNY[i].Id);
			// GET LAST WIG
			let resultlastwig= await getLastWIG(props.teamId);
			if(resultlastwig.success){
				//COPY AXIS WIG
		
				await CopyWIGAXIS(props,props.WigListNNY[i].Id,resultlastwig.data.Id)
			}
		}
	}

}

//Get WIG for team and leaderId to next next next year
async function getWIGs3(props){
	let currentYear=new Date().getFullYear();
	currentYear= currentYear +3;
	let result = await GetWigsFromTeamandLeader(props.teamId,props.leader, currentYear);
	if(result.success){
		
		if(result.data!= undefined){
			
			props.setWigListNNNY(result.data)
			if(result.data.length>0){
				w_nnny=true;
				props.SetWigsNNNY(true)
			}
			
		}
		
	}
}


//GET WIGS FOR NEW PARENT NEXT YEAR
async function getParentWIGList3(props) {
	
	
	if (!props.team.parentTeamId) {
		return
	}
	let currentYear = new Date().getFullYear();
	currentYear= currentYear + 3;
	let result = await getWIGByTeamIdAndCURRENTYear(props.team.parentTeamId, currentYear)
	if (result.success) {
		if (_isMounted) 
		{
			let wig
		for (wig of result.data) {
			wig.name = formatWIGName(wig, getUserLocale())
		}
		if(result.data.length<=0){
			l_nnny=true;
		
		}
			props.setParentWIGList3(result.data)
		}
	}
}

//COPY WIGS NEXT NEXT YEAR
async function CopyWIGSNNNY(props){
	
	for(let i = 0; i < props.WigListNNNY.length; i++){
		let resultcopywig = await copyWIG(props.WigListNNNY[i].Id,props.WigListNNNY[i].ParentWig,props.teamId);
		if(resultcopywig.success){
			//INACTIVATE WIG FOR WIGID
			await InactivateWIG(props,props.WigListNNNY[i].Id);
			// GET LAST WIG
			let resultlastwig= await getLastWIG(props.teamId);
			if(resultlastwig.success){
				//COPY AXIS WIG
		
				await CopyWIGAXIS(props,props.WigListNNNY[i].Id,resultlastwig.data.Id)
			}
		}
	}

}

//GET WIGS TO OLD PARENT TEAM TO NEXT YEAR
async function GetWigsOldParent1(props){
	if (!props.parentId) {
		return
	}
	let currentYear = new Date().getFullYear();
	currentYear= currentYear + 1;
	let result = await getWIGByTeamIdAndCURRENTYear(props.parentId, currentYear)
	if (result.success) {
		if (_isMounted) 
		{
			let wig
		for (wig of result.data) {
			wig.name = formatWIGName(wig, getUserLocale())
		}
		
		
			
			await GetPredictivesListNY(props,result.data)
			
		}
	}
}


//GET PREDICITVES NY
async function GetPredictivesListNY(props,wigList){
	let predictivesparent1 = [];
	console.log("WIGS 23")
	console.log(wigList)
	for(let i =0; i < wigList.length; i++){
		let result = await GetPredictivesParent(props.leader,props.teamId,wigList[i].id);
		
		if(result.success){
			//console.log(result)
			for(let y =0; y< result.message.length;y++){
					result.message[y].WIGId= null;
					predictivesparent1.push(result.message[y]);
	
			}

		}
		//await GetPredictivesToParent(props,wigList[i].id);
	}
	if(predictivesparent1.length>0){
		props.SetPreNY(true)
	}
	console.log("PRE 23")
	console.log(predictivesparent1)
	props.setPredictiveListNY(predictivesparent1)
	//console.log(predictivesparent)

}

//Copy PREDICTIVES
async function CopyPredictives1(props){

	
	for(let i =0; i < props.PredictiveListNY.length; i++){
		if(props.PredictiveListNY[i].WIGId!= null){
			await savePredictiveUpdate(props.PredictiveListNY[i].Id,props.PredictiveListNY[i].WIGId)}
		
	}
}


async function deleteUserFromTeam(props) {
	props.changeAlertLoading(true)

	let result = await deleteUserFromTeamById(props.parentId,props.leader)

	if (result.success) { //result.success		
		props.changeDeleteSuccess(true)
		setTimeout(function () {
			props.changeDeleteSuccess(false)
		}, 5000);
	} else {
		// Show error message
		if (result) {
			let message = result.message
			if (!message) {
				message = "Algo ocurrió mal, inténtalo nuevamente."
			}
			props.changeValidationMessage(message)
			props.changeDeleteError(true)
		}
	}

	props.changeAlertLoading(false)

}

async function AddMember(props){
	if (props.team.parentTeamId) {
		let result = await addMemberToTeam(props.team.parentTeamId, props.leaderO);
		// Show confirmation message
		if (result.success && _isMounted) {
	
			props.setCreateSuccess(true)
			setTimeout(function () {
				if (_isMounted) {
				//	props.history.push('/admin/dashboard')
				}
			}, 1500);
		} else {
			// Show error message
			if (result) {
				let message = result.message
				if (!message) {
					message = "Algo salió mal, inténtelo nuévamente."
				}
				props.setValidationMessage(message)
				props.setCreateError(true)
			}
		}
	}
}
async function VerifyAssignPredictives(props){
	let verify =false;
	if(props.PredictiveList.length>0){
		for(let i =0; i< props.PredictiveList.length; i++){
			if(props.PredictiveList[i].WIGId== null){
				verify= true;
			}
		}
	}
	if(props.PredictiveListNY.length>0){
		for(let y =0; y< props.PredictiveListNY.length; y++){
			if(props.PredictiveListNY[y].WIGId == null){
				verify= true
			}
		}
	}
	props.SetPredictivesAssign(verify);

	if(verify== false){
		await CopyPredictives(props)
		await CopyPredictives1(props)
		
		await AddMember(props)
		await onSubmit(props)
		
	}
}

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
			membersList: state.admin.membersList
		}),
		{}
	),
	withState("teamId", "setTeamId", undefined),
	withState("team", "setTeam", { ...newTeam }),
	withState("parentTeam", "setParentTeam", { ...newTeam}),
	withState("teamState", "setTeamState", { ...newTeamState }),
	withState("teamList", "setTeamList", []),
	withState("userList", "setUserList", []),
	withState("createSuccess", "setCreateSuccess", false),
	withState("createError", "setCreateError", false),
	withState("validationMessage", "setValidationMessage", ""),
	withState("isLoadingSave", "setIsLoadingSave", false),
	withState("loadingSpecialistList", "changeLoadingSpecialistList", false),
	withState("loadingTeamList", "changeLoadingTeamList", false),
	withState("loadingUserList", "changeLoadingUserLists", false),
	withState("userLists", "setUserLists", []),
	withState("changeLeader", "setChangeLeader",false),
	withState("teamLeaderOptions", "setTeamsLeaderOptions", []),
	withState("fromTeam", "SetFromTeam", ""),
	withState("selectFromTeam", "SetSelectFromTeam", true),
	withState("copyWIG", "SetCopyWIG", false),
	withState("copyPredictive", "SetCopyPredictive", false),
	withState("copyw","setCopyw",false),
	withState("copyp","setCopyp",false),
	withState("teamLead","setTeamLead", ""),
	withState("WigList", "setWigList", []),
	withState("PredictiveList", "setPredictiveList", []),
    withState("parentWIGList","setParentWIGList", []),
	withState("leader","setLeader", 0),
	withState("lastParent","setLastParent",0),
	withState("wigID", "setWigID",0),
	withState("parentId","setParentId",0),
	withState("WigListNY", "setWigListNY", []),
	withState("wigsCY", "SetWigsCY", false), //Have wigs current year
	withState("wigsNY", "SetWigsNY", false), //Have wigs next year
	withState("parentWIGList1","setParentWIGList1", []), //parentwigList Next Year
	withState("WigListNNY", "setWigListNNY", []),
	withState("wigsNNY", "SetWigsNNY", false), //Have wigs next next year
	withState("parentWIGList2","setParentWIGList2", []), //parentwigList Next NEXT Year
	withState("WigListNNNY", "setWigListNNNY", []),
	withState("wigsNNNY", "SetWigsNNNY", false), //Have wigs next next year
	withState("parentWIGList3","setParentWIGList3", []), //parentwigList Next NEXT Year
	withState("PredictiveListNY", "setPredictiveListNY", []), //predictiveList NEXT YEAR
	withState("preCY", "SetPreCY", false), //Have pre current year
	withState("preNY", "SetPreNY", false), //Have pre next year
	withState("yearsN","setYearN",false), //years dont have parent wigs
	withState("delete","setDelete",false), //delete user
	withState("deleteSuccess", "changeDeleteSuccess", false),
	withState("deleteError", "changeDeleteError", false),
	withState("predictivesAssign","SetPredictivesAssign",false), //status all predictives assign
	withState("alertLoading", "changeAlertLoading", false),
	withState("leaderO","setLeaderO", {}),
	withState("leader_st","setLeader_st",""),
	withHandlers({
		onFieldChange: (props) => (field, value) => {
			props.team[field] = value
			let isValid = validateProperty(teamUpdateSchema, props.team, field).isValid
			if (isValid) {
				props.teamState[field] = "success"
			} else {
				props.teamState[field] = "error"
			}
			if(field == "parentTeamId"){
				props.setChangeLeader(true)
				props.SetFromTeam(value);
				props.SetSelectFromTeam(false)
			}
			props.setTeamState(props.teamState)
			props.setTeam(props.team)
		},
		onSave: (props) => async () => {
			console.log();
			props.setIsLoadingSave(true)

			if(props.copyWIG== false && props.copyPredictive== false && props.changeLeader == true){
			
				await AddMember(props)
				await onSubmit(props)
			}
			let validation = validateObject(teamUpdateSchema, props.team)
			if (validation.isValid) {
				
				if(props.copyWIG){
					await CopyWIGS(props)
					await CopyWIGSNY(props)
					await CopyWIGSNNY(props)
					await CopyWIGSNNNY(props)
					
					await AddMember(props)
					await onSubmit(props)
				}else if(props.copyPredictive){
					//verify assign all predictives
					await VerifyAssignPredictives(props)
					//await CopyPredictives(props)
					//await CopyPredictives1(props)
				}
				else {
					await onSubmit(props)
				}
				if(props.delete){
					deleteUserFromTeam(props)
				}

				await window.location.reload(true);
				window.location.href = `/admin/team/index`;
			} else {
				let field
				for (field in props.team) {
					if (validation.errors[field]) {
						props.teamState[field] = "error"
					}
				}
				props.setTeamState(props.teamState)
			}
			props.setIsLoadingSave(false)
		},
		onFieldChangeLeader: (props) => (field, value) => {
			//Change leader
			
			props.setLeader(value)
			
			//getTeamsLeader(props,value) 
		},
		onFieldChangeTeam: (props) => (field, value) => {
			props.team[field] = value
		
			let isValid = validateProperty(teamUpdateSchema, props.team, field).isValid
			if (isValid) {
				props.teamState[field] = "success"
			} else {
				props.teamState[field] = "error"
			}
			props.setTeamState(props.teamState)
			props.setTeam(props.team)
			props.setChangeLeader(true)
			props.SetFromTeam(value);
			props.SetSelectFromTeam(false);
			
		},
		onFieldChangeWIG: (props) => (value) => {
			props.SetCopyWIG(value);
			if(value== true){
				props.setCopyp(true)
				props.SetCopyPredictive(false)
				getWIGs(props);
				getParentWIGList(props);
				getWIGs1(props)
				getParentWIGList1(props);
				getWIGs2(props)
				getParentWIGList2(props);
				getWIGs3(props)
				getParentWIGList3(props);
				if(w_cy==true && l_cy==true){
			
					props.setYearN(true)
				}
				if(w_ny== true && l_ny== true){
					
					props.setYearN(true)
				}
				if(w_nny== true && l_nny==true){
				
					props.setYearN(true)
				}
				if(w_nnny== true && l_nnny==true){
					
					props.setYearN(true)
				}
			}else{
				props.setCopyp(false)
				props.SetWigsCY(false)
				
			}
			
		},
		onFieldChangePredictive: (props) => (value) => {
			props.SetCopyPredictive(value);
			if(value==true){
				props.setCopyw(true)
				props.SetCopyWIG(false)
				props.setYearN(false)
				 GetWigsOldParent(props);
				 getParentWIGList(props);
				 GetWigsOldParent1(props);
				 getParentWIGList1(props);
				//GetPredictivesList(props);
			}else{
				props.setCopyw(false)
			}
			
		},
		AssignParentId: (props) => (field,value) => {
	
			
			let wiglist= props.WigList;
			for(let i =0; i< wiglist.length; i++){
				if(wiglist[i].Id== field){
					wiglist[i].ParentWig= value;
				}
			}
		
			props.setWigList(wiglist)
		},
		AssignParentIdNY: (props) => (field,value) => {
	
			
			let wiglist= props.WigListNY;
			for(let i =0; i< wiglist.length; i++){
				if(wiglist[i].Id== field){
					wiglist[i].ParentWig= value;
				}
			}
		
			props.setWigListNY(wiglist)
		},
		AssignParentIdNNY: (props) => (field,value) => {
	
			
			let wiglist= props.WigListNNY;
			for(let i =0; i< wiglist.length; i++){
				if(wiglist[i].Id== field){
					wiglist[i].ParentWig= value;
				}
			}
		
			props.setWigListNNY(wiglist)
		},
		AssignParentIdNNNY: (props) => (field,value) => {
	
			
			let wiglist= props.WigListNNNY;
			for(let i =0; i< wiglist.length; i++){
				if(wiglist[i].Id== field){
					wiglist[i].ParentWig= value;
				}
			}
		
			props.setWigListNNNY(wiglist)
		},
		AssignParentIdP: (props) => (field,value) => {
	
			
			let pred_list= props.PredictiveList;
			for(let i =0; i< pred_list.length; i++){
				if(pred_list[i].Id== field){
					pred_list[i].WIGId= value;
				}
			}
			
			props.setPredictiveList(pred_list)
		},
		AssignParentIdPNY: (props) => (field,value) => {
	
			
			let pred_list= props.PredictiveListNY;
			for(let i =0; i< pred_list.length; i++){
				if(pred_list[i].Id== field){
					pred_list[i].WIGId= value;
				}
			}
			
			props.setPredictiveListNY(pred_list)
		},
		
		onFieldChangeDelete: (props) => (value) => {
			props.setDelete(value);
			
			
		},
		CopyPredictives: (props) => async () => {
			props.changeAlertLoading(true)
				await CopyPredictives(props)
				await CopyPredictives1(props)
			
				await AddMember(props)
				await onSubmit(props)
				props.changeAlertLoading(false)
		},
		onModalClosed: (props) => () => {
			props.SetPredictivesAssign(false);
		},
	}),
	lifecycle({
		componentDidMount() {
			_isMounted = true

			getTeams(this.props)
			getUsers(this.props)

			teamId = parseInt(this.props.match.params.id)
			this.props.setTeamId(teamId)
			getTeam(this.props)

			this.props.setTeamState({ ...newTeamState })
			this.props.setCreateSuccess(false)
			getLeader(this.props)
			
		},
		componentWillUnmount() {
			_isMounted = false
			getLeader(this.props)
		},
		UNSAFE_componentWillUpdate(nextProps) {
			
			if (this.props.team && this.props.teamList.length > 0){
	
			}
			if(this.props.leader == 0){
				getLeader(this.props)
			}
			if (this.props.loadingSpecialistList !== nextProps.loadingSpecialistList && nextProps.loadingSpecialistList) {
				//getUserList(this.props)
				getUsers(this.props)
				
			}

			if (this.props.loadingTeamList !== nextProps.loadingTeamList && nextProps.loadingTeamList) {
				getTeams(this.props)
			}

			if (this.props.loadingUserList !== nextProps.loadingUserList && nextProps.loadingUserList) {
				//getUserList(this.props)
			
				getUsersL(this.props)
			}
		}
	})
)(EditView);
