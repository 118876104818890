/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
    CircularProgress,
    Select,
    MenuItem
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// material ui icons
import GroupAdd from "@material-ui/icons/GroupAdd";
import AddAlert from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Group from "@material-ui/icons/Group";
import SearchIcon from '@material-ui/icons/Search';

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/User/createStyle.js";

// Functions
import { translate } from "utils/translate";

const useStyles = makeStyles(styles);

export default function createView(props) {
    const classes = useStyles();

    const [openLeaderList, setOpenLeaderList] = React.useState(false);
    const [openSpecialistList, setOpenSpecialistList] = React.useState(false);
    const [openTeamList, setOpenTeamList] = React.useState(false);

    let breadcrumbViews = [
        {
            name: translate("routes_home_label"),
            url: "/admin/dashboard",
            icon: DashboardIcon
        },
        {
            name: translate("routes_team_label"),
            url: "/admin/team/index",
            icon: Group
        },
        {
            name: translate("routes_team_create_label"),
            url: "/admin/user/create",
            icon: GroupAdd
        }
    ]

    return (
        <>
            <Breadcrumbs views={breadcrumbViews} />
            <Heading
                textAlign="center"
                title={translate("team_create_header")}
            />
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Group />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{translate("team_create_title")}</h4>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={6} md={6}>
                                                <CustomInput
                                                    success={props.teamState.title === "success"}
                                                    error={props.teamState.title === "error"}
                                                    labelText={translate("team_create_name_label") + '*'}
                                                    id="inputTitle"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        onChange: event => {
                                                            props.onFieldChange("title", event.target.value)
                                                        },
                                                        type: "text",
                                                        value: props.team.team,
                                                        endAdornment:
                                                            props.teamState["title"] === "error" ? (
                                                                <InputAdornment position="end">
                                                                    <Close className={classes.danger} />
                                                                </InputAdornment>
                                                            ) : (
                                                                    undefined
                                                                )
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={6}>

                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <Autocomplete
                                                        loadingText={translate("autocomplete_loading_text")}
                                                        clearText={translate("autocomplete_clear_text")}
                                                        noOptionsText={translate("autocomplete_no_options_text")}
                                                        id="teamList"
                                                        open={openTeamList}
                                                        onOpen={() => {
                                                            setOpenTeamList(true);
                                                            props.changeLoadingTeamList(true);
                                                        }}
                                                        onClose={() => {
                                                            setOpenTeamList(false);
                                                            //props.setTeamList([]);
                                                        }}
                                                        //onChange={handleTeamChage}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                newValue = parseInt(newValue.id)
                                                            }
                                                            props.onFieldChange("parentTeamId", newValue)
                                                        }}
                                                        getOptionSelected={(option, value) => option.id === value.parentTeamId}
                                                        getOptionLabel={(option) => `${option.title}`}
                                                        options={props.teamList}
                                                        loading={props.loadingTeamList}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={translate("choose_parent_team")}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            <SearchIcon />
                                                                            {props.loadingTeamList ? <CircularProgress color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>

                                                {/* 
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <InputLabel
                                                        htmlFor="simple-select"
                                                        className={classes.selectLabel}
                                                    >
                                                        Equipo al que pertenece
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        classes={{
                                                            select: classes.select
                                                        }}
                                                        value={props.team[`parentTeamId`]}
                                                        onChange={e => props.onFieldChange(`parentTeamId`, e.target.value)}
                                                        inputProps={{
                                                            name: `parentTeamId`,
                                                            id: `parentTeamId`
                                                        }}
                                                    >
                                                        <MenuItem
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value=""
                                                        >
                                                            Seleccione el equipo al que pertenece
                                                        </MenuItem>
                                                        {props.teamList.map((team, index) => (
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                                                                value={team.id}
                                                                key={index}
                                                            >
                                                                {team.title}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                */ }
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <Autocomplete
                                                        loadingText={translate("autocomplete_loading_text")}
                                                        clearText={translate("autocomplete_clear_text")}
                                                        noOptionsText={translate("autocomplete_no_options_text")}
                                                        id="userList"
                                                        open={openLeaderList}

                                                        onOpen={() => {
                                                            setOpenLeaderList(true);
                                                            props.changeLoadingUserList(true);
                                                        }}
                                                        onClose={() => {
                                                            setOpenLeaderList(false);
                                                            props.setUserList([]);
                                                        }}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                newValue = parseInt(newValue.userId)
                                                            }
                                                            props.onFieldChange("leaderId", newValue)
                                                        }}
                                                        getOptionSelected={(option, value) => option.userId === value.userId}
                                                        getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
                                                        options={props.userList}
                                                        loading={props.loadingUserList}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={translate("leader_user_label")}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            <SearchIcon />
                                                                            {props.loadingUserList ? <CircularProgress color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                                {/* 
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >                                                                                                    
                                                    <InputLabel
                                                        htmlFor="simple-select"
                                                        className={classes.selectLabel}
                                                    >
                                                    
                                                        Usuario líder
                                                    </InputLabel>
                                                                                                        
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        classes={{
                                                            select: classes.select
                                                        }}
                                                        value={props.team[`leaderId`]}
                                                        onChange={e => props.onFieldChange(`leaderId`, e.target.value)}
                                                        inputProps={{
                                                            name: `leaderId`,
                                                            id: `leaderId`
                                                        }}
                                                    >
                                                    
                                                        <MenuItem
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value={""}
                                                        >
                                                            Seleccione al usuario líder
                                                        </MenuItem>
                                                        {props.userList.map((user, index) => (
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                style={{ overflowX: "auto" }}
                                                                value={user.userId}
                                                                key={index}
                                                            >
                                                                {`${user.firstName} ${user.lastName} (${user.email})`}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                */}
                                            </GridItem>
                                            
                                            <GridItem xs={12} sm={12} md={6}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <Autocomplete
                                                        loadingText={translate("autocomplete_loading_text")}
                                                        clearText={translate("autocomplete_clear_text")}
                                                        noOptionsText={translate("autocomplete_no_options_text")}
                                                        id="userList"
                                                        disabled={false}
                                                        open={openSpecialistList}

                                                        onOpen={() => {
                                                            setOpenSpecialistList(true);
                                                            props.changeLoadingSpecialistList(true);
                                                        }}
                                                        onClose={() => {
                                                            setOpenSpecialistList(false);
                                                            props.setSuperadminList([]);
                                                        }}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                newValue = parseInt(newValue.userId)
                                                            }
                                                            props.onFieldChange("specialistId", newValue)
                                                        }}
                                                        getOptionSelected={(option, value) => option.userId === value.userId}
                                                        getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
                                                        options={props.superadminList}
                                                        loading={props.loadingSpecialistList}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={translate("specialist_user_label")}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            <SearchIcon />
                                                                            {props.loadingSpecialistList ? <CircularProgress color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                                {/* 
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <InputLabel
                                                        htmlFor="simple-select"
                                                        className={classes.selectLabel}
                                                    >
                                                        Especialista
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        classes={{
                                                            select: classes.select
                                                        }}
                                                        value={props.team[`specialistId`]}
                                                        onChange={e => props.onFieldChange(`specialistId`, e.target.value)}
                                                        inputProps={{
                                                            name: `specialistId`,
                                                            id: `specialistId`
                                                        }}
                                                    >
                                                        <MenuItem
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value={""}
                                                        >
                                                            Seleccione al especialista
                                                        </MenuItem>
                                                        {props.userList.map((user, index) => (
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                style={{ overflowX: "auto" }}
                                                                value={user.userId}
                                                                key={index}
                                                            >
                                                                {`${user.firstName} ${user.lastName} (${user.email})`}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                            */}
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                                <div className={classes.formCategory}>
                                    <small>*</small> {translate("required_fields")}
                                </div>
                                <div className={classes.center}>
                                    {props.isLoadingSave ?
                                        <CircularProgress color="inherit" /> :
                                        <Button color="primary" onClick={props.onTeamCreate}>
                                            {translate("button_save")}
                                        </Button>
                                    }
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                    <Snackbar
                        place="br"
                        color="success"
                        icon={AddAlert}
                        message={translate("alert_team_created_success")}
                        open={props.createSuccess}
                        closeNotification={() => props.setCreateSuccess(false)}
                        close
                    />
                    <Snackbar
                        place="bl"
                        color="danger"
                        icon={AddAlert}
                        message={props.validationMessage}
                        open={props.createError}
                        closeNotification={() => props.setCreateError(false)}
                        close
                    />
                </GridItem>
            </GridContainer>
        </>
    );
}
