/*eslint-disable*/
import React from "react";

// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer, Views } from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
//import globalize from 'globalize';

// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
    CircularProgress,
    Select,
    MenuItem
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// material ui icons
import AddAlert from "@material-ui/icons/AddAlert";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Edit from "@material-ui/icons/Edit";
import ScheduleIcon from '@material-ui/icons/Schedule';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import { Days,DaysE } from "components/Constants";
import { getUserLocale } from "utils/translate";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/Accountability/indexStyle.js";

import { verifyPermission } from "utils/functions";
import { translate } from "utils/translate"

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(styles);

function weekDayHeader(props) {
    if(getUserLocale() === "en-US"){
        return (<div style={{ textAlign: "center" }}>{DaysE[`${props.date.getDay()}`]}</div>)
    }
    else{
        return (<div style={{ textAlign: "center" }}>{Days[`${props.date.getDay()}`]}</div>)
    }
    
}

const CustomToolbar = (toolbar) => {
    const goToBack = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
        toolbar.onNavigate('prev');
    };

    const goToNext = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
        toolbar.onNavigate('next');
    };

    const goToCurrent = () => {
        const now = new Date();
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        toolbar.onNavigate('current');
    };

    const label = () => {
        const date = moment(toolbar.date);
        return (
            <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
        );
    };
    const sCalendar = {}
    return (
        <div className={sCalendar['toolbar-container']}>
            <label className={sCalendar['label-date']}>{label()}</label>

            <div className={sCalendar['back-next-buttons']}>
                <button className={sCalendar['btn-back']} onClick={goToBack}>&#8249;</button>
                <button className={sCalendar['btn-current']} onClick={goToCurrent}>today</button>
                <button className={sCalendar['btn-next']} onClick={goToNext}>&#8250;</button>
            </div>
        </div >
    );
};

export default function createView(props) {
    const classes = useStyles();

    const selectedEvent = event => {
        // window.location.href = `/admin/accountabilityrecord/team`;
        window.location.href = `/admin/accountabilityrecords/team/${event.teamId}`;

    };

    let breadcrumbViews = [
        {
            name: translate("routes_home_label"),
            url: "/admin/dashboard",
            icon: DashboardIcon
        },
        {
            name: translate("routes_wig_session_label"),
            url: `/admin/team/accountability/${props.teamId}`,
            icon: ScheduleIcon
        }
    ]

    const eventColors = event => {
        var backgroundColor = "event-";
        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");
        return {
            className: backgroundColor
        };
    };

    
    return (
        <>
            <Breadcrumbs views={breadcrumbViews} />
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <ScheduleIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{translate("accountability_view_title")}</h4>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={6}>
                                            <GridContainer>
                                                <CustomInput
                                                    labelText= {translate("accountability_record_create_team_label")}
                                                    id="inputTitle"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        disabled: true,
                                                        type: "text",
                                                        value: props.team.title
                                                    }}
                                                />
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <InputLabel
                                                        htmlFor="simple-select"
                                                        className={classes.selectLabel}
                                                    >
                                                        {translate("accountability_view_day_label")}
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        classes={{
                                                            select: classes.select
                                                        }}
                                                        value={props.team[`day`]}
                                                        onChange={e => props.onFieldChange(`day`, e.target.value)}
                                                        inputProps={{
                                                            name: `day`,
                                                            id: `day`
                                                        }}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            value={""}
                                                        >
                                                            {translate("accountability_view_day_input")}
                                                        </MenuItem>
                                                        {
                                                           (getUserLocale() === "en-US")?
                                                           Object.keys(DaysE).map((index) => {
                                                            return (
                                                                <MenuItem
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    style={{ overflowX: "auto" }}
                                                                    value={parseInt(index)}
                                                                    key={index}
                                                                >
                                                                    {`${DaysE[index]}`}
                                                                </MenuItem>
                                                            )
                                                        })
                                                           :
                                                        
                                                           
                                                           Object.keys(Days).map((index) => {
                                                            return (
                                                                <MenuItem
                                                                    classes={{
                                                                        root: classes.selectMenuItem,
                                                                        selected: classes.selectMenuItemSelected
                                                                    }}
                                                                    style={{ overflowX: "auto" }}
                                                                    value={parseInt(index)}
                                                                    key={index}
                                                                >
                                                                    {`${Days[index]}`}
                                                                </MenuItem>
                                                            )
                                                        })
                                                        }
                                                    </Select>
                                                </FormControl>

                                            </GridContainer>

                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <GridContainer justify="center">
                                                <GridItem xs={12} sm={12} md={8}>
                                                    <InputLabel className={classes.label}>{translate("accountability_view_hour_input")}</InputLabel>
                                                    <br />
                                                    <FormControl fullWidth>
                                                        <Datetime
                                                            input={false}
                                                            dateFormat={false}
                                                            timeConstraints={{ hours: { min: 7, max: 17 }, minutes: { step: 15 } }}
                                                            onChange={e => {
                                                                if (e._d) {
                                                                    props.onFieldChange(`time`,
                                                                        `${String(e._d.getHours()).padStart(2, '0')}:${String(e._d.getMinutes()).padStart(2, '0')}`)
                                                                }
                                                            }}
                                                        />
                                                    </FormControl>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <div className={classes.center}>
                                    {props.isLoadingSave ?
                                        <CircularProgress color="inherit" /> :
                                        <Button color="primary" onClick={props.onSave}>
                                            {translate("button_save")}
                                        </Button>
                                    }
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                    <Snackbar
                        place="br"
                        color="success"
                        icon={AddAlert}
                        message={translate("alert_wig_session_updated_success")}
                        open={props.createSuccess}
                        closeNotification={() => props.setCreateSuccess(false)}
                        close
                    />
                    <Snackbar
                        place="bl"
                        color="danger"
                        icon={AddAlert}
                        message={props.validationMessage}
                        open={props.createError}
                        closeNotification={() => props.setCreateError(false)}
                        close
                    />
                </GridItem>
                {
                    verifyPermission(props.roleId, props.team.dataRoleId, "calendar", "read.all") ?
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="primary" icon>
                                    <CardIcon color="primary">
                                        <CalendarTodayIcon />
                                    </CardIcon>
                                    <h4 className={classes.cardIconTitle}>{translate("accountability_view_calendar_title")}</h4>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={props.viewAllTeams}
                                                onChange={event => props.onFilterChange("viewAllTeams", event.target.checked)}
                                                value={"checkedA"}
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                            />
                                        }
                                        className={classes.switchFilter}
                                        label={translate("accountability_view_visualize_toggle_label")}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <BigCalendar
                                        localizer={localizer}
                                        events={props.events}
                                        toolbar={false}
                                        views={["work_week", "day"]}
                                        drilldownView="work_week"
                                        defaultView="work_week"
                                        min={new Date(2020, 1, 0, 7, 0, 0)}
                                        max={new Date(2020, 1, 0, 17, 30, 0)}
                                        components={{
                                            //toolbar: CustomToolbar,
                                            work_week: {
                                                header: weekDayHeader
                                            }
                                        }}
                                        step={15}
                                        onSelectEvent={event => selectedEvent(event)}
                                        eventPropGetter={eventColors}
                                    />
                                </CardBody>
                            </Card>
                        </GridItem>
                        :
                        null
                }
            </GridContainer>
        </>
    );
}
