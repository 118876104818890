import { ResponseMessages, Commitment } from "components/Constants";
import axios from "axios";
import { signOut } from "store/LoginState";
import store from "store";

/*
Get the list of external commitments by user
*/
async function getExternalCommitments() {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Commitment.GetUserCommitmentEndPoint}/external`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getExternalCommitments returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of commitments for a team
*/
async function getCommitmentsByTeam(teamId) {
    try {
        let requestHeaders = createHeaders()
       
        let response = await axios({
            method: "get",
            url: `${Commitment.GetEndPoint}/${teamId}/commitment`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getCommitmentsByTeam returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Used to create a commitment:
Retuns the id of the response
*/
async function createCommitment(newCommitment, teamId) {
    try {
        let url = `${Commitment.GetEndPoint}/${teamId}/wig/${newCommitment.wigId}/commitment`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: newCommitment
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`createCommitment returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for createCommitment`)

            // If it's a success response
            if (response.status === 201) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


/*
Used to delete a commitment:
Retuns the id of the response
*/
async function deleteCommitmentById(teamId, wigId, commitmentId) {
    try {
        let url = `${Commitment.GetEndPoint}/${teamId}/wig/${wigId}/commitment/${commitmentId}`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "delete",
            url: url,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`deleteCommitment returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for deleteCommitment`)

            // If it's a success response
            if (response.status === 204) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }

}



/*
Used to add a commitmeent tracking
*/
async function saveCommitmentTracking(newTracking, teamId, wigId, commitmentId) {
    try {
        let url = `${Commitment.GetEndPoint}/${teamId}/wig/${wigId}/commitment/${commitmentId}/tracking`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: newTracking
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`saveCommitmentTracking returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for saveCommitmentTracking`)

            // If it's a success response
            if (response.status === 201) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of commitments for a minute
*/
async function getCommitmentsByDate(teamId,recordDate) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Commitment.GetEndPoint}/${teamId}/${recordDate}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getCommitmentsByDate returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get Commitment by id
*/
async function getCommitmentsById(teamId,commitmentId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Commitment.GetCommitmentByIDEndPoint}/${teamId}/${commitmentId}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getCommitmentsById returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}
/*
Get the list of ids commitments by a minute
*/
async function getCommitmentsByMinute(teamId,minuteId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Commitment.GetCommitmentListbyMinuteEndPoint}/${teamId}/${minuteId}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        console.log(error.response);
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getCommitmentsByMinute returned a status code ${error.response.status}`)
                }
            } 
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload, teamId: teamId, minuteId: minuteId }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}
function createHeaders() {
    let headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
        "nonce": localStorage.getItem('nonce')
    }

    return headers
}
/*
Get ClearPath by id
*/
async function getClearPathById(teamId,commitmentId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Commitment.GetClearPathtByIDEndPoint}/${teamId}/${commitmentId}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getClearPathById returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get CommitmentTracking by id
*/
async function getCommitmentTrackingById(teamId,commitmentId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Commitment.GetTrackingByIDEndPoint}/${teamId}/${commitmentId}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getCommitmentTracking returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


/*
Get Commitment Reminder
*/
async function getCommitmentReminder(recordDate) {
    
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Commitment.GetCommitmentReminder}/${recordDate}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getCommitmentReminder returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get Commitments WHERE YEAR AND WEEK
*/
async function getCommitmentWeek(week,year) {
    
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Commitment.GetCommitmentAv}/${week}/${year}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getCommitmentWeek returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}
export {
    createCommitment,
    getExternalCommitments,
    getCommitmentsByTeam,
    saveCommitmentTracking,
    deleteCommitmentById,
    getCommitmentsByDate,
    getCommitmentsByMinute,
    getCommitmentsById,
    getClearPathById,
    getCommitmentTrackingById,
    getCommitmentReminder,
    getCommitmentWeek
}