/*eslint-disable*/
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
    CircularProgress,
    Select,
    MenuItem
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from '@material-ui/core/Tooltip';

import { DataTypes } from "components/Constants"
import { formatValue, formatWIGEditName } from "utils/dataTypeFormatter.js";

// material ui icons
import List from "@material-ui/icons/List";
import TrackChanges from "@material-ui/icons/TrackChanges";
import AddAlert from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";
import Flag from "@material-ui/icons/Flag";
import DashboardIcon from "@material-ui/icons/Dashboard";

// core components
import Table from "components/Table/Table.js";
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputMask from "components/CustomInputMask/CustomInputMask";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import { formatDate } from "utils/functions"
import { NavLink } from "react-router-dom";
import { getUserLocale } from "utils/translate";
import moment from "moment";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

// Functions
import { translate } from "utils/translate";

const useStyles = makeStyles(styles);

export default function trackingView(props) {
    let idiomChange = getUserLocale();
    const [data, setData] = useState(null);
    const classes = useStyles();

    function fetchData() {
        const mesesEntreFechas = [];
        if (idiomChange == "en-US") {
            moment.locale('en');
            var fechaInicial = props.wig != undefined ? moment.utc(props.wig.startDate) : null; // Reemplaza '2022-01-01' con tu fecha inicial
            var fechaFinal = props.wig != undefined ? moment.utc(props.wig.endDate).endOf('month') : null;// Fecha actual, puedes reemplazarlo con tu fecha final

            var mesesDeDiferencia = fechaInicial && fechaFinal ? fechaFinal.diff(fechaInicial, 'months') : null;

            for (let i = 0; i <= mesesDeDiferencia; i++) {
                const fecha = moment(fechaInicial).add(i, 'months');
                mesesEntreFechas.push(fecha.format('MMMM'));
            }

        } else {
            moment.locale('es');
            var fechaInicial = props.wig != undefined ? moment.utc(props.wig.startDate) : null; // Reemplaza '2022-01-01' con tu fecha inicial
            var fechaFinal = props.wig != undefined ? moment.utc(props.wig.endDate).endOf('month') : null;// Fecha actual, puedes reemplazarlo con tu fecha final

            var mesesDeDiferencia = fechaInicial && fechaFinal ? fechaFinal.diff(fechaInicial, 'months') : null;

            for (let i = 0; i <= mesesDeDiferencia; i++) {
                const fecha = moment(fechaInicial).add(i, 'months');
                mesesEntreFechas.push(fecha.format('MMMM'));
            }

        }
        setData(mesesEntreFechas)
    }


    useEffect(() => {
        // Assuming fetchData is a function that returns a Promise
        fetchData()
    }, [props.wig]);

    function getAxesTrackingForm(index) {
        if (props.wig === undefined || props.wig[`x${index}`] === null) {
            return (null);
        }
        let smSize = (props.wig.axesNumber === 2 ? 6 : 12)
        return (
            <GridItem xs={12} sm={smSize} md={smSize}>
                <FormLabel component="legend">{props.wig[`displayName${index}`]}</FormLabel>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInputMask
                            labelText={translate("const_y")}
                            id={`inputY${index}`}
                            formControlProps={{
                                fullWidth: true
                            }}
                            suffix={DataTypes[props.wig[`dataTypeId${index}`]].suffix}
                            decimalScale={DataTypes[props.wig[`dataTypeId${index}`]].decimalScale}
                            inputProps={{
                                type: "text",
                                value: props.wig[`y${index}`],
                                disabled: true
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInputMask
                            success={props.trackingState[`goalAchived${index}`] === "success"}
                            error={props.trackingState[`goalAchived${index}`] === "error"}
                            labelText={translate("const_goalachieved") + " *"}
                            id={`goalAchived${index}`}
                            formControlProps={{
                                fullWidth: true
                            }}
                            suffix={DataTypes[props.wig[`dataTypeId${index}`]].suffix}
                            decimalScale={DataTypes[props.wig[`dataTypeId${index}`]].decimalScale}
                            inputProps={{
                                onChange: event => {
                                    event.target.value = (event.target.value === "" ? "" : parseFloat(event.target.value))
                                    props.onFieldChange(`goalAchived${index}`, event);
                                },
                                type: "text",
                                value: props.tracking[`goalAchived${index}`],
                                endAdornment:
                                    props.trackingState[`goalAchived${index}`] === "error" ? (
                                        <InputAdornment position="end">
                                            <Close className={classes.danger} />
                                        </InputAdornment>
                                    ) : (
                                        undefined
                                    )
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                            success={props.trackingState[`commentary${index}`] === "success"}
                            error={props.trackingState[`commentary${index}`] === "error"}
                            labelText={translate("const_commentary")}
                            id={`inputCommentary${index}`}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: event => {
                                    props.onFieldChange(`commentary${index}`, event)
                                },
                                type: "text",
                                value: props.tracking[`commentary${index}`],
                                endAdornment:
                                    props.trackingState[`commentary${index}`] === "error" ? (
                                        <InputAdornment position="end">
                                            <Close className={classes.danger} />
                                        </InputAdornment>
                                    ) : (
                                        undefined
                                    )
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
        );
    }

    function getTableForAxis(index) {
        if (props.wig === undefined || props.wig[`x${index}`] === null) {
            return (null);
        }
        return (
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary" icon>
                        <CardIcon color="primary">
                            <List />
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>{`${props.wig[`displayName${index}`]} - ${translate("const_tracking")}`}</h4>
                    </CardHeader>
                    <CardBody>
                        <Table
                            tableHead={[
                                translate("table_header_date"),
                                translate("table_header_month"),
                                translate("const_y"),
                                translate("const_goalachieved"),
                                translate("const_difference"),
                                translate("const_commentary")
                            ]}
                            tableData={
                                props[`trackingList${index}`].result.map((item, key) => {
                                    return [
                                        formatDate(item.createdAt, getUserLocale()),

                                        props.monthsList[`${item.period - 1}`],
                                        formatValue(item.y, item.dataTypeId),
                                        formatValue(item.goalAchived, item.dataTypeId),
                                        formatValue(item.difference, item.dataTypeId),
                                        item.commentary
                                    ]
                                })}
                            customCellClasses={[classes.center, classes.right, classes.right, classes.center, classes.right]}
                            customClassesForCells={[0, 2, 3, 4, 6]}
                            customHeadCellClasses={[classes.center, classes.right, classes.right, classes.center, classes.right]}
                            customHeadClassesForCells={[0, 2, 3, 4, 6]}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        );
    }

    let breadcrumbViews = [
        {
            name: translate("routes_home_label"),
            url: "/admin/dashboard",
            icon: DashboardIcon
        },
        {
            name: translate("wig"),
            url: `/admin/wig/details/${props.wigId}`,
            icon: Flag
        },
        {
            name: translate("const_tracker"),
            url: `/admin/wig/tracking/${props.wigId}`,
            icon: TrackChanges
        }
    ]

    return (
        <div>
            <Breadcrumbs views={breadcrumbViews} />
            <Heading
                textAlign="center"
                title={(props.wig ? formatWIGEditName(props.wig) : "")}
                category={
                    <span>
                        {translate("wig_tracker_subheader")}
                    </span>
                }
            />
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <TrackChanges />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{translate("const_tracker")}</h4>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="simple-select"
                                                className={classes.selectLabel}
                                            >
                                                {translate("const_month")}
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu
                                                }}
                                                classes={{
                                                    select: classes.select
                                                }}
                                                value={props.tracking.month-1}
                                                onChange={e => props.onFieldChange("month", e)}
                                                inputProps={{
                                                    name: "selectMonth",
                                                    id: "selectMonth"
                                                }}
                                            >

                                                {props.monthsList.map((month, index) => {
                                                    let valueDesabilitacion = true;
                                                    if (idiomChange == "en-US") {
                                                        if (data) {
                                                            data.forEach((mes) => {
                                                                if (month == mes) {
                                                                    valueDesabilitacion = false;
                                                                }
                                                            });
                                                        }
                                                    } else {
                                                        if (data) {
                                                            data.forEach((mes) => {
                                                                if (month.toLowerCase() == mes) {
                                                                    valueDesabilitacion = false;
                                                                }
                                                            });
                                                        }
                                                    }
                                                    
                                                    return (
                                                        <MenuItem
                                                            disabled={valueDesabilitacion}
                                                            classes={{
                                                                root: classes.selectMenuItem,
                                                                selected: classes.selectMenuItemSelected
                                                            }}
                                                            style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                                                            value={index}
                                                            key={index}
                                                        >
                                                            {month}
                                                        </MenuItem>
                                                    );
                                                })}

                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridContainer>
                                            {getAxesTrackingForm(1)}
                                            {getAxesTrackingForm(2)}
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                                <div className={classes.formCategory}>
                                    <small>*</small> {translate("required_fields")}
                                </div>
                                <div className={classes.center}>
                                    {props.isLoadingSave ?
                                        <CircularProgress color="inherit" /> :
                                        <Button color="primary" onClick={props.onTrackingSave}
                                            disabled={props.wig === undefined}>
                                            {translate("button_save")}
                                        </Button>
                                    }
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                    <Snackbar
                        place="br"
                        color="success"
                        icon={AddAlert}
                        message={translate("alert_tracking_success")}
                        open={props.createSuccess}
                        closeNotification={() => props.changeCreateSuccess(false)}
                        close
                    />
                    <Snackbar
                        place="bl"
                        color="danger"
                        icon={AddAlert}
                        message={props.validationMessage}
                        open={props.createError}
                        closeNotification={() => props.changeCreateError(false)}
                        close
                    />
                </GridItem>
                {getTableForAxis(1)}
                {getTableForAxis(2)}
            </GridContainer>
        </div>
    );
}
