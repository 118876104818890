/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// material ui icons
import AddAlert from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Group from "@material-ui/icons/Group";
import Edit from "@material-ui/icons/Edit";
import SearchIcon from '@material-ui/icons/Search';

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/Team/editStyle.js";
import Explore from "@material-ui/icons/Explore";
import ReactTable from "react-table";
import Tooltip from '@material-ui/core/Tooltip';
import SweetAlert from "react-bootstrap-sweetalert";
import { CircularProgress, Select, MenuItem, Grid } from "@material-ui/core";
// Functions 
import { translate } from "utils/translate";

const useStyles = makeStyles(styles);

export default function createView(props) {
    const classes = useStyles();
    const [openSpecialistList, setOpenSpecialistList] = React.useState(false);
    const [openTeamList, setOpenTeamList] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    let currentYear=new Date().getFullYear();
    let nextYear= new Date().getFullYear() + 1;
    let nnextYear = new Date().getFullYear() + 2;
    let nnnextYear = new Date().getFullYear() + 3;
    let t_mcis_cy= translate("assigment_title_wigs") + " " + currentYear;
    let t_mcis_ny= translate("assigment_title_wigs") + " " + nextYear;
    let t_mcis_nny= translate("assigment_title_wigs") + " " + nnextYear;
    let t_mcis_nnny= translate("assigment_title_wigs") + " " + nnnextYear;
    let t_pre_cy= translate("assigment_title_predictives") + " " + currentYear;
    let t_pre_ny= translate("assigment_title_predictives") + " " + nextYear;
    let messageyear= "";
    let years= false;

    if((props.parentWIGList.length<=0 && props.wigsCY) || (props.parentWIGList.length<=0 && props.preCY)){
      messageyear= messageyear + currentYear + " ";
      years= true;
    }
    if((props.parentWIGList1.length<=0 && props.wigsNY) || (props.parentWIGList1.length<=0 && props.preNY)){
      messageyear= messageyear + nextYear + " ";
      years= true;
    }
    if(props.parentWIGList2.length<=0 && props.wigsNNY){
      messageyear= messageyear + nnextYear + " ";
      years= true;
    }
    if(props.parentWIGList3.length<=0 && props.wigsNNNY){
      messageyear= messageyear + nnnextYear + " ";
      years= true;
    }
    
    let messagey= translate( "parent_notwigss") + ": " + messageyear;
    let dataTableInfo = props.WigList.map((prop) => {
        return {
          id: prop.id,
          name: prop.Name,
          parent: prop.Parent,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <Tooltip title={translate("table_header_parent_WIG")}>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Lead Wig
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                          newValue = parseInt(newValue.id)
                      }
                      props.AssignParentId(prop.Id, event.target.value)
                    }}
                    inputProps={{
                      name: "parentWig",
                      id: "parentWig"
                    }}
                  >
    
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={""}
                      disabled
                    >
                      {translate("commitment_wig_input")}
                    </MenuItem>
                    {props.parentWIGList.map((wig, index) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        style={{ overflowX: "auto" }}
                        value={wig.id}
                        key={index}
                      >
                        {wig.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
            </div>
          )
        }
    })
    
      let dataTableInfoNY = props.WigListNY.map((prop) => {
        return {
          id: prop.id,
          name: prop.Name,
          parent: prop.Parent,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <Tooltip title={translate("table_header_parent_WIG")}>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Lead Wig
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                          newValue = parseInt(newValue.id)
                      }
                      props.AssignParentIdNY(prop.Id, event.target.value)
                    }}
                    inputProps={{
                      name: "parentWig",
                      id: "parentWig"
                    }}
                  >
    
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={""}
                      disabled
                    >
                      {translate("commitment_wig_input")}
                    </MenuItem>
                    {props.parentWIGList1.map((wig, index) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        style={{ overflowX: "auto" }}
                        value={wig.id}
                        key={index}
                      >
                        {wig.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
            </div>
          )
        }
      })
      
      let dataTableInfoNNY = props.WigListNNY.map((prop) => {
        return {
          id: prop.id,
          name: prop.Name,
          parent: prop.Parent,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <Tooltip title={translate("table_header_parent_WIG")}>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Lead Wig
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                          newValue = parseInt(newValue.id)
                      }
                      props.AssignParentIdNNY(prop.Id, event.target.value)
                    }}
                    inputProps={{
                      name: "parentWig",
                      id: "parentWig"
                    }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={""}
                      disabled
                    >
                      {translate("commitment_wig_input")}
                    </MenuItem>
                    {props.parentWIGList2.map((wig, index) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        style={{ overflowX: "auto" }}
                        value={wig.id}
                        key={index}
                      >
                        {wig.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
            </div>
          )
        }
      })

      
      let dataTableInfoNNNY = props.WigListNNNY.map((prop) => {
        return {
          id: prop.id,
          name: prop.Name,
          parent: prop.Parent,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <Tooltip title={translate("table_header_parent_WIG")}>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Lead Wig
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                          newValue = parseInt(newValue.id)
                      }
                      props.AssignParentIdNNNY(prop.Id, event.target.value)
                    }}
                    inputProps={{
                      name: "parentWig",
                      id: "parentWig"
                    }}
                  >
    
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={""}
                      disabled
                    >
                      {translate("commitment_wig_input")}
                    </MenuItem>
                    {props.parentWIGList3.map((wig, index) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        style={{ overflowX: "auto" }}
                        value={wig.id}
                        key={index}
                      >
                        {wig.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
            </div>
          )
        }
      })
      let dataTableInfo1 = props.PredictiveList.map((prop) => {
        return {
          id: prop.Id,
          name: prop.Name,
          parent: prop.WIGName,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <Tooltip title={translate("table_header_parent_WIG")}>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Lead Wig
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    
                    onChange={(event, newValue) => {
                      if (newValue) {
                          newValue = parseInt(newValue.id)
                      }
                      props.AssignParentIdP(prop.Id, event.target.value)
                  }}
                    inputProps={{
                      name: "parentWig",
                      id: "parentWig"
                    }}
                  >
    
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={""}
                      disabled
                    >
                      {translate("commitment_wig_input")}
                    </MenuItem>
                    {props.parentWIGList.map((wig, index) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        style={{ overflowX: "auto" }}
                        value={wig.id}
                        key={index}
                      >
                        {wig.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
            </div>
          )
        }
      })

      let dataTableInfoNY1 = props.PredictiveListNY.map((prop) => {
        return {
          id: prop.Id,
          name: prop.Name,
          parent: prop.WIGName,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <Tooltip title={translate("table_header_parent_WIG")}>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Lead Wig
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                          newValue = parseInt(newValue.id)
                      }
                      props.AssignParentIdPNY(prop.Id, event.target.value)
                    }}
                    inputProps={{
                      name: "parentWig",
                      id: "parentWig"
                    }}
                  >

                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={""}
                      disabled
                    >
                      {translate("commitment_wig_input")}
                    </MenuItem>
                    {props.parentWIGList1.map((wig, index) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        style={{ overflowX: "auto" }}
                        value={wig.id}
                        key={index}
                      >
                        {wig.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
            </div>
          )
        }
      })

      const PredictivesAssignAlert = () => {
        let alertMessage = (null)
        if (props.predictivesAssign) {
          alertMessage = (
            <SweetAlert
              warning
              style={{ display: "block" }}
              title={translate("alert_assign_predictives")}
              onConfirm={() => props.CopyPredictives()}
              onCancel={() => props.onModalClosed()}
              confirmBtnCssClass={(props.alertLoading ? classes.disabled : "") + " " + classes.button + " " + classes.success}
              cancelBtnCssClass={classes.button + " " + classes.danger}
              confirmBtnText={translate("assign_predictives")}
              cancelBtnText={translate("alert_commitment_delete_cancel_text")}
              showCancel
              disabled={props.alertLoading}
            >
              {
               // getCommitment(props.alertId)
              }
              {props.alertLoading ?
                <GridContainer justify="center">
                  <Grid>
                    <div className={classes.circularProgress}>
                      <CircularProgress color="inherit" />
                    </div>
                  </Grid>
                </GridContainer>
                :
                null
              }
            </SweetAlert>
          )
        }
        return (alertMessage)
      }
    
      
    let breadcrumbViews = [
        {
            name: translate("routes_home_label"),
            url: "/admin/dashboard",
            icon: DashboardIcon
        },
        {
            name: translate("routes_team_label"),
            url: "/admin/team/index",
            icon: Group
        },
        {
            name: translate("routes_team_edit_label"),
            url: `/admin/team/edit/${props.teamId}`,
            icon: Edit
        }
    ]

    return (
        <>
         {PredictivesAssignAlert()}
            <Breadcrumbs views={breadcrumbViews} />
            <Heading
                textAlign="center"
                title={translate("team_edit_header")}
            />
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Group />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{translate("team_edit_title")}</h4>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={6} md={6}>
                                                <CustomInput
                                                    success={props.teamState.title === "success"}
                                                    error={props.teamState.title === "error"}
                                                    labelText={translate("team_create_name_label") + "*"}
                                                    id="inputTitle"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        onChange: event => {
                                                            props.onFieldChange("title", event.target.value)
                                                        },
                                                        type: "text",
                                                        value: props.team.title,
                                                        endAdornment:
                                                            props.teamState["title"] === "error" ? (
                                                                <InputAdornment position="end">
                                                                    <Close className={classes.danger} />
                                                                </InputAdornment>
                                                            ) : (
                                                                    undefined
                                                                )
                                                    }}
                                                />
                                            </GridItem>

                                            <GridItem xs={12} sm={6} md={6}>

                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <Autocomplete
                                                        loadingText={translate("autocomplete_loading_text")}
                                                        clearText={translate("autocomplete_clear_text")}
                                                        noOptionsText={translate("autocomplete_no_options_text")}
                                                        id="teamList"
                                                        open={openTeamList}
                                                        onOpen={() => {
                                                            setOpenTeamList(true);
                                                            props.changeLoadingTeamList(true);
                                                        }}
                                                        onClose={() => {
                                                            setOpenTeamList(false);
                                                            //props.setTeamList([]);
                                                        }}
                                                        //onChange={handleTeamChage}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                newValue = parseInt(newValue.id)
                                                            }
                                                            props.onFieldChange("parentTeamId", newValue)
                                                        }}
                                                       // onChange={e => props.onFieldChangeTeam("parentTeamId",e.target.value)}
                                                        getOptionSelected={(option, value) => option.id === value.parentTeamId}
                                                        getOptionLabel={(option) => `${option.title}`? `${option.title}` : ''}
                                                        options={props.teamList}
                                                        loading={props.loadingTeamList}
                                                        value={props.teamList.find(e => e.id === props.team.parentTeamId)?props.teamList.find(e => e.id === props.team.parentTeamId):''}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={translate("choose_parent_team")}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            <SearchIcon />
                                                                            {props.loadingTeamList ? <CircularProgress color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </GridItem>

                                            <GridItem xs={12} sm={12} md={6}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <Autocomplete
                                                        loadingText={translate("autocomplete_loading_text")}
                                                        clearText={translate("autocomplete_clear_text")}
                                                        noOptionsText={translate("autocomplete_no_options_text")}
                                                        id="userList"
                                                        disabled={false}
                                                        open={openSpecialistList}

                                                        onOpen={() => {
                                                            setOpenSpecialistList(true);
                                                            props.changeLoadingSpecialistList(true);
                                                        }}
                                                        onClose={() => {
                                                            setOpenSpecialistList(false);
                                                            //props.setUserList([]);
                                                        }}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                newValue = parseInt(newValue.userId)
                                                            }
                                                            props.onFieldChange("specialistId", newValue)
                                                        }}
                                                        getOptionSelected={(option, value) => option.userId === value.userId}
                                                        getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`?`${option.firstName} ${option.lastName} (${option.email})`:''}                                                        
                                                        options={props.userList}
                                                        value={props.userList.find(e => e.userId === props.team.specialistId)?props.userList.find(e => e.userId === props.team.specialistId):''}
                                                        loading={props.loadingSpecialistList}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={translate("specialist_user_label")}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            <SearchIcon />
                                                                            {props.loadingSpecialistList ? <CircularProgress color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </GridItem> <GridItem xs={12} sm={4} md={4}>
                                            <CustomInput
                                                labelText= {translate("leader_user_label") } 
                                                id="inputFullName"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    type: "text",
                                                    value: `${props.leader_st}`,
                                                }}
                                            />
                                                                                            </GridItem>
                                            {(props.changeLeader ) ?
                                            
                                            <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText= {translate("table_header_parent") } 
                                                id="inputFullName"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    type: "text",
                                                    value: `${props.teamLead}`,
                                                }}
                                            />
                                        </GridItem>

                                        :
                                        null
                                    }
                                    {(props.selectFromTeam== false) ?
                                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                                <div className={classes.block}>
                                                <FormControlLabel
                                                disabled = {props.copyw}
                                                control={
                                                <Switch
                                                checked={props.copyWIG}
                                                onChange={event => props.onFieldChangeWIG(event.target.checked)}
                                                value="copyWIG"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                                />
                                                }
                                                classes={{
                                                    label: classes.label
                                                }}
                                                label={translate("copy_wig_leader_label")}
                                                />

                                                </div>
                                                <div className={classes.block}>
                                                <FormControlLabel
                                                 disabled = {props.copyp}
                                                control={
                                                <Switch
                                                checked={props.copyPredictive}
                                                onChange={event => props.onFieldChangePredictive(event.target.checked)}
                                                value="copyPredictive"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                                />
                                                }
                                                classes={{
                                                    label: classes.label
                                                }}
                                                label={translate("copy_predictive_leader_label")}
                                                />

                                                </div>
                                                <div className={classes.block}>
                                                <FormControlLabel
                                          
                                                control={
                                                <Switch
                                                checked={props.delete}
                                                onChange={event => props.onFieldChangeDelete(event.target.checked)}
                                                value="copyWIG"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                                />
                                                }
                                                classes={{
                                                    label: classes.label
                                                }}
                                                label={translate("delete_user")}
                                                />

                                                </div>
                                                
                                            </GridItem>
                                        :
                                        null
                                    }
                                        </GridContainer>
                                        {(props.copyWIG && props.wigsCY) ?
                                            <GridContainer>
                                            <GridItem xs={12}>
                                              <Card>
                                                <CardHeader color="primary" icon>
                                                  <CardIcon color="primary">
                                                    <Explore />
                                                  </CardIcon>
                                                  <h4 className={classes.cardIconTitle}>{t_mcis_cy}</h4>
                                                </CardHeader>
                                                <CardBody>
                                                  <ReactTable
                                                    data={dataTableInfo}
                                                    filterable
                                                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                                                    columns={[
                                                      {
                                                        Header: translate("table_header_name"),
                                                        accessor: "name"
                                                      },
                                                      {
                                                        Header: translate("table_header_parent_WIG"),
                                                        accessor: "parent",
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                      {
                                                        Header: translate("table_header_new_parent_WIG"),
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                     
                                                      {
                                                        
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                      {sortable: false,
                                                        filterable: false}
                                                    ]}
                                                    defaultPageSize={10}
                                                    showPaginationTop
                                                    minRows={0}
                                                    showPaginationBottom={false}
                                                    className="-striped -highlight noActions"
                                                    previousText={translate("table_previous_text")}
                                                    nextText={translate("table_next_text")}
                                                    loadingText={translate("table_loading_text")}
                                                    noDataText={translate("table_not_found_text")}
                                                    pageText={translate("table_page_text")}
                                                    ofText={translate("table_of_text")}
                                                    rowsText={translate("table_rows_text")}
                                                  />
                                                </CardBody>
                                              </Card>
                                            </GridItem>
                                          </GridContainer> :
                                        null
                                    }
                                     {(props.copyWIG && props.wigsNY) ?
                                            <GridContainer>
                                            <GridItem xs={12}>
                                              <Card>
                                                <CardHeader color="primary" icon>
                                                  <CardIcon color="primary">
                                                    <Explore />
                                                  </CardIcon>
                                                  <h4 className={classes.cardIconTitle}>{t_mcis_ny}</h4>
                                                </CardHeader>
                                                <CardBody>
                                                  <ReactTable
                                                    data={dataTableInfoNY}
                                                    filterable
                                                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                                                    columns={[
                                                      {
                                                        Header: translate("table_header_name"),
                                                        accessor: "name"
                                                      },
                                                      {
                                                        Header: translate("table_header_parent_WIG"),
                                                        accessor: "parent",
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                      {
                                                        Header: translate("table_header_new_parent_WIG"),
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                     
                                                      {
                                                        
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                      {sortable: false,
                                                        filterable: false}
                                                    ]}
                                                    defaultPageSize={10}
                                                    showPaginationTop
                                                    minRows={0}
                                                    showPaginationBottom={false}
                                                    className="-striped -highlight noActions"
                                                    previousText={translate("table_previous_text")}
                                                    nextText={translate("table_next_text")}
                                                    loadingText={translate("table_loading_text")}
                                                    noDataText={translate("table_not_found_text")}
                                                    pageText={translate("table_page_text")}
                                                    ofText={translate("table_of_text")}
                                                    rowsText={translate("table_rows_text")}
                                                  />
                                                </CardBody>
                                              </Card>
                                            </GridItem>
                                          </GridContainer> :
                                        null
                                    }
                                    
                                    {(props.copyWIG && props.wigsNNY) ?
                                            <GridContainer>
                                            <GridItem xs={12}>
                                              <Card>
                                                <CardHeader color="primary" icon>
                                                  <CardIcon color="primary">
                                                    <Explore />
                                                  </CardIcon>
                                                  <h4 className={classes.cardIconTitle}>{t_mcis_nny}</h4>
                                                </CardHeader>
                                                <CardBody>
                                                  <ReactTable
                                                    data={dataTableInfoNNY}
                                                    filterable
                                                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                                                    columns={[
                                                      {
                                                        Header: translate("table_header_name"),
                                                        accessor: "name"
                                                      },
                                                      {
                                                        Header: translate("table_header_parent_WIG"),
                                                        accessor: "parent",
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                      {
                                                        Header: translate("table_header_new_parent_WIG"),
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                     
                                                      {
                                                        
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                      {sortable: false,
                                                        filterable: false}
                                                    ]}
                                                    defaultPageSize={10}
                                                    showPaginationTop
                                                    minRows={0}
                                                    showPaginationBottom={false}
                                                    className="-striped -highlight noActions"
                                                    previousText={translate("table_previous_text")}
                                                    nextText={translate("table_next_text")}
                                                    loadingText={translate("table_loading_text")}
                                                    noDataText={translate("table_not_found_text")}
                                                    pageText={translate("table_page_text")}
                                                    ofText={translate("table_of_text")}
                                                    rowsText={translate("table_rows_text")}
                                                  />
                                                </CardBody>
                                              </Card>
                                            </GridItem>
                                          </GridContainer> :
                                        null
                                    }
                                    
                                    {(props.copyWIG && props.wigsNNNY) ?
                                            <GridContainer>
                                            <GridItem xs={12}>
                                              <Card>
                                                <CardHeader color="primary" icon>
                                                  <CardIcon color="primary">
                                                    <Explore />
                                                  </CardIcon>
                                                  <h4 className={classes.cardIconTitle}>{t_mcis_nnny}</h4>
                                                </CardHeader>
                                                <CardBody>
                                                  <ReactTable
                                                    data={dataTableInfoNNNY}
                                                    filterable
                                                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                                                    columns={[
                                                      {
                                                        Header: translate("table_header_name"),
                                                        accessor: "name"
                                                      },
                                                      {
                                                        Header: translate("table_header_parent_WIG"),
                                                        accessor: "parent",
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                      {
                                                        Header: translate("table_header_new_parent_WIG"),
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                     
                                                      {
                                                        
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                      {sortable: false,
                                                        filterable: false}
                                                    ]}
                                                    defaultPageSize={10}
                                                    showPaginationTop
                                                    minRows={0}
                                                    showPaginationBottom={false}
                                                    className="-striped -highlight noActions"
                                                    previousText={translate("table_previous_text")}
                                                    nextText={translate("table_next_text")}
                                                    loadingText={translate("table_loading_text")}
                                                    noDataText={translate("table_not_found_text")}
                                                    pageText={translate("table_page_text")}
                                                    ofText={translate("table_of_text")}
                                                    rowsText={translate("table_rows_text")}
                                                  />
                                                </CardBody>
                                              </Card>
                                            </GridItem>
                                          </GridContainer> :
                                        null
                                    }
                                     {(props.copyPredictive && props.preCY) ?
                                            <GridContainer>
                                            <GridItem xs={12}>
                                              <Card>
                                                <CardHeader color="primary" icon>
                                                  <CardIcon color="primary">
                                                    <Explore />
                                                  </CardIcon>
                                                  <h4 className={classes.cardIconTitle}>{t_pre_cy}</h4>
                                                </CardHeader>
                                                <CardBody>
                                                  <ReactTable
                                                    data={dataTableInfo1}
                                                    filterable
                                                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                                                    columns={[
                                                      {
                                                        Header: translate("table_header_name"),
                                                        accessor: "name"
                                                      },
                                                      
                                                      {
                                                        Header: translate("table_header_parent_WIG"),
                                                        accessor: "parent",
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                      {
                                                        Header: translate("table_header_new_parent_WIG"),
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                     
                                                      {
                                                        
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                      {sortable: false,
                                                        filterable: false}
                                                    ]}
                                                    defaultPageSize={10}
                                                    showPaginationTop
                                                    minRows={0}
                                                    showPaginationBottom={false}
                                                    className="-striped -highlight noActions"
                                                    previousText={translate("table_previous_text")}
                                                    nextText={translate("table_next_text")}
                                                    loadingText={translate("table_loading_text")}
                                                    noDataText={translate("table_not_found_text")}
                                                    pageText={translate("table_page_text")}
                                                    ofText={translate("table_of_text")}
                                                    rowsText={translate("table_rows_text")}
                                                  />
                                                </CardBody>
                                              </Card>
                                            </GridItem>
                                          </GridContainer>
                                    :
                                        null
                                    }
                                    {(props.copyPredictive && props.preNY) ?
                                            <GridContainer>
                                            <GridItem xs={12}>
                                              <Card>
                                                <CardHeader color="primary" icon>
                                                  <CardIcon color="primary">
                                                    <Explore />
                                                  </CardIcon>
                                                  <h4 className={classes.cardIconTitle}>{t_pre_ny}</h4>
                                                </CardHeader>
                                                <CardBody>
                                                  <ReactTable
                                                    data={dataTableInfoNY1}
                                                    filterable
                                                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                                                    columns={[
                                                      {
                                                        Header: translate("table_header_name"),
                                                        accessor: "name"
                                                      },
                                                      
                                                      {
                                                        Header: translate("table_header_parent_WIG"),
                                                        accessor: "parent",
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                      {
                                                        Header: translate("table_header_new_parent_WIG"),
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                     
                                                      {
                                                        
                                                        sortable: false,
                                                        filterable: false
                                                      },
                                                      {sortable: false,
                                                        filterable: false}
                                                    ]}
                                                    defaultPageSize={10}
                                                    showPaginationTop
                                                    minRows={0}
                                                    showPaginationBottom={false}
                                                    className="-striped -highlight noActions"
                                                    previousText={translate("table_previous_text")}
                                                    nextText={translate("table_next_text")}
                                                    loadingText={translate("table_loading_text")}
                                                    noDataText={translate("table_not_found_text")}
                                                    pageText={translate("table_page_text")}
                                                    ofText={translate("table_of_text")}
                                                    rowsText={translate("table_rows_text")}
                                                  />
                                                </CardBody>
                                              </Card>
                                            </GridItem>
                                          </GridContainer>
                                    :
                                        null
                                    }
                                    </GridItem>
                                </GridContainer>
                                <div className={classes.formCategory}>
                                    <small>*</small> {translate("required_fields")}
                                </div>
                                <div className={classes.center}>
                                    {props.isLoadingSave ?
                                        <CircularProgress color="inherit" /> :
                                        <Button color="primary" onClick={props.onSave}>
                                            {translate("button_save")}
                                        </Button>
                                    }
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                    <Snackbar
                        place="br"
                        color="success"
                        icon={AddAlert}
                        message="Equipo actualizado exitósamente"
                        open={props.createSuccess}
                        closeNotification={() => props.setCreateSuccess(false)}
                        close
                    />
                    <Snackbar
                        place="bl"
                        color="danger"
                        icon={AddAlert}
                        message={props.validationMessage}
                        open={props.createError}
                        closeNotification={() => props.setCreateError(false)}
                        close
                    />
                      <Snackbar
                        place="bl"
                        color="warning"
                        icon={AddAlert}
                        message={messagey}
                        open={(props.yearsN && props.copyWIG) ||(props.yearsN && props.copyPredictive)}
                        closeNotification={() => props.setYearN(false)}
                        close
                    />
                </GridItem>
            </GridContainer>
        </>
    );
}
