/*eslint-disable*/
import React from "react";

// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer, Views } from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
//import globalize from 'globalize';

// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
    CircularProgress,
    Select,
    MenuItem
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// material ui icons
import AddAlert from "@material-ui/icons/AddAlert";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ScheduleIcon from '@material-ui/icons/Schedule';
import Edit from "@material-ui/icons/Edit";
import GradingIcon from '@material-ui/icons/AssignmentTurnedIn';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import { Days } from "components/Constants";
// table components
import Table from "components/Table/Table.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/Accountability/indexStyle.js";

import { verifyPermission } from "utils/functions";
import { translate } from "utils/translate"

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(styles);


function weekDayHeader(props) {
    return (<div style={{ textAlign: "center" }}>{Days[`${props.date.getDay()}`]}</div>)
}

const CustomToolbar = (toolbar) => {
    const goToBack = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
        toolbar.onNavigate('prev');
    };

    const goToNext = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
        toolbar.onNavigate('next');
    };

    const goToCurrent = () => {
        const now = new Date();
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        toolbar.onNavigate('current');
    };

    const label = () => {
        const date = moment(toolbar.date);
        return (
            <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
        );
    };
    const sCalendar = {}
    return (
        <div className={sCalendar['toolbar-container']}>
            <label className={sCalendar['label-date']}>{label()}</label>

            <div className={sCalendar['back-next-buttons']}>
                <button className={sCalendar['btn-back']} onClick={goToBack}>&#8249;</button>
                <button className={sCalendar['btn-current']} onClick={goToCurrent}>today</button>
                <button className={sCalendar['btn-next']} onClick={goToNext}>&#8250;</button>
            </div>
        </div >
    );
};

export default function createView(props) {
    const classes = useStyles();

    const selectedEvent = event => {
        window.location.href = `/admin/accountability`;
    };

    let breadcrumbViews = [
        {
            name: translate("routes_home_label"),
            url: "/admin/dashboard",
            icon: DashboardIcon
        },
        {
            name: translate("routes_wig_session_label"),
            url: `/admin/accountability`,
            icon: ScheduleIcon
        },
        {
            name: translate("routes_wig_sessions_label"),
            url: `/admin/accountabilityrecords/team/${props.teamId}`,
            icon: FormatListNumberedIcon
        },
        {
            name: translate("accountability_record_create_title"),
            url: `/admin/accountabilityrecord/team/`,
            icon: GradingIcon
        }
    ]

    const eventColors = event => {
        var backgroundColor = "event-";
        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");
        return {
            className: backgroundColor
        };
    };

    const [multipleSelect, setMultipleSelect] = React.useState([]);

    const handleMultiple = event => {
        setMultipleSelect(event.target.value);
    };


    let currDate = new Date();
    if (props.team.time) {
        currDate = new Date(currDate.setHours((props.team.time).substring(0, 2)));
        currDate = new Date(currDate.setMinutes((props.team.time).substring(3, 5)));
    }


    let questionsRows = [];
    if (props.questionsList && props.questionsList.length > 0 && props.multipleGrades.length>0 && props.multipleNotes.length > 0 && props.selectedMembers.length > 0) {
        props.questionsList.map((question, index) => (
            question.questionType == 1 ? questionsRows.push(
                [
                    `${question.Id}`,
                    `${translate("accountability_record_create_question_label_" + question.Id)}`,
                    <FormControl  fullWidth className={classes.selectFormControl} >
                        <InputLabel
                            htmlFor={`acGrade_${index}`}
                            className={classes.selectLabel}
                        >
                            {translate("accountability_record_create_score_input")}*
                        </InputLabel>
                        <Select
                            MenuProps={{
                                className: classes.selectMenu
                            }}
                            classes={{
                                select: classes.select
                            }}
                            value={props.multipleGrades[props.selectedTab][index]}
                            //error={props.gradesState[`acGrade_${index}`] == 'error' ? true : false}
                            //onChange={e => props.onGradeChange(`acGrade_${index}`, e.target.value)}
                            onChange={e => props.onMultipleGradesChange(props.selectedTab,index, e.target.value)}
                            inputProps={{
                                name: "simpleSelect",
                                id: `${index}`
                            }}
                        >
                            {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "N/A"].map((value, index) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  //selected: classes.selectMenuItemSelected
                                }}
                                style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                                value={value}
                                key={index}
                              >
                                {value}
                              </MenuItem>
                            ))}
                        </Select>
                    </FormControl >,
                    <CustomInput
                        labelText={translate("accountability_record_create_note_label")+"*"}
                        id={`acNotes_${index}`}
                        formControlProps={{
                            fullWidth: true
                        }}
                        error={props.gradesState[`acGrade_${index}`] == 'error' ? true : false}
                        inputProps={{
                            type: "text",
                            //onChange: e => { props.onNotesChange(`acGrade_${index}`, e.target.value) },
                            onChange: e => props.onMultipleNotesChange(props.selectedTab,index, e.target.value),
                            value: props.multipleNotes[props.selectedTab][index]
                        }}
                    />
                ]
            ) : null

        ));

    }

    return (
        <>
            <Breadcrumbs views={breadcrumbViews} />
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <GradingIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{translate("accountability_record_create_title")}</h4>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={6}>
                                            <GridContainer>
                                                <CustomInput
                                                    labelText={translate("accountability_record_create_team_label")}
                                                    id="inputTitle"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        disabled: true,
                                                        type: "text",
                                                        value: props.team.title
                                                    }}
                                                />
                                                <FormControl fullWidth className={classes.selectFormControl}>
                                                    <InputLabel
                                                        htmlFor="multiple-select"
                                                        className={classes.selectLabel}
                                                    >
                                                        {props.participants.length > 0 ? "" :
                                                            translate("accountability_record_create_participants_label")+"*"}
                                                    </InputLabel>
                                                    <Select
                                                        multiple
                                                        value={props.participants}
                                                        onChange={e => props.onParticipantsChange(e.target.value)}
                                                        MenuProps={{
                                                            className: classes.selectMenu,
                                                            classes: { paper: classes.selectPaper }
                                                        }}
                                                        classes={{ select: classes.select }}
                                                        inputProps={{
                                                            name: "multipleSelect",
                                                            id: "multiple-select"
                                                        }}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            {translate("accountability_record_create_participants_label")}
                                                        </MenuItem>
                                                        {props.membersList.map((user) => (
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelectedMultiple
                                                                }}
                                                                value={user.userId}
                                                                key={user.userId}
                                                            >
                                                                {`${user.firstName} ${user.lastName}`}
                                                            </MenuItem>
                                                        ))}


                                                    </Select>
                                                </FormControl>

                                            </GridContainer>

                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                {/******Table Details******/}
                                <Tabs
                                    TabIndicatorProps={{
                                        style: { background: "#2C867B"}
                                      }}
                                    
                                    value={props.selectedTab}
                                    onChange={props.onChangeTab}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    {props.selectedMembers.map((user, index) => (

                                        <Tab                                            
                                            label={user.firstName+' '+user.lastName} 
                                            key={user.userId}
                                        />    
                                        
                                    ))}
                                </Tabs>

                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer justify="center">
                                        
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Table
                                                striped
                                                tableHead={[
                                                    <p style={{ fontWeight: 500 }}>No.</p>,
                                                    <GridItem xs={12} sm={12} md={12} style={{ fontWeight: 500 }}>
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <p
                                                                    style={{ textAlign: "center" }}
                                                                >{translate("accountability_record_create_aspect_label")}</p>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <p
                                                                    style={{ textAlign: "center" }}
                                                                >{translate("accountability_record_create_methodology_label")}</p>
                                                            </GridItem>
                                                        </GridContainer>
                                                    </GridItem>,
                                                    <p style={{ fontWeight: 500 }}>{translate("accountability_record_create_score_label")}</p>,
                                                    <p style={{ fontWeight: 500 }}>{translate("accountability_record_create_observations_label")}</p>
                                                ]}

                                                tableData={
                                                    questionsRows
                                                }
                                                style={{ width: "100%" }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12} >
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <h4>{translate("accountability_record_create_general_observations_label")}:</h4>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText={translate("accountability_record_create_general_observations_label")+"*"}
                                                id="notes"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    defaultValue: props.observation,
                                                    type: "text",
                                                    //onChange: e => { props.onNotesChange(`acGrade_8`, e.target.value) },
                                                    //onChange: e => { props.onMultipleNotesChange(0,8, e.target.value) },
                                                    onChange: e => {props.setObservation(e.target.value)}
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <div className={classes.center}>
                                    {props.isLoadingSave ?
                                        <CircularProgress color="inherit" /> :
                                        <Button 
                                        color="primary" 
                                        onClick={props.gradesSave}
                                        >
                                            {translate("button_save")}
                                        </Button>
                                    }
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                    <Snackbar
                        place="br"
                        color="success"
                        icon={AddAlert}
                        message={translate("alert_wig_session_saved_success")}
                        open={props.createSuccess}
                        closeNotification={() => props.setCreateSuccess(false)}
                        close
                    />
                    <Snackbar
                        place="bl"
                        color="danger"
                        icon={AddAlert}
                        message={props.validationMessage}
                        open={props.createError}
                        closeNotification={() => props.setCreateError(false)}
                        close
                    />
                </GridItem>
            </GridContainer>
        </>
    );
}
