import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { getAllUsers, deleteUserById, partialUpdateUser } from "services/apiUser/userApi";

import IndexView from "./IndexView"

let _isMounted = false

async function getUsers(props) {
	let result = await getAllUsers();
	if (_isMounted && result.success) {
		props.setUserList(result.data)
	}
}

async function deleteUser(props, id) {
	props.changeAlertLoading(true)

	let result = await deleteUserById(id)

	if (result.success) { //result.success
		getUsers(props)
	}

	props.changeAlertLoading(false)
	props.changeAlertVisible(false)
	window.location.href = `/admin/user/index`;
}

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{}
	),
	withState("userList", "setUserList", []),
	withState("alertVisible", "changeAlertVisible", false),
	withState("alertId", "changeAlertId", undefined),
	withState("alertLoading", "changeAlertLoading", false),
	withHandlers({
		onModalClosed: (props) => () => {
			props.changeAlertLoading(false)
			props.changeAlertVisible(false)
		},
		onTeamDelete: (props) => (id) => {
			deleteUser(props, id)
		},
		onTeamChange: (props) => async (field, value, id) => {
			let user = props.userList.find((user)=>{
				return user.id === id
			})
			user[field] = value

			props.setUserList(props.userList)
			await partialUpdateUser(user.id, user)
	},
	}),
	lifecycle({
		componentDidMount() {
			_isMounted = true
			getUsers(this.props)

		}
	})
)(IndexView);
