/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
    CircularProgress,
    Select,
    MenuItem
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { DataTypes } from "components/Constants"
// material ui icons
import Flag from "@material-ui/icons/Flag";
import AddAlert from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Edit from "@material-ui/icons/Edit";

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputMask from "components/CustomInputMask/CustomInputMask";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import NavPills from "components/NavPills/NavPills.js";

// partial views
import GeneralPartialView from "./General";
import GoalsPartialView from "./Goals";

import { formatWIGName } from "utils/dataTypeFormatter";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/WIG/editStyle.js";
import { translate } from "utils/translate";

const useStyles = makeStyles(styles);

export default function createView(props) {
    const classes = useStyles();

    let breadcrumbViews = [
        {
            name: translate("routes_home_label"),
            url: "/admin/dashboard",
            icon: DashboardIcon
        },
        {
            name: translate("wig"),
            url: `/admin/wig/details/${props.wigId}`,
            icon: Flag
        },
        {
            name: translate("routes_edit_label"),
            url: `/admin/wig/edit/${props.wigId}`,
            icon: Edit
        }
    ]
    return (
        <div>
            <Breadcrumbs views={breadcrumbViews} />
            <Heading
                textAlign="center"
                title={translate("wig_edit_header")}
            />
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Flag />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{translate("wig_edit_title")}</h4>
                        </CardHeader>
                        <CardBody>
                            <NavPills
                                animateHeight
                                color="primary"
                                tabs={[
                                    {
                                        tabButton: translate("wig_edit_tab_general"),
                                        tabContent: (
                                            <GeneralPartialView
                                                wig={props.wig}
                                                wigState={props.wigState} 
                                                isLoadingSave={props.isLoadingSave}
                                                onFieldChange={props.onFieldChange} 
                                                onWIGSave={props.onWIGSave} 
                                                parentWIGList = {props.parentWIGList} 
                                                parentName= {props.parentName} 
                                                onFieldChangeParent={props.onFieldChangeParent}
                                                match={props.match}
                                            />
                                        )
                                    },
                                    {
                                        tabButton: translate("wig_edit_tab_goals"),
                                        tabContent: (
                                            <GoalsPartialView
                                                onGoalsSave={props.onGoalsSave}
                                                wigId={props.wigId}
                                                wig={props.wig}
                                            />
                                        )
                                    }
                                ]}
                            />

                        </CardBody>
                    </Card>
                    <Snackbar
                        place="br"
                        color="success"
                        icon={AddAlert}
                        message={translate("alert_wig_updated_success")}
                        open={props.createSuccess}
                        closeNotification={() => props.onWIGSaveM()}
                        close
                    />
                    <Snackbar
                        place="bl"
                        color="danger"
                        icon={AddAlert}
                        message={props.validationMessage}
                        open={props.createError}
                        closeNotification={() => props.changeCreateError(false)}
                        close
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
}
