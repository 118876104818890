import { Team, ResponseMessages } from "components/Constants";
import axios from "axios";
import { signOut } from "store/LoginState";
import store from "store";

/*
Get the list of Teams of the current user
*/
async function getTeamsByUser() {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Team.GetUserTeamEndPoint}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getTeams returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


/*
Get the list of Teams that the user can see (his team and father team)
*/
async function getAllowedTeamsByUser() {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Team.GetUserTeamEndPoint}/allowed`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getTeams returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of Teams
*/
async function getAllTeams() {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Team.GetEndPoint}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getTeams returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Used to create a team:
Retuns the id of the response
*/
async function createTeam(newTeam) {
    try {
        let url = `${Team.GetEndPoint}`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: newTeam
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`createTeam returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for createTeam`)

            // If it's a success response
            if (response.status === 201) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Used to get the users of a team:
Retuns the list of users
*/
async function getUsersByTeam(teamId) {
    try {
        let url = `${Team.GetEndPoint}/${teamId}/users`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: url,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getUsersByTeam returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            //console.info(`statusCode: ${response.status} for getUsersByTeam`)

            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Used to add a member to a team
*/
async function addMemberToTeam(teamId, bodyMember) {
    try {
        let url = `${Team.GetEndPoint}/${teamId}/users`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: bodyMember
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`addMemberToTeam returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for addMemberToTeam`)

            // If it's a success response
            if (response.status === 201) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the Team by it's id
*/
async function getTeamById(teamId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Team.GetEndPoint}/${teamId}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getTeamById returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the Company Team is it defined
*/
async function getCompanyTeam() {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Team.GetEndPoint}/company`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getCompanyTeam returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Used to update a team:
Retuns the id of the response
*/
async function updateTeam(teamId, teamBody) {
    try {
        let url = `${Team.GetEndPoint}/${teamId}`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "put",
            url: url,
            headers: requestHeaders,
            data: teamBody
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`updateTeam returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for updateTeam`)

            // If it's a success response
            if (response.status === 200) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Used to partial updates for teams:
Retuns the id of the response
*/
async function partialUpdateTeam(teamId, teamBody) {
    try {
        let url = `${Team.GetEndPoint}/${teamId}`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "patch",
            url: url,
            headers: requestHeaders,
            data: teamBody
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`updateTeam returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for updateTeam`)

            // If it's a success response
            if (response.status === 200) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Used to delete a team:
Retuns the id of the response
*/
async function deleteTeamById(teamId) {
    try {
        let url = `${Team.GetEndPoint}/${teamId}`
        let requestHeaders = createHeaders()



        let response = await axios({
            method: "delete",
            url: url,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`deleteTeam returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for deleteTeam`)

            // If it's a success response
            if (response.status === 204) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }

    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Used to delete a user from a team:
Retuns the id of the response
*/
async function deleteUserFromTeamById(teamId, userId) {
    try {
        let url = `${Team.GetEndPoint}/${teamId}/user/${userId}`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "delete",
            url: url,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`deleteUserFromTeam returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for deleteUserFromTeam`)

            // If it's a success response
            if (response.status === 204) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }

    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Used to update team accountability:
Retuns the id of the response
*/
async function updateTeamAccountability(teamId, teamBody) {
    try {
        let url = `${Team.GetEndPoint}/${teamId}/accountability`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "put",
            url: url,
            headers: requestHeaders,
            data: teamBody
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`updateTeamAccountability returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for updateTeamAccountability`)

            // If it's a success response
            if (response.status === 200) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list accountability dates
*/
async function getAccountabilitySchedule(getAll) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Team.GetEndPoint}/accountability`,
            headers: requestHeaders,
            params: {
                getAll: getAll
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getAccountabilitySchedule returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of Teams WHERE user is leader of the current user
*/
async function getTeamsLeaderByUser(userId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Team.GetUserTeamLeaderEndPoint}/${userId}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getTeams Leader returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of Teams WHERE user is leader of the current user
*/
async function getDataRolebyIdUser(teamId) {
    try {
        let requestHeaders = createHeaders()
    
        let response = await axios({
            method: "get",
            url: `${Team.GetDataRolebyUserIDEndPoint}/${teamId}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getDataRoleByUserID returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get All Accounts
*/
async function getAccounts() {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Team.GetAccountsEndPoint}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getAccounts returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get All Countries
*/
async function getCountries() {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Team.GetCountriesEndPoint}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getCountries returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


/*
Get All Countries
*/
async function getDepartments() {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Team.GetDepartmentsEndPoint}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getDepartments returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}
function createHeaders() {
    let headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
        "nonce": localStorage.getItem('nonce')
    }

    return headers
}

 /*
Get User´s Picture
*/
async function getPicture() {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Team.GetImage}`,
            headers: requestHeaders,
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getImage returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}
/*
Get User´s ID
*/
async function getUserID() {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Team.SetImage}`,
            headers: requestHeaders,
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getImage returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


/*
GET TEAMS FOR PARENT
*/
async function GetTeamsForParent(teamId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: `${Team.GetTeamsForParent}`,
            headers: requestHeaders,
            data: {
                teamId: teamId           
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(` GetTeamsForParent returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}



export {
    addMemberToTeam,
    getTeamsByUser,
    getAllTeams,
    getUsersByTeam,
    createTeam,
    updateTeam,
    partialUpdateTeam,
    deleteTeamById,
    deleteUserFromTeamById,
    updateTeamAccountability,
    getTeamById,
    getCompanyTeam,
    getAccountabilitySchedule,
    getAllowedTeamsByUser,
    getTeamsLeaderByUser,
    getDataRolebyIdUser,
    getAccounts,
    getCountries,
    getDepartments,
    getPicture,
    getUserID,
    GetTeamsForParent
}

