/*eslint-disable*/
import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress, Select, MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { DataTypes } from "components/Constants";
// material ui icons
import Flag from "@material-ui/icons/Flag";
import AddAlert from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Add from "@material-ui/icons/PlaylistAdd";
import ErrorIcon from "@material-ui/icons/Error";
import Star from "@material-ui/icons/Star";
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";

//dependy react-datePicker
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import eu from "date-fns/locale/en-US";
import "react-datepicker/dist/react-datepicker.css";

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputMask from "components/CustomInputMask/CustomInputMask";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";

// Functions
import { formatWIGName } from "utils/dataTypeFormatter";
import { translate, getUserLocale } from "utils/translate";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/WIG/createStyle.js";

const useStyles = makeStyles(styles);

export default function createView(props) {
  const [selectFecha, setSelectFecha] = useState(new Date());
  const classes = useStyles();

  function getFormAxes(index) {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={8}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  success={props.wigState[`displayName${index}`] === "success"}
                  error={props.wigState[`displayName${index}`] === "error"}
                  labelText={translate("wig_name")}
                  id={`inputDisplayName${index}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      props.onFieldChange(`displayName${index}`, event);
                    },
                    type: "text",
                    value: props.wig[`displayName${index}`],
                    endAdornment:
                      props.wigState[`displayName${index}`] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInputMask
                  success={props.wigState[`x${index}`] === "success"}
                  error={props.wigState[`x${index}`] === "error"}
                  labelText={`X${index}`}
                  id={`inputX${index}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  suffix={DataTypes[props.wig[`dataTypeId${index}`]].suffix}
                  decimalScale={
                    DataTypes[props.wig[`dataTypeId${index}`]].decimalScale
                  }
                  inputProps={{
                    onChange: (event) => {
                      event.target.value =
                        event.target.value === ""
                          ? ""
                          : parseFloat(event.target.value);
                      props.onFieldChange(`x${index}`, event);
                    },
                    type: "text",
                    value: props.wig[`x${index}`],
                    endAdornment:
                      props.wigState[`x${index}`] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInputMask
                  success={props.wigState[`y${index}`] === "success"}
                  error={props.wigState[`y${index}`] === "error"}
                  labelText={`Y${index}`}
                  id={`inputY${index}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  suffix={DataTypes[props.wig[`dataTypeId${index}`]].suffix}
                  decimalScale={
                    DataTypes[props.wig[`dataTypeId${index}`]].decimalScale
                  }
                  inputProps={{
                    onChange: (event) => {
                      event.target.value =
                        event.target.value === ""
                          ? ""
                          : parseFloat(event.target.value);
                      props.onFieldChange(`y${index}`, event);
                    },
                    type: "text",
                    value: props.wig[`y${index}`],
                    endAdornment:
                      props.wigState[`y${index}`] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    {translate("datatype_label")} *
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={props.wig[`dataTypeId${index}`]}
                    onChange={(e) =>
                      props.onFieldChange(`dataTypeId${index}`, e)
                    }
                    inputProps={{
                      name: `selectDataTypeId${index}`,
                      id: `selectDataTypeId${index}`,
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {translate("datatype_input")}
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                      value={1}
                    >
                      {translate("const_datatype_numeric")}
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                      value={2}
                    >
                      {translate("const_datatype_percent")}
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                      value={3}
                    >
                      {translate("const_datatype_dollar")}
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                      value={4}
                    >
                      {translate("const_datatype_quetzal")}
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                      value={5}
                    >
                      {translate("const_datatype_hours")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <GridContainer>
              <GridItem xs={4} sm={4} md={3}>
                <Button color={"success"} className={classes.buttonCriteria}>
                  <Check className={classes.icon} />
                </Button>
              </GridItem>
              <GridItem xs={8} sm={8} md={9}>
                <CustomInputMask
                  success={props.wigState[`level3_${index}`] === "success"}
                  error={props.wigState[`level3_${index}`] === "error"}
                  labelText={translate("const_goalexpected")}
                  id={`inputlevel3_${index}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  suffix={DataTypes[props.wig[`dataTypeId${index}`]].suffix}
                  decimalScale={
                    DataTypes[props.wig[`dataTypeId${index}`]].decimalScale
                  }
                  inputProps={{
                    onChange: (event) => {
                      event.target.value =
                        event.target.value === ""
                          ? ""
                          : parseFloat(event.target.value);
                      props.onFieldChange(`level3_${index}`, event);
                    },
                    type: "text",
                    value: props.wig[`level3_${index}`],
                    endAdornment:
                      props.wigState[`level3_${index}`] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={3}>
                <Button color={"warning"} className={classes.buttonCriteria}>
                  <Warning className={classes.icon} />
                </Button>
              </GridItem>
              <GridItem xs={8} sm={8} md={9}>
                <CustomInputMask
                  success={props.wigState[`level2_${index}`] === "success"}
                  error={props.wigState[`level2_${index}`] === "error"}
                  labelText={translate("const_goalacceptable")}
                  id={`inputlevel2_${index}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  suffix={DataTypes[props.wig[`dataTypeId${index}`]].suffix}
                  decimalScale={
                    DataTypes[props.wig[`dataTypeId${index}`]].decimalScale
                  }
                  inputProps={{
                    onChange: (event) => {
                      event.target.value =
                        event.target.value === ""
                          ? ""
                          : parseFloat(event.target.value);
                      props.onFieldChange(`level2_${index}`, event);
                    },
                    type: "text",
                    value: props.wig[`level2_${index}`],
                    endAdornment:
                      props.wigState[`level2_${index}`] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={3}>
                <Button color={"danger"} className={classes.buttonCriteria}>
                  <ErrorIcon className={classes.icon} />
                </Button>
              </GridItem>
              <GridItem xs={8} sm={8} md={9}>
                <CustomInputMask
                  success={props.wigState[`level1_${index}`] === "success"}
                  error={props.wigState[`level1_${index}`] === "error"}
                  labelText={translate("const_goalfailed")}
                  id={`inputlevel1_${index}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  suffix={DataTypes[props.wig[`dataTypeId${index}`]].suffix}
                  decimalScale={
                    DataTypes[props.wig[`dataTypeId${index}`]].decimalScale
                  }
                  inputProps={{
                    onChange: (event) => {
                      event.target.value =
                        event.target.value === ""
                          ? ""
                          : parseFloat(event.target.value);
                      props.onFieldChange(`level1_${index}`, event);
                    },
                    type: "text",
                    value: props.wig[`level1_${index}`],
                    endAdornment:
                      props.wigState[`level1_${index}`] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <br />
      </GridItem>
    );
  }

  function getFormAxesTwo(index) {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <br />
        <GridContainer>
          {props.wig.axesNumber === 2 ? (
            <>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  success={props.wigState["verbTwo"] === "success"}
                  error={props.wigState["verbTwo"] === "error"}
                  labelText={translate("verb") + " *"}
                  id="inputVerbTwo"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      props.onFieldChange("verbTwo", event);
                    },
                    type: "text",
                    value: props.wig.verbTwo,
                    endAdornment:
                      props.wigState["verbTwo"] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  success={props.wigState["whatTwo"] === "success"}
                  error={props.wigState["whatTwo"] === "error"}
                  labelText={translate("what") + " *"}
                  id="inputWhatTwo"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      props.onFieldChange("whatTwo", event);
                    },
                    type: "text",
                    value: props.wig.whatTwo,
                    endAdornment:
                      props.wigState["whatTwo"] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
            </>
          ) : null}

          <GridItem xs={12} sm={6} md={6} lg={8}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  success={props.wigState[`displayName${index}`] === "success"}
                  error={props.wigState[`displayName${index}`] === "error"}
                  labelText={translate("wig_name")}
                  id={`inputDisplayName${index}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      props.onFieldChange(`displayName${index}`, event);
                    },
                    type: "text",
                    value: props.wig[`displayName${index}`],
                    endAdornment:
                      props.wigState[`displayName${index}`] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInputMask
                  success={props.wigState[`x${index}`] === "success"}
                  error={props.wigState[`x${index}`] === "error"}
                  labelText={`X${index}`}
                  id={`inputX${index}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  suffix={DataTypes[props.wig[`dataTypeId${index}`]].suffix}
                  decimalScale={
                    DataTypes[props.wig[`dataTypeId${index}`]].decimalScale
                  }
                  inputProps={{
                    onChange: (event) => {
                      event.target.value =
                        event.target.value === ""
                          ? ""
                          : parseFloat(event.target.value);
                      props.onFieldChange(`x${index}`, event);
                    },
                    type: "text",
                    value: props.wig[`x${index}`],
                    endAdornment:
                      props.wigState[`x${index}`] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInputMask
                  success={props.wigState[`y${index}`] === "success"}
                  error={props.wigState[`y${index}`] === "error"}
                  labelText={`Y${index}`}
                  id={`inputY${index}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  suffix={DataTypes[props.wig[`dataTypeId${index}`]].suffix}
                  decimalScale={
                    DataTypes[props.wig[`dataTypeId${index}`]].decimalScale
                  }
                  inputProps={{
                    onChange: (event) => {
                      event.target.value =
                        event.target.value === ""
                          ? ""
                          : parseFloat(event.target.value);
                      props.onFieldChange(`y${index}`, event);
                    },
                    type: "text",
                    value: props.wig[`y${index}`],
                    endAdornment:
                      props.wigState[`y${index}`] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    {translate("datatype_label")} *
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={props.wig[`dataTypeId${index}`]}
                    onChange={(e) =>
                      props.onFieldChange(`dataTypeId${index}`, e)
                    }
                    inputProps={{
                      name: `selectDataTypeId${index}`,
                      id: `selectDataTypeId${index}`,
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {translate("datatype_input")}
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                      value={1}
                    >
                      {translate("const_datatype_numeric")}
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                      value={2}
                    >
                      {translate("const_datatype_percent")}
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                      value={3}
                    >
                      {translate("const_datatype_dollar")}
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                      value={4}
                    >
                      {translate("const_datatype_quetzal")}
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                      value={5}
                    >
                      {translate("const_datatype_hours")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={4}>
            <GridContainer>
              <GridItem xs={4} sm={4} md={3}>
                <Button color={"success"} className={classes.buttonCriteria}>
                  <Check className={classes.icon} />
                </Button>
              </GridItem>
              <GridItem xs={8} sm={8} md={9}>
                <CustomInputMask
                  success={props.wigState[`level3_${index}`] === "success"}
                  error={props.wigState[`level3_${index}`] === "error"}
                  labelText={translate("const_goalexpected")}
                  id={`inputlevel3_${index}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  suffix={DataTypes[props.wig[`dataTypeId${index}`]].suffix}
                  decimalScale={
                    DataTypes[props.wig[`dataTypeId${index}`]].decimalScale
                  }
                  inputProps={{
                    onChange: (event) => {
                      event.target.value =
                        event.target.value === ""
                          ? ""
                          : parseFloat(event.target.value);
                      props.onFieldChange(`level3_${index}`, event);
                    },
                    type: "text",
                    value: props.wig[`level3_${index}`],
                    endAdornment:
                      props.wigState[`level3_${index}`] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={3}>
                <Button color={"warning"} className={classes.buttonCriteria}>
                  <Warning className={classes.icon} />
                </Button>
              </GridItem>
              <GridItem xs={8} sm={8} md={9}>
                <CustomInputMask
                  success={props.wigState[`level2_${index}`] === "success"}
                  error={props.wigState[`level2_${index}`] === "error"}
                  labelText={translate("const_goalacceptable")}
                  id={`inputlevel2_${index}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  suffix={DataTypes[props.wig[`dataTypeId${index}`]].suffix}
                  decimalScale={
                    DataTypes[props.wig[`dataTypeId${index}`]].decimalScale
                  }
                  inputProps={{
                    onChange: (event) => {
                      event.target.value =
                        event.target.value === ""
                          ? ""
                          : parseFloat(event.target.value);
                      props.onFieldChange(`level2_${index}`, event);
                    },
                    type: "text",
                    value: props.wig[`level2_${index}`],
                    endAdornment:
                      props.wigState[`level2_${index}`] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={3}>
                <Button color={"danger"} className={classes.buttonCriteria}>
                  <ErrorIcon className={classes.icon} />
                </Button>
              </GridItem>
              <GridItem xs={8} sm={8} md={9}>
                <CustomInputMask
                  success={props.wigState[`level1_${index}`] === "success"}
                  error={props.wigState[`level1_${index}`] === "error"}
                  labelText={translate("const_goalfailed")}
                  id={`inputlevel1_${index}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  suffix={DataTypes[props.wig[`dataTypeId${index}`]].suffix}
                  decimalScale={
                    DataTypes[props.wig[`dataTypeId${index}`]].decimalScale
                  }
                  inputProps={{
                    onChange: (event) => {
                      event.target.value =
                        event.target.value === ""
                          ? ""
                          : parseFloat(event.target.value);
                      props.onFieldChange(`level1_${index}`, event);
                    },
                    type: "text",
                    value: props.wig[`level1_${index}`],
                    endAdornment:
                      props.wigState[`level1_${index}`] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <br />
      </GridItem>
    );
  }

  let breadcrumbViews = [
    {
      name: translate("routes_home_label"),
      url: "/admin/dashboard",
      icon: DashboardIcon,
    },
    {
      name: translate("routes_create_label"),
      url: "/admin/wig/create",
      icon: Add,
    },
  ];

  return (
    <div>
      <Breadcrumbs views={breadcrumbViews} />
      <Heading textAlign="center" title={translate("wig_create_header")} />
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Flag />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {translate("wig_create_title")}
              </h4>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <div>{translate("commitment_for_initial_input") + "*"}</div>
                    <DatePicker
                      className="pickers"
                      selected={selectFecha}
                      onChange={(date) => {
                        props.onFieldChange("startDate", date);
                      }}
                      value={props.wig.startDate}
                      dateFormat={
                        translate("commitment_for_when_input") != "When?"
                          ? "dd MMMM, yyyy"
                          : "MMMM d, yyyy"
                      }
                      locale={
                        translate("commitment_for_when_input") != "When?"
                          ? es
                          : eu
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div>{translate("commitment_for_end_input") + "*"}</div>
                    <DatePicker
                      className="pickers"
                      selected={selectFecha}
                      onChange={(date) => props.onFieldChange("endDate", date)}
                      value={props.wig.endDate}
                      dateFormat={
                        translate("commitment_for_when_input") != "When?"
                          ? "dd MMMM, yyyy"
                          : "MMMM d, yyyy"
                      }
                      locale={
                        translate("commitment_for_when_input") != "When?"
                          ? es
                          : eu
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          success={props.wigState["verb"] === "success"}
                          error={props.wigState["verb"] === "error"}
                          labelText={translate("verb") + " *"}
                          id="inputVerb"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              props.onFieldChange("verb", event);
                            },
                            type: "text",
                            value: props.wig.verb,
                            endAdornment:
                              props.wigState["verb"] === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              ),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          success={props.wigState["what"] === "success"}
                          error={props.wigState["what"] === "error"}
                          labelText={translate("what") + " *"}
                          id="inputWhat"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              props.onFieldChange("what", event);
                            },
                            type: "text",
                            value: props.wig.what,
                            endAdornment:
                              props.wigState["what"] === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              ),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            {translate("axis_amount")}
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={props.wig.axesNumber}
                            onChange={(e) =>
                              props.onFieldChange("axesNumber", e)
                            }
                            inputProps={{
                              name: "selectaxesNumber",
                              id: "selectaxesNumber",
                            }}
                          >
                            {[1, 2].map((value, index) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                style={{
                                  overflowX: "auto",
                                  textOverflow: "ellipsis",
                                }}
                                value={value}
                                key={index}
                              >
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            {translate("lead_wig")}
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={props.wig.parentWig}
                            onChange={(e) =>
                              props.onFieldChange("parentWig", e)
                            }
                            inputProps={{
                              name: "parentWig",
                              id: "parentWig",
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={""}
                              disabled
                            >
                              {translate("commitment_wig_input")}
                            </MenuItem>
                            {props.parentWIGList.map((wig, index) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                style={{ overflowX: "auto" }}
                                value={wig.id}
                                key={index}
                              >
                                {wig.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  {getFormAxes(1)}
                  {props.wig.axesNumber === 2 ? getFormAxesTwo(2) : null}
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            {translate("when")}
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={props.wig.year}
                            onChange={(e) => props.onFieldChange("year", e)}
                            inputProps={{
                              name: "selectYear",
                              id: "selectYear",
                            }}
                          >
                            {[2022, 2023, 2024, 2025].map((value, index) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                style={{
                                  overflowX: "auto",
                                  textOverflow: "ellipsis",
                                }}
                                value={value}
                                key={index}
                              >
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          success={props.wigState["description"] === "success"}
                          error={props.wigState["description"] === "error"}
                          labelText={translate("description")}
                          id="inputDescription"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              props.onFieldChange("description", event);
                            },
                            type: "text",
                            value: props.wig.description,
                            endAdornment:
                              props.wigState["description"] === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              ),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText={translate("wig_full_name")}
                          id="inputFullName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            disabled: true,
                            type: "text",
                            value: `${formatWIGName(
                              props.wig,
                              getUserLocale()
                            )}`,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <div className={classes.formCategory}>
                  <small>*</small> {translate("required_fields")}
                </div>
                <div className={classes.center}>
                  {props.isLoadingSave ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    <Button color="primary" onClick={props.onWIGSave}>
                      {translate("button_save")}
                    </Button>
                  )}
                </div>
              </form>
            </CardBody>
          </Card>
          <Snackbar
            place="br"
            color="success"
            icon={AddAlert}
            message={translate("alert_wig_create_success")}
            open={props.createSuccess}
            closeNotification={() => props.changeCreateSuccess(false)}
            close
          />
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.createError}
            closeNotification={() => props.changeCreateError(false)}
            close
          />
          <Snackbar
            place="bl"
            color="warning"
            icon={Warning}
            message={translate("year_later_warning")}
            open={props.currentYear}
            closeNotification={() => props.setCurrentYear(false)}
            close
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
