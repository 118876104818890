import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { wigUpdateSchema } from "models/wigModel";
import { editWIG, getWIGById } from "services/apiAdmin/wigApi";
import { getUserLocale } from "utils/translate";
import moment from "moment";

import GeneralView from "./GeneralView";

const newWIG = {
  endDate: "",
  startDate: "",
  verb: "",
  verbTwo: "",
  what: "",
  whatTwo: "",
  axesNumber: "",
  x1: "",
  y1: "",
  level1_1: "",
  level2_1: "",
  level3_1: "",
  displayName1: "",
  dataTypeId1: 1,
  x2: "",
  y2: "",
  level1_2: "",
  level2_2: "",
  level3_2: "",
  displayName2: "",
  dataTypeId2: 1,
  description: "",
  year: new Date().getFullYear(),
  parentWig: 0,
  activeOne: true,
  activeTwo: true,
};

const newWIGState = {
  endDate: "",
  startDate: "",
  verb: "",
  verbTwo: "",
  what: "",
  whatTwo: "",
  axesNumber: "",
  x1: "",
  y1: "",
  level1_1: "",
  level2_1: "",
  level3_1: "",
  displayName1: "",
  dataType1: "",
  x2: "",
  y2: "",
  level1_2: "",
  level2_2: "",
  level3_2: "",
  displayName2: "",
  dataType2: "",
  year: "",
  description: "",
  parentWig: "",
  activeOne: true,
  activeTwo: true,
};

let wigId;
let _isMounted = false;

async function onSubmit(props) {

  let aggIdionPropsWig = {
    ...props.wig,
    idiom: getUserLocale(),
  };
  if (props.wig.startDate && props.wig.year >= 2024) {
    if (getUserLocale() === "en-US") {
      const fechaStart = moment(props.wig.startDate);
      const fechaEndate = moment(props.wig.endDate);
      if (props.wig.year == fechaStart.year() && props.wig.year == fechaEndate.year()) {
        let mydateStart = moment(fechaStart, 'MMMM DD, YYYY').format('YYYY-MM-DD');
        let mydateEndaDate = moment(fechaEndate, 'MMMM DD, YYYY').format('YYYY-MM-DD');
        const fecha1 = moment(mydateStart);
        const fecha2 = moment(mydateEndaDate);
        const estaAntes = fecha1.isBefore(fecha2);
        if (estaAntes) {

          if (
            props.wig.axesNumber === 2 &&
            props.wig.activeOne === false &&
            props.wig.activeTwo === false
          ) {
            let message =
              aggIdionPropsWig.idiom === "es-ES"
              ? "La fecha final no debe ser  anterior a la fecha inicial"
              : "The end date must not be earlier than the start date";
            props.changeValidationMessage(message);
            props.changeCreateError(true);
          } else {
            let aggIdionPropsWigFinal = {
              ...aggIdionPropsWig,
              startDate: fecha1,
              endDate: fecha2
            };

            let result = await editWIG(aggIdionPropsWigFinal, props.wigId);
            // Show confirmation message
            if (result && result.success && _isMounted) {
              props.changeCreateSuccess(true);
              setTimeout(function () {
                if (_isMounted) {
                  window.location.href = `/admin/wig/details/${props.wigId}`;
                }
              }, 1500);
            } else {
              // Show error message
              if (result) {
                let message = result.message;
                if (!message) {
                  message = "Algo ocurrió mal, inténtalo nuevamente.";
                }
                props.changeValidationMessage(message);
                props.changeCreateError(true);
              }
            }
          }
        } else {

          let message =
            aggIdionPropsWig.idiom === "es-ES"
              ? "La fecha final no debe ser  anterior a la fecha inicial"
              : "The end date must not be earlier than the start date";
          props.changeValidationMessage(message);
          props.changeCreateError(true);
        }
      } else {
        let message =
          aggIdionPropsWig.idiom === "es-ES"
            ? "El año de inicio y finalización debe ser el mismo que el año del Mci."
            : "The start and end year must be the same as the Mci year.";
        props.changeValidationMessage(message);
        props.changeCreateError(true);
      }



    } else {

      let mydateStart = moment(props.wig.startDate, 'DD MMMM, YYYY').format('YYYY-MM-DD');
      let mydateEndaDate = moment(props.wig.endDate, 'DD MMMM, YYYY').format('YYYY-MM-DD');
      const fechaStartAño = moment(mydateStart);
      const fechaEndAño = moment(mydateEndaDate);

      if (props.wig.year == fechaStartAño.year() && props.wig.year == fechaEndAño.year()) {
        const fecha1 = moment(mydateStart);
        const fecha2 = moment(mydateEndaDate);
        const estaAntes = fecha1.isBefore(fecha2);
        if (estaAntes) {

          if (
            props.wig.axesNumber === 2 &&
            props.wig.activeOne === false &&
            props.wig.activeTwo === false
          ) {
            let message =
              aggIdionPropsWig.idiom === "es-ES"
                ? "Problemas en el rango de Fechas, verificar."
                : "Problems in the Date range, check.";
            props.changeValidationMessage(message);
            props.changeCreateError(true);
          } else {
            let aggIdionPropsWigFinal = {
              ...aggIdionPropsWig,
              startDate: fecha1,
              endDate: fecha2
            };

            let result = await editWIG(aggIdionPropsWigFinal, props.wigId);
            // Show confirmation message
            if (result && result.success && _isMounted) {
              props.changeCreateSuccess(true);
              setTimeout(function () {
                if (_isMounted) {
                  window.location.href = `/admin/wig/details/${props.wigId}`;
                }
              }, 1500);
            } else {
              // Show error message
              if (result) {
                let message = result.message;
                if (!message) {
                  message = "Algo ocurrió mal, inténtalo nuevamente.";
                }
                props.changeValidationMessage(message);
                props.changeCreateError(true);
              }
            }
          }
        } else {
          let message =
            aggIdionPropsWig.idiom === "es-ES"
              ? "La fecha final no debe ser  anterior a la fecha inicial"
              : "The end date must not be earlier than the start date";
          props.changeValidationMessage(message);
          props.changeCreateError(true);
        }

      } else {
        let message =
          aggIdionPropsWig.idiom === "es-ES"
            ? "El año de inicio y finalización debe ser el mismo que el año del Mci."
            : "The start and end year must be the same as the Mci year.";
        props.changeValidationMessage(message);
        props.changeCreateError(true);
      }


    }



  } else {

    if (
      props.wig.axesNumber === 2 &&
      props.wig.activeOne === false &&
      props.wig.activeTwo === false
    ) {
      let message =
        aggIdionPropsWig.idiom === "es-ES"
          ? "No se pueden desactivar ambos ejes a la vez"
          : "Both axis cannot be disable at the same time";
      props.changeValidationMessage(message);
      props.changeCreateError(true);
    } else {
      let result = await editWIG(aggIdionPropsWig, props.wigId);
      // Show confirmation message
      if (result && result.success && _isMounted) {
        props.changeCreateSuccess(true);
        setTimeout(function () {
          if (_isMounted) {
            window.location.href = `/admin/wig/details/${props.wigId}`;
          }
        }, 1500);
      } else {
        // Show error message
        if (result) {
          let message = result.message;
          if (!message) {
            message = "Algo ocurrió mal, inténtalo nuevamente.";
          }
          props.changeValidationMessage(message);
          props.changeCreateError(true);
        }
      }
    }
  }

}

async function getWIG(props) {
  let result = await getWIGById(wigId, 0);
  let idiom = getUserLocale();
  if (result.success) {
    if (_isMounted) {
      if (
        idiom === "es-ES" &&
        result.data.endDate != null &&
        result.data.startDate != null
      ) {
        moment.locale('es')
        let mydateStart = moment(result.data.startDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
        let mydateEndaDate = moment(result.data.endDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
        if (result.data.x2 != null) {
          let nuevoObject = {
            ...result.data,
            startDate: mydateStart,
            endDate: mydateEndaDate,
          };
          props.changeWIG(nuevoObject);
        } else {
          let nuevoObject = {
            ...result.data,
            startDate: mydateStart,
            endDate: mydateEndaDate,
            dataTypeId2: 1
          };
          props.changeWIG(nuevoObject);
        }

      } else if (
        idiom === "en-US" &&
        result.data.endDate != null &&
        result.data.startDate != null
      ) {
        moment.locale('en')
        const fechaFormateadaStart = moment
          .utc(result.data.startDate)
          .format("MMMM DD, YYYY");
        const fechaFormateadaEnd = moment
          .utc(result.data.endDate)
          .format("MMMM DD, YYYY");
        if (result.data.x2 != null) {
          let nuevoObject = {
            ...result.data,
            startDate: fechaFormateadaStart,
            endDate: fechaFormateadaEnd,
          };
          props.changeWIG(nuevoObject);
        } else {
          let nuevoObject = {
            ...result.data,
            startDate: fechaFormateadaStart,
            endDate: fechaFormateadaEnd,
            dataTypeId2: 1
          };
          props.changeWIG(nuevoObject);
        }


      } else {
        let nuevoObject = {
          ...result.data,
          endDate: new Date(),
          startDate: new Date(),
          verbTwo: "",
          whatTwo: "",
        };
        props.changeWIG(nuevoObject);
      }
    }
  }
}

export default compose(
  connect(
    (state) => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
    }),
    {}
  ),
  withState("wigId", "changeWIGId", undefined),
  withState("wig", "changeWIG", { ...newWIG }),
  withState("wigState", "changeWIGState", { ...newWIGState }),
  withState("createSuccess", "changeCreateSuccess", false),
  withState("createError", "changeCreateError", false),
  withState("validationMessage", "changeValidationMessage", ""),
  withState("isLoadingSave", "changeIsLoadingSave", false),
  withHandlers({
    onFieldChange: (props) => (field, event) => {
      let idiom = getUserLocale();
      if (field === "endDate" || field === "startDate") {
        if (idiom === "es-ES") {
          moment.locale("es")
          let formatoCorrespondiente = moment(event).format("DD MMMM, YYYY");
          props.wig[field] = formatoCorrespondiente;

          let isValid = validateProperty(wigUpdateSchema, props.wig, field)
            .isValid;
          if (isValid) {
            props.wigState[field] = "success";
          } else {
            props.wigState[field] = "error";
          }
          props.changeWIGState(props.wigState);
          props.changeWIG(props.wig, function () { });
        } else if (idiom === "en-US") {
          moment.locale("en")
          let formatoCorrespondiente = moment(event).format("MMMM DD, YYYY");
          props.wig[field] = formatoCorrespondiente;

          let isValid = validateProperty(wigUpdateSchema, props.wig, field)
            .isValid;
          if (isValid) {
            props.wigState[field] = "success";
          } else {
            props.wigState[field] = "error";
          }
          props.changeWIGState(props.wigState);
          props.changeWIG(props.wig, function () { });
        }
      } else if (field == "activeTwo" || field == "activeOne") {
        props.wig[field] = !event;
      } else {
        props.wig[field] = event.target.value;
        let isValid = validateProperty(wigUpdateSchema, props.wig, field)
          .isValid;
        if (isValid) {
          props.wigState[field] = "success";
        } else {
          props.wigState[field] = "error";
        }
        props.changeWIGState(props.wigState);
        props.changeWIG(props.wig, function () { });
      }
    },
    onFieldChangeParent: (props) => (field, event) => {
      props.wig[field] = event.target.value;

      props.wigState[field] = "success";

      props.changeWIGState(props.wigState);

      props.changeWIG(props.wig, function () { });
      //props.getParentWIG(props,event.target.value)
    },
    onWIGSave: (props) => async () => {
      props.changeIsLoadingSave(true);

      let validation = validateObject(wigUpdateSchema, props.wig);
      if (validation.isValid) {
        await onSubmit(props);
      } else {
        let field;
        for (field in props.wig) {
          if (validation.errors[field]) {
            props.wigState[field] = "error";
          }
        }
        props.changeWIGState(props.wigState);
      }
      props.changeIsLoadingSave(false);
    },
  }),
  lifecycle({
    async componentDidMount() {
      _isMounted = true;
      wigId = this.props.match.params.id;
      this.props.changeWIGId(wigId);
      await getWIG(this.props);

      this.props.changeCreateSuccess(false);
    },
    componentWillUnmount() {
      _isMounted = false;
    },
  })
)(GeneralView);
