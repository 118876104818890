import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { getPredictiveById } from "services/apiAdmin/predictiveApi";
import { getDataset0 } from "services/apiStat/statApi";
import { getTrackingListByAxisId } from "services/apiAdmin/trackingApi";
import { formatPredictiveName }from "utils/dataTypeFormatter";

import DetailsView from "./DetailsView";
let _isMounted = false
let predictiveId = 0

const emptyDataTable = { "header": ["loading"], "result": [] }

const mockAxes = {
	name: "...",
	description: "",
	axes: [
		{
			displayName: "...",
			dataTypeId: 1,
			data: new Array(53).fill().map((_, index) => {
				return (
					{
						"goalAchived": null,
						"name": `Semana: ${index + 1}`
					}
				)
			})
		}
	]
}
const restartMock = { ...mockAxes }

async function getPredictive(props) {
	let result = await getPredictiveById(predictiveId)
	if (result.success) {
		if (_isMounted) {
			// Set first result as selected value if found
			result.data.name = formatPredictiveName(result.data)
			props.changePredictive(result.data)
			getAxesDataset(props, result.data)

			getTrackingTables(props, result.data)
		}

	}
}

async function getAxesDataset(props, predictive) {
	// Create axes data
	props.axesDataset.name = predictive.name
	props.axesDataset.description = predictive.description
	props.axesDataset.consistency = predictive.consistency
	props.axesDataset.axes = []

	let index
	for (index of [1, 2]) {
		if (predictive[`axisId${index}`] !== null) {
			let datasetResult = await getDataset0(predictive[`axisId${index}`])
			if (datasetResult.success) {
				let axisData = {
					displayName: predictive[`displayName${index}`],
					dataTypeId: predictive[`dataTypeId${index}`],
					data: datasetResult.data
				}
				props.axesDataset.axes.push(axisData)
			}
		}
	}

	props.changeAxesDataset(props.axesDataset)
}

async function getTrackingTables(props, predictive){
	let index
	for (index of [1,2]) {
		if(predictive[`axisId${index}`] !== null){
			let trackingResult = await getTrackingListByAxisId(predictive[`axisId${index}`])
			if(trackingResult.success && _isMounted){
				props[`changeTrackingList${index}`](trackingResult.data)
			}
			console.log(trackingResult);
		}
	}
	console.log(props);
}

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
			team: state.admin.team,
			roleId: state.admin.roleId
		}),
		{}
	),
	withState("predictiveId", "changePredictiveId", undefined),
	withState("predictive", "changePredictive", undefined),
	withState("axesDataset", "changeAxesDataset", mockAxes),
	withState("trackingList1", "changeTrackingList1", emptyDataTable),
	withState("trackingList2", "changeTrackingList2", emptyDataTable),
	withHandlers({
	}),
	lifecycle({
		componentDidMount() {
			this.props.changeAxesDataset({ ...restartMock })
			predictiveId = this.props.match.params.id
			this.props.changePredictiveId(predictiveId)
			_isMounted = true
			getPredictive(this.props)
		},
		componentWillUnmount() {
			_isMounted = false
		}
	})
)(DetailsView);
