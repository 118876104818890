import React from "react";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';

// material-ui icons
import TrackChanges from "@material-ui/icons/TrackChanges";
import List from "@material-ui/icons/List";
import TrendingUp from "@material-ui/icons/TrendingUp";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Flag from "@material-ui/icons/Flag";

// core components
import Heading from "components/Heading/Heading.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import TrackingDashboard from "components/TrackingDashboard/TrackingDashboard.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";

// functions
import { formatValue } from "utils/dataTypeFormatter.js";
import { verifyPermission } from "utils/functions"
import { translate } from "utils/translate"

import styles from "assets/jss/material-dashboard-pro-react/views/WIG/detailsStyle.js";

const useStyles = makeStyles(styles);

export default function DetailsView(props) {
  const classes = useStyles();

  function getTableForAxis(index) {
    if (props.predictive === undefined || props.predictive[`x${index}`] === null) {
      return (null);
    }
    return (
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <List />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{`${props.predictive[`displayName${index}`]} - ${translate("const_tracking")}`}</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={[
                translate("table_header_date"),
                translate("table_header_week"),
                translate("const_y"),
                translate("const_goalachieved"),
                translate("const_difference"),
                translate("const_commentaries")
              ]}
              tableData={
                props[`trackingList${index}`].result.map((item, key) => {
                  return [
                    item.createdAt,
                    item.period,
                    formatValue(item.y, item.dataTypeId),
                    formatValue(item.goalAchived, item.dataTypeId),
                    formatValue(item.difference, item.dataTypeId),
                    item.commentary
                  ]
                })}
              customCellClasses={[classes.center, classes.right, classes.right, classes.center, classes.right]}
              customClassesForCells={[0, 2, 3, 4, 6]}
              customHeadCellClasses={[classes.center, classes.right, classes.right, classes.center, classes.right]}
              customHeadClassesForCells={[0, 2, 3, 4, 6]}
            />
          </CardBody>
        </Card>
      </GridItem>
    );
  }

  let breadcrumbViews = [
    {
      name: translate("routes_home_label"),
      url: "/admin/dashboard",
      icon: DashboardIcon
    },
    {
      name: translate("wig"),
      url: props.predictive ? `/admin/wig/details/${props.predictive.wigId}` : '',
      icon: Flag
    },
    {
      name: translate("predictive"),
      url: `/admin/predictive/details/${props.predictiveId}`,
      icon: TrendingUp
    }
  ]

  return (
    <div>
      {console.log(props)}
      <Breadcrumbs views={breadcrumbViews} />
      <Heading
        textAlign="center"
        title={props.axesDataset.name}
        category={
          <span>
            {translate("predictive_details_title")}
          </span>
        }
      />
      <GridContainer>
        <GridItem xs={12}>
          <GridItem xs={12}>
            <GridContainer justify="flex-end">
              {verifyPermission(props.roleId, props.team.dataRoleId, "predictive", "track.all") ?
                <NavLink
                  to={`/admin/predictive/tracking/${props.predictiveId}`}
                >
                  <Tooltip title={translate("predictive_track_tooltip")}>
                    <Button color="primary">
                      <TrackChanges className={classes.icons} /> {translate("const_track")}
                    </Button>
                  </Tooltip>
                </NavLink>
                :
                null
              }
            </GridContainer>
          </GridItem>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <TrackingDashboard description={props.axesDataset.description} 
          axes={props.axesDataset.axes} showDetails={false} showInRow={true}/>
        </GridItem>
        {getTableForAxis(1)}
        {getTableForAxis(2)}
      </GridContainer>
    </div>
  );
}
