const commitmentCreateSchema = {
    wigId: {
        type: "integer",
        required: true,
        displayName: "MCI",
    },
    title: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 512
        },
        displayName: "Título"
    },
    when: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 512
        },
        displayName: "Cuándo"
    },
    internalDependency: {
        type: "boolean",
        required: true,
        displayName: "Despeje interno?"
    },
    hasDependency: {
        type: "boolean",
        required: true,
        displayName: "Existe despeje?"
    },
    dependencyUser: {
        type: "integer",
        required: false,
        displayName: "Usuario que se solicita ayuda?",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if (data.hasDependency) {
                        isValid = !(data.dependencyUser === null || data.dependencyUser === undefined || data.dependencyUser === "")
                    }

                    return isValid
                },
                message: "El usuario al que se solicita ayuda es requerido"
            }
        ]
    },
    applyForAllMembers: {
        type: "boolean",
        required: true,
        displayName: "Aplica para todos?"
    },
    assignedUser: {
        type: "integer",
        required: false,
        displayName: "Usuario asignado",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if (!data.applyForAllMembers) {
                        isValid = !(data.assignedUser === null || data.assignedUser === undefined || data.assignedUser === "")
                    }

                    return isValid
                },
                message: "El usuario asignado es requerido"
            }
        ]
    },
}

const commitmentTrackingSchema = {
    commentary: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 256
        },
        displayName: "Comentario"
    },
    isDone: {
        type: "boolean",
        required: true,
        displayName: "Terminado?"
    },
    isClosed: {
        type: "boolean",
        required: false,
        displayName: "Cerrado?"
    }
}

export {
    commitmentCreateSchema,
    commitmentTrackingSchema
}