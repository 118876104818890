import {
  primaryColor,
  whiteColor
} from "assets/jss/material-dashboard-pro-react.js";

export const overrides = {
    MuiCircularProgress: {
      root: {
        color: primaryColor[0],
      }
    },
    MuiTypography:{
      colorTextSecondary:{
        color: whiteColor
      }
    },
    MuiOutlinedInput:{
      root:{
        '&:hover $notchedOutline': {
          borderColor: primaryColor[0]
        },
        '&$focused $notchedOutline': {
          borderColor: primaryColor[0]
        }
      }
    },
    MuiInputLabel:{
      root:{
        '&$focused':{
          color: primaryColor[4]
        }
      }
    }
};