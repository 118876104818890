/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
    CircularProgress,
    Select,
    MenuItem
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// material ui icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import AddAlert from "@material-ui/icons/AddAlert";
import DashboardIcon from "@material-ui/icons/Dashboard";

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// constants
import { DataRoles } from "components/Constants";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/User/createStyle.js";

// Functions
import { translate } from "utils/translate"

const useStyles = makeStyles(styles);

export default function createView(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    let breadcrumbViews = [
        {
            name: translate("routes_home_label"),
            url: "/admin/dashboard",
            icon: DashboardIcon
        },
        {
            name: translate("routes_team_add_member_label"),
            url: "/admin/members",
            icon: PersonAdd
        }
    ]

    return (
        <>
            <Breadcrumbs views={breadcrumbViews} />
            <Heading
                textAlign="center"
                title={translate("routes_team_add_member_label")}
            />
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <PersonAdd />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{translate("add_member_title")}</h4>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <InputLabel
                                                        htmlFor="simple-select"
                                                        className={classes.selectLabel}
                                                    >
                                                        {translate("add_member_team_label")}
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        classes={{
                                                            select: classes.select
                                                        }}
                                                        value={props.member[`teamId`]}
                                                        onChange={e => props.onFieldChange(`teamId`, e.target.value)}
                                                        inputProps={{
                                                            name: `teamId`,
                                                            id: `teamId`
                                                        }}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            {translate("add_member_team_input")}
                                                        </MenuItem>
                                                        {props.teamOptions.map((team, index) => (
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                                                                value={team.teamId}
                                                                key={index}
                                                            >
                                                                {team.title}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <Autocomplete
                                                        loadingText= {translate("autocomplete_loading_text")}
                                                        clearText={translate("autocomplete_clear_text")}
                                                        noOptionsText={translate("autocomplete_no_options_text")}
                                                        id="userList"
                                                        open={open}

                                                        onOpen={() => {
                                                            setOpen(true);
                                                            props.changeLoadingUserList(true);
                                                        }}
                                                        onClose={() => {
                                                            setOpen(false);
                                                            props.setUserList([]);
                                                        }}
                                                        onChange={(event, newValue) => {
                                                            if (newValue) {
                                                                newValue = parseInt(newValue.userId)
                                                            }
                                                            props.onFieldChange("userId", newValue)
                                                        }}
                                                        getOptionSelected={(option, value) => option.userId === value.userId}
                                                        getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
                                                        options={props.userList}
                                                        loading={props.loadingUserList}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={translate("add_member_user_label") + "*"}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            {props.loadingUserList ? <CircularProgress color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                                {/* 
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <InputLabel
                                                        htmlFor="simple-select"
                                                        className={classes.selectLabel}
                                                    >
                                                        Selecciona a una persona*
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        classes={{
                                                            select: classes.select
                                                        }}
                                                        value={props.member[`userId`]}
                                                        onChange={e => props.onFieldChange(`userId`, e.target.value)}
                                                        inputProps={{
                                                            name: `userId`,
                                                            id: `userId`
                                                        }}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            Seleccione a la persona
                                                        </MenuItem>
                                                        {props.userList.map((user, index) => (
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                style={{ overflowX: "auto" }}
                                                                value={user.userId}
                                                                key={index}
                                                            >
                                                                {`${user.firstName} ${user.lastName} (${user.email})`}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                            */}
                                            </GridItem>
                                            <GridItem xs={12} sm={4} md={4}>
                                                <FormControl
                                                    fullWidth
                                                    className={classes.selectFormControl}
                                                >
                                                    <InputLabel
                                                        htmlFor="simple-select"
                                                        className={classes.selectLabel}
                                                    >
                                                        {translate("add_member_role_label")}*
                                                    </InputLabel>
                                                    <Select
                                                        
                                                        MenuProps={{
                                                            className: classes.selectMenu
                                                        }}
                                                        classes={{
                                                            select: classes.select
                                                        }}
                                                        value={props.member[`dataRoleId`]}
                                                        onChange={e => props.onFieldChange(`dataRoleId`, e.target.value)}
                                                        inputProps={{
                                                            name: `dataRoleId`,
                                                            id: `dataRoleId`
                                                        }}
                                                    >
                                                        <MenuItem
                                                            
                                                            disabled
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            {translate("add_member_role_input")}
                                                        </MenuItem>
                                                        {Object.keys(DataRoles).map((key, index) => (
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelected
                                                                }}
                                                                style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                                                                value={parseInt(key)}
                                                                key={index}
                                                            >
                                                                {translate(`const_datarole_${key}`)}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                
                                    {/*((props.roleId <= 2) || (props.roleId == 3 && props.dataRoleId == 1) || (props.roleId == 3 && props.dataRoleId == 2)) ?
                                   <GridItem xs={12} sm={4} md={4}>
                                   <FormControl
                                       fullWidth
                                       className={classes.selectFormControl}
                                       disabled= {props.isLeader}
                                   >
                                       <InputLabel
                                           htmlFor="simple-select"
                                           className={classes.selectLabel}
                                       >
                                           {translate("from_team_label")}
                                       </InputLabel>
                                       <Select
                                           
                                           MenuProps={{
                                               className: classes.selectMenu
                                           }}
                                           classes={{
                                               select: classes.select
                                           }}
                                           value={props.fromTeam}
                                           onChange={e => props.onFieldChangeTeam(e.target.value)}
                                           inputProps={{
                                               name: `teamId`,
                                               id: `teamId`
                                           }}
                                       >
                                           <MenuItem
                                               
                                               disabled
                                               classes={{
                                                   root: classes.selectMenuItem
                                               }}
                                           >
                                               {translate("chooose_team_imput")}
                                           </MenuItem>
                                           {props.teamLeaderOptions.map((team, index) => (
                                               <MenuItem
                                                   classes={{
                                                       root: classes.selectMenuItem,
                                                       selected: classes.selectMenuItemSelected
                                                   }}
                                                   style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                                                   value={team.teamId}
                                                   key={index}
                                               >
                                                   {team.title}
                                               </MenuItem>
                                           ))}
                                       </Select>
                                   </FormControl>
                               </GridItem>
                           
                                        :
                                        null
                                                */}
                                       {/*((props.roleId <= 2) || (props.roleId == 3 && props.dataRoleId == 1) || (props.roleId == 3 && props.dataRoleId == 2)) ?
                                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                                <div className={classes.block}>
                                                <FormControlLabel
                                                disabled = {props.selectFromTeam}
                                                control={
                                                <Switch
                                                checked={props.copyWIG}
                                                onChange={event => props.onFieldChangeWIG(event.target.checked)}
                                                value="copyWIG"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                                />
                                                }
                                                classes={{
                                                    label: classes.label
                                                }}
                                                label={translate("copy_wig_leader_label")}
                                                />

                                                </div>
                                                <div className={classes.block}>
                                                <FormControlLabel
                                                 disabled = {props.selectFromTeam}
                                                control={
                                                <Switch
                                                checked={props.copyPredictive}
                                                onChange={event => props.onFieldChangePredictive(event.target.checked)}
                                                value="copyPredictive"
                                                classes={{
                                                    switchBase: classes.switchBase,
                                                    checked: classes.switchChecked,
                                                    thumb: classes.switchIcon,
                                                    track: classes.switchBar
                                                }}
                                                />
                                                }
                                                classes={{
                                                    label: classes.label
                                                }}
                                                label={translate("copy_predictive_leader_label")}
                                                />

                                                </div>
                                            </GridItem>
                                        :
                                        null
                                            */ }
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                                <div className={classes.formCategory}>
                                    <small>*</small> {translate("required_fields")}
                                </div>
                                <div className={classes.center}>
                                    {props.isLoadingSave ?
                                        <CircularProgress color="inherit" /> :
                                        <Button color="primary" onClick={props.onMemberSave}>
                                            {translate("button_add")}
                                        </Button>
                                    }
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                    <Snackbar
                        place="br"
                        color="success"
                        icon={AddAlert}
                        message={translate("alert_add_member_success")}
                        open={props.createSuccess}
                        closeNotification={() => props.setCreateSuccess(false)}
                        close
                    />
                    <Snackbar
                        place="bl"
                        color="danger"
                        icon={AddAlert}
                        message={props.validationMessage}
                        open={props.createError}
                        closeNotification={() => props.setCreateError(false)}
                        close
                    />
                </GridItem>
            </GridContainer>
        </>
    );
}