export const initialState = {
	teamId: "",
	allowedTeams: [],
	teamList: [],
	wigList: [],
	membersList: [],
	roleId: "",
	team: {},
	reminderList: [],
	reminder: ""
};

export const SET_ROLE = "Admin/SET_ROLE";
export const SET_CURRENT_TEAM = "Admin/SET_CURRENT_TEAM";
export const SET_TEAM = "Admin/SET_TEAM";
export const SET_ALLOWED_TEAMS = "Admin/SET_ALLOWED_TEAMS";
export const SET_OWNED_TEAMS = "Admin/SET_OWNED_TEAMS";
export const SET_TEAM_LIST = "Admin/SET_TEAM_LIST";
export const SET_WIG_LIST = "Admin/SET_WIG_LIST";
export const SET_REMINDER_LIST = "Admin/SET_REMINDER_LIST";
export const SET_REMINDER_ = "Admin/SET_REMINDER_";
export const SET_TEAM_MEMBERS_LIST = "Admin/SET_TEAM_MEMBERS_LIST";
export const SET_LANGUAGE = "Admin/SET_LANGUAGE";

export const setTeam = (team) => dispatch => {
	dispatch({ type: SET_CURRENT_TEAM, payload: team })
}

export const setRole = (roleId) => dispatch => {
	dispatch({ type: SET_ROLE, payload: roleId })
}

export const setTeamList = (teamList) => dispatch => {
	dispatch({ type: SET_TEAM_LIST, payload: teamList })
}

export const setTeamId = (teamId) => dispatch => {
	dispatch({ type: SET_TEAM, payload: teamId })
}

export const setAllowedTeams = (allowedTeams) => dispatch => {
	dispatch({ type: SET_ALLOWED_TEAMS, payload: allowedTeams })
}

export const setOwnedTeams = (ownedTeams) => dispatch => {
	dispatch({ type: SET_OWNED_TEAMS, payload: ownedTeams })
}

export const setWIGList = (wigList) => dispatch => {
	dispatch({ type: SET_WIG_LIST, payload: wigList })
}

export const setReminderList = (reminderList) => dispatch => {
	dispatch({ type: SET_REMINDER_LIST, payload: reminderList })
}
export const setReminder = (reminder) => dispatch => {
	dispatch({ type: SET_REMINDER_, payload: reminder })
}
export const setTeamMembersList = (membersList) => dispatch => {
	dispatch({ type: SET_TEAM_MEMBERS_LIST, payload: membersList })
}

export const setLanguage = (language) => dispatch => {
	dispatch({ type: SET_LANGUAGE, payload: language })
}

export default function AdminReducer(state = initialState, { type, payload }) {
	switch (type) {
		case SET_ROLE:
			return {
				...state,
				roleId: payload
			};
		case SET_CURRENT_TEAM:
			return {
				...state,
				team: payload
			};
		case SET_TEAM:
			return {
				...state,
				teamId: payload
			};
		case SET_TEAM_LIST:
			return {
				...state,
				teamList: payload
			};
		case SET_WIG_LIST:
			return {
				...state,
				wigList: payload
			};
			case SET_REMINDER_LIST:
				return {
					...state,
					reminderList: payload
				};	
				case SET_REMINDER_:
				return {
					...state,
					reminder: payload
				};	
		case SET_TEAM_MEMBERS_LIST:
			return {
				...state,
				membersList: payload
			};
		case SET_ALLOWED_TEAMS:
			return {
				...state,
				allowedTeams: payload
			}
		case SET_OWNED_TEAMS:
			return {
				...state,
				ownedTeams: payload
			}
		case SET_LANGUAGE:
			return {
				...state,
				language: payload
			}
		default:
			return state;
	}
}
