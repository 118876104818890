import { compose, lifecycle, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import { signOut } from "store/LoginState";
import { setTeamList, setTeamId, setWIGList, setReminderList, setReminder, setTeamMembersList, setTeam, setRole, setAllowedTeams, setOwnedTeams, setLanguage } from "store/AdminState";
import { getUserRole } from "utils/functions"
import { getCommitmentReminder} from "services/apiAdmin/commitmentApi";
import { getInputFormatDate,  getInputFormatDateY} from "utils/functions"

// services
import { getTeamsByUser, getUsersByTeam, getAllowedTeamsByUser } from "services/apiAdmin/teamApi";
import { getWIGByTeamIdAndYear } from "services/apiAdmin/wigApi";

import AdminView from "./AdminView";

let _isMounted = false


async function getTeamsList(props) {
	let result = await getTeamsByUser()
	if (result.success) {
		if (_isMounted) {
			// Set first result as selected value if found
			props.setTeamList(result.data)

			if ((props.teamId === "" || props.teamId === undefined) && result.data.length) {
				props.setTeamId(result.data[0].teamId)
				props.setTeam(result.data[0])
			}
		}
	}
	props.changeLoadingTeamList(false)
}

async function getCommitmentsReminder(props) {
	let today_date = getInputFormatDateY(new Date());
	let result = await getCommitmentReminder(today_date);
	if(_isMounted && result.success){
		props.setReminderList(result.data);
		props.setReminder(result.data.length)
	}

}
async function getAllowedTeamsList(props) {
	let result = await getAllowedTeamsByUser()

	if (result.success) {
		if (_isMounted) {
			// Set first result as selected value if found			
			let allowed = result.data.map(allowGroup => {
				return [allowGroup.TeamId, allowGroup.ParentTeamId]
				//teamId: allowGroup.TeamId,
				//parentTeamId: allowGroup.ParentTeamId

			})
			let flattenedArray = [].concat.apply([], allowed) //? Flatten the array and remove nulls
			var filtered = flattenedArray.filter(function (el) {
				return el != null;
			});
			let owned = result.data.map(allowGroup => {
				return [allowGroup.TeamId]
				//teamId: allowGroup.TeamId,
				//parentTeamId: allowGroup.ParentTeamId

			})
			let ownedFlattenedArray = [].concat.apply([], owned) //? Flatten the array and remove nulls
			var ownedFiltered = ownedFlattenedArray.filter(function (el) {
				return el != null;
			});
			props.setAllowedTeams(filtered)
			props.setOwnedTeams(ownedFiltered)

		}
	}
	props.changeLoadingTeamList(false)
}

async function getWIGList(props, teamId) {
	let menu = []
	// if team Id is not defined
	if (!teamId) {
		return menu
	}
	let wigListYear = []
	let currentYear = new Date().getFullYear()
	for (let iterateYear = currentYear + 1; iterateYear > currentYear - 4; iterateYear--){
		let result = await getWIGByTeamIdAndYear(teamId, iterateYear)
		if (result.success) {		
			if (_isMounted) {
				let wigList = result.data.map(function (wig) {
					return {
						id: wig.id,
						name: wig.displayName1,
						teamId: wig.teamId,
						isParentTeam: wig.isParentTeam,
						year: wig.year
					}
				})
				wigListYear.push(wigList)
			}
		}	
	}
	if (_isMounted) {
		/*wigListYear = 
		[
			[{id:1, name:"hola 2021", teamId:1, isParentTeam:0, year:2021}],
			[{id:2, name:"hola 2020", teamId:1, isParentTeam:0, year:2020}],
			[],
			[],
			[{id:3, name:"hola 2017", teamId:1, isParentTeam:1, year:2017}],
		]*/
		props.setWIGList(wigListYear)
	}

	return menu
}

async function getTeamUsers(props, teamId) {
	if (!teamId) {
		return
	}
	let result = await getUsersByTeam(teamId);
	if (_isMounted && result.success) {
		props.setTeamMembersList(result.data)
	}
}


export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
			teamId: state.admin.teamId,
			team: state.admin.team,
			teamList: state.admin.teamList,
			isInitial: state.admin.isInitial,
			wigList: state.admin.wigList,
			membersList: state.admin.membersList,
			roleId: state.admin.roleId,
			allowedTeams: state.admin.allowedTeams,
			ownedTeams: state.admin.ownedTeams,
			language: state.admin.language //? leaved this as an example of using language by props instead of localStorage
		}),
		{ signOut, setTeamList, setTeamId, setWIGList, setReminderList, setReminder, setTeamMembersList, setTeam, setRole, setAllowedTeams, setOwnedTeams, setLanguage }
	),
	withState("loadingTeamList", "changeLoadingTeamList", false),
	withHandlers({
		onTeamChange: (props) => (id) => {
			let team = props.teamList.find(function (team) {
				return team.teamId === id
			})
			if (team) {
				console.log("new team", team)
				props.setTeam(team)
				props.history.push('/admin/dashboard')
			}
			props.setTeamId(id)
		}
	}),
	lifecycle({
		async componentDidMount() {
			_isMounted = true

			let role = getUserRole()
			this.props.setRole(role)

			await getTeamsList(this.props)
			await getWIGList(this.props, this.props.teamId)
			await getCommitmentsReminder(this.props)
			await getTeamUsers(this.props, this.props.teamId)
			await getAllowedTeamsList(this.props)

		},
		UNSAFE_componentWillUpdate(nextState) {
			// Check team changes
			if (nextState.teamId !== this.props.teamId) {
				getWIGList(this.props, nextState.teamId)
				getTeamUsers(this.props, nextState.teamId)
				getCommitmentsReminder(this.props)
			}

			//If loading changes
			if (this.props.loadingTeamList !== nextState.loadingTeamList && nextState.loadingTeamList) {
				getTeamsList(this.props)
			}
		},
		componentWillUnmount() {
			_isMounted = false
		}
	})
)(AdminView);
