import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { getExternalCommitments } from "services/apiAdmin/commitmentApi";
import { GetWIGSUnassigned } from "services/apiAdmin/wigApi"
import { getWIGByTeamIdAndCURRENTYear, getWIGByTeamAndParentIdAndYear, updateParentWIG } from "services/apiAdmin/wigApi"
import AssignView from "./AssignView"
import { formatWIGName } from "utils/dataTypeFormatter";
import { getUserLocale } from "utils/translate";
import { GetUnassignedPredictives, UpdatePredictiveWigID } from "services/apiAdmin/predictiveApi";
let _isMounted = false

async function getWIGS(props) {
	let result= await GetWIGSUnassigned(props.teamId);
	if(result.success){
		props.setWigList(result.data)
	}
}

async function getWIGList(props) {
	let currentYear = new Date().getFullYear()
	let result = await getWIGByTeamAndParentIdAndYear(props.team.teamId, currentYear)

	if (result.success) {
		props.changeWIGList(result.data)
	}
}

async function getPredictives(props){
	let result = await GetUnassignedPredictives(props.teamId)
	if(result.success){
		props.setPredictiveList(result.message)
	}
}

async function getParentWIGList(props) {
	// if team Id is not defined
	if (!props.team.parentTeamId) {
		return
	}
	
	let currentYear = new Date().getFullYear()
	let result = await getWIGByTeamIdAndCURRENTYear(props.team.parentTeamId, currentYear)
	if (result.success) {
		if (_isMounted) 
		{
			let wig
		for (wig of result.data) {
			wig.name = formatWIGName(wig, getUserLocale())
		}
			props.setParentWIGList(result.data)
		}
	}
}

async function savePredictiveUpdate(predictiveId,wigId){
	let result = await UpdatePredictiveWigID(wigId, predictiveId);
	if(result.success){
		console.log("SE ACTUALIZO LA PREDICTIVA")
	}
}

async function saveWigUpdate(wigId,parentWigId){
	let result = await updateParentWIG(wigId, parentWigId);
	if(result.success){
		console.log("SE ACTUALIZO EL WIG");
	}
}

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
            teamId: state.admin.teamId,
            team: state.admin.team
		}),
		{  }
	),
	withState("WigList", "setWigList", []),
	withState("PredictiveList", "setPredictiveList", []),
    withState("parentWIGList","setParentWIGList", []),
	withState("PredictiveList", "setPredictiveList", []),
	withState("wigTeamList", "changeWIGList", []),
	withState("wigID", "setWigID",0),
	withHandlers({
		onWigIDSelect: (props) => async (event) => {
			
			console.log("WIGID" + event.target.value);
			props.setWigID(event.target.value);
		},
		
		
		onPredictiveSave: (props) => async(predictiveId) => {
			// same as here: window.location.href = `/admin/accountabilityrecord/team/${event.teamId}`; to add new. 
			
			await savePredictiveUpdate(predictiveId, props.wigID)
			window.location.href = `/admin/assigment/team`;
		},
		
		onWIGSave: (props) => async (wigId) => {
			// same as here: window.location.href = `/admin/accountabilityrecord/team/${event.teamId}`; to add new. 
			
			await saveWigUpdate(wigId, props.wigID)
			window.location.href = `/admin/assigment/team`;
		}

	}),
	lifecycle({
		componentDidMount() {
			_isMounted = true
			getWIGS(this.props)
            getParentWIGList(this.props)
			getPredictives(this.props)
			getWIGList(this.props);
		}
	})
)(AssignView);
