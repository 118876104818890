import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { teamAccountabilitySchema } from "models/teamModel";
import { addDays } from "utils/functions"
import { getTeamById, updateTeamAccountability, getAccountabilitySchedule} from "services/apiAdmin/teamApi";
// questions Api
import { getAllQuestions, saveAccountabilityGrades , getAccountabilityMultipleRecordById, getAccountabilityMultipleRecordParticipantsById, getRecordById} from "services/apiAdmin/accountabilityApi";
import { setTeamMembersList } from "store/AdminState";

import { verifyPermission, getFormatDateY } from "utils/functions";

import AccountabilityView from "./recordView";
import { translate } from "utils/translate";
import { getUserLocale } from "utils/translate";

const colorsArray = [
  "default",
  "green",
  "red",
  "azure",
  "orange",
  "rose",
]

const newTeam = {
  title: "",
  parentTeamId: "",
  day: "",
  time: "7:00:00"
}


const gradesData = {
  'acGrade_0': 0,
  'acGrade_1': 0,
  'acGrade_2': 0,
  'acGrade_3': 0,
  'acGrade_4': 0,
  'acGrade_5': 0,
  'acGrade_6': 0,
  'acGrade_7': 0,
  'acGrade_8': 0
}

const gradesState = {
  'acGrade_0': "success",
  'acGrade_1': "success",
  'acGrade_2': "success",
  'acGrade_3': "success",
  'acGrade_4': "success",
  'acGrade_5': "success",
  'acGrade_6': "success",
  'acGrade_7': "success",
  'acGrade_8': "success"
}

const participantsData = [];

const newTeamState = {
  title: "",
  parentTeamId: "",
  day: "",
  time: ""
}

let _isMounted = false

async function onSubmit(props) {
  let result = await updateTeamAccountability(props.teamId, props.team)
  // Show confirmation message
  if (result && result.success && _isMounted) {
    props.setCreateSuccess(true)
    setTimeout(function () {
      if (_isMounted) {
        props.setCreateSuccess(false)
        //props.history.push('/admin/dashboard')
      }
    }, 1500);
  } else {
    // Show error message
    if (result) {
      let message = result.message
      if (!message) {
        message = "Algo ocurrió mal, inténtalo nuevamente."
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

async function getTeam(props, teamId) {
  let result = await getTeamById(teamId)

  if (result.success) {
    if (_isMounted) {
      props.setTeam(result.data)
    }

  }
}

async function getRecordbyID(props, recordId) {
  let result = await getRecordById(recordId)


  if (result.success) {
    if (_isMounted) {
      console.log(result.data)
     // props.setTeam(result.data)
    }

  }
}

async function getMultipleRecordById(props, recordId){
  let resultr=await getRecordById(recordId);

  if (resultr.success) {
    if (_isMounted) {
      let recordDateString;
			let recordTime_h;
			let recordDate;
      recordDateString=resultr.data[0].ACDate;
			recordTime_h = resultr.data[0].ACTime;
			recordDateString= recordDateString.slice(0,10) + " "+ recordTime_h;
			recordDate = new Date(resultr.data[0].ACDate);
			recordDateString = new Date(recordDateString);
			recordDate = getFormatDateY(recordDate, recordDateString,getUserLocale());
      props.setRecordDetail(recordDate);
     // props.setTeam(result.data)
  
    }

  }
  let result = await getAccountabilityMultipleRecordById(recordId)
  if(result.success){
    if(_isMounted){
      props.setResults(result.data);
    }
  }
 // props.setNote(result.data[8].observations);
  getTeamUsers(props,recordId,result.data);
}

async function getTeamUsers(props, recordId, results) {
  if (!recordId) {
    return
  }
  let participants = [];
  let team_p = {
    firstName: translate("team_create_title"),
    lastName: translate("accountability_record_average_title")
  }
  let result = await getAccountabilityMultipleRecordParticipantsById(recordId);
  if (_isMounted && result.success) {
    participants.push(team_p);
    for(let c =0; c< result.data.length; c++){
      participants.push(result.data[c]);
    }
    props.setMembersselect(participants)
    let grad=[] ;
    let not = [];
    let prom = [];
    let total =0;
    let count_total =0;
    let can = results.length /9;
    let val =[0,0,0,0,0,0,0,0];
    let notes = ["","","","","","","",""]
    let pos =0;
    
    for(let p =0; p < results.length; p++){
      if(pos < 8 && (results[p].score !== "N/A" && results[p].score !== "")){
        val[pos] = parseInt(val[pos]) + parseInt(results[p].score);
        pos ++;
      }
      else if( pos == 8){
        pos = 0;
      }
    }
   for(let r=0; r < 9; r++){
    val[r] =val[r] / can;
    val[r] =Number(val[r].toFixed(2));
    grad.push(val[r]);
    not.push("");
   }
   prom.push("");
   
      for(let j =0; j< results.length; j++){
          grad.push(results[j].score);
          not.push(results[j].observations);
      }
      for(let i =0; i< result.data.length; i++){
        let aux =0;
        let can =0;
        for(let x =0; x < results.length; x++){
          if(results[x].userId == result.data[i].userId ){
            if(results[x].score !== "N/A" && results[x].score !== ""){
              aux = aux + parseInt(results[x].score,10);
              can=can + 1;
              total = total +  parseInt(results[x].score,10);
              count_total = count_total + 1;
            }
          }
        }
        aux = aux/can;
        aux = Number(aux.toFixed(2));
        prom.push(aux);
      
      }
      total = total / count_total;
      total = Number(total.toFixed(2));
      props.setMultipleAverage(prom);
      props.setAverage(total);
      props.setMultipleGrades(grad);
      props.setMultipleNotes(not);
    }
  }



let questionsList;

async function getQuestions() {

  let result = await getAllQuestions();
  if (_isMounted && result.success) {
    questionsList = result.data;
  }
}



async function getSchedule(props, filter) {
  let result = await getAccountabilitySchedule(filter)

  if (result.success) {
    if (_isMounted) {
      const today = new Date();
      const y = today.getFullYear()
      const m = today.getMonth() + 1
      const wd = today.getDay()

      let events = result.data.map((team) => {
        let eventDate = addDays(today, team.day - wd)
        eventDate = new Date(`${y}-${m}-${String(eventDate.getDate()).padStart(2, '0')} ${team.time}`)
        return {
          teamId: team.teamId,
          title: team.title,
          allDay: false,
          start: eventDate,
          end: new Date(eventDate.getTime() + 30 * 60000),
          color: colorsArray[Math.floor(Math.random() * 6)]
        }
      })
      props.setEvents(events)
    }

  }
}

async function onGradesSubmit(props) {
  // defining data object
  let data = {};

  // defining arrays to store values
  const grades = [],
    notes = [],
    questions = [];

  for (let counter = 0; counter <= Object.keys(props.grades).length - 1; counter++) {
    grades.push(props.grades[`acGrade_${counter}`][0]);
    questions.push(props.questionsList[counter].Id);
    if (props.grades[`acGrade_${counter}`][1]) {
      notes.push(props.grades[`acGrade_${counter}`][1]);
    } else {
      notes.push('');
    }
  }
  data['grades'] = grades;
  data['notes'] = notes;
  data['participants'] = props.participants;
  data['questions'] = questions;
  await saveAccountabilityGrades(data, props.teamId);
  window.location.href = `/admin/accountability`;
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      teamId: state.admin.teamId,
      roleId: state.admin.roleId,
      team: state.admin.team,
      questionsList: questionsList,
    }),
    { setTeamMembersList }
  ),
  withState("team", "setTeam", { ...newTeam }),
  withState("teamState", "setTeamState", { ...newTeamState }),
  withState("events", "setEvents", []),
  withState("results", "setResults", []),
  withState("membersList", "setMembersselect", []),
  withState("viewAllTeams", "setViewAllTeams", true),
  withState("createSuccess", "setCreateSuccess", false),
  withState("createError", "setCreateError", false),
  withState("validationMessage", "setValidationMessage", ""),
  withState("isLoadingSave", "setIsLoadingSave", false),
  withState("grades", "changeGrades", { ...gradesData }),
  withState("gradesState", "changeGradesState", { ...gradesState }),
  withState("participants", "changeParticipants", participantsData),
  withState("selectedTab", "setSelectedTab", 0),
  withState("selectedTabR", "setSelectedTabR", 0),
  withState("recordId", "setRecordId", ""),
  withState("selectedTabRN", "setSelectedTabRN", 0),
  withState("multipleGrades", "setMultipleGrades", []),
  withState("multipleNotes", "setMultipleNotes", []),
  withState("note", "setNote", ""),
  withState("multipleAverage", "setMultipleAverage", []),
  withState("average", "setAverage", 0),
  withState("recordDetail", "setRecordDetail", ""),
  withHandlers({
    onChangeTab: (props) => (element, value) =>{
      let v = (value*9)+8;
      props.setSelectedTab(value);
      props.setSelectedTabR(value*9);
      props.setSelectedTabRN(v);
    },
    onFieldChange: (props) => (field, value) => {
      props.team[field] = value
      let isValid = validateProperty(teamAccountabilitySchema, props.team, field).isValid
      if (isValid) {
        props.teamState[field] = "success"
      } else {
        props.teamState[field] = "error"
      }
      props.setTeamState(props.teamState)
      props.setTeam(props.team)
      
    },
    onParticipantsChange: (props) => (value) => {
      props.participants.length = 0;

      for (let i = 0, l = value.length; i < l; i += 1) {
        props.participants[i] = value[i];
      };

      props.changeParticipants(props.participants);
    },
    onGradeChange: (props) => (field, value) => {
      props.grades[field][0] = value;
      props.changeGrades(props.grades);
    },
    onNotesChange: (props) => (field, value) => {
      props.grades[field][1] = value;
      if (field === `acGrade_8`) {
        props.grades[field][0] = 'N/A';
      }
      if (value.length < 1) {
        // un comment this if notes are required. 
        // props.gradesState[field] = 'error';
        props.gradesState[field] = 'success';
      } else {
        props.gradesState[field] = 'success';
      }
      props.changeGrades(props.grades);
      props.changeGradesState(props.gradesState);
    },
    onFilterChange: (props) => async (field, value) => {
      props.setViewAllTeams(value)

      getSchedule(props, value)
    },
    gradesSave: (props) => async () => {
      props.setIsLoadingSave(true)

      let canSave = true;
      for (var [key, value] of Object.entries(props.grades)) {

        if ((!value[0]) && key !== 'acGrade_8') {
          props.gradesState[key] = 'error';
          canSave = false;
        } else {
          props.gradesState[key] = 'success';
        }
      }
      setTimeout(function () {
        props.setIsLoadingSave(false)
        if (canSave) {
          props.setCreateSuccess(true);
          onGradesSubmit(props);
          setTimeout(function () {
            if (_isMounted) {
              props.setCreateSuccess(false)
              //props.history.push('/admin/dashboard')
            }
          }, 1000);
        } else {
          let message = "Completa todos los campos para continuar."
          props.setValidationMessage(message)
          props.setCreateError(true)
        }

      }, 1000);

    },
    onSave: (props) => async () => {
      props.setIsLoadingSave(true)
      let validation = validateObject(teamAccountabilitySchema, props.team)
      if (validation.isValid) {
        await onSubmit(props)
      } else {
        let field
        for (field in props.team) {
          if (validation.errors[field]) {
            props.teamState[field] = "error"
          }
        }
        props.setTeamState(props.teamState)
      }
      props.setIsLoadingSave(false)

      if (verifyPermission(props.roleId, props.team.dataRoleId, "calendar", "read.all")) {
        getSchedule(props, props.viewAllTeams)
      }
    }
  }),
  lifecycle({
    componentWillMount() {
      getQuestions();
     // getMultipleRecordById(this.props,this.props.recordId);
     // getRecordbyID(this.props,this.props.recordId);
    //  getTeamUsers(this.props, 11);
   
  
     
      
    },
    componentDidMount() {
      _isMounted = true

      getTeam(this.props, this.props.teamId);
      let recordId = this.props.match.params.id;
      this.props.setRecordId(recordId);
      getQuestions();
      getMultipleRecordById(this.props,recordId);
      getRecordbyID(this.props,this.props.recordId);
  
     
 


      if (verifyPermission(this.props.roleId, this.props.team.dataRoleId, "calendar", "read.all")) {
        getSchedule(this.props, true)
      }

      this.props.setTeamState({ ...newTeamState })
      this.props.setCreateSuccess(false)
      this.props.setViewAllTeams(true)

    },
    UNSAFE_componentWillUpdate(nextState) {
      // Check team changes
      if (nextState.teamId !== this.props.teamId) {
        getTeam(this.props, nextState.teamId)
        getTeamUsers(this.props, this.props.teamId)
      }
    },
    componentWillUnmount() {
      _isMounted = false
    }
  })
)(AccountabilityView);
