import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { getPredictiveByWIGId, deletePredictiveById, GetPredictivesByWIGTeamAndUser } from "services/apiAdmin/predictiveApi";
import { getDataset0 } from "services/apiStat/statApi";
import { getWIGById, deleteWIGById, getWIGByTeamIdAndYear } from "services/apiAdmin/wigApi";
import { Months } from "components/Constants";
import { formatWIGEditName, formatPredictiveName } from "utils/dataTypeFormatter";
import { setWIGList } from "store/AdminState";

import {getAllowedTeamsByUser,getTeamById } from "services/apiAdmin/teamApi";
import { getUserLocale } from "utils/translate";

import { setOwnedTeams, setAllowedTeam, setTeam} from "store/AdminState";
import DetailsView from "./DetailsView";
import { useIsFocusVisible } from "@material-ui/core";
import { validateProperty, validateObject } from "utils/validator";
import { teamAccountabilitySchema } from "models/teamModel";

let _isMounted = false
let wigId = 0
let parentwig= false;
let predictives = [];

const mockMeasure = { amount: "", description: "" }
const mockAxes = {
	title: "",
	name: "...",
	description: "",
	axes: [
		{
			displayName: "...",
			dataTypeId: 1,
			data: Object.keys(Months).map((index) => {
				return (
					{
						"goalAchived": null,
						"name": Months[index]
					}
				)
			})
		}
	]
}
const restartMock = { ...mockAxes }

async function getWIGList(props) {
	// if team Id is not defined
	if (!props.teamId) {
		return
	}

	let years = [];
	let currentYear = new Date().getFullYear()
	for(let iterateYear = currentYear + 1; iterateYear > currentYear -4; iterateYear--) {
		let result = await getWIGByTeamIdAndYear(props.teamId, iterateYear)
		let object = result.data.map(function (wig) {
			return {
				...wig,
				id: wig.id,
				name: wig.displayName1
			}
		})
		years.push(object)
	}

	props.setWIGList(years.map(function (wig) {
		return wig
	}))
}

async function getPredictiveList(props) {
	let result = await getPredictiveByWIGId(wigId)
	result = result.data.filter(predictive => predictive.teamId === props.teamId);
	predictives = result;

	
	if (result) {
		if (_isMounted) {
			getPredictiveAxesDataset(props, result)
			// Set first result as selected value if found
			props.changePredictiveList(result)
		}

	}
}

async function getWIG(props) {
let result;
let wigListYear=[];
let authorize =0;
let currentYear = new Date().getFullYear()
for (let iterateYear = currentYear + 1; iterateYear > currentYear - 4; iterateYear--){
	let result = await getWIGByTeamIdAndYear(props.teamId, iterateYear)
	if (result.success) {		
		if (_isMounted) {
			
			for(let x =0; x< result.data.length; x++){
				wigListYear.push(result.data[x])
			}
		
		
		}
	}	
}

for(let x =0; x< wigListYear.length;x++){
	if(wigListYear[x].id==wigId){
		authorize=1;
	}
}
		result = await getWIGById(wigId,authorize)
	
	
	if (result.success) {
		if (_isMounted) {
			// Set first result as selected value if found
			props.setWigYear(result.data.year)
			result.data.name = formatWIGEditName(result.data, getUserLocale())
			VerifyMyWIG(props,result.data.teamId)
			props.changewig(result.data)
			await getAxesDataset(props, result.data, "MCI")
			await getWIGListS(props,result.data.year)
		}

	}
}

async function getAxesDataset(props, wig, title) {
	// Create axes data
	props.axesDataset.title = title
	props.axesDataset.name = wig.name
	props.axesDataset.description = wig.description
	props.axesDataset.axes = []
	let index
	for (index of [1, 2]) {
		if (wig[`axisId${index}`] !== null) {
			let datasetResult = await getDataset0(wig[`axisId${index}`])
			if (datasetResult.success) {
				let axisData = {
					displayName: wig[`displayName${index}`],
					dataTypeId: wig[`dataTypeId${index}`],
					data: datasetResult.data
				}
				props.axesDataset.axes.push(axisData)
			}
		}
	}

	if (_isMounted) {
		props.changeAxesDataset(props.axesDataset)
	}
}

async function getPredictiveAxesDataset(props, predictives) {
	let result = await getPredictiveByWIGId(wigId)

	let resultConsistency = result.data[0]?.consistency;
	let resultConsistencyTwo = result.data[0]?.consistencyTwo;

	
	let predictiveDataset = []

	let predictive
	for (predictive of predictives) {
		// Create axes data

		let axesDataset = {
			id: predictive.id,
			title: predictive.displayName1,
			name: formatPredictiveName(predictive),
			description: predictive.description,
			axes: []
		}

		let index
		for (index of [1, 2]) {
			if (index === 1) {
				if (predictive[`axisId${index}`] !== null) {
					let datasetResult = await getDataset0(predictive[`axisId${index}`], predictive[`wigId`], predictive.consistency)
					if (datasetResult.success) {
						let axisData = {
							displayName: predictive[`displayName${index}`],
							dataTypeId: predictive[`dataTypeId${index}`],
							data: datasetResult.data,
							detailsConsistency: resultConsistency
						}
						axesDataset.axes.push(axisData)
					}
				}
			} else{
				if (predictive[`axisId${index}`] !== null) {
					let datasetResult = await getDataset0(predictive[`axisId${index}`], predictive[`wigId`], predictive.consistencyTwo)
					if (datasetResult.success) {
						let axisData = {
							displayName: predictive[`displayName${index}`],
							dataTypeId: predictive[`dataTypeId${index}`],
							data: datasetResult.data,
							detailsConsistency: resultConsistencyTwo
						}
						axesDataset.axes.push(axisData)
					}
				}
			}
			
		}

		predictiveDataset.push(axesDataset)

	}
	props.changePredictiveDataset(predictiveDataset)
}

async function deletePredictive(props, id) {
	props.changeAlertLoading(true)
	let result = await deletePredictiveById(id)
	if (result.success) {
		if (_isMounted) {
			getPredictiveList(props)
			props.changeAlertType("deleteConfirmation")
		}
	}

	props.changeAlertLoading(false)
}

async function deleteWIG(props, id) {
	props.changeAlertLoading(true)
	
	await deletePredictiveList();

	let result = await deleteWIGById(id)
	if (result.success) {
		if (_isMounted) {
			await getWIGList(props)
			props.changeAlertType("deleteConfirmation")

			setTimeout(function () {
				props.changeAlertLoading(false)
				props.history.push("/admin/dashboard")
			}, 1500);
		}
		
	}
	
}


async function VerifyPredictivesLead(props, wigId){
	
	let result = await GetPredictivesByWIGTeamAndUser(props.teamId,wigId);
	
	if(result.success){
		
		if(result.message.length>0){
			props.setPredictivesLead(true)
			
		}
	}
}

async function VerifyMyWIG(props,teamWig){
	if(props.teamId== teamWig){
		props.setwigparent(false)
	}else{
		props.setwigparent(true)
	
	}
}

async function deletePredictiveList(){

	for(let i =0; i < predictives.length; i ++){
		let result = await deletePredictiveById(predictives[i].id);
		if(result.success){

		}
	}
}
async function onDidMount(props, id) {
	props.changeAxesDataset({ ...restartMock })
	props.changePredictiveList([])
	props.changePredictiveDataset([])

	wigId = id
	props.changeWIGId(wigId)

	_isMounted = true
	await getTeam(props,props.teamId)
	await getWIG(props)
	await getPredictiveList(props)

	props.setPredictiveCulminada(false)
}

async function getWIGListS(props,year) {
	// if team Id is not defined
	if (!props.teamId) {
		return
	}


	//let currentYear = new Date().getFullYear()

	let result = await getWIGByTeamIdAndYear(props.teamId, year)
	if (result.success) {
		if (_isMounted) {
			//Verify predictives in my team
			
			props.setPredictivesTeam(false);
			for(let i =0; i< result.data.length; i++){
				if(result.data[i].teamId == props.teamId){
					//HAVE WIGS IN MY TEAM
					

					//VerifyPredictives(props,result.data[i].id);}
					props.setPredictivesTeam(true);
				}else{
					//Verify predictives in parent´s wigs
					
					await VerifyPredictivesLead(props,result.data[i].id);
				}
			}
			// update general state
		
	}
}}

async function getAllowedTeamsList(props) {
	let result = await getAllowedTeamsByUser()

	if (result.success) {
		if (_isMounted) {
			// Set first result as selected value if found			
			let allowed = result.data.map(allowGroup => {
				return [allowGroup.TeamId, allowGroup.ParentTeamId]
				//teamId: allowGroup.TeamId,
				//parentTeamId: allowGroup.ParentTeamId

			})
			let flattenedArray = [].concat.apply([], allowed) //? Flatten the array and remove nulls
			var filtered = flattenedArray.filter(function (el) {
				return el != null;
			});
			let owned = result.data.map(allowGroup => {
				return [allowGroup.TeamId]
				//teamId: allowGroup.TeamId,
				//parentTeamId: allowGroup.ParentTeamId

			})
			let ownedFlattenedArray = [].concat.apply([], owned) //? Flatten the array and remove nulls
			var ownedFiltered = ownedFlattenedArray.filter(function (el) {
				return el != null;
			});
			//props.setAllowedTeams(filtered)
			await setOwnedTeams(ownedFiltered)

		}
	}
	//props.changeLoadingTeamList(false)
}

async function getTeam(props,teamId) {
	let result = await getTeamById(teamId)

	if (result.success) {
		if (_isMounted) {
		
			props.setAllowedition(result.data.allowLeaderEdition)
			await setTeam(result.data)
		}

	}
}



const newTeamState = {
	title: "",
	parentTeamId: "",
	day: "",
	time: "",
	month: ""
}

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
			teamId: state.admin.teamId,
			team: state.admin.team,
			roleId: state.admin.roleId,
			ownedTeams: state.admin.ownedTeams,
			wigList: state.admin.wigList
		}),
		{ setWIGList, setTeam }
	),
	withState("predictiveList", "changePredictiveList", []),
	withState("predictiveDataset", "changePredictiveDataset", []),
	withState("wigId", "changeWIGId", undefined),
	withState("wig", "changewig", undefined),
	withState("measurement", "changeMeasurement", mockMeasure),
	withState("axesDataset", "changeAxesDataset", mockAxes),
	withState("alertVisible", "changeAlertVisible", false),
	withState("alertLoading", "changeAlertLoading", false),
	withState("alertId", "changeAlertId", undefined),
	withState("alertType", "changeAlertType", ""),
	withState("alertObjectType", "changeAlertObjectType", ""),
	withState("showCumulativeGraph", "changeShowCumulativeGraph", false),
	withState("showAverageGraph", "changeShowAverageGraph", false),
	withState("wigTeamList", "setWigTeamList",[]),
	withState("predictivesTeam", "setPredictivesTeam",false),
	withState("predictivesLead", "setPredictivesLead", false),
	withState("predictiveCulminada", "setPredictiveCulminada", true),
	withState("wigparent","setwigparent", false),
	withState("wigYear","setWigYear",0),
	withState("Allowedition","setAllowedition",0),
	withState("teamState", "setTeamState", { ...newTeamState }),
	
	withHandlers({
		handleDeleteConfirm: props => (id, type) => {
			if (type === "wig") {
				deleteWIG(props, id)
			} else {
				deletePredictive(props, id)
			}
		},
		onFieldChange: (props) => (field, value) => {
			props.team[field] = value
			let isValid = validateProperty(teamAccountabilitySchema, props.team, field).isValid
			
			if (isValid) {
				props.teamState[field] = "success"
			} else {
				props.teamState[field] = "error"
			}
			if( field == `time`){
				let TimeParts = value.split(':')
				if (parseInt(TimeParts[0]) >= 7 && parseInt(TimeParts[0]) <= 17) {
					props.teamState[field] = "success"
				} else {
					props.teamState[field] = "error"
				}
			}
			


			props.setTeamState(props.teamState)
			props.setTeam(props.team)
		},
	}),
	lifecycle({
		
		async componentDidMount() {
		
			await getAllowedTeamsList(this.props)
			await onDidMount(this.props, this.props.match.params.id)
			//getWIGListS(this.props)
			
			//VerifyPredictives(this.props, )
		},
		componentWillUnmount() {
			_isMounted = false
		},
		async UNSAFE_componentWillUpdate(nextState) {
			if (nextState.match.params.id !== this.props.match.params.id) {
				onDidMount(this.props, nextState.match.params.id)
			}
		}
	})
)(DetailsView);
