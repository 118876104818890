import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { getCommitmentsByTeam, createCommitment, deleteCommitmentById, saveCommitmentTracking, getCommitmentReminder} from "services/apiAdmin/commitmentApi";
import { getWIGByTeamIdAndYear,getWIGByTeamIdAndYearONLY } from "services/apiAdmin/wigApi";
import { formatWIGName } from "utils/dataTypeFormatter";
import { commitmentCreateSchema, commitmentTrackingSchema } from "models/commitmentModel";
import { validateProperty, validateObject } from "utils/validator";
import { getAllUsers } from "services/apiUser/userApi";
import { getUsersByTeam } from "services/apiAdmin/teamApi";
import { getInputFormatDate,getFormatDate,  getInputFormatDateY} from "utils/functions"
import { translate} from "utils/translate";

import InternalView from "./InternalView"
//import { PhotoSizeSelectLargeSharp } from "@material-ui/icons";
import { getUserLocale } from "utils/translate"
import { currentWeekDates } from "utils/functions"
import moment from "moment";

const colorsArray = [
	"default",
	"orange",
	"green",
	"red",	
]

let _isMounted = false

const staticProps = ["wigId", "hasDependency", "internalDependency", "applyForAllMembers", "dependencyUser"]

const newCommitment = {
	wigId: "",
	title: "",
	action: "",
	impact: "",
	when: getInputFormatDate(new Date),
	hasDependency: false,
	internalDependency: false,
	dependencyUser: "",
	applyForAllMembers: false,
	assignedUser: ""
}

const commitmentState = {
	wigId: "",
	title: "",
	action: "",
	impact: "",
	when: "",
	hasDependency: "",
	internalDependency: "",
	dependencyUser: "",
	applyForAllMembers: "",
	assignedUser: ""
}

const commitmentChart = {
	colors: ['#FFA726','#4CAF50','#F44336'],
	series: [3,2,1],
	options:{
		colors: ['#FFA726','#4CAF50','#F44336'],
		chart: {
			width: 380,
			type: 'pie',
		},
		//labels: ["", "", ""],
		labels: localStorage.getItem("4dxLocale") ? localStorage.getItem("4dxLocale") === 'en-US' ? ["Working", "Completed", "Closed"] : ["Trabajando", "Completado", "Cerrado"] : ["Working", "Completed", "Closed"],
		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 200
					},
					legend: {
						position: 'bottom'
					}
				}
			}
		]
	}
}

const newTracking = {
	commentary: "",
	isDone: false,
	isClosed: false
}

const trackingState = {
	commentary: "",
	isDone: "",
	isClosed: ""
}

async function getCommitments(props, teamId) {
	if (teamId !== "") {
		let result = await getCommitmentsByTeam(teamId)
		if (_isMounted && result.success) {
			props.changeCommitmentList(result.data)
			getSchedule(result.data, props)
			let workingState = 0
			let closedState = 0
			let completedState = 0
			result.data.forEach(function(x, index){
				if(x.status === 1)
					workingState++
				if(x.status === 2)
					completedState++
				if(x.status === 3)
					closedState++
			})
			props.changeCommitmentChartSeries([workingState,completedState,closedState])
		}
	}
}

async function getCommitmentsReminder(props) {
	let today_date = getInputFormatDateY(new Date());
	let result = await getCommitmentReminder(today_date);
	if(_isMounted && result.success){
	}

}

async function getWIGList(props) {
	let idiom = getUserLocale();
	// if team Id is not defined
	if (!props.teamId) {
		return
	}

	let currentYear = new Date().getFullYear()
	let result = await getWIGByTeamIdAndYear(props.teamId, currentYear)
	if (result.success) {
		if (_isMounted) {

			// Set first result as selected value if found
			let wig
			for (wig of result.data) {
				if(wig.year >=2024){
					if (
						idiom === "es-ES" &&
						wig.endDate != null &&
						wig.startDate != null
					  ) {
						moment.locale('es')
						let mydateStart = moment(wig.startDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
						let mydateEndaDate = moment(wig.endDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
						wig.startDate = mydateStart;
						wig.endDate = mydateEndaDate;
					  } else if (
						idiom === "en-US" &&
						wig.endDate != null &&
						wig.startDate != null
					  ) {
						moment.locale('en')
						const fechaFormateadaStart = moment
						  .utc(wig.startDate)
						  .format("MMMM DD, YYYY");
						const fechaFormateadaEnd = moment
						  .utc(wig.endDate)
						  .format("MMMM DD, YYYY");
						  wig.startDate = fechaFormateadaStart;
						  wig.endDate = fechaFormateadaEnd;
				
				
					  }
				}
				wig.name = formatWIGName(wig, getUserLocale())
			}
			props.changeWIGList(result.data)

			if (result.data.length) {
				props.commitment.wigId = result.data[0].id
				props.changeCommitment(props.commitment)
			}
		}
	}
}

async function getUserList(props) {
	let result
	if (props.commitment.internalDependency) {
		result = await getUsersByTeam(props.teamId)
	} else {
		result = await getAllUsers()
	}
	if (_isMounted && result.success) {
		props.changeHelpersList(result.data)
	}
	props.changeloadingHelperList(false)
}

async function onSubmit(props) {
	let result = await createCommitment(props.commitment, props.teamId)
	// Show confirmation message
	if (result && result.success && _isMounted) {

		let property
		// Clear state
		for (property in props.commitment) {
			if (!staticProps.includes(property)) {
				props.commitment[property] = ""
			}
		}
		props.commitment.when = getInputFormatDate(new Date);
		props.setInitialDate(props.commitment.when);
		

		for (property in props.commitmentState) {
			props.commitmentState[property] = ""
		}
		props.changeCommitmentState(props.commitmentState)

		props.changeCreateSuccess(true)
		setTimeout(function () {
			props.changeCreateSuccess(false)
		}, 5000);
		
		props.changeCommitment(newCommitment)
		
		
		
		getCommitments(props, props.teamId)

	} else {
		// Show error message
		if (result) {
			let message = result.message
			if (!message) {
				message = "Algo ocurrió mal, inténtalo nuevamente."
			}
			props.changeValidationMessage(message)
			props.changeCreateError(true)
		}
	}
}

async function onTrackingSubmit(props) {
	let commitment = props.commitmentList.find(function (commitment) {
		return commitment.id === props.alertId
	})

	let result = await saveCommitmentTracking(props.tracking, props.teamId, commitment.wigId, props.alertId)
	// Save
	if (result && result.success && _isMounted) {
		getCommitments(props, props.teamId)
		props.changeTracking({ ...newTracking })
		props.changeTrackingState({ ...trackingState })
		props.changeAlertLoading(false)
		props.changeTrackingCommitmentAlertVisible(false)
		props.changeDeleteCommitmentAlertVisible(false)
	}
}

async function deleteCommitment(props, id) {
	props.changeAlertLoading(true)

	let result = await deleteCommitmentById(props.team.teamId, props.commitment.wigId, id)

	if (result.success) { //result.success
		getCommitments(props, props.teamId)
	}
	props.changeAlertLoading(false)
	props.changeDeleteCommitmentAlertVisible(false)
}

async function getSchedule(commitmentList, props) {
	//! Make the events List with the commitment data
	let commitmentDay = new Date()
	const today = new Date();
	const y = today.getFullYear()
	const m = today.getMonth() + 1
	const wd = today.getDay()
	
	const time = "09:00:00" //! change this with commitment
	let events = []
	commitmentList.forEach(function (commitment){
		
		commitmentDay = Date.parse(commitment.when)
		if (commitmentDay){
			let eventDate = new Date(commitmentDay)
			
			let pro =  getFormatDate(eventDate,getUserLocale());
		
			const currentWeekCommitment = currentWeekDates(eventDate)
			if (currentWeekCommitment){
				events.push(
					{
						commitmentId: commitment.id,
						title: commitment.title,
						allDay: false,
						start: eventDate,
						end:  new Date(eventDate.getTime() + 60*60000),
						color: colorsArray[commitment.status]
					}
				)
			}
		}

	})
	/*let events = commitmentList.map((commitment) =>{
		commitmentDay = Date.parse(commitment.when)
		if (commitmentDay){
			//console.log(commitmentDay)
			//console.log(today)
			console.log(currentWeekDates(new Date(commitmentDay)))
			//console.log(getWeekByDate(today))
		}
			
		let eventDate = addDays(today, 1 - wd) //! Change "1" with commitment.day value from db
		eventDate = new Date(`${y}-${m}-${String(eventDate.getDate()).padStart(2, '0')} ${time}`)
		return{
			commitmentId: commitment.id,
			title: commitment.title,
			allDay: false,
			start: eventDate,
			end:  new Date(eventDate.getTime() + 60*60000),
			color: colorsArray[commitment.status]
		}
	})
		const time2 = "15:00:00"
		let eventToday = addDays(today, 2 - wd) //! Change "1" with commitment.day value from db
		eventToday = new Date(`${y}-${m}-${String(eventToday.getDate()).padStart(2, '0')} ${time2}`)
		events.push(
			{
				commitmentId: 1,
				title: "Compromiso hoy",
				allDay: false,
				start: eventToday,
				end:  new Date(eventToday.getTime() + 60*60000),
				color: colorsArray[1]
			}
		)*/
	

	props.setEvents(events)

	/*
	if (result.success) {
		if (_isMounted) {
			const today = new Date();
			const y = today.getFullYear()
			const m = today.getMonth() + 1
			const wd = today.getDay()

			let events = result.data.map((team)=>{
				let eventDate = addDays(today, team.day - wd)
				eventDate = new Date(`${y}-${m}-${String(eventDate.getDate()).padStart(2, '0')} ${team.time}`)
				return{
					teamId: team.teamId,
					title: team.title,
					allDay: false,
					start: eventDate,
					end:  new Date(eventDate.getTime() + 30*60000),
					color: colorsArray[Math.floor(Math.random() * 6)]
				}
			})

			console.log(events);
			props.setEvents(events)
		}

		console.log("getSchedule")
		console.log(props.commitmentList)
	}
	*/
}


export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
			teamId: state.admin.teamId,
			membersList: state.admin.membersList,
			team: state.admin.team,
			roleId: state.admin.roleId
		}),
		{}
	),
	withState("commitment", "changeCommitment", { ...newCommitment }),
	withState("commitmentState", "changeCommitmentState", { ...commitmentState }),
	withState("commitmentChart", "changeCommitmentChart", { ...commitmentChart }),
	withState("commitmentChartSeries", "changeCommitmentChartSeries", [1,1,1]),
	withState("tracking", "changeTracking", { ...newTracking }),
	withState("trackingState", "changeTrackingState", { ...trackingState }),
	withState("commitmentList", "changeCommitmentList", []),
	withState("wigList", "changeWIGList", []),
	withState("helpersList", "changeHelpersList", []),
	withState("loadingHelperList", "changeloadingHelperList", false),
	withState("createSuccess", "changeCreateSuccess", false),
	withState("createError", "changeCreateError", false),
	withState("validationMessage", "changeValidationMessage", ""),
	withState("isLoadingSave", "changeIsLoadingSave", false),
	withState("trackingCommitmentAlertVisible", "changeTrackingCommitmentAlertVisible", false),
	withState("deleteCommitmentAlertVisible", "changeDeleteCommitmentAlertVisible", false),
	withState("alertLoading", "changeAlertLoading", false),
	withState("alertId", "changeAlertId", undefined),
	withState("events", "setEvents", []),
	withState("show", "setShow",false),
	withState("initialDate", "setInitialDate", getInputFormatDate(new Date())),
	withHandlers({
		setInitialDate: (props) => (value) => {
			props.commitment["when"] = value
			props.changeCommitment(props.commitment)
		},
		onFieldChange: (props) => (field, value) => {

			props.commitment[field] = value
			let isValid = validateProperty(commitmentCreateSchema, props.commitment, field).isValid
			if (isValid) {
				props.commitmentState[field] = "success"
			} else {
				props.commitmentState[field] = "error"
			}
			if(props.commitment.hasDependency === false) {
				const valueFormaat = {
					...props.commitment,
					dependencyUser: ""
				}
				props.changeCommitment(valueFormaat)
			} else {
				props.changeCommitment(props.commitment)
			}
			props.changeCommitmentState(props.commitmentState)
		},
		onTrackingFieldChange: (props) => (field, value) => {

			props.tracking[field] = value
			let isValid = validateProperty(commitmentTrackingSchema, props.tracking, field).isValid
			if (isValid) {
				props.trackingState[field] = "success"
			} else {
				props.trackingState[field] = "error"
			}
			props.changeTrackingState(props.trackingState)
			props.changeTracking(props.tracking)
		},
		onModalClosed: (props) => () => {
			props.changeTracking({ ...newTracking })
			props.changeTrackingState({ ...trackingState })
			props.changeAlertLoading(false)
			props.changeTrackingCommitmentAlertVisible(false)
			props.changeDeleteCommitmentAlertVisible(false)
		},
		onUserListGet: (props) => async () => {
			getUserList(props)
		},
		onCommitmentSave: (props) => async () => {
			
			props.changeIsLoadingSave(true)
			
			let validation = validateObject(commitmentCreateSchema, props.commitment)
			if (validation.isValid) {
		
				await onSubmit(props)
				props.setShow(true)
				window.location.href = '/admin/commitment/internal';
			} else {
				let field
				for (field in props.commitment) {
					
					if (validation.errors[field]) {
						props.commitmentState[field] = "error"
					}
				}
				props.changeCommitmentState(props.commitmentState)
			}
			props.changeIsLoadingSave(false)
			getSchedule(props.commitmentList, props)
		},
		onTrackingSave: (props) => async () => {
			props.changeAlertLoading(true)

			let validation = validateObject(commitmentTrackingSchema, props.tracking)
			if (validation.isValid) {
				await onTrackingSubmit(props)
			} else {
				let field
				for (field in props.tracking) {
					if (validation.errors[field]) {
						props.trackingState[field] = "error"
					}
				}
				props.changeTrackingState(props.trackingState)
			}
			props.changeAlertLoading(false)
		},
		onCommitmentDelete: (props) => async (id) => {
			deleteCommitment(props, id)
		},
		onTeamIdChange: (props) => async () => {
			getWIGList(props)
		},
		viewTracking: (props) => (commitmentId) => {
			// same as here: window.location.href = `/admin/accountabilityrecord/team/${event.teamId}`; to add new. 
			window.location.href = `/admin/commitment/tracking/${commitmentId}`;
		}
	}),
	lifecycle({
		async componentDidMount() {
		
			let commitmentChart2 = {
				colors: ['#FFA726','#4CAF50','#F44336'],
				series: [3,2,1],
				options:{
					colors: ['#FFA726','#4CAF50','#F44336'],
					chart: {
						width: 380,
						type: 'pie',
					},
					labels: [translate("const_commitment_status_1"), translate("const_commitment_status_2"),translate("const_commitment_status_3")],
					responsive: [
						{
							breakpoint: 480,
							options: {
								chart: {
									width: 200
								},
								legend: {
									position: 'bottom'
								}
							}
						}
					]
				}
			}
			_isMounted = true
			this.props.changeCommitment({ ...newCommitment })
			this.props.changeCommitmentState({ ...commitmentState })
			commitmentChart.options.labels = [translate("const_commitment_status_1"), translate("const_commitment_status_2"),translate("const_commitment_status_3")]
			this.props.changeCommitmentChart({ ...commitmentChart2 })
			await getCommitments(this.props, this.props.teamId)
			await getCommitmentsReminder(this.props)
			await getWIGList(this.props)			
		},
		componentWillUnmount() {
			_isMounted = false
			let commitmentChart2 = {
				colors: ['#FFA726','#4CAF50','#F44336'],
				series: [3,2,1],
				options:{
					colors: ['#FFA726','#4CAF50','#F44336'],
					chart: {
						width: 380,
						type: 'pie',
					},
					labels: [translate("const_commitment_status_1"), translate("const_commitment_status_2"),translate("const_commitment_status_3")],
					responsive: [
						{
							breakpoint: 480,
							options: {
								chart: {
									width: 200
								},
								legend: {
									position: 'bottom'
								}
							}
						}
					]
				}
			}
			this.props.changeCommitment({ ...newCommitment })
			this.props.changeCommitmentState({ ...commitmentState })
			commitmentChart.options.labels = [translate("const_commitment_status_1"), translate("const_commitment_status_2"),translate("const_commitment_status_3")]
			this.props.changeCommitmentChart({ ...commitmentChart2 })
		},
		async UNSAFE_componentWillUpdate(nextProps) {
			//If loading changes
			if (this.props.loadingHelperList !== nextProps.loadingHelperList && nextProps.loadingHelperList) {
				await getUserList(this.props)
			}

			// Check team changes
			if (nextProps.teamId !== this.props.teamId) {
				await getCommitments(this.props, nextProps.teamId)
			}
		}
	})
)(InternalView);