import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Heading from "components/Heading/Heading.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TrackingChart from "components/TrackingDashboard/TrackingChart.js";

//icons
import Search from "@material-ui/icons/Search";
import BarCharIcon from "@material-ui/icons/BarChart";

import { NavLink } from "react-router-dom";

//Color
import styles from "assets/jss/material-dashboard-pro-react/components/trackingDashboardStyle.js";

// Functions
import { translate } from "utils/translate";

const useStyles = makeStyles(styles);

export default function TrackingDashboard(props) {
  let colWidth = 12;

  // Details
  let graphWidthDetails = 12;
  let colWidthDetails = props.axes.length === 2 ? 2 : 2;
  const classes = useStyles();
  return (
    <GridContainer>
      {props.showDetails ? (
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <props.icon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                {props.type === "wig" ? translate("wig") : props.title}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={colWidthDetails}>
                  <h5 className={classes.cardName}>{props.name}</h5>
                  {props.description}
                  {props.type === "wig" ? ( //? Is a WIG graphic
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={props.showCumulativeGraph}
                            onChange={(event) =>
                              props.changeShowCumulativeGraph(
                                event.target.checked
                              )
                            }
                            value="showCumulative"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={translate("chart_show_cumulative")}
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={!props.showCumulativeGraph}
                            checked={
                              props.showCumulativeGraph &&
                              props.showAverageGraph
                            }
                            onChange={(event) =>
                              props.changeShowAverageGraph(event.target.checked)
                            }
                            value="showAverageGraph"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                        }}
                        label={translate("chart_show_average")}
                      />
                    </div>
                  ) : null}
                </GridItem>
                <br />
                <GridItem GridItem xs={12} sm={12} md={12} lg={10}>
                  {props.axes.map((prop, key) => {
                    return (
                      <GridContainer justify="flex-end">
                        {/* Monthly chart */}
                        <GridItem xs={12} sm={12} md={12} lg={graphWidthDetails} key={key}>
                          {
                            props.type === "predictive" ?
                              <Heading
                                textAlign="center"
                                title={translate("chart") + prop.displayName}
                              />
                              :
                              <Heading
                                textAlign="center"
                                title={translate("monthly_chart") + prop.displayName}
                              />
                          }
                          <TrackingChart data={prop.data} name={prop.displayName} dataTypeId={prop.dataTypeId} changeBarColor={props.changeBarColor}
                            type={props.type} consistency={props.consistency} axesNumber={props.axesNumber} detailsConsistency={key === 0 ? props.typePredictive ? props.typePredictive.consistency : null : props.typePredictive ? props.typePredictive.consistencyTwo : null}
                          />
                          <br />
                        </GridItem>

                        {/* Cumulative chart */}
                        {props.showCumulativeGraph ? (
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            lg={graphWidthDetails}
                            key={"C" + key}
                          >
                            <Heading
                              textAlign="center"
                              title={
                                translate("cumulative_chart") + prop.displayName
                              }
                            />
                            <GridContainer>
                              {props.showAverageGraph ? (
                                <TrackingChart
                                  data={prop.data}
                                  name={
                                    prop.displayName + translate("cumulative")
                                  }
                                  dataTypeId={prop.dataTypeId}
                                  changeBarColor={props.changeBarColor}
                                  type={props.type}
                                  graphType="average"
                                  wig={props.wig}
                                />
                              ) : (
                                <TrackingChart
                                  data={prop.data}
                                  name={
                                    prop.displayName + translate("cumulative")
                                  }
                                  dataTypeId={prop.dataTypeId}
                                  changeBarColor={props.changeBarColor}
                                  type={props.type}
                                  graphType="cumulative"
                                  wig={props.wig}
                                />
                              )}
                              <br />
                            </GridContainer>
                          </GridItem>
                        ) : null}
                      </GridContainer>
                    );
                  })}
                </GridItem>
              </GridContainer>
            </CardBody>
            {props.showDetailsBtn ? (
              <CardFooter>
                <NavLink to={props.detailsUrl}>
                  <Tooltip title={translate("wig_details")}>
                    <Button color="primary">
                      <Search />
                    </Button>
                  </Tooltip>
                </NavLink>
              </CardFooter>
            ) : null}
          </Card>
        </GridItem>
      ) : (
        props.axes.map((prop, key) => {
          return (
            <GridItem xs={12} sm={12} md={colWidth} key={key}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <BarCharIcon />
                  </CardIcon>
                  {translate("monthly_tracker")}
                </CardHeader>
                <CardBody>
                  <TrackingChart
                    data={prop.data}
                    name={prop.displayName}
                    dataTypeId={prop.dataTypeId}
                  />
                </CardBody>
              </Card>
            </GridItem>
          );
        })
      )}
    </GridContainer>
  );
}
