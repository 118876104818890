/*eslint-disable*/
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CircularProgress, Select, MenuItem } from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import { DataTypes } from "components/Constants";
import {
  formatValue,
  formatPredictiveName,
  languageDays,
} from "utils/dataTypeFormatter.js";
import { getWeeksNumberByYear, getDaysOfTheWeek } from "utils/functions";

// material ui icons
import List from "@material-ui/icons/List";
import TrackChanges from "@material-ui/icons/TrackChanges";
import AddAlert from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";
import TrendingUp from "@material-ui/icons/TrendingUp";
import Flag from "@material-ui/icons/Flag";
import DashboardIcon from "@material-ui/icons/Dashboard";

//dependy react-datePicker
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import eu from "date-fns/locale/en-US";
import "react-datepicker/dist/react-datepicker.css";

// core components
import Table from "components/Table/Table.js";
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputMask from "components/CustomInputMask/CustomInputMask";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import NavPills from "components/NavPills/NavPills.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import Warning from "@material-ui/icons/Warning";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

// Functions
import { translate } from "utils/translate";

import { formatDate } from "utils/functions";
import { getUserLocale } from "utils/translate";
import moment from "moment";
const useStyles = makeStyles(styles);

export default function trackingView(props) {
  let idiomDefault = getUserLocale()
  const [selectFecha, setSelectFecha] = useState(new Date());
  const [activeTab, setActiveTab] = useState(0); // Esto establece el primer tab como activo por defecto

  // Función para cambiar de tab
  const handleChangeTab = (index) => {
    setActiveTab(index);
  };

  const classes = useStyles();
  let weeks = props.weeks;

  function getAxesTrackingForm(index) {
    if (
      props.predictive === undefined ||
      props.predictive[`x${index}`] === null ||
      props.predictive[`x${index}`] === undefined
    ) {
      return null;
    }
    //  let smSize = props.predictive.axesNumber === 2 ? 6 : 12;
    let consistency = "";
    {
      index === 1
      ? (consistency = props.predictive.consistency)
      : (consistency = props.predictive.consistencyTwo)
    }

    // fechas que se han de bloquear en el calendario, que esten fuera de los rango de la mp
    const startDate = new Date(props.predictive.StartDate);
    startDate.setDate(startDate.getDate() + 1);
    const endDate = new Date(props.predictive.EndDate);
    endDate.setDate(endDate.getDate() + 1);

    return (
      <GridItem xs={12} sm={12} md={12}>
        <FormLabel component="legend">
          {props.predictive[`displayName${index}`]}
        </FormLabel>
        {consistency === "daily" || consistency === "diario" ? (
          <GridItem xs={10} sm={12} md={6}>
            <div>{translate("predictiveDays") + "*"}</div>
            <DatePicker
              className="pickers"
              selected={selectFecha}
              onChange={(date) => props.onFieldChange(`day${index}`, date)}
              value={props.tracking[`day${index}`]}
              minDate={startDate}
              maxDate={endDate}
              dateFormat={
                translate("commitment_for_when_input") != "When?"
                  ? "dd MMMM, yyyy"
                  : "MMMM d, yyyy"
              }
              locale={
                translate("commitment_for_when_input") != "When?" ? es : eu
              }
            />
          </GridItem>
        ) : <div>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
            {translate("week") + "*"}
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            value={`${translate("week")} ${props.tracking[`week${index}`]}`}
            onChange={(e) => props.onFieldChange(`week${index}`, e)}
            inputProps={{
              name: "selectWeek",
              id: "selectWeek",
            }}
          >
            { }
            {weeks?.map((value, index) => (
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                style={{
                  overflowX: "auto",
                  textOverflow: "ellipsis",
                }}
                value={value}
                key={index}
              >
                {value}
              </MenuItem>
            ))}
          </Select>
        </div>}
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInputMask
              labelText={translate("const_y")}
              id={`inputY${index}`}
              formControlProps={{
                fullWidth: true,
              }}
              suffix={DataTypes[props.predictive[`dataTypeId${index}`]].suffix}
              decimalScale={
                DataTypes[props.predictive[`dataTypeId${index}`]].decimalScale
              }
              inputProps={{
                type: "text",
                value: props.predictive[`y${index}`],
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInputMask
              success={props.trackingState[`goalAchived${index}`] === "success"}
              error={props.trackingState[`goalAchived${index}`] === "error"}
              labelText={translate("const_goalachieved") + " *"}
              id={`goalAchived${index}`}
              formControlProps={{
                fullWidth: true
              }}
              suffix={DataTypes[props.predictive[`dataTypeId${index}`]].suffix}
              decimalScale={
                DataTypes[props.predictive[`dataTypeId${index}`]].decimalScale
              }
              inputProps={{
                onChange: (event) => {
                  event.target.value =
                    event.target.value === ""
                      ? ""
                      : parseFloat(event.target.value);
                  props.onFieldChange(`goalAchived${index}`, event);
                },
                type: "text",
                value: props.tracking[`goalAchived${index}`],
                endAdornment:
                  props.trackingState[`goalAchived${index}`] === "error" ? (
                    <InputAdornment position="end">
                      <Close className={classes.danger} />
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              success={props.trackingState[`commentary${index}`] === "success"}
              error={props.trackingState[`commentary${index}`] === "error"}
              labelText={translate("const_commentary")}
              id={`inputCommentary${index}`}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => {
                  props.onFieldChange(`commentary${index}`, event);
                },
                type: "text",
                value: props.tracking[`commentary${index}`],
                endAdornment:
                  props.trackingState[`commentary${index}`] === "error" ? (
                    <InputAdornment position="end">
                      <Close className={classes.danger} />
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
              }}
            />
          </GridItem>
        </GridContainer>
        <div style={{ justifyContent: "center", display: "flex", }} className={classes.saveCenter} onClick={() => props.onTrackingSave(index)}>
          <Button color="primary">
            {translate("button_save")}
          </Button>
        </div>
      </GridItem>
    );
  }

  function getTableForAxis(index) {
    if (
      props.predictive === undefined ||
      props.predictive[`x${index}`] === null
    ) {
      return null;
    }
    return (
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <List />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{`${props.predictive[`displayName${index}`]
              } - Rastreos`}</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={[
                translate("table_header_date"),
                translate("table_header_week"),
                translate("predictiveDays"),
                translate("const_y"),
                translate("const_goalachieved"),
                translate("const_difference"),
                translate("const_commentaries"),
              ]}
              tableData={props[`trackingList${index}`].result.map(
                (item, key) => {
                  let day;
                  if (idiomDefault === "es-ES" && item.day) {
                    moment.locale('es')
                    let mydateStart = moment(item.day, 'YYYY-MM-DD').format('DD MMMM, YYYY');
                    day = mydateStart
                  } else if (item.day) {
                    moment.locale('en')
                    const fechaFormateadaStart = moment.utc(item.day, 'YYYY-MM-DD').format("MMMM DD, YYYY");
                    day = fechaFormateadaStart
                  }
                  return [
                    formatDate(item.createdAt, getUserLocale()),
                    item.period,
                    day,
                    formatValue(item.y, item.dataTypeId),
                    formatValue(item.goalAchived, item.dataTypeId),
                    formatValue(item.difference, item.dataTypeId),
                    item.commentary,
                  ];
                }
              )}
              customCellClasses={[
                classes.center,
                classes.right,
                classes.right,
                classes.center,
                classes.right,
              ]}
              customClassesForCells={[0, 2, 3, 4, 6]}
              customHeadCellClasses={[
                classes.center,
                classes.right,
                classes.right,
                classes.center,
                classes.right,
              ]}
              customHeadClassesForCells={[0, 2, 3, 4, 6]}
            />
          </CardBody>
        </Card>
      </GridItem>
    );
  }

  let breadcrumbViews = [
    {
      name: translate("routes_home_label"),
      url: "/admin/dashboard",
      icon: DashboardIcon,
    },
    {
      name: translate("wig"),
      url: props.predictive
        ? `/admin/wig/details/${props.predictive.wigId}`
        : "",
      icon: Flag,
    },
    {
      name: translate("routes_predictive_track_label"),
      url: `/admin/predictive/tracking/${props.predictiveId}`,
      icon: TrackChanges,
    },
  ];

  const displayNameAxi = props.predictive && props.predictive.displayName1 ? props.predictive.displayName1 : 'No displayName1 found';
  const displayNameAxi2 = props.predictive && props.predictive.displayName2 ? props.predictive.displayName2 : translate("leve2_axis1");


  return (
    <>
      <Breadcrumbs views={breadcrumbViews} />
      <Heading
        textAlign="center"
        title={props.predictive ? formatPredictiveName(props.predictive) : ""}
        category={<span>{translate("routes_predictive_track_label")}</span>}
      />
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>


          <NavPills
            active={activeTab}
            color="primary"
            tabs={ props.predictive && props.predictive.displayName2 ?
              [
                {
                  tabButton: displayNameAxi,
                  tabContent: (
                    <>
                      <Card>
                        <CardBody>
                          <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                              <TrackChanges />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                              {translate("const_tracker")}
                            </h4>
                          </CardHeader>
                          {getAxesTrackingForm(1)}
                        </CardBody>
                      </Card>
                      <div style={{ marginTop: '50px' }}>
                        {getTableForAxis(1)}
                      </div>
  
                    </>
                  )
                },
                {
                  tabButton: displayNameAxi2,
                  tabContent: (
                    <>
                      <Card>
                        <CardBody>
                          <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                              <TrackChanges />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                              {translate("const_tracker")}
                            </h4>
                          </CardHeader>
                          {getAxesTrackingForm(2)}
                        </CardBody>
                      </Card>
  
                      <div style={{ marginTop: '50px' }}>
                        {getTableForAxis(2)}
                      </div>
  
                    </>
                  )
                }
              ] : [
                {
                  tabButton: displayNameAxi,
                  tabContent: (
                    <>
                      <Card>
                        <CardBody>
                          <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                              <TrackChanges />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                              {translate("const_tracker")}
                            </h4>
                          </CardHeader>
                          {getAxesTrackingForm(1)}
                        </CardBody>
                      </Card>
                      <div style={{ marginTop: '50px' }}>
                        {getTableForAxis(1)}
                      </div>
  
                    </>
                  )
                }
              ] }
            onChangeIndex={handleChangeTab}
          />
          <Snackbar
            place="br"
            color="success"
            icon={AddAlert}
            message={translate("alert_tracking_success")}
            open={props.createSuccess}
            closeNotification={() => props.changeCreateSuccess(false)}
            close
          />
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.createError}
            closeNotification={() => props.changeCreateError(false)}
            close
          />
          <Snackbar
            place="bl"
            color="warning"
            icon={Warning}
            message={translate("year_later_warning_Tracking")}
            open={props.currentYear}
            closeNotification={() => props.setCurrentYear(false)}
            close
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
