/*eslint-disable*/
import React from "react";

// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer, Views } from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
//import globalize from 'globalize';

// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
    CircularProgress,
    Select,
    MenuItem
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// material ui icons
import AddAlert from "@material-ui/icons/AddAlert";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ScheduleIcon from '@material-ui/icons/Schedule';
import Add from "@material-ui/icons/Add";
import Launch from "@material-ui/icons/Launch";
import GradingIcon from '@material-ui/icons/AssignmentTurnedIn';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import { Days } from "components/Constants";
// table components
import Table from "components/Table/Table.js";
// Routes
 import { Link } from "react-router-dom"

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/Accountability/indexStyle.js";

// Functions
import { verifyPermission } from "utils/functions";
import { translate } from "utils/translate"
import { setTeamId } from "store/AdminState";

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(styles);


function weekDayHeader(props) {
    return (<div style={{ textAlign: "center" }}>{Days[`${props.date.getDay()}`]}</div>)
}

const CustomToolbar = (toolbar) => {
    const goToBack = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
        toolbar.onNavigate('prev');
    };

    const goToNext = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
        toolbar.onNavigate('next');
    };

    const goToCurrent = () => {
        const now = new Date();
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        toolbar.onNavigate('current');
    };

    const label = () => {
        const date = moment(toolbar.date);
        return (
            <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
        );
    };
    const sCalendar = {}
    return (
        <div className={sCalendar['toolbar-container']}>
            <label className={sCalendar['label-date']}>{label()}</label>

            <div className={sCalendar['back-next-buttons']}>
                <button className={sCalendar['btn-back']} onClick={goToBack}>&#8249;</button>
                <button className={sCalendar['btn-current']} onClick={goToCurrent}>today</button>
                <button className={sCalendar['btn-next']} onClick={goToNext}>&#8250;</button>
            </div>
        </div >
    );
};

export default function createView(props) {
    const classes = useStyles();

    let breadcrumbViews = [
        {
            name: translate("routes_home_label"),
            url: "/admin/dashboard",
            icon: DashboardIcon
        },
        {
            name: translate("routes_wig_session_minute"),
            url: `/admin/accountabilityminutes/team/${props.teamId}`,
            icon: ScheduleIcon
        }
    ]

    const eventColors = event => {
        var backgroundColor = "event-";
        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");
        return {
            className: backgroundColor
        };
    };

    let currDate = new Date();
    if (props.team.time) {
        currDate = new Date(currDate.setHours((props.team.time).substring(0, 2)));
        currDate = new Date(currDate.setMinutes((props.team.time).substring(3, 5)));
    }


    // Accountability Records Rows construction
    let recordsRows = [];
    if (props.minuteslist && props.minuteslist.length > 0) {
        props.minuteslist.map((minute, index) => (
            recordsRows.push(
                [
                    <p
                        style={{ textAlign: "center" }}
                    >{`${index + 1}`}</p>,
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                            <p
                                style={{ textAlign: "center" }}
                            >
                                {`${minute.minuteDate.substring(0, 16)}hrs`}
                            </p>
                        </GridItem>
                    </GridContainer>
                    ,
                    <GridContainer
                        direction="row"
                        justify="center"
                        alignItems="center">
                        <GridItem>
                            <Button color="primary" onClick={() => props.viewSingleMinute(props.teamId,minute.id)} key={index}>
                                <Launch className={classes.icon} />
                            </Button>
                        </GridItem>
                    </GridContainer>

                ]
            )

        ));

    }

    return (
        <>
            <Breadcrumbs views={breadcrumbViews} />
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <FormatListNumberedIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{translate("routes_wig_sessions_label_minutes")}</h4>
                        </CardHeader>
                        <CardBody>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <GridContainer justify="center">
                                    
                                    {((props.roleId <= 2) || (props.roleId == 3 && props.dataRoleId == 1) || (props.roleId == 3 && props.dataRoleId == 2)) ?
                                        <GridItem xs={12} sm={12} md={12}>
                                          
                                            <GridContainer justify="center" alignItems="center">
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <h3>{translate("accountability_minute_create_title")}</h3>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                             
                                                <Button color="primary" onClick={() => props.minute()}>
                                                            <Add className={classes.icon} />
                                                        </Button>
                                                
                                                </GridItem>
                                            </GridContainer>
                                            <br />
                                        </GridItem>
                                        :
                                        null
                                    }
                                    <GridItem myt={5} xs={12} sm={12} md={12}>
                                        <h4>{translate("accountability_minute_list_title")}</h4>
                                        <br />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={12} md={10} lg={8} xl={7}>
                                        <Table
                                            striped
                                            tableHead={[
                                                <p style={{ fontWeight: 500 }}>No.</p>,
                                                <GridItem xs={12} sm={12} md={12} style={{ fontWeight: 500 }}>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <p
                                                                style={{ textAlign: "center" }}
                                                            >{translate("accountability_record_list_date")}</p>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>,
                                                <p style={{ fontWeight: 500, textAlign: "center" }}>{translate("accountability_record_list_sessions")}</p>
                                            ]}
                                            tableData={
                                                recordsRows
                                            }
                                            style={{ width: "100%" }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </CardBody>
                    </Card>
                    <Snackbar
                        place="br"
                        color="success"
                        icon={AddAlert}
                        message={translate("alert_wig_session_saved_success")}
                        open={props.createSuccess}
                        closeNotification={() => props.setCreateSuccess(false)}
                        close
                    />
                    <Snackbar
                        place="bl"
                        color="danger"
                        icon={AddAlert}
                        message={props.validationMessage}
                        open={props.createError}
                        closeNotification={() => props.setCreateError(false)}
                        close
                    />
                </GridItem>
            </GridContainer>
        </>
    );
}
