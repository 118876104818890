import { compose, withState, lifecycle,withHandlers } from "recompose";
import { connect } from "react-redux";
import { getExternalCommitments } from "services/apiAdmin/commitmentApi";
import { getCommitmentsByDate } from "services/apiAdmin/commitmentApi";
import {getAccountabilityRecordByTeam,saveMinuteTeam,getLastMinuteByTeam } from "services/apiAdmin/accountabilityApi";
import {  getFormatDateY,getInputFormatDate } from "utils/functions";
import MinuteView from "./MinuteView";
import { validateProperty, validateObject } from "utils/validator";
import { teamAccountabilitySchema } from "models/teamModel";
import { getTeamById, GetTeamsForParent,getUsersByTeam} from "services/apiAdmin/teamApi";
import { getMinuteListByTeam} from "services/apiAdmin/accountabilityApi";
import { getUserLocale } from "utils/translate";

let _isMounted = false
let teams_ = [];

let clearpaths = [];
let commits= [];
const newTeam = {
	title: "",
	parentTeamId: "",
	day: "",
	time: "7:00:00"
  }

const newRecord={
	id: "",
	recordDate: ""
}

const newTeamState = {
	title: "",
	parentTeamId: "",
	day: "",
	time: ""
  }
async function getTeam(props, teamId) {
	let result = await getTeamById(teamId)
  
	if (result.success) {
	  if (_isMounted) {
		props.setTeam(result.data)
	  }
  
	}
  }

async function getCommitmentsDate(props,teamId, recordDate) {
	let result = await getCommitmentsByDate(teamId, recordDate)
	if (result.success) {
		for(let i =0; i< result.data.length; i ++){
			if(result.data[i].hasDependency == 1){
				clearpaths.push(result.data[i]);
			}
			commits.push(result.data[i]);
		}


	}
}

let recordDate;
let recordId;
let recordTime;
let recordTime_hours;
let recordDatestring;
let server_date;
async function getRecord(props,teamId) {

	let result = await getAccountabilityRecordByTeam(teamId)
	
	if (result.success) {
		recordDatestring= result.data[0].recordDate;
		recordTime_hours = result.data[0].recordTime;
		recordDatestring= recordDatestring.slice(0,10) + " "+ recordTime_hours;
		server_date= recordDatestring;
		recordDate = new Date(result.data[0].recordDate);
		recordDatestring = new Date(recordDatestring);
		recordTime = getFormatDateY(recordDate, recordDatestring, getUserLocale());
		recordDate = recordTime;
		result.data[0].recordDate = recordDate;
		recordId = result.data[0].id;
		props.setRecord(result.data[0]);
	

		
		for(let i =0; i < teams_.length; i++){
			await getCommitmentsDate(props,teams_[i],server_date);
			
		}
	props.setClearPathList(clearpaths);
	props.setCommitmentList(commits)
	}
}

let minuteDate;
let minuteDatestring;
let minuteTime_hours;
let minuteTime;
let minuteId;

async function getMinute(props,teamId) {
	let result = await getLastMinuteByTeam(teamId)
	if (result.success) {
		console.log(result)
		minuteDatestring= result.data[0].minuteDate;
		minuteTime_hours = result.data[0].minuteTime;
		minuteDatestring= minuteDatestring.slice(0,10) + " "+ minuteTime_hours;
		server_date= minuteDatestring;
		minuteDate = new Date(result.data[0].minuteDate);
		minuteDatestring = new Date(minuteDatestring);
		minuteTime = getFormatDateY(minuteDate, minuteDatestring, getUserLocale());
		minuteDate = minuteTime;
		result.data[0].minuteDate = minuteDate;
		minuteId = result.data[0].id;
		console.log(server_date)
		
		for(let i =0; i < teams_.length; i++){
			await getCommitmentsDate(props,teams_[i],server_date);
			
		}
	props.setClearPathList(clearpaths);
	props.setCommitmentList(commits)
	
	}
}

async function getMinutes(props,teamId){

	let result = await getMinuteListByTeam(teamId);
	if(result.success){
		if(result.data.length>0){
			await getMinute(props,teamId);
		}else{
			await getRecord(props,teamId);
		}
	}
}
async function onMinuteSubmit(props, recordId) {
	let data = {};
	let commitmentsid =[];
	for(let i=0; i < props.commitmentList.length; i++){
		commitmentsid[i] = props.commitmentList[i].id;
	}
	data['commitments'] = commitmentsid;
	data['clearpaths'] = commitmentsid;

	await saveMinuteTeam(data, props.teamId);
	window.location.href = `/admin/accountabilityminutes/team/${props.teamId}`;
	//window.location.href = `/admin/accountability`;
	// console.log(result);
  
  }


async function GetTeamsFParent(props){
	let result= await GetTeamsForParent(props.teamId);
	if(result.success){
	
		for(let i =0; i < result.data[0].length; i++){
			//GET LEADER
			let result_l = await getUsersByTeam(result.data[0][i].Id)
			if(result_l.success){
				for(let y=0; y < result_l.data.length;y++){
					if(result_l.data[y].dataRoleId==1){
						let data = {
							teamId : result.data[0][i].Id,
							userId: result_l.data[y].userId
						}
						teams_.push(result.data[0][i].Id)
					}
				}
			}
			
		}
		await getMinutes(props,props.teamId);
		
	
	}
}

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
			teamId: state.admin.teamId
		}),
		{  }
	),

	withState("team", "setTeam", { ...newTeam }),
	withState("commitmentList", "setCommitmentList", []),
	withState("clearpathList", "setClearPathList", []),
	withState("commitmentIdList", "setCommitmentIdList", []),
	withState("record", "setRecord",{...newRecord }),
	withState("teamState", "setTeamState", { ...newTeamState }),
	withState("recordId","setrecordId",""),
	withState("recorDate","setrecordDate",""),
	withState("teams","setTeams",[]),

	withHandlers({
		onMinuteSave: (props) => async () => {
			
			let validation = validateObject(teamAccountabilitySchema, props.team)
			if (validation.isValid) {
				await onMinuteSubmit(props)
			} else {
				let field
				for (field in props.team) {
				  if (validation.errors[field]) {
					props.teamState[field] = "error"
				  }

			}
			
		
		}
		}
	}),

	lifecycle({
		async componentDidMount() {
			_isMounted = true
			//getCommitments(this.props)
			await getTeam(this.props, this.props.teamId);
		
			await GetTeamsFParent(this.props);
			
			
		
		}
	})
)(MinuteView);
