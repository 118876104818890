import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { wigGoalsSchema } from "models/wigModel";
import { getWIGGoalsById, saveWIGGoals } from "services/apiAdmin/wigApi";
import { Months } from "components/Constants";

import GoalsView from "./GoalsView";


const wigGoalsState = {

	// axis 1
	enero1: "",
	enero1_level1: "",
	enero1_level2: "",
	enero1_level3: "",

	febrero1: "",
	febrero1_level1: "",
	febrero1_level2: "",
	febrero1_level3: "",

	marzo1: "",
	marzo1_level1: "",
	marzo1_level2: "",
	marzo1_level3: "",

	abril1: "",
	abril1_level1: "",
	abril1_level2: "",
	abril1_level3: "",

	mayo1: "",
	mayo1_level1: "",
	mayo1_level2: "",
	mayo1_level3: "",

	junio1: "",
	junio1_level1: "",
	junio1_level2: "",
	junio1_level3: "",

	julio1: "",
	julio1_level1: "",
	julio1_level2: "",
	julio1_level3: "",

	agosto1: "",
	agosto1_level1: "",
	agosto1_level2: "",
	agosto1_level3: "",

	septiembre1: "",
	septiembre1_level1: "",
	septiembre1_level2: "",
	septiembre1_level3: "",

	octubre1: "",
	octubre1_level1: "",
	octubre1_level2: "",
	octubre1_level3: "",

	noviembre1: "",
	noviembre1_level1: "",
	noviembre1_level2: "",
	noviembre1_level3: "",

	diciembre1: "",
	diciembre1_level1: "",
	diciembre1_level2: "",
	diciembre1_level3: "",

	// axis 2
	enero2: "",
	enero2_level1: "",
	enero2_level2: "",
	enero2_level3: "",

	febrero2: "",
	febrero2_level1: "",
	febrero2_level2: "",
	febrero2_level3: "",

	marzo2: "",
	marzo2_level1: "",
	marzo2_level2: "",
	marzo2_level3: "",

	abril2: "",
	abril2_level1: "",
	abril2_level2: "",
	abril2_level3: "",

	mayo2: "",
	mayo2_level1: "",
	mayo2_level2: "",
	mayo2_level3: "",

	junio2: "",
	junio2_level1: "",
	junio2_level2: "",
	junio2_level3: "",

	julio2: "",
	julio2_level1: "",
	julio2_level2: "",
	julio2_level3: "",

	agosto2: "",
	agosto2_level1: "",
	agosto2_level2: "",
	agosto2_level3: "",

	septiembre2: "",
	septiembre2_level1: "",
	septiembre2_level2: "",
	septiembre2_level3: "",

	octubre2: "",
	octubre2_level1: "",
	octubre2_level2: "",
	octubre2_level3: "",

	noviembre2: "",
	noviembre2_level1: "",
	noviembre2_level2: "",
	noviembre2_level3: "",

	diciembre2: "",
	diciembre2_level1: "",
	diciembre2_level2: "",
	diciembre2_level3: "",

}

let _isMounted = false


async function onGoalsSubmit(props) {

	let data = {
	}
	for (let index = 1; index <= props.wigGoals.axesNumber; index++) {
		const goals = []
		const goals_3 = []
		const goals_2 = []
		const goals_1 = []

		for (let auxIdx = 1; auxIdx <= 12; auxIdx++) {
			goals.push(props.wigGoals[`${Months[auxIdx].toLowerCase()}${index}`]);
			goals_3.push(props.wigGoals[`${Months[auxIdx].toLowerCase()}${index}_level3`]);
			goals_2.push(props.wigGoals[`${Months[auxIdx].toLowerCase()}${index}_level2`]);
			goals_1.push(props.wigGoals[`${Months[auxIdx].toLowerCase()}${index}_level1`]);
		}
		data[`goals${index}`] = goals;
		data[`goals${index}_level3`] = goals_3;
		data[`goals${index}_level2`] = goals_2;
		data[`goals${index}_level1`] = goals_1;
	}

	let result = await saveWIGGoals(data, props.wigId)

	props.onGoalsSave(result)
}


async function getWIGGoals(props, wigId) {
	if (!wigId) {
		return
	}
	let result = await getWIGGoalsById(wigId)
	if (result.success) {
		if (_isMounted) {
			let final = {
				axesNumber: result.data.axesNumber
			}

			let finalData = {
				axesNumber: result.data.axesNumber
			}

			let data = result.data
			for (let index = 1; index <= data.axesNumber; index++) {
				const goals = data[`goals${index}`]
				let period
				for (period of goals) {
					final[`${Months[period.period].toLowerCase()}${index}`] = period.goal
					finalData[`${Months[period.period].toLowerCase()}${index}`] = period
				}

			}
			props.changeWIGGoalsData(finalData, function () {
				props.changeWIGGoals(final)
			})
		}

	}
}


export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{}
	),
	withState("wigGoalsState", "changeWIGGoalsState", { ...wigGoalsState }),
	withState("wigGoals", "changeWIGGoals", null),
	withState("wigGoalsData", "changeWIGGoalsData", null),
	withState("isLoadingSave", "changeIsLoadingSave", false),
	withHandlers({
		onFieldChange: (props) => (field, value) => {

			props.wigGoals[field] = value
			let isValid = validateProperty(wigGoalsSchema, props.wigGoals, field).isValid
			if (value.length < 1) {
				isValid = false;
			}
			if (isValid) {
				props.wigGoalsState[field] = "success"
			} else {
				props.wigGoalsState[field] = "error"
			}
			props.changeWIGGoalsState(props.wigGoalsState)
			props.changeWIGGoals(props.wigGoals)
		},
		onWIGGoalsSave: (props) => async () => {
			props.changeIsLoadingSave(true)

			// end of assigning default values:

			let validation = validateObject(wigGoalsSchema, props.wigGoals)
			if (validation.isValid) {
				//assigning default values:
				Object.keys(props.wigGoalsData).forEach((key) => ( // eslint-disable-next-line
					props.wigGoals[key] === undefined ? props.wigGoals[key] = props.wigGoalsData[key].level4 : null,
					props.wigGoals[`${key}_level3`] === undefined ? props.wigGoals[`${key}_level3`] = props.wigGoalsData[key].level3 : null,
					props.wigGoals[`${key}_level2`] === undefined ? props.wigGoals[`${key}_level2`] = props.wigGoalsData[key].level2 : null,
					props.wigGoals[`${key}_level1`] === undefined ? props.wigGoals[`${key}_level1`] = props.wigGoalsData[key].level1 : null
				));
				await onGoalsSubmit(props)
			} else {
				let field
				for (field in props.wigGoals) {
					if (validation.errors[field]) {
						props.wigGoalsState[field] = "error"
					}
				}
				props.changeWIGGoalsState(props.wigGoalsState)
			}
			props.changeIsLoadingSave(false)
		}
	}),
	lifecycle({
		componentDidMount() {
			_isMounted = true

			getWIGGoals(this.props, this.props.wigId)

			this.props.changeWIGGoalsState({ ...wigGoalsState })
		},
		componentWillUnmount() {
			_isMounted = false
		},
		UNSAFE_componentWillUpdate(nextProps) {
			//If wig id changes
			if (this.props.wigId !== nextProps.wigId) {
				getWIGGoals(this.props, nextProps.wigId)
			}
		}
	})
)(GoalsView);
