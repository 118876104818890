import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateObject, validateProperty } from "utils/validator";
import { predictiveCreateSchema } from "models/predictiveModel";
import {
  createPredictive,
  getPredictiveByWIGIdAndUser,
} from "services/apiAdmin/predictiveApi";
import {
  getWIGById,
  getWIGByTeamIdAndYear,
  getWIGByTeamAndParentIdAndYear,
} from "services/apiAdmin/wigApi";
import { formatWIGName } from "utils/dataTypeFormatter";
import { getUserLocale } from "utils/translate";
import CreateView from "./CreateView";
import { translate } from "utils/translate";
import moment from "moment";
let wigId = 0;

let newPredictive = {
  wigId: "-1",
  verb: "",
  what: "gen",
  focus: "",
  quality: "",
  consistency: "",
  verbTwo: "",
  focusTwo: "",
  qualityTwo: "",
  consistencyTwo: "",
  description: "",
  axesNumber: 1,
  displayName1: "",
  dataTypeId1: 1,
  dir1: 1,
  x1: "",
  y1: "",
  dataTypeId2: 1,
  dir2: 1,
  x2: "",
  y2: "",
  teamId: -1,
};

const newPredictiveState = {
  wigId: "",
  verb: "",
  what: "gen",
  focus: "",
  quality: "",
  consistency: "",
  verbTwo: "",
  focusTwo: "",
  qualityTwo: "",
  consistencyTwo: "",
  description: "",
  axesNumber: "",
  displayName1: "",
  dataTypeId1: "",
  dir1: "",
  x1: "",
  y1: "",
  dataTypeId2: "",
  dir2: "",
  x2: "",
  y2: "",
  teamId: -1,
};

let _isMounted = false;

async function getWIGList(props) {
  let currentYear = new Date().getFullYear();
  let result = await getWIGByTeamAndParentIdAndYear(
    props.team.teamId,
    currentYear
  );

  if (result.success) {
    props.changeWIGList(result.data);
  }
}

async function getWIG(props) {
  let currentYear = new Date().getFullYear();
  let result = await getWIGById(wigId, 0);
  let idiom = getUserLocale();
  if (result.success) {
    if (_isMounted) {
      // Set first result as selected value if found
      if (
        idiom === "es-ES" &&
        result.data.endDate != null &&
        result.data.startDate != null
      ) {
        moment.locale('es')
        let mydateStart = moment(result.data.startDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
        let mydateEndaDate = moment(result.data.endDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
        if (result.data.x2 != null) {
          let nuevoObject = {
            ...result.data,
            startDate: mydateStart,
            endDate: mydateEndaDate,
          };
          nuevoObject.name = formatWIGName(nuevoObject, getUserLocale());
          props.changeWIG(nuevoObject);
        } else {
          let nuevoObject = {
            ...result.data,
            startDate: mydateStart,
            endDate: mydateEndaDate,
            dataTypeId2: 1
          };
          nuevoObject.name = formatWIGName(nuevoObject, getUserLocale());
          props.changeWIG(nuevoObject);
        }

      } else if (
        idiom === "en-US" &&
        result.data.endDate != null &&
        result.data.startDate != null
      ) {
        moment.locale('en')
        const fechaFormateadaStart = moment
          .utc(result.data.startDate)
          .format("MMMM DD, YYYY");
        const fechaFormateadaEnd = moment
          .utc(result.data.endDate)
          .format("MMMM DD, YYYY");
        if (result.data.x2 != null) {
          let nuevoObject = {
            ...result.data,
            startDate: fechaFormateadaStart,
            endDate: fechaFormateadaEnd,
          };
          nuevoObject.name = formatWIGName(nuevoObject, getUserLocale());
          props.changeWIG(nuevoObject);
        } else {
          let nuevoObject = {
            ...result.data,
            startDate: fechaFormateadaStart,
            endDate: fechaFormateadaEnd,
            dataTypeId2: 1
          };
          nuevoObject.name = formatWIGName(nuevoObject, getUserLocale());
          props.changeWIG(nuevoObject);
        }


      } else {
        let nuevoObject = {
          ...result.data,
          endDate: new Date(),
          startDate: new Date(),
          verbTwo: "",
          whatTwo: "",
        }
        nuevoObject.name = formatWIGName(nuevoObject, getUserLocale());
        props.changeWIG(nuevoObject);
      }

      if (result.data.year != currentYear) {
        props.setIsOtherYear(true);
      }
    }
  }
}

async function onSubmit(props) {
  let val = true;

  let resultwig = await getPredictiveByWIGIdAndUser(props.predictive.wigId);
  if (resultwig.success) {
    if (_isMounted) {
      if (resultwig.data.length >= 3) {
        val = false;
      } else {
        val = true;
      }
    }
  }

  if (val) {
    let result = await createPredictive(props.predictive);
    // Show confirmation message
    if (result && result.success && _isMounted) {
      props.changeCreateSuccess(true);
      setTimeout(function() {
        props.history.push("/admin/dashboard");
      }, 1500);
    } else {
      // Show error message
      if (result) {
        let message = result.message;
        if (!message) {
          message = translate("try_again");
        }
        props.changeValidationMessage(message);
        props.changeCreateError(true);
      }
    }
  } else {
    let message = translate("predictive_warning");
    props.changeValidationMessage(message);
    props.changeCreateError(true);
  }
}

export default compose(
  connect(
    (state) => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      team: state.admin.team,
    }),
    {}
  ),
  withState("predictive", "changePredictive", { ...newPredictive }),
  withState("predictiveState", "changePredictiveState", {
    ...newPredictiveState,
  }),
  withState("createSuccess", "changeCreateSuccess", false),
  withState("createError", "changeCreateError", false),
  withState("validationMessage", "changeValidationMessage", ""),
  withState("isLoadingLogin", "changeIsLoadingLogin", false),
  withState("wig", "changeWIG", { name: "..." }),
  withState("wigList", "changeWIGList", undefined),
  withState("wigId", "changeWIGId", undefined),
  withState("isOtherYear", "setIsOtherYear", false),
  withHandlers({
    onFieldChange: (props) => (field, event, ...extraFields) => {
      props.predictive[field] = event.target.value;
      let isValid = validateProperty(
        predictiveCreateSchema,
        props.predictive,
        field
      ).isValid;
      if (isValid) {
        props.predictiveState[field] = "success";
      } else {
        props.predictiveState[field] = "error";
      }

      //Validate extra fields
      if (extraFields) {
        extraFields.forEach((attr) => {
          // if has a state, then validate it
          if (props.predictiveState[attr] !== "") {
            isValid = validateProperty(
              predictiveCreateSchema,
              props.predictive,
              attr
            ).isValid;
            if (isValid) {
              props.predictiveState[attr] = "success";
            } else {
              props.predictiveState[attr] = "error";
            }
          }
        });
      }

      props.changePredictiveState(props.predictiveState);
      props.changePredictive(props.predictive);
    },
    onPredictiveSave: (props) => async () => {
      props.changeIsLoadingLogin(true);
      let validation = validateObject(predictiveCreateSchema, props.predictive);
      if (validation.isValid) {
        await onSubmit(props);
      } else {
        let field;
        for (field in props.predictive) {
          if (validation.errors[field]) {
            props.predictiveState[field] = "error";
            props.changeValidationMessage(validation.errors[field][0]);
          }
        }
        props.changePredictiveState(props.predictiveState);
        props.changeCreateError(true);
      }
      props.changeIsLoadingLogin(false);
    },
  }),
  lifecycle({
    componentDidMount() {
      getWIGList(this.props);
      wigId = this.props.match.params.id;

      this.props.changeWIGId(wigId);

      newPredictive.wigId = wigId;
      newPredictive.axesNumber = 1;
      newPredictive.teamId = this.props.team.teamId;
      this.props.changePredictive({ ...newPredictive });
      this.props.changePredictiveState({ ...newPredictiveState });
      _isMounted = true;
      getWIG(this.props);
    },
    componentWillUnmount() {
      _isMounted = false;
    },
  })
)(CreateView);
