import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { userUpdate as userUpdateSchema } from "models/userModel";
import { getUser, updateUser } from "services/apiUser/userApi";
import { removeEmptyFields } from "utils/functions";

import EditView from "./EditView";


const newUser = {
	firstName: "",
	lastName: "",
	email: "",
	roleId: 3,
	password: "",
	code: ""
}

const newUserState = {
	firstName: "",
	lastName: "",
	email: "",
	roleId: "",
	password: "",
	employeeCode: ""
}

let userId
let _isMounted = false

async function onSubmit(props) {
	let body = removeEmptyFields({ ...props.user }, ["password"])
	let result = await updateUser(props.userId, body)
	// Show confirmation message
	if (result && result.success && _isMounted) {
		props.setCreateSuccess(true)
		setTimeout(function () {
			if (_isMounted) {
				props.history.push('/admin/user/index')
			}
		}, 1500);
	} else {
		// Show error message
		if (result) {
			let message = result.message
			if (!message) {
				message = "Algo ocurrió mal, inténtalo nuevamente."
			}
			props.setValidationMessage(message)
			props.setCreateError(true)
		}
	}
}

async function fetchUser(props) {
	let result = await getUser(userId)
	if (result.success) {
		if (_isMounted) {
			let user = result.data[0]
			user.password = ""
			props.setUser(user)
		}

	}
}


export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{}
	),
	withState("userId", "setUserId", undefined),
	withState("user", "setUser", { ...newUser }),
	withState("userState", "setUserState", { ...newUserState }),
	withState("createSuccess", "setCreateSuccess", false),
	withState("createError", "setCreateError", false),
	withState("validationMessage", "setValidationMessage", ""),
	withState("isLoadingSave", "setIsLoadingSave", false),
	withState( "stateModal", "setStateModal", false),
	withHandlers({
		onFieldChange: (props) => (field, value) => {
		
			props.user[field] = value
			let isValid = validateProperty(userUpdateSchema, props.user, field).isValid
			if (isValid) {
				props.userState[field] = "success"
			} else {
				props.userState[field] = "error"
			}
			props.setUserState(props.userState)
			props.setUser(props.user)
		},
		onModalClosed: (props) => async () => {
			props.setStateModal(false)

	},
		saveUserValidation: (props) => async () => {

			// if(props.user.employeeCode == ""){
			// 	//props.user["code"]= NULL;
			// 	props.setStateModal(true)
			// }else{
			props.setIsLoadingSave(true)
			let validation = validateObject(userUpdateSchema, props.user)
			if (validation.isValid) {
				await onSubmit(props)
			} else {
				let field
				for (field in props.user) {
					if (validation.errors[field]) {
						props.userState[field] = "error"
					}
				}
				props.setUserState(props.userState)
			}
			props.setIsLoadingSave(false)
			// }

		},
		onSave: (props) => async () => {
			props.setStateModal(false)
			props.setIsLoadingSave(true)
			let validation = validateObject(userUpdateSchema, props.user)
			if (validation.isValid) {
				await onSubmit(props)
			} else {
				let field
				for (field in props.user) {
					if (validation.errors[field]) {
						props.userState[field] = "error"
					}
				}
				props.setUserState(props.userState)
			}
			props.setIsLoadingSave(false)
		}
	}),
	lifecycle({
		componentDidMount() {
			_isMounted = true

			userId = parseInt(this.props.match.params.id)
			this.props.setUserId(userId)
			fetchUser(this.props)

			this.props.setUserState({ ...newUserState })
			this.props.setCreateSuccess(false)
		},
		componentWillUnmount() {
			_isMounted = false
		}
	})
)(EditView);
