import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";
import classNames from 'classnames';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";


// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import AdminLanguageButton from "./AdminLanguageButton";
import AdminReminderButton from "./AdminReminderButton";
import MailIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import Button from "components/CustomButtons/Button.js";


import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";

import { getCommitmentReminder} from "services/apiAdmin/commitmentApi";
import { getInputFormatDateY} from "utils/functions"

import { translate } from "utils/translate";

import { signOut } from "store/LoginState";

const useStyles = makeStyles(styles);

let reminder; 
let reminderlist= []
async function getCommitmentsReminder() {
	let today_date = getInputFormatDateY(new Date());
	let result = await getCommitmentReminder(today_date);
	if(result.success){
    reminderlist = result.data;
    reminder= result.data.length;
	}

}
export default function AdminNavbar(props) {
  const whiteList = ["/admin/commitment/internal", "/admin/dashboard", "/admin/commitment/external", "/admin/accountability",
    "/admin/team/index", "/admin/team/create", "/admin/user/index", "/admin/user/create", "/admin/history/index"]
  const blockChangeTeam = !whiteList.includes(window.location.pathname.toLowerCase())
  getCommitmentsReminder();
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const { color, rtlActive, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
                <Button
                  justIcon
                  round
                  color="white"
                  onClick={props.sidebarMinimize}
                >
                  <MoreVert className={classes.sidebarMiniIcon} />
                </Button>
              )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href="#" className={classes.title} color="transparent">
            {brandText}
          </Button>
          <FormControl
            fullWidth
            className={classNames(classes.selectFormControl, classes.navbarSelect)}
          >
            <Autocomplete
              value={props.team}
              loadingText="Cargando..."
              clearText="Limpiar"
              noOptionsText="Sin resultados"
              id="teamList"
              open={open}
              disabled={blockChangeTeam}
              onOpen={() => {
                setOpen(true);
                props.changeLoadingTeamList(true);
              }}
              onClose={() => {
                setOpen(false);
                // props.setTeamList([]);
              }}
              //onChange={handleTeamChage}
              onChange={(event, newValue) => {
                if (newValue) {
                  newValue = parseInt(newValue.teamId)
                }
                props.onTeamChange(newValue)
              }}
              getOptionSelected={(option, value) => option.teamId === value.teamId}
              getOptionLabel={(option) => `${option.title}`}
              options={props.teamList}
              loading={props.loadingTeamList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate("choose_team_label")}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {props.loadingTeamList ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
          {/* 
          <FormControl
            className={classNames(classes.selectFormControl, classes.navbarSelect)}
          >
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
            >
              Equipo
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              disabled={blockChangeTeam}
              value={props.teamId}
              onChange={handleTeamChage}
              inputProps={{
                name: `navBarTeamId`,
                id: `navBarTeamId`
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Seleccione el equipo
                        </MenuItem>

              {props.teamList.map((team, index) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  style={{ overflowX: "auto" }}
                  value={team.teamId}
                  key={index}
                >
                  {team.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
                */}
        </div>
        {/* 
        <Select
          id="language-selector"
          open={openLanguage}
          onClose={() => setOpenLanguage(false)}
          onOpen={() => setOpenLanguage(true)}
          value={props.language}
          onChange={props.onLanguageChange}
        >
          <MenuItem value="default">
            <LanguageIcon />
          </MenuItem>
          <MenuItem value={"spa"}>Español</MenuItem>
          <MenuItem value={"eng"}>English</MenuItem>
        </Select>
        */}
          <Hidden smDown implementation="css">
          <Badge badgeContent={reminder} color="primary">
 
  <AdminReminderButton reminderList={reminderlist} rtlActive={rtlActive} />
</Badge>
          
        </Hidden>
        <Hidden smDown implementation="css">
          <AdminLanguageButton setLanguage={props.setLanguage} rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>

        <Hidden smDown implementation="css">
          <AdminNavbarLinks signOut={props.signOut} rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func
};
