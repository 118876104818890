import { Accountability, ResponseMessages } from "components/Constants";
import axios from "axios";
import { signOut } from "store/LoginState";
import store from "store";

/*
Get the list of Questions
*/
async function getAllQuestions() {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Accountability.GetQuestionsEndPoint}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getAllQuestions returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}
/*
Used to save WIG goals with multiple participants
*/
async function saveMultipleAccountabilityGrades(accountabilityRecordData, teamId) {
    try {
        let url = `${Accountability.PostAccountabilityMultipleRecordEndPoint}/${teamId}`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: accountabilityRecordData
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`saveAccountabilityGrades returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for saveAccountabilityGrades`)

            // If it's a success response
            if (response.status === 201) {
                return { success: true}
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}
/*
Used to save WIG goals
*/
async function saveAccountabilityGrades(accountabilityRecordData, teamId) {
    try {
        let url = `${Accountability.PostAccountabilityRecordEndPoint}/${teamId}`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: accountabilityRecordData
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`saveAccountabilityGrades returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for saveAccountabilityGrades`)

            // If it's a success response
            if (response.status === 204) {
                return { success: true}
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


/*
Get Accountability Record details by id
*/
async function getAccountabilityRecordById(id) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Accountability.GetRecordEndPoint}/${id}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getAccountabilityRecordsByTeam  returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of Accountability Records
*/
async function getAccountabilityRecordsListByTeam(teamId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Accountability.GetRecordsByTeamEndPoint}/${teamId}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getAccountabilityRecordsByTeam  returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get Accountability Record details by id
*/
async function getAccountabilityParticipantsByRecordId(id) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Accountability.GetRecordEndPoint}/${id}/participants`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getAccountabilityRecordsByTeam  returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of Accountability Records
*/
async function getAccountabilityRecordByTeam(teamId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Accountability.GetRecordByTeamEndPoint}/${teamId}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getAccountabilityRecordByTeam  returned a status code ${error.response.status}`)
                }
            }
        });

        if (response && response.data.success === true) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of Accountability Records 
*/
async function getMinuteListByTeam(teamId) {
    try {
        let requestHeaders = createHeaders()
       let url1= `${Accountability.GetMinutesByTeamEndPoint}/${teamId}`
      
        let response = await axios({
            method: "get",
            url: `${Accountability.GetMinutesByTeamEndPoint}/${teamId}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getMinutesByTeam  returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}
function createHeaders() {
    let headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
        "nonce": localStorage.getItem('nonce')
    }

    return headers
}

/*
Used to save Minute for team
*/
async function saveMinuteTeam(MinuteData, teamId) {
    try {

        let url = `${Accountability.PostMinuteTeam}/${teamId}`
        let requestHeaders = createHeaders()
       
        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: MinuteData
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`saveMinuteTeam returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for saveMinuteTeam`)

            // If it's a success response
            if (response.status === 204) {
                return { success: true}
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get Accountability Multiple Record details by id
*/
async function getAccountabilityMultipleRecordById(id) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Accountability.GetMultipleRecordById}/${id}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getAccountabilityMultipleRecordsById  returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get Accountability Multiple Record Participants by id
*/
async function getAccountabilityMultipleRecordParticipantsById(id) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Accountability.GetMultipleRecordParticipantsById}/${id}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getAccountabilityMultipleRecordsParticipantsById  returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get Accountability Record details by id
*/
async function getRecordById(id) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Accountability.GetRecordById}/${id}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getRecordById returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the last Minute Records
*/
async function getLastMinuteByTeam(teamId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Accountability.GetLastMinuteTeamEndPoint}/${teamId}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getLastMinutebyTeam  returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get Accountability Record By User and Year
*/
async function getRecordbyUserAndYear(year) {
    try {
        let requestHeaders = createHeaders()
        
        let response = await axios({
            method: "get",
            url: `${Accountability.GetRecordList}/${year}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if(error.response.data.message === ResponseMessages.INVALID_TOKEN){
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getRecordbyUserAndYear  returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


export {
    getAllQuestions,
    saveAccountabilityGrades,
    saveMultipleAccountabilityGrades,
    getAccountabilityRecordsListByTeam,
    getAccountabilityRecordById,
    getAccountabilityParticipantsByRecordId,
    getAccountabilityRecordByTeam,
    saveMinuteTeam,
    getMinuteListByTeam,
    getAccountabilityMultipleRecordById,
    getAccountabilityMultipleRecordParticipantsById,
    getRecordById,
    getLastMinuteByTeam,
    getRecordbyUserAndYear

}