import React from "react";

// @material-ui/core components
import { CircularProgress, Grid } from "@material-ui/core";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";

import styles from "assets/jss/material-dashboard-pro-react/components/loaderStyle.js";

const useStyles = makeStyles(styles);

const LoginPage = () => {
  const classes = useStyles();

  return (
      <div className={classes.wrapper}>
        <div className={classes.fullPage}>
          <div className={classes.container}>
          <GridContainer justify="center">
              <Grid container className={classes.progressContainer}>
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
          </GridContainer>
        </div>
        </div>
      </div>
  );
}

export default LoginPage