import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { getExternalCommitments } from "services/apiAdmin/commitmentApi";
import { getCommitmentsByDate } from "services/apiAdmin/commitmentApi";
import { getAccountabilityRecordByTeam, saveMinuteTeam } from "services/apiAdmin/accountabilityApi";

import MinuteView from "./MinuteView";
import { validateProperty, validateObject } from "utils/validator";
import { teamAccountabilitySchema } from "models/teamModel";
import { getTeamById } from "services/apiAdmin/teamApi";
import { getCommitmentsByMinute, getCommitmentsById } from "services/apiAdmin/commitmentApi";

let _isMounted = false
const newTeam = {
	title: "",
	parentTeamId: "",
	day: "",
	time: "7:00:00"
}

const newRecord = {
	id: "",
	recordDate: ""
}

const newTeamState = {
	title: "",
	parentTeamId: "",
	day: "",
	time: ""
}
async function getTeam(props, teamId) {
	let result = await getTeamById(teamId)

	if (result.success) {
		if (_isMounted) {
			props.setTeam(result.data)
		}

	}
}

let idslist = [];

let commitments = [];
let clearpaths = [];
async function getCommitmentsIdByMinute(props, teamId, minuteId) {
	let result = await getCommitmentsByMinute(teamId, minuteId);
	console.log(result);
	console.log(teamId);
	console.log(minuteId);

	if (_isMounted && result.success) {

		idslist = result.data;

		for (let i = 0; i < idslist.length; i++) {

			let result = await getCommitmentsById(teamId, idslist[i].commitmentId);

			if (_isMounted && result.success) {
				//props.setCommitmentList(result.data
				if (result.data.hasDependency == 1) {
					clearpaths.push(result.data);
				}
				commitments.push(result.data);
			}
		}

		props.setClearPathList(clearpaths);
		props.setCommitmentList(commitments);
	}
}



let recordDate;
let recordId;
let recordTime;




export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
			teamId: state.admin.teamId
		}),
		{}
	),

	withState("team", "setTeam", { ...newTeam }),
	withState("commitmentList", "setCommitmentList", []),
	withState("clearpathList", "setClearPathList", []),
	withState("commitmentIdList", "setCommitmentIdList", []),
	withState("record", "setRecord", { ...newRecord }),
	withState("teamState", "setTeamState", { ...newTeamState }),
	withState("recordId", "setrecordId", ""),
	withState("recorDate", "setrecordDate", ""),



	lifecycle({
		componentDidMount() {
			_isMounted = true
			//getCommitments(this.props)
			let minuteId = this.props.match.params.id;

			getTeam(this.props, this.props.teamId);
			//getRecord(this.props,this.props.teamId);
			getCommitmentsIdByMinute(this.props, this.props.teamId, minuteId);

		}
	})
)(MinuteView);
