import { Stat, ResponseMessages } from "components/Constants"
import { signOut } from "store/LoginState";
import store from "store";
import axios from "axios";

/*
Get the list of WIG with Name and Id
*/
async function getDataset0(axisId, wigId, typeAxi) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Stat.Dataset0}${axisId}/${wigId}/${typeAxi}?lang=${localStorage.getItem("4dxLocale")}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`createPredictive returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for getDataset0`)

            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

function createHeaders() {
    let headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
        "nonce": localStorage.getItem('nonce')
    }

    return headers
}

async function getReportByUser(params) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${Stat.GetReportByUser}${params.year}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`createPredictive returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            // console.info(`statusCode: ${response.status} for GetReportByUser`)

            // If it's a success response
            if (response.status === 201) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


export { getDataset0, getReportByUser }

