/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  CircularProgress,
  Select,
  MenuItem
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// material ui icons
import Person from "@material-ui/icons/Person";
import AddAlert from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Group from "@material-ui/icons/Group";
import Edit from "@material-ui/icons/Edit";

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import SweetAlert from "react-bootstrap-sweetalert";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/User/createStyle.js";

import { translate } from "utils/translate"

const useStyles = makeStyles(styles);

export default function createView(props) {
  const classes = useStyles();

  let breadcrumbViews = [
    {
      name: translate("routes_home_label"),
      url: "/admin/dashboard",
      icon: DashboardIcon
    },
    {
      name: translate("routes_user_label"),
      url: "/admin/user/index",
      icon: Person
    },
    {
      name: translate("routes_edit_label"),
      url: `/admin/user/edit/${props.userId}`,
      icon: Edit
    }
  ]

  const CodeAlert = () => {
    let alertMessage = (null)
    if (props.stateModal) {
      alertMessage = (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={translate("user_code_warnig")}
          onConfirm={() => props.onSave()}
          onCancel={() => props.onModalClosed()}
          confirmBtnCssClass={(props.alertLoading ? classes.disabled : "") + " " + classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.dangerColor}
          confirmBtnText={translate( "user_code_no")}
          cancelBtnText={translate("user_code_yes")}
          showCancel
        >
        </SweetAlert>
      )
    }
    return (alertMessage)
  }
  return (
    <>
        {CodeAlert()}
      <Breadcrumbs views={breadcrumbViews} />
      <Heading
        textAlign="center"
        title={translate("user_edit_header")}
      />
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Person />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{translate("user_create_title")}</h4>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={4} md={4}>
                        <CustomInput
                          success={props.userState.firstName === "success"}
                          error={props.userState.firstName === "error"}
                          labelText={translate("user_first_name_label") + "*"}
                          id="inputFirstName"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              props.onFieldChange("firstName", event.target.value)
                            },
                            type: "text",
                            value: props.user.firstName,
                            endAdornment:
                              props.userState["firstName"] === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                  undefined
                                )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <CustomInput
                          success={props.userState.lastName === "success"}
                          error={props.userState.lastName === "error"}
                          labelText={translate("user_last_name_label") + "*"}
                          id="inputLastName"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              props.onFieldChange("lastName", event.target.value)
                            },
                            type: "text",
                            value: props.user.lastName,
                            endAdornment:
                              props.userState["lastName"] === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                  undefined
                                )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            {translate("user_role_label")}*
                                                    </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={props.user[`roleId`]}
                            onChange={e => props.onFieldChange(`roleId`, e.target.value)}
                            inputProps={{
                              name: `roleId`,
                              id: `roleId`
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                              {translate("user_role_input")}
                            </MenuItem>
                            {[translate("const_roles_1"), translate("const_roles_2"), translate("const_roles_3"), translate("const_roles_4")].map((role, index) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                                value={index + 1}
                                key={index}
                              >
                                {role}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          success={props.userState.email === "success"}
                          error={props.userState.email === "error"}
                          labelText={translate("user_mail_label")}
                          id="inputEmail"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              props.onFieldChange("email", event.target.value)
                            },
                            type: "text",
                            value: props.user.email,
                            endAdornment:
                              props.userState["email"] === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                  undefined
                                )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          success={props.userState.password === "success"}
                          error={props.userState.password === "error"}
                          labelText={translate("user_password_label")}
                          id="inputPassword"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              props.onFieldChange("password", event.target.value)
                            },
                            type: "password",
                            value: props.user.password,
                            endAdornment:
                              props.userState["password"] === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                  undefined
                                )
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          success={props.userState.employeeCode === "success"}
                          error={props.userState.employeeCode === "error"}
                          labelText={translate("user_code_label")}
                          id="inputCode"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: event => {
                              // Replace everything that isn't a number with ""
                              // Only works with text typed inputs
                              event.target.value = event.target.value.replace(/[^0-9]/i, "");
                              props.onFieldChange("employeeCode", event.target.value);
                            },
                            type: "text",
                            value: props.user.employeeCode,
                            endAdornment:
                              props.userState["employeeCode"] === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                  undefined
                                )
                          }}
                          labelProps={{
                            shrink: true
                          }}
                        />
                          
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <div className={classes.formCategory}>
                  <small>*</small> {translate("required_fields")}
                </div>
                <div className={classes.center}>
                  {props.isLoadingSave ?
                    <CircularProgress color="inherit" /> :
                    <Button color="primary" onClick={props.saveUserValidation}>
                      {translate("button_save")}
                    </Button>
                  }
                </div>
              </form>
            </CardBody>
          </Card>
          <Snackbar
            place="br"
            color="success"
            icon={AddAlert}
            message={translate("alert_user_edit_success")}
            open={props.createSuccess}
            closeNotification={() => props.setCreateSuccess(false)}
            close
          />
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.createError}
            closeNotification={() => props.setCreateError(false)}
            close
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
