/*eslint-disable*/
import React from "react";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Select, MenuItem } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

// material ui icons
import TrendingUp from "@material-ui/icons/TrendingUp";
import Close from "@material-ui/icons/Close";
import AddAlert from "@material-ui/icons/AddAlert";
import Flag from "@material-ui/icons/Flag";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputMask from "components/CustomInputMask/CustomInputMask";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import { DataTypes } from "components/Constants";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import { formatPredictiveName } from "utils/dataTypeFormatter";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/Predictive/editStyle.js";

// Functions
import { translate } from "utils/translate";

const useStyles = makeStyles(styles);

export default function createView(props) {
  const classes = useStyles();
  function getFormAxes(index) {
    // let mdCol = 12 / props.predictive.axesNumber
    //let className = ((index === 1 && props.predictive.axesNumber === 2)?classes.formVerticalDivider:"")
    return (
      <GridItem xs={12} sm={12} md={12} className={""}>
        <h5>{translate("predictive_info_label")}</h5>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              success={
                props.predictiveState[`displayName${index}`] === "success"
              }
              error={props.predictiveState[`displayName${index}`] === "error"}
              labelText={translate("predictive_name_label") + " *"}
              id={`inputDisplayName${index}`}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (event) => {
                  props.onFieldChange(`displayName${index}`, event);
                },
                type: "text",
                value: props.predictive[`displayName${index}`],
                endAdornment:
                  props.predictiveState[`displayName${index}`] === "error" ? (
                    <InputAdornment position="end">
                      <Close className={classes.danger} />
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                {translate("predictive_datatype_label")} *
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={props.predictive[`dataTypeId${index}`]}
                onChange={(e) => props.onFieldChange(`dataTypeId${index}`, e)}
                inputProps={{
                  name: `selectDataTypeId${index}`,
                  id: `selectDataTypeId${index}`,
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {translate("predictive_datatype_input")}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                  value={1}
                >
                  {translate("const_datatype_numeric")}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                  value={2}
                >
                  {translate("const_datatype_percent")}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                  value={3}
                >
                  {translate("const_datatype_dollar")}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                  value={4}
                >
                  {translate("const_datatype_quetzal")}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                  value={5}
                >
                  {translate("const_datatype_hours")}
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                {translate("predictive_direction_label")} *
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={props.predictive[`dir${index}`]}
                onChange={(e) => props.onFieldChange(`dir${index}`, e)}
                inputProps={{
                  name: "selectDirection",
                  id: "selectDirection",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {translate("predictive_direction_input")}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                  value={1}
                  key={1}
                >
                  {translate("predictive_direction_ascendent")}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                  value={0}
                  key={0}
                >
                  {translate("predictive_direction_descendent")}
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <CustomInputMask
              success={props.predictiveState[`y${index}`] === "success"}
              error={props.predictiveState[`y${index}`] === "error"}
              labelText={translate("const_goalexpected") + "*"}
              id={`inputY${index}`}
              formControlProps={{
                fullWidth: true,
              }}
              suffix={
                props.predictive.Active2 == 1
                  ? ""
                  : DataTypes[props.predictive[`dataTypeId${index}`]].suffix
              }
              decimalScale={
                props.predictive.Active2 == 1
                  ? ""
                  : DataTypes[props.predictive[`dataTypeId${index}`]]
                      .decimalScale
              }
              inputProps={{
                onChange: (event) => {
                  event.target.value =
                    event.target.value === ""
                      ? ""
                      : parseFloat(event.target.value);
                  props.onFieldChange(`y${index}`, event);
                },
                type: "text",
                value: props.predictive[`y${index}`],
                startAdornment: <Check className={classes.success} />,
                endAdornment:
                  props.predictiveState[`y${index}`] === "error" ? (
                    <InputAdornment position="end">
                      <Close className={classes.danger} />
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInputMask
              success={props.predictiveState[`x${index}`] === "success"}
              error={props.predictiveState[`x${index}`] === "error"}
              labelText={translate("const_goalacceptable") + "*"}
              id={`inputX${index}`}
              formControlProps={{
                fullWidth: true,
              }}
              suffix={
                props.predictive.Active2 == 1
                  ? ""
                  : DataTypes[props.predictive[`dataTypeId${index}`]].suffix
              }
              decimalScale={
                props.predictive.Active2 == 1
                  ? ""
                  : DataTypes[props.predictive[`dataTypeId${index}`]]
                      .decimalScale
              }
              inputProps={{
                onChange: (event) => {
                  event.target.value =
                    event.target.value === ""
                      ? ""
                      : parseFloat(event.target.value);
                  props.onFieldChange(`x${index}`, event);
                },
                type: "text",
                value: props.predictive[`x${index}`],
                startAdornment: <Warning className={classes.warning} />,
                endAdornment:
                  props.predictiveState[`x${index}`] === "error" ? (
                    <InputAdornment position="end">
                      <Close className={classes.danger} />
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
              }}
            />
            <div className={classes.formDisclaimer}>
              <small>*</small>{" "}
              {translate("predictive_info_text_1") +
                props.predictive.focus +
                translate("predictive_info_text_2")}
            </div>
          </GridItem>
        </GridContainer>
      </GridItem>
    );
  }

  function getFormAxesPredictiveTwo(index) {
    // let mdCol = 12 / props.predictive.axesNumber
    //let className = ((index === 1 && props.predictive.axesNumber === 2)?classes.formVerticalDivider:"")
    return (
      <GridItem xs={12} sm={12} md={12} className={""}>
        {props.predictive.axesNumber === 2 ? (
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={3}>
                <CustomInput
                  success={props.predictiveState["verbTwo"] === "success"}
                  error={props.predictiveState["verbTwo"] === "error"}
                  labelText={translate("verb") + "*"}
                  id="inputVerb"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      props.onFieldChange("verbTwo", event);
                    },
                    type: "text",
                    value: props.predictive.verbTwo,
                    endAdornment:
                      props.predictiveState["verbTwo"] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              {/*
<GridItem xs={12} sm={6} md={2}>
<CustomInput
success={props.predictiveState["what"] === "success"}
error={props.predictiveState["what"] === "error"}
labelText="¿Qué? *"
id="inputWhat"
formControlProps={{
fullWidth: true
}}
inputProps={{
onChange: event => {
props.onFieldChange("what", event)
},
type: "text",
value: props.predictive.what,
endAdornment:
props.predictiveState["what"] === "error" ? (
<InputAdornment position="end">
<Close className={classes.danger} />
</InputAdornment>
) : (
undefined
)
}}
/>
</GridItem>
*/}
              <GridItem xs={12} sm={6} md={3}>
                <CustomInput
                  success={props.predictiveState["focusTwo"] === "success"}
                  error={props.predictiveState["focusTwo"] === "error"}
                  labelText={translate("focus") + "*"}
                  id="inputFocus"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      props.onFieldChange("focusTwo", event);
                    },
                    type: "text",
                    value: props.predictive.focusTwo,
                    endAdornment:
                      props.predictiveState["focusTwo"] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    {translate("consistency")} *
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={props.predictive.consistencyTwo}
                    onChange={(e) => props.onFieldChange("consistencyTwo", e)}
                    inputProps={{
                      name: `selectConsistencyTwo`,
                      id: `selectConsistencyTwo`,
                    }}
                  >
                    {[translate("daily"), translate("weekly")].map(
                      (valueTwo, indexTwo) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          style={{
                            overflowX: "auto",
                            textOverflow: "ellipsis",
                          }}
                          value={valueTwo}
                          key={indexTwo}
                        >
                          {valueTwo}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
                {/*
<CustomInput
success={props.predictiveState["consistency"] === "success"}
error={props.predictiveState["consistency"] === "error"}
labelText="Consistencia *"
id="inputConsistency"
formControlProps={{
fullWidth: true
}}
inputProps={{
onChange: event => {
props.onFieldChange("consistency", event)
},
type: "text",
value: props.predictive.consistency,
endAdornment:
props.predictiveState["consistency"] === "error" ? (
<InputAdornment position="end">
<Close className={classes.danger} />
</InputAdornment>
) : (
undefined
)
}}
/>
*/}
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <CustomInput
                  success={props.predictiveState["qualityTwo"] === "success"}
                  error={props.predictiveState["qualityTwo"] === "error"}
                  labelText={translate("quality") + "*"}
                  id="inputQuality"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => {
                      props.onFieldChange("qualityTwo", event);
                    },
                    type: "text",
                    value: props.predictive.qualityTwo,
                    endAdornment:
                      props.predictiveState["qualityTwo"] === "error" ? (
                        <InputAdornment position="end">
                          <Close className={classes.danger} />
                        </InputAdornment>
                      ) : (
                        undefined
                      ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(event) =>
                        props.onFieldChange("activeTwo", event.target.checked)
                      }
                      value={props.wig.activeTwo}
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label={translate("Active_axis_two")}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        ) : null}
        {props.predictive.axesNumber === 2 ? getFormAxes(2) : null}
      </GridItem>
    );
  }

  let breadcrumbViews = [
    {
      name: translate("routes_home_label"),
      url: "/admin/dashboard",
      icon: DashboardIcon,
    },
    {
      name: translate("wig"),
      url: props.predictive
        ? `/admin/wig/details/${props.predictive.wigId}`
        : "",
      icon: Flag,
    },
    {
      name: translate("predictive"),
      url: `/admin/predictive/edit/${props.predictiveId}`,
      icon: TrendingUp,
    },
  ];

  return (
    <div>
      <Breadcrumbs views={breadcrumbViews} />
      <Heading textAlign="center" title={translate("predictive_edit_header")} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <TrendingUp />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {translate("predictive_edit_title")}
              </h4>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h5>{translate("general_information_label")}</h5>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        {translate("wig")} *
                      </InputLabel>
                      <Select
                        disabled
                        MenuProps={{
                          className: classes.selectMenu,
                        }}
                        classes={{
                          select: classes.select,
                        }}
                        value={1}
                        onChange={(e) => props.onFieldChange("wigId", e)}
                        inputProps={{
                          name: "selectPredictiveType",
                          id: "selectPredictiveType",
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={1}
                          style={{
                            overflowX: "auto",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {props.wig.name}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={3}>
                        <CustomInput
                          success={props.predictiveState["verb"] === "success"}
                          error={props.predictiveState["verb"] === "error"}
                          labelText={translate("verb") + "*"}
                          id="inputVerb"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              props.onFieldChange("verb", event);
                            },
                            type: "text",
                            value: props.predictive.verb,
                            endAdornment:
                              props.predictiveState["verb"] === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              ),
                          }}
                        />
                      </GridItem>
                      {/*
<GridItem xs={12} sm={6} md={2}>
<CustomInput
success={props.predictiveState["what"] === "success"}
error={props.predictiveState["what"] === "error"}
labelText="¿Qué? *"
id="inputWhat"
formControlProps={{
fullWidth: true
}}
inputProps={{
onChange: event => {
props.onFieldChange("what", event)
},
type: "text",
value: props.predictive.what,
endAdornment:
props.predictiveState["what"] === "error" ? (
<InputAdornment position="end">
<Close className={classes.danger} />
</InputAdornment>
) : (
undefined
)
}}
/>
</GridItem>
*/}
                      <GridItem xs={12} sm={6} md={3}>
                        <CustomInput
                          success={props.predictiveState["focus"] === "success"}
                          error={props.predictiveState["focus"] === "error"}
                          labelText={translate("focus") + "*"}
                          id="inputFocus"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              props.onFieldChange("focus", event);
                            },
                            type: "text",
                            value: props.predictive.focus,
                            endAdornment:
                              props.predictiveState["focus"] === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              ),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={3}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            {translate("consistency")} *
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={props.predictive.consistency}
                            onChange={(e) =>
                              props.onFieldChange("consistency", e)
                            }
                            inputProps={{
                              name: `selectConsistency`,
                              id: `selectConsistency`,
                            }}
                          >
                            {[translate("daily"), translate("weekly")].map(
                              (value, index) => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  style={{
                                    overflowX: "auto",
                                    textOverflow: "ellipsis",
                                  }}
                                  value={value}
                                  key={index}
                                >
                                  {value}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                        {/*
<CustomInput
success={props.predictiveState["consistency"] === "success"}
error={props.predictiveState["consistency"] === "error"}
labelText="Consistencia *"
id="inputConsistency"
formControlProps={{
fullWidth: true
}}
inputProps={{
onChange: event => {
props.onFieldChange("consistency", event)
},
type: "text",
value: props.predictive.consistency,
endAdornment:
props.predictiveState["consistency"] === "error" ? (
<InputAdornment position="end">
<Close className={classes.danger} />
</InputAdornment>
) : (
undefined
)
}}
/>
*/}
                      </GridItem>
                      <GridItem xs={12} sm={6} md={3}>
                        <CustomInput
                          success={
                            props.predictiveState["quality"] === "success"
                          }
                          error={props.predictiveState["quality"] === "error"}
                          labelText={translate("quality") + "*"}
                          id="inputQuality"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (event) => {
                              props.onFieldChange("quality", event);
                            },
                            type: "text",
                            value: props.predictive.quality,
                            endAdornment:
                              props.predictiveState["quality"] === "error" ? (
                                <InputAdornment position="end">
                                  <Close className={classes.danger} />
                                </InputAdornment>
                              ) : (
                                undefined
                              ),
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  {props.predictive["Active2"] == 1 ? (
                    <>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            {translate("axis_amount")}
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={props.predictive.axesNumber}
                            onChange={(e) =>
                              props.onFieldChange("axesNumber", e)
                            }
                            inputProps={{
                              name: "selectaxesNumber",
                              id: "selectaxesNumber",
                            }}
                          >
                            {[1, 2].map((value, index) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                style={{
                                  overflowX: "auto",
                                  textOverflow: "ellipsis",
                                }}
                                value={value}
                                key={index}
                              >
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </>
                  ) : (
                    <>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                          >
                            {translate("axis_amount")}
                          </InputLabel>
                          <Select
                            disabled
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            value={props.predictive.axesNumber}
                            inputProps={{
                              name: "selectaxesNumber",
                              id: "selectaxesNumber",
                            }}
                          >
                            {[1, 2].map((value, index) => (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                style={{
                                  overflowX: "auto",
                                  textOverflow: "ellipsis",
                                }}
                                value={value}
                                key={index}
                              >
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </>
                  )}
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(event) =>
                            props.onFieldChange(
                              "activeOne",
                              event.target.checked
                            )
                          }
                          value={props.wig.activeOne}
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={translate("Active_axis_two")}
                    />
                  </GridItem>
                  {getFormAxes(1)}
                  {/* <Divider orientation="vertical" flexItem /> */}
                  {props.predictive.axesNumber === 2
                    ? getFormAxesPredictiveTwo(2)
                    : null}
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={translate("predictive_full_name_label")}
                      id="inputFullName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: true,
                        type: "text",
                        value: `${formatPredictiveName(props.predictive)}`,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      success={
                        props.predictiveState["description"] === "success"
                      }
                      error={props.predictiveState["description"] === "error"}
                      labelText={translate("description")}
                      id="inputDescription"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (event) => {
                          props.onFieldChange("description", event);
                        },
                        type: "text",
                        value: props.predictive.description,
                        endAdornment:
                          props.predictiveState["description"] === "error" ? (
                            <InputAdornment position="end">
                              <Close className={classes.danger} />
                            </InputAdornment>
                          ) : (
                            undefined
                          ),
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <div className={classes.formCategory}>
                  <small>*</small> {translate("required_fields")}
                </div>
                <div className={classes.center}>
                  {props.isLoadingLogin ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    <Button color="primary" onClick={props.onPredictiveSave}>
                      {translate("button_save")}
                    </Button>
                  )}
                </div>
              </form>
            </CardBody>
          </Card>
          <Snackbar
            place="br"
            color="success"
            icon={AddAlert}
            message="Predictiva actualizada exitósamente"
            open={props.createSuccess}
            closeNotification={() => props.changeCreateSuccess(false)}
            close
          />
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.createError}
            closeNotification={() => props.changeCreateError(false)}
            close
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
