import { compose, withState, withHandlers, lifecycle } from "recompose";
import moment from "moment";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { wigCreateSchema } from "models/wigModel";
import {
  createWIG,
  getWIGByTeamIdAndYear,
  getWIGByTeamIdAndCURRENTYear,
} from "services/apiAdmin/wigApi";
import { formatWIGName } from "utils/dataTypeFormatter";
import { setWIGList } from "store/AdminState";
import { getUserLocale } from "utils/translate";
import CreateView from "./CreateView";
import { GetPredictivesByWIGTeamAndUser } from "services/apiAdmin/predictiveApi";

import { translate } from "utils/translate";


let idiomChange = getUserLocale();

let fechaStart;
let fechaEnd;
if (idiomChange === "es-ES") {
  // let fechastartInitial = moment
  // let fechastartEnd = moment
  moment.lang('es', {
    months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
    monthsShort: 'enero._feb._mar_abr._may_jun_jul._ago_sept._oct._nov._dec.'.split('_'),
    weekdays: 'domingo_lunes_martes_miercoles_jueves_viernes_sabado'.split('_'),
    weekdaysShort: 'dom._lun._mar._mier._jue._vier._sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
  }
  );
  fechaStart = moment().format('DD MMMM, YYYY');
  fechaEnd = moment().format('DD MMMM, YYYY');
} else {
  fechaStart = moment().format('MMMM DD, YYYY');
  fechaEnd = moment().format('MMMM DD, YYYY');
}

const newWIG = {
  endDate: fechaEnd,
  startDate: fechaStart,
  verb: "",
  verbTwo: "",
  what: "",
  whatTwo: "",
  axesNumber: 1,
  x1: "",
  y1: "",
  level1_1: "",
  level2_1: "",
  level3_1: "",
  displayName1: "",
  dataTypeId1: 1,
  x2: "",
  y2: "",
  level1_2: "",
  level2_2: "",
  level3_2: "",
  displayName2: "",
  dataTypeId2: 1,
  description: "",
  year: new Date().getFullYear(),
  parentWig: 0,
};

const newWIGState = {
  endDate: "",
  startDate: "",
  verb: "",
  verbTwo: "",
  what: "",
  whatTwo: "",
  axesNumber: "",
  x1: "",
  y1: "",
  level1_1: "",
  level2_1: "",
  level3_1: "",
  displayName1: "",
  dataType1: "",
  x2: "",
  y2: "",
  level1_2: "",
  level2_2: "",
  level3_2: "",
  displayName2: "",
  dataType2: "",
  year: "",
  description: "",
  parentWig: "",
};

// let onChangeFlag = false;
// let onChangeFlagEnd = false;
let _isMounted = false;
let wigsteam = false;
let parentpredictives = false;

async function getParentWIGList(props) {
  // if team Id is not defined
  if (!props.team.parentTeamId) {
    return;
  }

  let currentYear = new Date().getFullYear();
  let result = await getWIGByTeamIdAndCURRENTYear(
    props.team.parentTeamId,
    currentYear
  );
  if (result.success) {
    if (_isMounted) {
      let wig;
      for (wig of result.data) {
        if(wig.year >=2024){
          if (
            idiomChange === "es-ES" &&
            wig.endDate != null &&
            wig.startDate != null
            ) {
            moment.locale('es')
            let mydateStart = moment(wig.startDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
            let mydateEndaDate = moment(wig.endDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
            wig.startDate = mydateStart;
            wig.endDate = mydateEndaDate;
            // let nuevoObject = {
            // 	...wig,
            // 	startDate: mydateStart,
            // 	endDate: mydateStart,
            //   };
        
            } else if (
            idiomChange === "en-US" &&
            wig.endDate != null &&
            wig.startDate != null
            ) {
            moment.locale('en')
            const fechaFormateadaStart = moment
              .utc(wig.startDate)
              .format("MMMM DD, YYYY");
            const fechaFormateadaEnd = moment
              .utc(wig.endDate)
              .format("MMMM DD, YYYY");
  
              wig.startDate = fechaFormateadaStart;
              wig.endDate = fechaFormateadaEnd;
        
        
            }
        }
        wig.name = formatWIGName(wig, getUserLocale());
      }
      props.setParentWIGList(result.data);
    }
  }
}

async function getWIGList(props) {
  // if team Id is not defined
  if (!props.teamId) {
    return;
  }

  let years = [];
  let currentYear = new Date().getFullYear();
  for (
    let iterateYear = currentYear + 1;
    iterateYear > currentYear - 4;
    iterateYear--
  ) {
    let result = await getWIGByTeamIdAndYear(props.teamId, iterateYear);
    let object = result.data.map(function (wig) {
      return {
        ...wig,
        id: wig.id,
        name: wig.displayName1,
      };
    });

    years.push(object);
  }

  props.setWIGList(
    years.map(function (wig) {
      return wig;
    })
  );
}

async function getWIGListS(props) {
  // if team Id is not defined
  if (!props.teamId) {
    return;
  }

  //let currentYear = new Date().getFullYear()
  let result = await getWIGByTeamIdAndYear(props.teamId, props.wig.year);
  if (result.success) {
    //Verify predictives in my team

    for (let i = 0; i < result.data.length; i++) {
      if (result.data[i].teamId == props.teamId) {
        //HAVE WIGS IN MY TEAM

        //VerifyPredictives(props,result.data[i].id);}
        wigsteam = true;
      } else {
        //Verify predictives in parent´s wigs
        VerifyPredictivesLead(props, result.data[i].id);
      }
    }
    // update general state
  }
}

async function VerifyPredictivesLead(props, wigId) {
  let result = await GetPredictivesByWIGTeamAndUser(props.teamId, wigId);

  if (result.success) {
    if (result.message.length > 0) {
      parentpredictives = true;
    }
  }
}
async function onSubmit(props) {

  let nuevoObjeto = {
    ...props.wig,
    idiom: idiomChange
  }
  if (props.wig.startDate && props.wig.year >= 2024) {
    let mydateStart = moment(props.wig.startDate, 'MMMM DD, YYYY').format('YYYY-MM-DD');
    let mydateEndaDate = moment(props.wig.endDate, 'MMMM DD, YYYY').format('YYYY-MM-DD');
    const fecha1 = moment(mydateStart);
    const fecha2 = moment(mydateEndaDate);
    if (props.wig.year == fecha1.year() && props.wig.year == fecha2.year()) {
      const estaAntes = fecha1.isBefore(fecha2);
      if (estaAntes) {
        let resultwig = await getWIGByTeamIdAndCURRENTYear(
          props.teamId,
          props.wig.year
        );
        let create = true;

        if (resultwig.success) {
          if (_isMounted) {
            if (resultwig.data.length < 4 && parentpredictives == false) {
              create = true;
            } else {
              create = false;
            }
          }
        }

        if (create) {
          // onChangeFlag = false;
          // onChangeFlagEnd = false;
          let result = await createWIG(nuevoObjeto, props.teamId);

          // Show confirmation message
          if (result && result.success && _isMounted) {
            await getWIGList(props);
            props.changeCreateSuccess(true);
            setTimeout(function () {
              if (_isMounted) {
                props.history.push("/admin/dashboard");
              }
            }, 1500);
          } else {
            // Show error message
            if (result) {
              let message = result.message;
              if (!message) {
                message = translate("try_again");
              }
              props.changeValidationMessage(message);
              props.changeCreateError(true);
            }
          }
        } else {
          let message;
          if (parentpredictives == true) {
            //message
            message = translate("wigs_predictives_warning");
          } else {
            message = translate("mcis_warning");
          }

          props.changeValidationMessage(message);
          props.changeCreateError(true);
        }
      } else {

        let message =
          nuevoObjeto.idiom === "es-ES"
          ? "La fecha final no debe ser  anterior a la fecha inicial"
          : "The end date must not be earlier than the start date";
        props.changeValidationMessage(message);
        props.changeCreateError(true);

      }
    } else {
      let message =
        nuevoObjeto.idiom === "es-ES"
          ? "El año de inicio y finalización debe ser el mismo que el año del Mci."
          : "The start and end year must be the same as the Mci year.";
      props.changeValidationMessage(message);
      props.changeCreateError(true);
    }


  } else {

    let resultwig = await getWIGByTeamIdAndCURRENTYear(
      props.teamId,
      props.wig.year
    );
    let create = true;

    if (resultwig.success) {
      if (_isMounted) {
        if (resultwig.data.length < 4 && parentpredictives == false) {
          create = true;
        } else {
          create = false;
        }
      }
    }

    if (create) {
      // onChangeFlag = false;
      // onChangeFlagEnd = false;
      let result = await createWIG(nuevoObjeto, props.teamId);

      // Show confirmation message
      if (result && result.success && _isMounted) {
        await getWIGList(props);
        props.changeCreateSuccess(true);
        setTimeout(function () {
          if (_isMounted) {
            props.history.push("/admin/dashboard");
          }
        }, 1500);
      } else {
        // Show error message
        if (result) {
          let message = result.message;
          if (!message) {
            message = translate("try_again");
          }
          props.changeValidationMessage(message);
          props.changeCreateError(true);
        }
      }
    } else {
      let message;
      if (parentpredictives == true) {
        //message
        message = translate("wigs_predictives_warning");
      } else {
        message = translate("mcis_warning");
      }

      props.changeValidationMessage(message);
      props.changeCreateError(true);
    }
  }

}

export default compose(
  connect(
    (state) => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      teamId: state.admin.teamId,
      team: state.admin.team,
      roleId: state.admin.roleId,
    }),
    { setWIGList }
  ),
  withState("wig", "changeWIG", { ...newWIG }),
  withState("wigState", "changeWIGState", { ...newWIGState }),
  withState("createSuccess", "changeCreateSuccess", false),
  withState("createError", "changeCreateError", false),
  withState("validationMessage", "changeValidationMessage", ""),
  withState("isLoadingSave", "changeIsLoadingSave", false),
  withState("parentWIGList", "setParentWIGList", []),
  withState("currentYear", "setCurrentYear", false),
  withHandlers({
    onFieldChange: (props) => (field, event) => {
      let idiom = getUserLocale();
      if (field == "endDate" || field == "startDate") {
        // if (field == "endDate") {
        //   onChangeFlagEnd = true;
        // } else {
        //   onChangeFlag = true;
        // }
        let formatoCorrespondiente;
        if (idiom === "es-ES") {
          formatoCorrespondiente = moment(event).format("DD MMMM, YYYY");
        } else {
          formatoCorrespondiente = moment(event).format("MMMM DD, YYYY");
        }
        props.wig[field] = formatoCorrespondiente;
        if (field == "year") {
          let currentYear = new Date().getFullYear();
          if (props.wig[field] != currentYear) {
            props.setCurrentYear(true);
            parentpredictives = false;
            getWIGListS(props);
          } else {
            parentpredictives = false;
            getWIGListS(props);
          }
        }
        let isValid = validateProperty(wigCreateSchema, props.wig, field)
          .isValid;
        if (isValid) {
          props.wigState[field] = "success";
        } else {
          props.wigState[field] = "error";
        }
        props.changeWIGState(props.wigState);
        props.changeWIG(props.wig, function () { });
      } else {
        props.wig[field] = event.target.value;
        if (field == "year") {
          let currentYear = new Date().getFullYear();
          if (props.wig[field] != currentYear) {
            props.setCurrentYear(true);
            parentpredictives = false;
            getWIGListS(props);
          } else {
            parentpredictives = false;
            getWIGListS(props);
          }
        }
        let isValid = validateProperty(wigCreateSchema, props.wig, field)
          .isValid;
        if (isValid) {
          props.wigState[field] = "success";
        } else {
          props.wigState[field] = "error";
        }
        props.changeWIGState(props.wigState);
        props.changeWIG(props.wig, function () { });
      }
    },
    onWIGSave: (props) => async () => {
      props.changeIsLoadingSave(true);

      let validation = validateObject(wigCreateSchema, props.wig);
      if (validation.isValid) {
        await onSubmit(props);
      } else {
        let field;
        for (field in props.wig) {
          if (validation.errors[field]) {
            props.wigState[field] = "error";
            props.changeValidationMessage(validation.errors[field][0]);
          } else {
            props.wigState[field] = "success";
          }
        }
        props.changeWIGState(props.wigState);

        props.changeCreateError(true);
      }
      props.changeIsLoadingSave(false);
    },
  }),
  lifecycle({
    componentDidMount() {
      _isMounted = true;
      this.props.changeWIG({ ...newWIG });
      this.props.changeWIGState({ ...newWIGState });
      this.props.changeCreateSuccess(false);

      getParentWIGList(this.props);
      getWIGListS(this.props);
    },
    componentWillUnmount() {
      _isMounted = false;
    },
  })
)(CreateView);
