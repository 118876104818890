import React from "react";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// icons
import Add from "@material-ui/icons/PlaylistAdd";

//functions
import { verifyPermission } from "utils/functions"
import { translate } from "utils/translate"

import styles from "assets/jss/material-dashboard-pro-react/views/mainDashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const classes = useStyles();
  let adminPermission = verifyPermission(props.roleId, props.team.dataRoleId, "wig", "read.write.all")
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={8}>
          <div className={`${classes.leftTextAlign} ${classes.verticalCenter}`}>
            <h3 className={classes.title}>{translate('home_welcome')}</h3>
            <p>
              “{translate('home_phrase')}”
            </p>
            <p>
              ― Chris McChesney, The 4 Disciplines of Execution: Achieving Your Wildly Important Goals
                        </p>
            {adminPermission && props.wigList.length < 100 ?
              <NavLink
                to={`/admin/wig/create`}>
                <Tooltip title={translate("wig_create_tooltip")}>
                  <Button color="primary" className={classes.createBtn}>
                    <Add className={classes.icons} /> {translate('wig')}
                  </Button>
                </Tooltip>
              </NavLink>
              :
              null
            }
        
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
