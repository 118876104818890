import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import HistoryIcon from "@material-ui/icons/History";
import Person from "@material-ui/icons/Person";
import AddAlert from "@material-ui/icons/AddAlert";
import Close from "@material-ui/icons/Close";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Group from "@material-ui/icons/Group";
import Edit from "@material-ui/icons/Edit";
import {CloudDone} from "@material-ui/icons";


import {
  CircularProgress,
  Select,
  MenuItem
} from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";


// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

//functions
import { formatDate, filterCaseInsensitive } from "utils/functions";
import { CommitmentStatus } from "components/Constants";
import { getCommitmentColor } from "utils/dataTypeFormatter";
import { translate } from "utils/translate";

//library to export to excel
import writeXlsxFile from 'write-excel-file'


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};


const useStyles = makeStyles(styles);

export default function ReactTables(props) {
  
let d = new Date();
let year = d.getFullYear();
let yearsToDisplay=[year-3,year-2,year-1,year,year+1]

  const handleLastPageReached = async () => {
    let result = await props.onLastPageReached('')
  };

  const classes = useStyles();

  const [addrtype, setAddrtype] = React.useState([])

  const Add = addrtype.map(Add => Add)
  const handleAddrTypeChange = (e) => {
    // console.log((addrtype[e.target.value]))
    props.setYears(this.yearsToDisplay)
    props.setValueOption(addrtype[e.target.value])
  }


  let breadcrumbViews = [
    {
      name: translate("routes_home_label"),
      url: "/admin/dashboard",
      icon: DashboardIcon
    },
    {
      name: translate("routes_user_label"),
      url: "/admin/user/index",
      icon: Person
    },
    {
      name: translate("routes_download_report_label"),
      url: `/admin/user/Download`,
      icon: CloudDone
    }
  ]

  return (
    <>
      <Breadcrumbs views={breadcrumbViews} />
      <Heading
        textAlign="center"
        title={translate("user_download_report_header")}
      />
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <CloudDone />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{translate("user_download_report_header")}</h4>
            </CardHeader>
            <CardBody>
           
              <form>
              <FormControl
                  fullWidth
                  className={classes.selectFormControl}
              >
                  <InputLabel
                      htmlFor="simple-select"
                      // className={classes.selectLabel}
                  >
                      {translate("year")}
                  </InputLabel>                

                       
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={props.valueOption}
                    onChange={e => props.onFieldChange(`roleId`, e.target.value)}
                    inputProps={{
                      name: `roleId`,
                      id: `roleId`
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                    {translate("select_year")}
                    </MenuItem>
                    
                    {yearsToDisplay.map((role, index) => (
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                        value={role}
                        key={index}
                      >
                        {role}
                      </MenuItem>
                    ))}
                  </Select>


              </FormControl>
            </form>

            </CardBody>
            <CardBody>
              
              <div className={classes.center}>
                {props.isLoadingSave ?
                  <CircularProgress color="inherit" /> :
                  <Button color="primary" onClick={props.onSave}>
                    {translate("button_download_report_label")}
                  </Button>
                }
              </div>
                
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
