/*eslint-disable*/
import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
    CircularProgress,
    Select,
    MenuItem
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import { DataTypes } from "components/Constants"
// material ui icons
import Close from "@material-ui/icons/Close";
// -- Extra material UI icons
// material ui icons
import ErrorIcon from "@material-ui/icons/Error";
import Star from "@material-ui/icons/Star";
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputMask from "components/CustomInputMask/CustomInputMask";
import Button from "components/CustomButtons/Button.js";

import { Months, MonthsE } from "components/Constants";
import { formatWIGName } from "utils/dataTypeFormatter";
import { translate, getUserLocale } from "utils/translate";
import moment from "moment";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/WIG/editStyle.js";

const useStyles = makeStyles(styles);

export default function createView(props) {
    let idiom = getUserLocale()
    const [data, setData] = useState(null);
    
    const classes = useStyles();

    function fetchData() {
        moment.locale('es'); // Establece el idioma a español
        var fechaInicial = props.wig !== undefined ?  moment.utc(props.wig.startDate) : null;
        var fechaFinal =  props.wig !== undefined ?  moment.utc(props.wig.endDate).endOf('month') : null;
    
        var mesesDeDiferencia = fechaInicial && fechaFinal ? fechaFinal.diff(fechaInicial, 'months') : null;
    
        const mesesEntreFechas = [];
    
        for (let i = 0; i <= mesesDeDiferencia; i++) {
            const fecha = moment(fechaInicial).add(i, 'months');
            mesesEntreFechas.push(fecha.format('MMMM'));
        }
    
        setData(mesesEntreFechas);
    }
    

    useEffect(() => {
      // Assuming fetchData is a function that returns a Promise
      fetchData()
    }, [props.wig]);


    




    function getFormAxes(index) {
        if (props.wigGoals.axesNumber < index) {
            return (null);
        }
        let width = (props.wigGoals.axesNumber == 2 ? 12 : 12)
        return (
            <GridItem xs={12} sm={12} md={width} mt={index == 2 ? 5 : 0} >
                <GridContainer justify="center">
                    {/* <FormLabel component="legend">{props.wig[`displayName${index}`]}</FormLabel> */}
                    <h3 component="legend">{props.wig[`displayName${index}`]}</h3>
                </GridContainer>
                {/* <GridContainer justify="center"> */}
                {
                    [...Array(12).keys()].map((i) => {
                        let value = true
                        
                        if (data) {
                            if (idiom === "en-US") {
                                data.map((mes) => {
                                    if ( `${MonthsE[`${i + 1}`].toLowerCase()}` == `${mes.toLowerCase()}`){
                                      value = false
                                    }
                                  });
                            } else {
                                data.map((mes) => {
                                    if ( `${Months[`${i + 1}`].toLowerCase()}` == mes) {
                                      value = false
                                    }
                                  });
                            }

                          }
                                // if(meses && meses.length > 0) {
                                // for( index of meses){
                            //     }
                            // }
                        // main goal

                        let fieldName = `${Months[`${i + 1}`].toLowerCase()}${index}`
                        let goal = props.wigGoals[fieldName]
                        let goalState = props.wigGoalsState[fieldName]
                        let goalData = props.wigGoalsData[fieldName]


                        // success goal
                        let fieldName_level3 = `${Months[`${i + 1}`].toLowerCase()}${index}` + '_level3'
                        let goal_level3 = props.wigGoals[fieldName_level3]
                        let goalState_level3 = props.wigGoalsState[fieldName_level3]


                        // danger goal
                        let fieldName_level2 = `${Months[`${i + 1}`].toLowerCase()}${index}` + '_level2'
                        let goal_level2 = props.wigGoals[fieldName_level2]
                        let goalState_level2 = props.wigGoalsState[fieldName_level2]

                        // alert goal
                        let fieldName_level1 = `${Months[`${i + 1}`].toLowerCase()}${index}` + '_level1'
                        let goal_level1 = props.wigGoals[fieldName_level1]
                        let goalState_level1 = props.wigGoalsState[fieldName_level1]


                        return (
                            <GridContainer justify="center" key={i}>
                                {/* Row Label */}
                                <GridItem container alignItems="center" xs={12} sm={12} md={2} lg={1}>
                                    <h4 align="right" mr={5}>{translate(`const_month_${goalData.period}`)}</h4>
                                </GridItem>

                                {/* Check goal */}
                                <GridItem xs={12} sm={12} md={2} lg={3} mr={3} >
                                    <CustomInputMask
                                        success={goalState_level3 === "success"}
                                        desabilitacion={value}
                                        error={goalState_level3 === "error"}
                                        labelText={translate("const_goalexpected")}
                                        id={`inputGoal${index}` + '_level3'}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        suffix={DataTypes[goalData[`dataTypeId`]].suffix}
                                        decimalScale={DataTypes[goalData[`dataTypeId`]].decimalScale}
                                        inputProps={{
                                            onChange: event => {
                                                event.target.value = (event.target.value === "" ? "" : parseFloat(event.target.value))
                                                props.onFieldChange(fieldName_level3, event.target.value);
                                            },
                                            type: "text",
                                            value: goal_level3,
                                            defaultValue: goalData.level3,
                                            endAdornment:
                                                goalState_level3 === "error" ? (
                                                    <InputAdornment position="end">
                                                        <Close className={classes.danger} />
                                                    </InputAdornment>
                                                ) : (
                                                        <InputAdornment position="end">
                                                            <Check className={classes.success} />
                                                        </InputAdornment>
                                                    )
                                        }}
                                    />

                                </GridItem>
                                {/* Warning goal */}
                                <GridItem xs={12} sm={12} md={2} lg={3} mr={3} >
                                    <CustomInputMask
                                    desabilitacion={value}
                                        success={goalState_level2 === "success"}
                                        error={goalState_level2 === "error"}
                                        labelText={translate("const_goalacceptable")}
                                        id={`inputGoal${index}` + '_level2'}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        suffix={DataTypes[goalData[`dataTypeId`]].suffix}
                                        decimalScale={DataTypes[goalData[`dataTypeId`]].decimalScale}
                                        inputProps={{
                                            onChange: event => {
                                                event.target.value = (event.target.value === "" ? "" : parseFloat(event.target.value))
                                                props.onFieldChange(fieldName_level2, event.target.value);
                                            },
                                            type: "text",
                                            value: goal_level2,
                                            defaultValue: goalData.level2,
                                            endAdornment:
                                                goalState_level2 === "error" ? (
                                                    <InputAdornment position="end">
                                                        <Close className={classes.danger} />
                                                    </InputAdornment>
                                                ) : (
                                                        <InputAdornment position="end">
                                                            <Warning className={classes.warning} />
                                                        </InputAdornment>
                                                    )
                                        }}
                                    />

                                </GridItem>
                                {/* Alert goal */}
                                <GridItem xs={12} sm={12} md={2} lg={3} >
                                    <CustomInputMask
                                        desabilitacion={value}
                                        success={goalState_level1 === "success"}
                                        error={goalState_level1 === "error"}
                                        labelText={translate("const_goalfailed")}
                                        id={`inputGoal${index}` + '_level1'}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        suffix={DataTypes[goalData[`dataTypeId`]].suffix}
                                        decimalScale={DataTypes[goalData[`dataTypeId`]].decimalScale}
                                        inputProps={{
                                            onChange: event => {
                                                event.target.value = (event.target.value === "" ? "" : parseFloat(event.target.value))
                                                props.onFieldChange(fieldName_level1, event.target.value);
                                            },
                                            type: "text",
                                            value: goal_level1,
                                            defaultValue: goalData.level1,
                                            endAdornment:
                                                goalState_level1 === "error" ? (
                                                    <InputAdornment position="end">
                                                        <Close className={classes.danger} />
                                                    </InputAdornment>
                                                ) : (
                                                        <InputAdornment position="end">
                                                            <ErrorIcon className={classes.danger2} />
                                                        </InputAdornment>
                                                    )
                                        }}
                                    />

                                </GridItem>
                            </GridContainer>
                        )
                    })

                }
                {/* </GridContainer> */}
            </GridItem>
        );
    }

    return (
        <GridItem xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="center">
                    {props.wigGoals ?
                        <>
                            {getFormAxes(1)}
                            {getFormAxes(2)}
                        </>
                        :
                        null
                    }
                </GridContainer>
            </GridItem>
            <div className={classes.formCategory}>
                <small>*</small> {translate("required_fields")}
            </div>
            <div className={classes.saveCenter}>
                {props.isLoadingSave ?
                    <CircularProgress color="inherit" /> :
                    <Button color="primary" onClick={props.onWIGGoalsSave}>
                        {translate("button_save")}
                    </Button>
                }
            </div>
        </GridItem>

    );
}
