import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import { getAllTeams, deleteTeamById, partialUpdateTeam } from "services/apiAdmin/teamApi";

import IndexView from "./IndexView"

let _isMounted = false

async function getTeams(props) {
	let result = await getAllTeams();
	if (_isMounted && result.success) {
		props.setTeamList(result.data)
	}
}

async function deleteTeam(props, id) {
	props.changeAlertLoading(true)

	let result = await deleteTeamById(id)

	if (result.success) { //result.success
		getTeams(props)
	}

	props.changeAlertLoading(false)
	props.changeAlertVisible(false)
}

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{}
	),
	withState("teamList", "setTeamList", []),
	withState("alertVisible", "changeAlertVisible", false),
	withState("alertId", "changeAlertId", undefined),
	withState("alertLoading", "changeAlertLoading", false),
	withHandlers({
		onModalClosed: (props) => () => {
			props.changeAlertLoading(false)
			props.changeAlertVisible(false)
		},
		onTeamDelete: (props) => (id) => {
			deleteTeam(props, id)
		},
		onTeamChange: (props) => async (field, value, id) => {
			let team = props.teamList.find((team)=>{
				return team.id === id
			})
			team[field] = value

			props.setTeamList(props.teamList)

			let body = {
				...team,
				field: field,
				value: value
			}

			await partialUpdateTeam(team.id, body)
	},
	}),
	lifecycle({
		componentDidMount() {
			_isMounted = true
			getTeams(this.props)

		}
	})
)(IndexView);
