import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { wigUpdateSchema } from "models/wigModel";
import { editWIG, getWIGById,getWIGByTeamIdAndCURRENTYear } from "services/apiAdmin/wigApi";
import { getUserLocale } from "utils/translate";
import { formatWIGName } from "utils/dataTypeFormatter";
import moment from "moment";

import EditView from "./EditView";

const newWIG = {
	verb: "",
	what: "",
	axesNumber: 1,
	x1: "",
	y1: "",
	displayName1: "",
	dataTypeId1: 1,
	x2: "",
	y2: "",
	displayName2: "",
	dataTypeId2: 1,
	description: "",
	year: new Date().getFullYear(),
	parentWig: 0
}

const newWIGState = {
	verb: "",
	what: "",
	axesNumber: "",
	x1: "",
	y1: "",
	displayName1: "",
	dataType1: "",
	x2: "",
	y2: "",
	displayName2: "",
	dataType2: "",
	year: "",
	description: "",
	parentWig: ""
}


let wigId
let _isMounted = false


async function onSubmit(props) {

	
	let result = await editWIG(props.wig, props.wigId)
	// Show confirmation message
	if (result && result.success && _isMounted) {
		props.changeCreateSuccess(true)
		setTimeout(function () {
			if (_isMounted) {
				window.location.href = `/admin/wig/details/${props.wigId}`;
				
			}
		}, 1500);
	} else {
		// Show error message
		if (result) {
			let message = result.message
			if (!message) {
				message = "Algo ocurrió mal, inténtalo nuevamente."
			}
			props.changeValidationMessage(message)
			props.changeCreateError(true)
		}
	}
}


async function getWIG(props) {
	let result = await getWIGById(wigId,0)
	if (result.success) {
		if (_isMounted) {
			props.changeWIG(result.data)
			getParentWIG(props,result.data.parentWig)
		}

	}
}


async function getParentWIGList(props) {
	let idiom = getUserLocale();
	// if team Id is not defined
	if (!props.team.parentTeamId) {
		return
	}
	
	let currentYear = new Date().getFullYear()
	let result = await getWIGByTeamIdAndCURRENTYear(props.team.parentTeamId, currentYear)
	if (result.success) {
		if (_isMounted) 
		{
			let wig
		for (wig of result.data) {
			if(wig.year >=2024){
				if (
					idiom === "es-ES" &&
					wig.endDate != null &&
					wig.startDate != null
				  ) {
					moment.locale('es')
					let mydateStart = moment(wig.startDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
					let mydateEndaDate = moment(wig.endDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
					wig.startDate = mydateStart;
					wig.endDate = mydateEndaDate;
					// let nuevoObject = {
					// 	...wig,
					// 	startDate: mydateStart,
					// 	endDate: mydateStart,
					//   };
			
				  } else if (
					idiom === "en-US" &&
					wig.endDate != null &&
					wig.startDate != null
				  ) {
					moment.locale('en')
					const fechaFormateadaStart = moment
					  .utc(wig.startDate)
					  .format("MMMM DD, YYYY");
					const fechaFormateadaEnd = moment
					  .utc(wig.endDate)
					  .format("MMMM DD, YYYY");

					  wig.startDate = fechaFormateadaStart;
					  wig.endDate = fechaFormateadaEnd;
			
			
				  }
			}
			wig.name = formatWIGName(wig, getUserLocale())
		}
			props.setParentWIGList(result.data)
			
		}
	}
}

async function getParentWIG(props,parentWigID) {
	let idiom = getUserLocale();
	let result = await getWIGById(parentWigID,0)
	if (result.success) {
		if (_isMounted) {
			if(result.data.year >=2024){
				if (
					idiom === "es-ES" &&
					result.data.endDate != null &&
					result.data.startDate != null
				  ) {
					moment.locale('es')
					let mydateStart = moment(result.data.startDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
					let mydateEndaDate = moment(result.data.endDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
					result.data.startDate = mydateStart;
					result.data.endDate = mydateEndaDate;

					let name= formatWIGName(result.data, getUserLocale())
					props.setParentName(name)

				  } else if (
					idiom === "en-US" &&
					result.data.endDate != null &&
					result.data.startDate != null
				  ) {
					moment.locale('en')
					const fechaFormateadaStart = moment
					  .utc(result.data.startDate)
					  .format("MMMM DD, YYYY");
					const fechaFormateadaEnd = moment
					  .utc(result.data.endDate)
					  .format("MMMM DD, YYYY");

					  result.data.startDate = fechaFormateadaStart;
					  result.data.endDate = fechaFormateadaEnd;
					
					  let name= formatWIGName(result.data, getUserLocale())
					  props.setParentName(name)
			
				  }
			}


		}

	}
}

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
			teamId: state.admin.teamId,
			team: state.admin.team,
		}),
		{}
	),
	withState("wigId", "changeWIGId", undefined),
	withState("wig", "changeWIG", { ...newWIG }),
	withState("wigState", "changeWIGState", { ...newWIGState }),
	withState("createSuccess", "changeCreateSuccess", false),
	withState("createError", "changeCreateError", false),
	withState("validationMessage", "changeValidationMessage", ""),
	withState("isLoadingSave", "changeIsLoadingSave", false),
	withState("parentWIGList","setParentWIGList", []),
	withState("parentName","setParentName",""),
	withHandlers({
		onFieldChange: (props) => (field, event) => {
			props.wig[field] = event.target.value
			let isValid = validateProperty(wigUpdateSchema, props.wig, field).isValid
			if (isValid) {
				props.wigState[field] = "success"
			} else {
				props.wigState[field] = "error"
			}
			props.changeWIGState(props.wigState)
			props.changeWIG(props.wig, function () {
			})
		},
		
		onWIGSave: (props) => async () => {
			props.changeIsLoadingSave(true)

			let validation = validateObject(wigUpdateSchema, props.wig)
			if (validation.isValid) {
				await onSubmit(props)
			} else {
				let field
				for (field in props.wig) {
					if (validation.errors[field]) {
						props.wigState[field] = "error"
						props.changeValidationMessage(validation.errors[field][0])
					} else {
						props.wigState[field] = "success"
					}
				}
				props.changeWIGState(props.wigState)

				props.changeCreateError(true)
			}
			props.changeIsLoadingSave(false)
		},
		onGoalsSave: (props) => async (result) => {
			// Show confirmation message
			if (result && result.success && _isMounted) {
				props.changeCreateSuccess(true)
				setTimeout(function () {
					if (_isMounted) {
					
						props.history.push(`/admin/wig/details/${props.wigId}`)
					}
				}, 1500);
			} else {
				// Show error message
				if (result) {
					let message = result.message
					if (!message) {
						message = "Algo ocurrió mal, inténtalo nuevamente."
					}
					props.changeValidationMessage(message)
					props.changeCreateError(true)
				}
			}
		},
		onWIGSaveM: (props) => async () => {
			props.changeCreateSuccess(false);
			window.location.href = `/admin/wig/details/${props.wigId}`;
		}
		
		
		
	}),
	lifecycle({
		componentDidMount() {
			_isMounted = true

			wigId = this.props.match.params.id
			this.props.changeWIGId(wigId)
			getWIG(this.props)

			this.props.changeWIGState({ ...newWIGState })
			this.props.changeCreateSuccess(false)
			getParentWIGList(this.props)
	
		},
		componentWillUnmount() {
			_isMounted = false
		}
	})
)(EditView);
