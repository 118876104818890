import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
// @material-ui/core components
import Loader from "components/Loader/Loader.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import store from "./store";
import App from "./components/AppContainer";

// Translation functions
import { IntlProvider } from 'react-intl';
import { getUserLocale, getMessages } from 'utils/translate';

const hist = createBrowserHistory();


const userLocale = getUserLocale();

getMessages().then(messages => {
  ReactDOM.render(
    <Router history={hist}>
      <Provider store={store().store}>
        <PersistGate loading={<Loader />} persistor={store().persistor}>
          <IntlProvider locale={userLocale} messages={messages}>
            <App />
          </IntlProvider>,
        </PersistGate>
      </Provider>
    </Router>,
    document.getElementById("root")
  );
});