import { compose, withState,withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { getExternalCommitments } from "services/apiAdmin/commitmentApi";
import {  formatDate,getInputFormatDate } from "utils/functions";
import { getCommitmentsById, getClearPathById, getCommitmentTrackingById} from "services/apiAdmin/commitmentApi";
import {  getWIGById } from "services/apiAdmin/wigApi";
import { translate } from "utils/translate"
import { getUserLocale } from "utils/translate";
import TrackingView from "./TrackingView"

let _isMounted = false
const newCommitment = {
	wigId: "",
	title: "",
	action: "",
	impact: "",
	when: getInputFormatDate(new Date),
	hasDependency: false,
	internalDependency: false,
	dependencyUser: "",
	applyForAllMembers: false,
	assignedUser: "",
	firstName : " ",
	lastName: " "

}


async function getCommitments(props) {
	let result = await getExternalCommitments()
	if (_isMounted && result.success) {
		props.setCommitmentList(result.data)
	}
}

async function getCommitment(props,teamId,commitmentId) {
	let result = await  getCommitmentsById(teamId,commitmentId);
	if (_isMounted && result.success) {
		result.data.when = formatDate (result.data.when,getUserLocale());
		if(result.data.hasDependency == 1){
			getClearPath(props,teamId,result.data.id);
		}
		result.data.hasDependency= (result.data.hasDependency ? translate("commitment_dependency_true") : translate("commitment_dependency_false"));
		getWIG(props,result.data.wigId);
		props.setCommitment(result.data)
	}
}

async function getClearPath(props, teamId, commitmentId){
	let result = await getClearPathById(teamId,commitmentId);
	if(_isMounted && result.success){
		props.setClearPath(result.data);
		props.setClearPathLabel(translate("commitment_autocomplete_input"));
	}

}
async function getWIG(props, wigId){
	let result = await  getWIGById(wigId,0);
	if(_isMounted && result.success){
		props.setWig(result.data.name);
	}
}

async function getTracking(props, teamId, commitmentId) {
	let result = await getCommitmentTrackingById(teamId,commitmentId);
	if (_isMounted && result.success) {
		props.setCommitmentList(result.data)
	}
}
export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{  }
	),
    withState("commitment", "setCommitment", { ...newCommitment }),
	withState("clearpath", "setClearPath", { ...newCommitment }),
	withState("commitmentList", "setCommitmentList", []),
	withState("wig","setWig", ""),
	withState("clearpathlabel", "setClearPathLabel", " "),
	withState("trackingList", "setTrackingList", []),
    withHandlers({
        
    }),
	lifecycle({
		componentDidMount() {
			_isMounted = true
            let commitmentId = this.props.match.params.id;
            getCommitment(this.props, this.props.teamId, commitmentId);
			//getCommitments(this.props)
			getTracking(this.props,this.props.teamId,commitmentId);
		}
	})
)(TrackingView);