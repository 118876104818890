import { User, ResponseMessages, LoginUser } from "components/Constants";
import { signOut } from "store/LoginState";
import store from "store";
import axios from "axios";

/*
Used to create user:
Retuns the id of the response
*/
async function createUser(newUser) {
    try {
        let url = `${User.GetEndPoint}`
        let requestHeaders = createHeaders()
        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: newUser
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`createUser returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for createUser`)

            // If it's a success response
            if (response.status === 201) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of users
*/
async function getAllUsers() {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${User.GetEndPoint}`,
            headers: requestHeaders,
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getAllUsers returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the user info
*/
async function getUser(userId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${User.GetEndPoint}`,
            headers: requestHeaders,
            params: { userId: userId }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getAllUsers returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the user info
*/
async function getUserByMail(userMail) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${LoginUser.UserByMail}`,
            headers: requestHeaders,
            params: { email: userMail }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getByMail returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Used to update a user:
Retuns the id of the response
*/
async function updateUser(userId, userBody) {
    try {
        let url = `${User.GetEndPoint}/${userId}`
        let requestHeaders = createHeaders()
        let response = await axios({
            method: "put",
            url: url,
            headers: requestHeaders,
            data: userBody
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`updateUser returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for updateUser`)

            // If it's a success response
            if (response.status === 200) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

function createHeaders() {
    let headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
        "nonce": localStorage.getItem('nonce')
    }

    return headers
}


/*
Used to delete a team:
Retuns the id of the response
*/
async function deleteUserById(userId) {
    try {
        let url = `${User.GetEndPoint}/${userId}`
        let requestHeaders = createHeaders()



        let response = await axios({
            method: "delete",
            url: url,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`deleteTeam returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for delete User`)

            // If it's a success response
            if (response.status === 200) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }

    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

async function partialUpdateUser(userId, userBody) {
    try {
        let url = `${User.GetEndPoint}/${userId}`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "patch",
            url: url,
            headers: requestHeaders,
            data: userBody
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`updateTeam returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for updateTeam`)

            // If it's a success response
            if (response.status === 200) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of superadmin users
*/
async function getSuperadminUsers() {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${User.GetEndPoint}/superadmin`,
            headers: requestHeaders,
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getAllUsers returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Save User´s Picture
*/
async function SaveImage(imagenBase64, userId) {
    
    try {
        let url = `${User.SaveImage}`
        let requestHeaders = createHeaders()
        
        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: {"archivo": imagenBase64,
                    "id" : userId}
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`SaveImage returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for createUser`)

            // If it's a success response
            if (response.status === 201) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
    
}

async function getBase64async(file){

    return new Promise((resolve, reject) => {

      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.onload = () => resolve(reader.result)

      reader.onerror = error => reject(error)

    })

  }

 

export { createUser, getAllUsers, updateUser, partialUpdateUser, getUser, deleteUserById, getUserByMail, getSuperadminUsers ,getBase64async, SaveImage}

