import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

import { filterCaseInsensitive } from "utils/functions";
import { Roles } from "components/Constants";

import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import { CircularProgress, Grid } from "@material-ui/core";
// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import Edit from "@material-ui/icons/Edit";
import Person from "@material-ui/icons/Person";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Close from "@material-ui/icons/Close";
import {CloudDone} from "@material-ui/icons";
// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { NavLink } from "react-router-dom";

// Functions
import { translate } from "utils/translate"

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  addButton: {
    marginTop: "-25px",
    float: "right"
  }
};

const useStyles = makeStyles(styles);

export default function ReactTables(props) {
  const classes = useStyles();

  const getTeam = (id) => {
    let team = props.userList.find(function (team) {
      return team.id === id
    })
    return translate("user_deleted")
  }
  
  let dataTableInfo = props.userList.map((prop) => {
    return {
      id: prop.id,
      name: `${prop.firstName} ${prop.lastName}`,
      email: prop.email,
      role: Roles[prop.roleId],
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          <NavLink
            to={`/admin/user/edit/${prop.userId}`}
          >
            <Tooltip title={translate("user_edit_tooltip")}>
              <Button
                color={"warning"}
                simple
                className={classes.actionButton}
              >
                <Edit className={classes.icon} />
              </Button>
            </Tooltip>
          </NavLink>
          <Tooltip title={translate("user_index_delete_tooltip")}>
            <Button
              color={"danger"}
              simple
              disabled={props.status === 1}
              className={classes.actionButton}
              //onClick={() => { if (prop.status === 1) { alertWithInput(prop.id) } }}
              onClick={() => alertWithInput(prop.userId)}
            >
              <Close className={classes.icon} />
            </Button>
          </Tooltip>
        </div>
      )
    }
  })

  const alertWithInput = (id) => {
    props.changeAlertId(id)
    props.changeAlertVisible(true)
  }

  const alert = () => {
    let alertMessage = (null)
    if (props.alertVisible) {
      alertMessage = (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={translate("alert_user_delete_title")}
          onConfirm={() => props.onTeamDelete(props.alertId)}
          onCancel={() => props.onModalClosed()}
          confirmBtnCssClass={(props.alertLoading ? classes.disabled : "") + " " + classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={translate("alert_team_delete_confirm")}
          cancelBtnText={translate("alert_team_delete_cancel")}
          showCancel
          disabled={props.alertLoading}
        >
          {
            getTeam(props.alertId)
          }
          {props.alertLoading ?
            <GridContainer justify="center">
              <Grid>
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
            </GridContainer>
            :
            null
          }
        </SweetAlert>
      )
    }
    return (alertMessage)
  }

  let breadcrumbViews = [
    {
      name: translate("routes_home_label"),
      url: "/admin/dashboard",
      icon: DashboardIcon
    },
    {
      name: translate("routes_user_label"),
      url: "/admin/user/index",
      icon: Person
    }
  ]

  return (
    <>
      {alert()}
      <Breadcrumbs views={breadcrumbViews} />
      <Heading
        textAlign="center"
        title={translate("user_index_header")}
      />
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Person />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{translate("user_index_title")}</h4>
              <NavLink
                to={`/admin/user/create`}>
                <Tooltip title={translate("user_add_tooltip")}>
                  <Button color="primary" className={classes.addButton}>
                    <PersonAdd className={classes.icons} />
                  </Button>
                </Tooltip>
              </NavLink>
              <NavLink
                to={`/admin/user/Download`}>
                <Tooltip title={translate("user_download_report_tooltip")}>
                  <Button color="primary" className={classes.addButton}>
                  <CloudDone className={classes.icons} />
                  </Button>
                </Tooltip>
              </NavLink>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={dataTableInfo}
                filterable
                defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                columns={[
                  {
                    Header: translate("table_header_name"),
                    accessor: "name"
                  },
                  {
                    Header: translate("table_header_mail"),
                    accessor: "email"
                  },
                  {
                    Header: translate("table_header_role"),
                    accessor: "role"
                  },
                  {
                    Header: translate("table_header_actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                minRows={0}
                showPaginationBottom={false}
                className="-striped -highlight"
                previousText={translate("table_previous_text")}
                nextText={translate("table_next_text")}
                loadingText={translate("table_loading_text")}
                noDataText={translate("table_not_found_text")}
                pageText={translate("table_page_text")}
                ofText={translate("table_of_text")}
                rowsText={translate("table_rows_text")}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
