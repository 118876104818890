import { DataTypes } from "components/Constants"
import moment from "moment";
import {
    dangerColor,
    warningColor,
    successColor,
    infoColor
} from "assets/jss/material-dashboard-pro-react.js";

import { getUserLocale } from "utils/translate";
/**
 * Validates an object according to the model provided
 */
function formatValue(value, dataType) {
    let valueResult = value
    let format = DataTypes[dataType]
    if (!format) {
        format = DataTypes["1"]
    }
    // Add prefix
    if (format.prefix) {
        valueResult = format.prefix + valueResult
    }
    // Add suffix
    if (format.suffix) {
        if (format.suffix == "Q" || format.suffix == "$" ) {
            valueResult = `${format.suffix}.${valueResult}`  
        } else {
            valueResult = valueResult + format.suffix
        }
        
    }
    return valueResult
}

/**
 * Returns the full name of a wig giving the correct format to each amount
 */
function formatWIGName(wig, locale) {
    if (wig.year >= 2024) {
        let x = formatValue(wig.x1, wig.dataTypeId1)
        let y = formatValue(wig.y1, wig.dataTypeId1)
        let x2 = formatValue(wig.x2, wig.dataTypeId2)
        let y2 = formatValue(wig.y2, wig.dataTypeId2)
        let fullName = ""

        if (wig.axesNumber === 1) {
            if (locale === "en-US") {
                const startDateValue =  moment(wig.startDate).format('MMMM DD');
                const endDateValue =  moment(wig.endDate).format('MMMM DD');
                fullName = `${wig.verb} ${wig.what} from ${x} to ${y} from ${startDateValue} to ${endDateValue}, ${wig.year}`
                
            } else {
                moment.lang('es', {
                    months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
                    monthsShort: 'enero._feb._mar_abr._may_jun_jul._ago_sept._oct._nov._dec.'.split('_'),
                    weekdays: 'domingo_lunes_martes_miercoles_jueves_viernes_sabado'.split('_'),
                    weekdaysShort: 'dom._lun._mar._mier._jue._vier._sab.'.split('_'),
                    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
                }
                );
                let startMoment =  moment(wig.startDate, 'DD MMMM, YYYY').format('DD MMMM');
                let startMomentEndDate =  moment(wig.endDate, 'DD MMMM, YYYY').format('DD MMMM');
                fullName = `${wig.verb} ${wig.what} de ${x} a ${y} desde ${startMoment} al ${startMomentEndDate} del ${wig.year}`
    
            }
        } else {
            if (locale === "en-US") {
                const startDateValue =  moment(wig.startDate).format('MMMM DD');
                const endDateValue =  moment(wig.endDate).format('MMMM DD');
                fullName = `${wig.verb} ${wig.what} from ${x} to ${y} and ${wig.verbTwo} ${wig.whatTwo} from ${x2} to ${y2} from ${startDateValue} to ${endDateValue}, ${wig.year}`
                
            } else {
                moment.lang('es', {
                    months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
                    monthsShort: 'enero._feb._mar_abr._may_jun_jul._ago_sept._oct._nov._dec.'.split('_'),
                    weekdays: 'domingo_lunes_martes_miercoles_jueves_viernes_sabado'.split('_'),
                    weekdaysShort: 'dom._lun._mar._mier._jue._vier._sab.'.split('_'),
                    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
                }
                );
                let startMoment =  moment(wig.startDate, 'DD MMMM, YYYY').format('DD MMMM');
                let startMomentEndDate =  moment(wig.endDate, 'DD MMMM, YYYY').format('DD MMMM');
                fullName = `${wig.verb} ${wig.what} de ${x} a ${y} y ${wig.verbTwo} ${wig.whatTwo} de ${x2} a ${y2} desde ${startMoment} al ${startMomentEndDate} del ${wig.year}`
    
            }
        }
        return fullName
    } else{
        let x = formatValue(wig.x1, wig.dataTypeId1)
        let y = formatValue(wig.y1, wig.dataTypeId1)
        let fullName = ""

        if (locale === "en-US") {
            fullName = `${wig.verb} ${wig.what} from ${x} to ${y} by December 31 ${wig.year}`
        } else {
            fullName = `${wig.verb} ${wig.what} de ${x} a ${y} para el 31 de Diciembre de ${wig.year}`
        }

        return fullName
    }
    
}

function formatWIGEditName(wig, locale) {
    if (wig.year >= 2024) {
        let x = formatValue(wig.x1, wig.dataTypeId1)
        let y = formatValue(wig.y1, wig.dataTypeId1)

        let fullName = ""
        if (wig.axesNumber === 1) {
            if (locale === "en-US") {
                const startDateValue =  moment.utc(wig.startDate).format('MMMM DD');
                const endDateValue =  moment.utc(wig.endDate).format('MMMM DD');
                fullName = `${wig.verb} ${wig.what} from ${x} to ${y} from ${startDateValue} to ${endDateValue}, ${wig.year}`
            } else {
                moment.lang('es', {
                    months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
                    monthsShort: 'enero._feb._mar_abr._may_jun_jul._ago_sept._oct._nov._dec.'.split('_'),
                    weekdays: 'domingo_lunes_martes_miercoles_jueves_viernes_sabado'.split('_'),
                    weekdaysShort: 'dom._lun._mar._mier._jue._vier._sab.'.split('_'),
                    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
                }
                );
                let startMoment =  moment(wig.startDate, 'YYYY-MM-DD').format('DD MMMM');
                let startMomentEndDate =  moment(wig.endDate, 'YYYY-MM-DD').format('DD MMMM');
    
    
                fullName = `${wig.verb} ${wig.what} de ${x} a ${y} desde ${startMoment} al ${startMomentEndDate} del ${wig.year}`
            }
        } else {
            let x2 = formatValue(wig.x2, wig.dataTypeId2)
            let y2 = formatValue(wig.y2, wig.dataTypeId2)

            if (locale === "en-US") {
                const startDateValue =  moment.utc(wig.startDate).format('MMMM DD');
                const endDateValue =  moment.utc(wig.endDate).format('MMMM DD');
                fullName = `${wig.verb} ${wig.what} from ${x} to ${y} and ${wig.verbTwo} ${wig.whatTwo} from ${x2} to ${y2} from ${startDateValue} to ${endDateValue}, ${wig.year}`
            } else {
                moment.lang('es', {
                    months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
                    monthsShort: 'enero._feb._mar_abr._may_jun_jul._ago_sept._oct._nov._dec.'.split('_'),
                    weekdays: 'domingo_lunes_martes_miercoles_jueves_viernes_sabado'.split('_'),
                    weekdaysShort: 'dom._lun._mar._mier._jue._vier._sab.'.split('_'),
                    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
                }
                );
                let startMoment =  moment(wig.startDate, 'YYYY-MM-DD').format('DD MMMM');
                let startMomentEndDate =  moment(wig.endDate, 'YYYY-MM-DD').format('DD MMMM');
    
    
                fullName = `${wig.verb} ${wig.what} de ${x} a ${y} y ${wig.verbTwo} ${wig.whatTwo} de ${x2} a ${y2} desde ${startMoment} al ${startMomentEndDate} del ${wig.year}`
            }
        }

        return fullName
    } else{
        let x = formatValue(wig.x1, wig.dataTypeId1)
        let y = formatValue(wig.y1, wig.dataTypeId1)
        let fullName = ""

        if (locale === "en-US") {
            fullName = `${wig.verb} ${wig.what} from ${x} to ${y} by December 31 ${wig.year}`
        } else {
            fullName = `${wig.verb} ${wig.what} de ${x} a ${y} para el 31 de Diciembre de ${wig.year}`
        }

        return fullName
    }
    
}

/**
 * Returns the full name of a predictive giving the correct format to each amount
 */
function formatPredictiveName(predictive) {
    let fullName = ""
    let consistency = ""
    let consistencyTwo = ""
    let y = formatValue(predictive.y1, predictive.dataTypeId1)
    let yTwo = formatValue(predictive.y2, predictive.dataTypeId2)
    if (getUserLocale() === "en-US") {
        if (predictive.consistency === "diario") {
            consistency = "daily"
        } else if (predictive.consistency === "semanal") {
            consistency = "weekly"
        } else{
            consistency = predictive.consistency
        }

        if (predictive.consistencyTwo === "diario") {
            consistencyTwo = "daily"
        } else if (predictive.consistencyTwo === "semanal") {
            consistencyTwo = "weekly"
        } else{
            consistencyTwo = predictive.consistencyTwo
        }
    } else {
        if (predictive.consistency === "daily") {
            consistency = "diario"
        } else if (predictive.consistency === "weekly") {
            consistency = "semanal"
        }else{
            consistency = predictive.consistency
        }

        if (predictive.consistencyTwo === "daily") {
            consistencyTwo = "diario"
        } else if (predictive.consistencyTwo === "weekly") {
            consistencyTwo = "semanal"
        }else{
            consistencyTwo = predictive.consistencyTwo
        }
    }
    if (yTwo != null) {
         fullName = `${predictive.verb} ${y} ${predictive.focus} ${consistency} ${predictive.quality}, ${predictive.verbTwo != null ? predictive.verbTwo : ""} ${yTwo} ${predictive.focusTwo != null ? predictive.focusTwo : ""} ${consistencyTwo != null ? consistencyTwo : ""} ${predictive.qualityTwo != null ? predictive.qualityTwo : ""}`
    }
    else {
         fullName = `${predictive.verb} ${y} ${predictive.focus} ${consistency} ${predictive.quality}`
    }
    return fullName
}

/**
 * Gets a color value according to the values
 */
function getBarColor(entry) {
    const COLORS = [infoColor[0], successColor[0], warningColor[1], dangerColor[0]];

    let color
    if (entry.dir === 1) {
        if (entry.goalAchived >= entry.level3) {
            color = COLORS[1]
        } else if (entry.goalAchived >= entry.level2) {
            color = COLORS[2]
        } else {
            color = COLORS[3]
        }
    } else {        
        if (entry.level1 === 0 && entry.level2 === 0 && entry.level3 === 0) {
            color = COLORS[1]
        } else if (entry.goalAchived <= entry.level3) {
            color = COLORS[1]
        } else if (entry.goalAchived <= entry.level2) {
            color = COLORS[2]
        } else if (entry.goalAchived <= entry.level1 || entry.goalAchived >= entry.level1) {
            color = COLORS[3]
        }else{
            color = COLORS[3]
        } 
    }

    return color
}

/**
 * Gets a color value according to the values
 */
function getCommitmentColor(status) {
    const COLORS = [warningColor[1], successColor[0], dangerColor[0]]

    return COLORS[status - 1]
}

/**
 * Gets display name from a data type
 */
function getDataTypeDisplayName(datatypeId) {
    return DataTypes[datatypeId].displayName
}

function languageDays(propsDay){
    let arrayDaysEn = [
            `All`,
            `Monday`,
            `Tuesday`,
            `Wednesday`,
            `Thursday`,
            `Friday`,
            `Saturday`,
            `Sunday`
    ]
    let arrayDaysEs = [
            `Todos`,
            `Lunes`,
            `Martes`,
            `Miércoles`,
            `Jueves`,
            `Viernes`,
            `Sabado`,
            `Domingo`
    ]

    let day = ""
    if(getUserLocale() === "en-US"){
       for (let index = 0; index < arrayDaysEn.length; index++) {
            if (propsDay == arrayDaysEn[index]) {
                day = arrayDaysEn[index]
            } else if (propsDay == arrayDaysEs[index]) {
                day = arrayDaysEn[index]
            }
       }
    } else{
        for (let index = 0; index < arrayDaysEs.length; index++) {
            if (propsDay == arrayDaysEn[index]) {
                day = arrayDaysEs[index]
            } else if (propsDay == arrayDaysEs[index]) {
                day = arrayDaysEs[index]
            }
        }
    }

    return day
}

export { formatValue, getBarColor, formatWIGName, formatPredictiveName, getCommitmentColor, getDataTypeDisplayName, languageDays, formatWIGEditName }