import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateObject, validateProperty } from "utils/validator";
import { predictiveUpdateSchema } from "models/predictiveModel";
import {
  getPredictiveById,
  editPredictive,
} from "services/apiAdmin/predictiveApi";
import { getWIGById } from "services/apiAdmin/wigApi";
import { formatWIGName } from "utils/dataTypeFormatter";
import { getUserLocale } from "utils/translate";
import EditView from "./EditView";
import moment from "moment";

let predictiveId = 0;

let newPredictive = {
  wigId: "",
  verb: "",
  what: "",
  focus: "",
  quality: "",
  consistency: "",
  verbTwo: "",
  focusTwo: "",
  qualityTwo: "",
  consistencyTwo: "",
  description: "",
  axesNumber: 1,
  dataTypeId1: 1,
  displayName1: "",
  dir1: 1,
  x1: "",
  y1: "",
  dataTypeId2: 1,
  dir2: 1,
  x2: "",
  y2: "",
  displayName2: "",
  activeOne: true,
  activeTwo: true,
};

const newPredictiveState = {
  wigId: "",
  verb: "",
  what: "",
  focus: "",
  quality: "",
  consistency: "",
  verbTwo: "",
  focusTwo: "",
  qualityTwo: "",
  consistencyTwo: "",
  description: "",
  axesNumber: "",
  dataTypeId1: "",
  dir1: "",
  x1: "",
  y1: "",
  displayName1: "",
  dataTypeId2: "",
  dir2: "",
  x2: "",
  y2: "",
  displayName2: "",
  activeOne: true,
  activeTwo: true,
};

let _isMounted = false;

async function getWIG(props, wigId) {
  let result = await getWIGById(wigId, 0);
  let idiom = getUserLocale();
  if (result.success) {
    if (_isMounted) {
      if (
        idiom === "es-ES" &&
        result.data.endDate != null &&
        result.data.startDate != null
      ) {
        moment.locale('es')
        let mydateStart = moment(result.data.startDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
        let mydateEndaDate = moment(result.data.endDate, 'YYYY-MM-DD').format('DD MMMM, YYYY');
        if (result.data.x2 != null) {
          let nuevoObject = {
            ...result.data,
            startDate: mydateStart,
            endDate: mydateEndaDate,
          };
          nuevoObject.name = formatWIGName(nuevoObject, getUserLocale());
          props.changeWIG(nuevoObject);
        } else {
          let nuevoObject = {
            ...result.data,
            startDate: mydateStart,
            endDate: mydateEndaDate,
            dataTypeId2: 1
          };
          nuevoObject.name = formatWIGName(nuevoObject, getUserLocale());
          props.changeWIG(nuevoObject);
        }

      } else if (
        idiom === "en-US" &&
        result.data.endDate != null &&
        result.data.startDate != null
      ) {
        moment.locale('en')
        const fechaFormateadaStart = moment
          .utc(result.data.startDate)
          .format("MMMM DD, YYYY");
        const fechaFormateadaEnd = moment
          .utc(result.data.endDate)
          .format("MMMM DD, YYYY");
        if (result.data.x2 != null) {
          let nuevoObject = {
            ...result.data,
            startDate: fechaFormateadaStart,
            endDate: fechaFormateadaEnd,
          };
          nuevoObject.name = formatWIGName(nuevoObject, getUserLocale());
          props.changeWIG(nuevoObject);
        } else {
          let nuevoObject = {
            ...result.data,
            startDate: fechaFormateadaStart,
            endDate: fechaFormateadaEnd,
            dataTypeId2: 1
          };
          nuevoObject.name = formatWIGName(nuevoObject, getUserLocale());
          props.changeWIG(nuevoObject);
        }


      } else {
        let nuevoObject = {
          ...result.data,
          endDate: new Date(),
          startDate: new Date(),
          verbTwo: "",
          whatTwo: "",
        }
        nuevoObject.name = formatWIGName(nuevoObject, getUserLocale());
        props.changeWIG(nuevoObject);
      }
    }
  }
}

async function getPredictive(props) {
  let result = await getPredictiveById(predictiveId);
  if (result.success) {
    if (_isMounted) {
      props.changePredictive(result.data);

      getWIG(props, result.data.wigId);
    }
  }
}

async function onSubmit(props) {
  let aggIdionPropsPre = {
    ...props.predictive,
    idiom: getUserLocale(),
  };

  if (
    props.predictive.axesNumber === 2 &&
    props.predictive.activeOne === false &&
    props.predictive.activeTwo === false
  ) {
    let message =
      aggIdionPropsPre.idiom === "es-ES"
        ? "No se pueden desactivar ambos ejes a la vez"
        : "Both axis cannot be disable at the same time";
    props.changeValidationMessage(message);
    props.changeCreateError(true);
  } else {
    let result = await editPredictive(props.predictive, props.predictiveId);
    // Show confirmation message
    if (result && result.success && _isMounted) {
      props.changeCreateSuccess(true);
      setTimeout(function() {
        props.history.push(`/admin/wig/details/${props.wig.id}`);
      }, 1500);
    } else {
      // Show error message
      if (result) {
        let message = result.message;
        if (!message) {
          message = "Algo ocurrió mal, inténtalo nuevamente.";
        }
        props.changeValidationMessage(message);
        props.changeCreateError(true);
      }
    }
  }
}

export default compose(
  connect(
    (state) => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
    }),
    {}
  ),
  withState("predictive", "changePredictive", { ...newPredictive }),
  withState("predictiveState", "changePredictiveState", {
    ...newPredictiveState,
  }),
  withState("createSuccess", "changeCreateSuccess", false),
  withState("createError", "changeCreateError", false),
  withState("validationMessage", "changeValidationMessage", ""),
  withState("isLoadingLogin", "changeIsLoadingLogin", false),
  withState("wig", "changeWIG", { name: "..." }),
  withState("predictiveId", "changePredictiveId", undefined),
  withHandlers({
    onFieldChange: (props) => (field, event, ...extraFields) => {
      if (field == "activeTwo" || field == "activeOne") {
        props.predictive[field] = !event;
      } else {
        props.predictive[field] = event.target.value;
      }
      let isValid = validateProperty(
        predictiveUpdateSchema,
        props.predictive,
        field
      ).isValid;
      if (isValid) {
        props.predictiveState[field] = "success";
      } else {
        props.predictiveState[field] = "error";
      }

      //Validate extra fields
      if (extraFields) {
        extraFields.forEach((attr) => {
          // if has a state, then validate it
          if (props.predictiveState[attr] !== "") {
            isValid = validateProperty(
              predictiveUpdateSchema,
              props.predictive,
              attr
            ).isValid;
            if (isValid) {
              props.predictiveState[attr] = "success";
            } else {
              props.predictiveState[attr] = "error";
            }
          }
        });
      }

      props.changePredictiveState(props.predictiveState);
      props.changePredictive(props.predictive);
    },
    onPredictiveSave: (props) => async () => {
      props.changeIsLoadingLogin(true);
      let validation = validateObject(predictiveUpdateSchema, props.predictive);
      if (validation.isValid) {
        await onSubmit(props);
      } else {
        let field;
        for (field in props.predictive) {
          if (validation.errors[field]) {
            props.predictiveState[field] = "error";
            props.changeValidationMessage(validation.errors[field][0]);
          }
        }
        props.changePredictiveState(props.predictiveState);
        props.changeCreateError(true);
      }
      props.changeIsLoadingLogin(false);
    },
  }),
  lifecycle({
    componentDidMount() {
      predictiveId = this.props.match.params.id;
      this.props.changePredictiveId(predictiveId);

      newPredictive.axesNumber = 1;
      this.props.changePredictive({ ...newPredictive });
      this.props.changePredictiveState({ ...newPredictiveState });
      _isMounted = true;
      getPredictive(this.props);
    },
    componentWillUnmount() {
      _isMounted = false;
    },
  })
)(EditView);
