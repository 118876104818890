import React from "react";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { CircularProgress, Grid } from "@material-ui/core";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import logo from "../../assets/img/alliedGlobal-01.png";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";


import { translate } from "utils/translate"


//import LoginImage from "./LoginImage.png";
const useStyles = makeStyles(styles);



const LoginPage = ({ ...props }) => {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  return (
    <div>
      <div className={classes.wrapper}>
        <div
          className={classes.fullPage}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              {props.isLoadingLogin ?
                <Grid container className={classes.progressContainer}>
                  <div className={classes.circularProgress}>
                    <CircularProgress color="inherit" />
                  </div>
                </Grid> :
                <GridItem xs={12} sm={6} md={4}>
                  <form>
                    <Card login className={classes[cardAnimaton]}>
                      <CardHeader
                        className={`${classes.cardHeader} ${classes.textCenter}`}
                        color="primary"
                      >
                        <h2 className={classes.cardTitle}>MY4DX</h2>
                        {/* <h4 className={classes.cardTitle}>Allied Global</h4> */}
                        <a
                          target="_blank"
                          >
                          <img src={logo} alt="logo" className={classes.logo}/>
                        </a>
                      </CardHeader>
                      
                      <CardFooter className={classes.justifyContentCenter}>
                        <Button color="primary" simple size="lg" block
                          onClick={props.handleLoginButtonClick}>
                          {translate("login")}
                        </Button>
                      </CardFooter>
                    </Card>
                  </form>
                  <SweetAlert
                    show={props.isUnauthorizedLogin}
                    title="Acceso no autorizado"
                    onConfirm={() => props.handleAunathorizedLogin()}
                    confirmBtnCssClass={classes.button + " " + classes.danger}
                  />
                </GridItem>
              }
            </GridContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage