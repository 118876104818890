import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Explore from "@material-ui/icons/Explore";

import DashboardIcon from "@material-ui/icons/Dashboard";
// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import { getUserLocale } from "utils/translate";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

//functions
import { formatDate, filterCaseInsensitive } from "utils/functions";
import { CommitmentStatus } from "components/Constants";
import { getCommitmentColor } from "utils/dataTypeFormatter";
import { translate } from "utils/translate"


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function ReactTables(props) {
  const classes = useStyles();

  let dataTableInfo = props.commitmentList.map((prop) => {
    let colorStatus = getCommitmentColor(prop.status)
    return {
      id: prop.id,
      createdAt: formatDate(prop.createdAt,getUserLocale()),
      commitment: prop.title,
      createdBy: `${prop.creatorFirstName} ${prop.creatorLastName} (${prop.creatorEmail})`,
      status: translate(`const_commitment_status_${prop.status}`),
      color: colorStatus
    }
  })

  let breadcrumbViews = [
    {
      name: translate("routes_home_label"),
      url: "/admin/dashboard",
      icon: DashboardIcon
    },
    {
      name: translate("routes_clear_path_label"),
      url: "/admin/commitment/external",
      icon: Explore
    }
  ]

  return (
    <>
      <Breadcrumbs views={breadcrumbViews} />
      <Heading
        textAlign="center"
        title={translate("clear_path_title")}
      />
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Explore />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{translate("routes_clear_path_label")}</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={dataTableInfo}
                filterable
                defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                columns={[
                  {
                    Header: translate("table_header_date"),
                    accessor: "createdAt"
                  },
                  {
                    Header: translate("table_header_commitment"),
                    accessor: "commitment"
                  },
                  {
                    Header: translate("table_header_who"),
                    accessor: "createdBy"
                  },
                  {
                    Header: translate("table_header_status"),
                    accessor: "status",
                    getProps: (state, rowInfo) => {
                      return {
                        style: {
                          color: rowInfo.row._original.color
                        },
                      };
                    }
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                minRows={0}
                showPaginationBottom={false}
                className="-striped -highlight noActions"
                previousText={translate("table_previous_text")}
                nextText={translate("table_next_text")}
                loadingText={translate("table_loading_text")}
                noDataText={translate("table_not_found_text")}
                pageText={translate("table_page_text")}
                ofText={translate("table_of_text")}
                rowsText={translate("table_rows_text")}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
