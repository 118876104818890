import { compose, withState, withHandlers, lifecycle } from "recompose";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Auth from "authentication/Auth";
import { getUserByMail } from "../../services/apiUser/userApi"

import LoginView from "./LoginView";
import { loginUser, resetError, signOut } from "store/LoginState";
import { LoginUser } from "components/Constants"

async function getNonce(props, auth) {
	props.changeIsLoadingLogin(true);
	var myHeaders = new Headers();
	myHeaders.append('Content-Type', 'application/json')
	myHeaders.append('Authorization', `Bearer ${localStorage.getItem('access_token')}`)
	let userInfo = JSON.parse(localStorage.getItem('userInfo'))
	let nickname = await getUserByMail(userInfo.email)
	if(nickname.data[0].status === 30)
		auth.logout()
	fetch(LoginUser.InsertUserUrl, 
		{ 
			method: 'POST', 
			headers: myHeaders, 
			body: JSON.stringify(userInfo) 
		}).then((response) => {
			return response.json()
		})
		.then((loginResponse) => {
			let payload = loginResponse.payload
			localStorage.setItem('nonce', payload.nonce)
			props.loginUser(localStorage.getItem('nonce'), userInfo.name || userInfo.given_name || userInfo.family_name)
		})
}

export default compose(
	connect(
		state => ({
			isLoading: state.login.isLoading,
			isAuthenticated: state.login.isAuthenticated,
			error: state.login.error
		}),
		{ loginUser, resetError, signOut }
	),
	withRouter,
	withState("isLoadingLogin", "changeIsLoadingLogin", true),
	withState("isUnauthorizedLogin", "changeUnauthorizedLogin", false),
	withHandlers({
		handleLoginButtonClick: props => () => {
			props.changeIsLoadingLogin(true);
			const auth = new Auth();
			auth.login()
		},
		handleAunathorizedLogin: props => () => {
			props.changeUnauthorizedLogin(false)
			props.changeIsLoadingLogin(true)
			props.signOut()
		}
	}),
	lifecycle({
		componentDidMount() {

			// first time
			let id_token = localStorage.getItem('id_token')
			if (!id_token) {
				const auth = new Auth()
				auth.handleAuthentication((err) => {
					if (!err) {
						getNonce(this.props, auth)
					}
					else{
						this.props.changeIsLoadingLogin(false);
						if(err.error === "unauthorized"){
							this.props.changeUnauthorizedLogin(true)
						}
					}
					this.props.loginUser(undefined) // state de redux
				})
			}
			else {
				this.props.loginUser(localStorage.getItem("nonce"))// state de redux
			}
		}
	})
)(LoginView);
