import { WIG, ResponseMessages } from "components/Constants"
import axios from "axios";
import { signOut } from "store/LoginState";
import store from "store";

/*
Used to create WIG:
Retuns the id of the response
*/
async function createWIG(newWIG, teamId) {
    try {
        let url = `${WIG.CreateEndPoint}/save/${teamId}/wig`
        console.log(url);
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: newWIG
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`createWIG returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for createWIG`)

            // If it's a success response
            if (response.status === 201) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Used to update WIG:
*/
async function editWIG(wig, wigId) {
    try {
        let url = `${WIG.GetEndPoint}/${wigId}`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "put",
            url: url,
            headers: requestHeaders,
            data: wig
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`createWIG returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for createWIG`)

            // If it's a success response
            if (response.status === 200) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of WIG by team and Id
*/
async function getWIGByTeamIdAndYear(teamId, year) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${WIG.CreateEndPoint}/${teamId}/wigParent`,
            headers: requestHeaders,
            params: {
                year: year
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getWIGByTeamIdAndYear returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of Company WIG by team and Id
*/
async function getCompanyWIGByTeamIdAndYear(teamId, year) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${WIG.CreateEndPoint}/${teamId}/companyWig`,
            headers: requestHeaders,
            params: {
                year: year
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getWIGByTeamIdAndYear returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


/*
Get the list of WIG by team and Id including the parent team WIGs
*/
async function getWIGByTeamAndParentIdAndYear(teamId, year) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${WIG.CreateEndPoint}/${teamId}/wigParent`,
            headers: requestHeaders,
            params: {
                year: year
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getWIGfromParent returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the  WIG with Name and Id
*/
async function getWIGById(idWIG, authorize) {
    try {
        let requestHeaders = createHeaders()
        console.log(`${WIG.GetEndPoint}/authorize/${idWIG}/${authorize}`)
        let response = await axios({
            method: "get",
            url: `${WIG.GetEndPoint}/authorize/${idWIG}/${authorize}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    console.error(error.response)
                    // Throw error if it's not a success response
                    throw new Error(`createPredictive returned a status code ${error.response.status}`)
                }
            }
        });
        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the WIG Goals by Id
*/
async function getWIGGoalsById(idWIG) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${WIG.GetEndPointStat}/${idWIG}/goals`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    console.error(error.response)
                    // Throw error if it's not a success response
                    throw new Error(`getWIGGoalsById returned a status code ${error.response.status}`)
                }
            }
        });
        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Used to save WIG goals
*/
async function saveWIGGoals(wigGoals, wigId) {
    try {
        let url = `${WIG.GetEndPointStat}/${wigId}/goals`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "put",
            url: url,
            headers: requestHeaders,
            data: wigGoals
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`saveWIGGoals returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for saveWIGGoals`)

            // If it's a success response
            if (response.status === 204) {
                return { success: true }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Used to create tracking information for WIG:
Retuns the id of the response
*/
async function createWIGTracking(tracking, wigId) {
    try {
        let url = `${WIG.GetEndPointStat}/${wigId}/tracking`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: tracking
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`createWIGTracking returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for createWIGTracking`)

            // If it's a success response
            if (response.status === 201) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Delete the WIG by Id
*/
async function deleteWIGById(idWIG) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "delete",
            url: `${WIG.GetEndPoint}/${idWIG}`,
            headers: requestHeaders
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    console.error(error.response)
                    // Throw error if it's not a success response
                    throw new Error(`createPredictive returned a status code ${error.response.status}`)
                }
            }
        });
        if (response) {
            // If it's a success response
            if (response.status === 204) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of WIG by team and Id ONLY
*/
async function getWIGByTeamIdAndYearONLY(teamId, year) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${WIG.CreateEndPoint}/${teamId}/wig`,
            headers: requestHeaders,
            params: {
                year: year
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getWIGByTeamIdAndYear returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of WIG by team and Id ONLY
*/
async function getLastWIG(teamId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: `${WIG.GetEndPointLast}`,
            headers: requestHeaders,
            data: {
                teamId: teamId
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getLastWIG returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


/*
Get the list of WIG by team UserId and Year
*/
async function getWIGByTeamIdYearAndUser(teamId, year,userId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${WIG.CreateEndPoint}/user/${teamId}/${userId}/wig`,
            headers: requestHeaders,
            params: {
                year: year
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getWIGByTeamIdYearAndUser returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}
/*
Used to create WIG:
Retuns the id of the response
*/
async function createWIGUser(newWIG, teamId, userId) {
    try {
        let url = `${WIG.CreateEndPoint}/user/${teamId}/${userId}/wig`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: newWIG
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`createWIG returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for createWIG`)

            // If it's a success response
            if (response.status === 201) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}
/*
Used to COPY WIG
*/
async function copyWIG(wigId, teamId) {
    try {
        let url = `${WIG.GetEndPointCopy}`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: {
                wigId: wigId,
                teamId: teamId
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`copyWIG returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for copyWIG`)

            // If it's a success response
            if (response.status === 201) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


/*
Used to CHANGE STATUS WIG (inactivate)
*/
async function inactivateWIG(wigId) {
    try {
        let url = `${WIG.GetEndPointChangeStatus}`
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: {
                wigId: wigId
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`inactivateWIG returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // Check response status code
            console.info(`statusCode: ${response.status} for inactivateWIG`)

            // If it's a success response
            if (response.status === 201) {
                return { success: true, message: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of WIG by team UserId and Year
*/
async function GetWIGSUnassigned (teamId) {
    try {
      
        let url = `${WIG.GetEndPointunasigned}`
        let requestHeaders = createHeaders()
   

        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: {
                teamId: teamId
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`GetWIGSUnassigned returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
Get the list of WIG by team and Id
*/
async function getWIGByTeamIdAndCURRENTYear(teamId, year) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${WIG.CreateEndPoint}/${teamId}/wigParent/${year}`,
            headers: requestHeaders,
            params: {
                year: year
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getWIGByTeamIdAndCURRENTYear returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}



/*
Get the list of WIG by ParentWIG And desactivate
*/
async function getWIGSParentWIG(parentWig) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: `${WIG.GetEndPointParentWig}`,
            headers: requestHeaders,
            data: {
                parentWig: parentWig
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getWIGSParentWIG returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 201) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


/*
Get the list of WIGS STATUS 60
*/
async function getWIGSInactivates(status,teamId,year) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: `${WIG.GetEndPointWigInactivate}`,
            headers: requestHeaders,
            data: {
                status: status,
                teamId: teamId,
                year: year
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getWIGSInactivates returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 201) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}



/*
Update WIG PARENTWIG AND CHANGE TO STATUS 1
*/
async function updateParentWIG(wigId, parentWigId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: `${WIG.GetEndPointUpdateParentWIG}`,
            headers: requestHeaders,
            data: {
                wigId: wigId,
                parentWigId:parentWigId
                
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`updateParentWIG returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 201) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


/*
COPY WIG AXIS
*/
async function CopyWIGAxis(lastWIGId, newWIGId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: `${WIG.GetEndPointCopyWIGAxis}`,
            headers: requestHeaders,
            data: {
                lastWIGId: lastWIGId,
                newWIGId: newWIGId,                
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`CopyWIGAxis returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

/*
GET WIGS FROM TEAM AND USERID
*/
async function GetWigsFromUserANDTeam(teamId, year) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: `${WIG.GetWIGsFromTeam}`,
            headers: requestHeaders,
            data: {
                teamId: teamId,
                year: year             
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(` GetWigsFromUserANDTeam returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}


/*
GET AXISID FROM WIGID
*/
async function GetWIGAxisID(wigId) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "post",
            url: `${WIG.GetWIgAxisID}`,
            headers: requestHeaders,
            data: {
                wigId: wigId         
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(` GetWIGAxisID returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}



/*
Get the list of WIG by team and leaderid
*/
async function GetWigsFromTeamandLeader (teamId, leaderId,year) {
    try {
      
        let url = `${WIG.GetEndPointWigList}`
        let requestHeaders = createHeaders()
    

        let response = await axios({
            method: "post",
            url: url,
            headers: requestHeaders,
            data: {
                teamId: teamId,
                leaderId: leaderId,
                year: year
            },
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`GetWigsFromTeamandLeader returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response
            if (response.status === 201) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }
    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

function createHeaders() {
    let headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
        "nonce": localStorage.getItem('nonce')
    }

    return headers
}

export {
    createWIG, editWIG, deleteWIGById,
    getWIGByTeamIdAndYear,
    getCompanyWIGByTeamIdAndYear, 
    getWIGById, createWIGTracking,
    getWIGGoalsById, saveWIGGoals, getWIGByTeamAndParentIdAndYear,
    getWIGByTeamIdAndYearONLY,
    getLastWIG,
    getWIGByTeamIdYearAndUser,
    createWIGUser,
    copyWIG,
    inactivateWIG,
    GetWIGSUnassigned,
    getWIGByTeamIdAndCURRENTYear,
    getWIGSParentWIG,
    getWIGSInactivates,
    updateParentWIG,
    CopyWIGAxis,
    GetWigsFromUserANDTeam,
    GetWIGAxisID,
    GetWigsFromTeamandLeader

}

