import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { userCreate as userCreateSchema } from "models/userModel";
import { createUser, getAllUsers ,getBase64async, SaveImage, getUserByMail} from "services/apiUser/userApi.js";
import { getAccounts, getCountries, getDepartments } from "services/apiAdmin/teamApi";
import { translate } from "utils/translate"
import CreateView from "./CreateView";

const newUser = {
	firstName: "",
	lastName: "",
	email: "",
	roleId: 3,
	password: "",
	employeeCode: "",
	/** 
	 position: "",
	 managerId:"",
	 accountId: "",
	 countryId: ""
	 */
}

const newUserState = {
	firstName: "",
	lastName: "",
	email: "",
	roleId: "",
	password: "",
	employeeCode: "",
	/**
	 position: "",
	 managerId: "",
	 accountId: "",
	 countryId: "",
	 */
}

let _isMounted = false

async function getUserList(props) {
	let result = await getAllUsers();
	if (_isMounted && result.success) {

		props.setUserList(result.data)
		
	}
	props.changeLoadingUserList(false)
	
}

async function getAccountsList(props){
	let result = await getAccounts();
	if(_isMounted && result.success){
	
		props.setAccountList(result.data)
	}
	props.changeLoadingAccountList(false)
}

async function getCountriesList(props){
	let result = await getCountries();
	if(_isMounted && result.success){
		props.setCountriesList(result.data);
	}
	props.changeLoadingCountriesList(false);
}

async function getDepartmentsList(props){
	let result = await getDepartments();
	if(_isMounted && result.success){
		props.setDepartmentsList(result.data)
	}
	props.changeLoadingDepartmentsList(false);
}

async function SaveImage_(props){
	let base = props.imageBase;
	let result_user = await  getUserByMail(props.user.email);
	if(result_user.success){
		let result = await SaveImage(props.imageBase,result_user.data[0].userId);
		if(result.success){
			console.log("result success")
		}
	}

	
}
async function onSubmit(props) {
	let result = await createUser(props.user)
	// Show confirmation message
	if (result.success && true && _isMounted) {

		props.setCreateSuccess(true)
		SaveImage_(props);
		setTimeout(function () {
			if (_isMounted) {
				props.history.push('/admin/user/index')
				props.setCreateSuccess(false)
			}
		}, 1500);
	} else {
		// Show error message
		if (result) {
			let message = result.message
			if (!message) {
				message = "Algo salió mal, inténtelo más tarde."
			}
			props.setValidationMessage(message)
			props.setCreateError(true)
		}
	}
}



export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{}
	),
	withState("user", "setUser", { ...newUser }),
	withState("userState", "setUserState", { ...newUserState }),
	withState("createSuccess", "setCreateSuccess", false),
	withState("createError", "setCreateError", false),
	withState("validationMessage", "setValidationMessage", ""),
	withState("isLoadingSave", "setIsLoadingSave", false),
	withState("userList", "setUserList", []),
	withState("loadingUserList", "changeLoadingUserList", false),
	withState("filepreview","setFilepreview",null),
	withState("accountList", "setAccountList", []),
	withState("loadingAccountList", "changeLoadingAccountList", false),
	withState("countriesList", "setCountriesList", []),
	withState("loadingCountriesList", "changeLoadingCountriesList", false),
	withState("departmentsList", "setDepartmentsList", []),
	withState("loadingDepartamentsList", "changeLoadingDepartmentsList", false),
	withState( "imageBase", "setImageBase", ""),
	withState( "stateModal", "setStateModal", false),

	withHandlers({
		onFieldChange: (props) => (field, value) => {
			props.user[field] = value
			let isValid = validateProperty(userCreateSchema, props.user, field).isValid
			if (isValid) {
				props.userState[field] = "success"
			} else {
				props.userState[field] = "error"
			}
			props.setUserState(props.userState)
			props.setUser(props.user)
		},onUserListGet: (props) => async () => {
			getUserList(props)
		},onConfirm:(props) => async (e)=>{
			console.log("OnConfirm: ",props)
		},onChangeImg:(props) => async (e)=>{
			
			props.setFilepreview(URL.createObjectURL(e.target.files[0]))  
			var file = file || e.target.files[0],
			pattern = /image-*/,
			reader = new FileReader();
			let extensions = new Array(".jpg", ".png");
			let extension =(file.name.substring(file.name.lastIndexOf("."))).toLowerCase();
			let extension_error = false;
			for( let i =0; i < extensions.length; i++){
				if(extensions[i]== extension){
					extension_error = true;
					break;
				}
			}
			if(!extension_error){
				let message= translate("image_error");
				props.setValidationMessage(message)
			props.setCreateError(true)
			}
			let base = await getBase64async(file);
			props.setImageBase(base);
		

		   },
		   onModalClosed: (props) => async () => {
				props.setStateModal(false)

		},
		saveUserValidation: (props) => async () => {

			// if(props.user.employeeCode == ""){
			// 	props.setStateModal(true)
			// }else{
				props.setIsLoadingSave(true)
				let validation = validateObject(userCreateSchema, props.user)
				if (validation.isValid) {
					await onSubmit(props)
				} else {
					let field
					for (field in props.user) {
						if (validation.errors[field]) {
							props.userState[field] = "error"
						}
					}
					props.setUserState(props.userState)
				}
				props.setIsLoadingSave(false)
			// }

		},
		onUserCreate: (props) => async () => {
			props.setStateModal(false)
			props.setIsLoadingSave(true)

			let validation = validateObject(userCreateSchema, props.user)
			if (validation.isValid) {
				await onSubmit(props)
			} else {
				let field
				for (field in props.user) {
					if (validation.errors[field]) {
						props.userState[field] = "error"
					}
				}
				props.setUserState(props.userState)
			}
			props.setIsLoadingSave(false)
		}
	}),
	lifecycle({
		componentDidMount() {
			_isMounted = true
			this.props.setUser({ ...newUser })
			this.props.setUserState({ ...newUserState })
			this.props.setCreateSuccess(false)
			getUserList(this.props)
			//getAccountsList(this.props)
			//getCountriesList(this.props)
			//getDepartmentsList(this.props)
		},
		componentWillUnmount() {
			_isMounted = false
		}
	})
)(CreateView);
