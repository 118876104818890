import { compose, lifecycle, withState } from "recompose";
import { connect } from "react-redux";
import { getCompanyWIGByTeamIdAndYear, getWIGById,getWIGByTeamIdAndYear } from "services/apiAdmin/wigApi";
import { getCompanyTeam } from "services/apiAdmin/teamApi";
import { GetPredictivesByWIGTeamAndUser } from "services/apiAdmin/predictiveApi";
import MainDashboardView from "./MainDashboardView";
import { getCommitmentReminder} from "services/apiAdmin/commitmentApi";
import {  getInputFormatDateY} from "utils/functions"

let _isMounted = false

async function getCompanyWIGList(props) {
	// if team Id is not defined
	if (!props.teamId) {
		return
	}
	let companyTeam = await getCompanyTeam()
	
	if (companyTeam.success && companyTeam.data.length > 0){

		let companyTeamId = companyTeam.data[0].Id
		let currentYear = new Date().getFullYear()
		let result = await getCompanyWIGByTeamIdAndYear(companyTeamId, currentYear)
		
		if (result.success) {
				// update general state
				props.setWIGList(result.data.map(function (wig) {
					return {
						id: wig.id,
						name: wig.displayName1,
					}
				}))
				/*props.wigList.forEach(element => {
					console.log(element)
					let result = await getWIGById(wig.id)
					console.log(result)
				});*/
		}
	}
}

async function getCommitmentsReminder(props) {
	let today_date = getInputFormatDateY(new Date());

	let result = await getCommitmentReminder(today_date);
	if(_isMounted && result.success){
		props.setReminderList(result.data);
	
		props.setReminder(result.data.length)
	}

}

async function getWIGList(props) {
	// if team Id is not defined
	if (!props.teamId) {
		return
	}
	
	let currentYear = new Date().getFullYear()
	let result = await getWIGByTeamIdAndYear(props.teamId, currentYear)
	if (result.success) {
		
			//Verify predictives in my team
			
			for(let i =0; i< result.data.length; i++){
				if(result.data[i].teamId == props.teamId){
					//HAVE WIGS IN MY TEAM

					//VerifyPredictives(props,result.data[i].id);}
					props.setPredictivesTeam(true);
				}else{
					//Verify predictives in parent´s wigs
					VerifyPredictivesLead(props,result.data[i].id);
				}
			}
			// update general state
		
		
	}
}

async function VerifyPredictivesLead(props, wigId){
	
	let result = await GetPredictivesByWIGTeamAndUser(props.teamId,wigId);
	
	if(result.success){
		
		if(result.message.length>0){
			props.setPredictivesLead(true)
		
			
		}
	}
}

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
			teamId: state.admin.teamId,
			team: state.admin.team,
			roleId: state.admin.roleId,
			wigList: state.admin.wigList,
			language: state.admin.language
		}),
		{}
	),
	withState("WIGList", "setWIGList", []),
	withState("WIGListAxes", "setWIGListAxes", []),
	withState("predictivesTeam", "setPredictivesTeam",false),
	withState("predictivesLead", "setPredictivesLead", false),
	lifecycle({
		componentDidMount(){
			
			getWIGList(this.props);

		},
		componentWillMount(){
			getCompanyWIGList(this.props)	
			getWIGList(this.props);
		}
	})
)(MainDashboardView);
