import { createIntl, createIntlCache } from 'react-intl';

const defaultLocale = 'en-US';
const supportedLocalesAndFallbackMap = {
  'en-US': 'en-US',
  'es-ES': 'es-ES',
};

export function getUserLocale() {
  if (localStorage.getItem("4dxLocale")) {
    return localStorage.getItem("4dxLocale")
  } else {
    return defaultLocale
  }
}

function getTranslationLocale(locale) {
  if (supportedLocalesAndFallbackMap.hasOwnProperty(locale)) {
    return supportedLocalesAndFallbackMap[locale];
  } else if (locale.indexOf('-') > -1) {
    const index = locale.indexOf('-');
    const languageCode = locale.substring(0, index);
    return supportedLocalesAndFallbackMap.hasOwnProperty(languageCode) ? supportedLocalesAndFallbackMap[languageCode] : defaultLocale;
  } else {
    return defaultLocale;
  }
}

async function loadTranslations(userLocale) {
  const translationLocale = getTranslationLocale(userLocale);
  let stringsModule;
  try {
    stringsModule = await import(`translations/${translationLocale}/strings.json`);
  } catch (e) {
    console.error(`Failed to load ${translationLocale}, falling back to es-ES`);
    stringsModule = await import(`translations/en-US/strings.json`);
  }

  return stringsModule.default;
}

const cache = createIntlCache();
let intl;

export async function getMessages() {
  const userLocale = getUserLocale();

  if (intl && (intl.locale === getUserLocale())) {
    return intl.messages;
  }

  const messages = await loadTranslations(userLocale);
  if (!messages) {
    console.error(`Unable to load ${userLocale} strings`);
    return {};
  }

  intl = createIntl(
    {
      locale: userLocale,
      messages: messages
    },
    cache
  );
  return messages;
}

export const translate = (id, values) => {
  return intl.formatMessage({ id }, values);
};
