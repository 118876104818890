import { ResponseMessages, History } from "components/Constants";
import axios from "axios";
import { signOut } from "store/LoginState";
import store from "store";

async function getHistoryLogsByTeam(params) {
    try {
        let requestHeaders = createHeaders()

        let response = await axios({
            method: "get",
            url: `${History.GetLogsEndpoint}/all`,
            headers: requestHeaders,
            params: {
                page: params.page
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
                        store().store.dispatch(signOut())
                    } else {
                        return error.response
                    }
                } else if (error.response.data) {
                    return error.response
                } else {
                    // Throw error if it's not a success response
                    throw new Error(`getExternalCommitments returned a status code ${error.response.status}`)
                }
            }
        });

        if (response) {
            // If it's a success response            
            if (response.status === 200) {
                return { success: true, data: response.data.payload }
            } else {
                return { success: false, message: response.data.message }
            }
        } else {
            return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
        }

    } catch (error) {
        console.error(error)
        return { success: false, message: "Algo ocurrió mal, intentélo nuevamente" }
    }
}

function createHeaders() {
    let headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
        "nonce": localStorage.getItem('nonce')
    }

    return headers
}

export {
    getHistoryLogsByTeam
}