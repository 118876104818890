
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { getWeek } from "utils/functions";
import { predictiveTrackingSchema, predictiveTrackingSchema1, predictiveTrackingSchema2 } from "models/predictiveModel";
import {
  getPredictiveById,
  createPredictiveTrackingAxi,
} from "services/apiAdmin/predictiveApi";
import { getTrackingListByAxisId } from "services/apiAdmin/trackingApi";
import { getWIGById } from "services/apiAdmin/wigApi";
import { translate, getUserLocale } from "utils/translate";
import moment from "moment";

import TrackingView from "./TrackingView";

let predictiveId;
let wigEndDate = "";
let wigStartDate = "";
let weeks = [];
let _isMounted = false;

const emptyDataTable = { header: ["loading"], result: [] };

let idiomDefault = getUserLocale()
let fechaActual = moment()
let idiomaInitial;
let numberWeek =  getWeek();
if (idiomDefault === "es-ES") {
  moment.locale('es')
  const startDate_Obj = moment(fechaActual).format("DD MMMM, YYYY");
  idiomaInitial = startDate_Obj
} else if (idiomDefault === "en-US") {
  moment.locale('en')
  const startDate_Obj = moment(fechaActual).format("MMMM DD, YYYY");
  idiomaInitial = startDate_Obj
}


const newTracking = {
  goalAchived1: "",
  commentary1: "",
  goalAchived2: "",
  commentary2: "",
  week1: numberWeek,
  week2: numberWeek,
  day1: idiomaInitial,
  day2: idiomaInitial,
};


const resetInfoTraking = {
  goalAchived1: "",
  commentary1: "",
  goalAchived2: "",
  commentary2: "",
  week1: numberWeek,
  week2: numberWeek,
  day1: idiomaInitial,
  day2: idiomaInitial,
};

const newTrackingState = {
  goalAchived1: "",
  commentary1: "",
  goalAchived2: "",
  commentary2: "",
  week1: "",
  week2: "",
  day1: "",
  day2: "",
};
const staticProps = ["week", "dataTypeId1", "dataTypeId2", "axesNumber"];

async function onSubmit(props, index) {
  if (index == 1) {
    let nuevoObjeto = {
      ...props.tracking,
      typeTracking: props.predictive.consistency,
      index
    }
    let valueRango;
    if (idiomDefault === "es-ES") {
      const startDate_Obj = moment(nuevoObjeto.day1, "DD MMMM, YYYY").format('YYYY-MM-DD');
      const startDate_Obj2 = moment(nuevoObjeto.day2, "DD MMMM, YYYY").format('YYYY-MM-DD');

      if (nuevoObjeto.typeTracking == "daily" || nuevoObjeto.typeTracking == "diario") {
        let fechaInit = moment(props.predictive.StartDate)
        let fechaEnd = moment(props.predictive.EndDate).add('days', 1)
        let fechaBusqueda = moment(startDate_Obj).isBetween(fechaInit, fechaEnd)
        valueRango = fechaBusqueda

      }

      let updateFecha = {
        ...nuevoObjeto,
        day1: startDate_Obj,
        day2: startDate_Obj2
      }
      nuevoObjeto = updateFecha
    } else {
      const startDate_Obj = moment(nuevoObjeto.day1, "MMMM DD, YYYY").format('YYYY-MM-DD');
      const startDate_Obj2 = moment(nuevoObjeto.day2, "MMMM DD, YYYY").format('YYYY-MM-DD');



      if (nuevoObjeto.typeTracking == "daily" || nuevoObjeto.typeTracking == "diario") {
        let fechaInit = moment(props.predictive.StartDate)
        let fechaEnd = moment(props.predictive.EndDate)
        let fechaBusqueda = moment(startDate_Obj).isBetween(fechaInit, fechaEnd)
        valueRango = fechaBusqueda
        // if (fechaBusqueda) {
        //   let message =
        //     nuevoObjeto.idiom === "es-ES"
        //     ? "La fecha de seguimiento debe estar en el rango de fechas de Mci"
        //     : "Tracking date must be in Mci date range";
        //     props.changeValidationMessage(message);
        //     props.changeCreateError(true);
        // }
      }

      let updateFecha = {
        ...nuevoObjeto,
        day1: startDate_Obj,
        day2: startDate_Obj2
      }
      nuevoObjeto = updateFecha
    }
    if (valueRango || nuevoObjeto.typeTracking == "weekly" || nuevoObjeto.typeTracking == "semanal") {
      let result = await createPredictiveTrackingAxi(
        nuevoObjeto,
        props.predictiveId
      );
      // Show confirmation message

      if (result && result.success) {
        let property;
        // Clear state
        // for (property in props.tracking) {
        //   if (!staticProps.includes(property)) {
        //     props.tracking[property] = "";
        //   }
        // }
        props.changeTracking(resetInfoTraking);

        for (property in props.trackingState) {
          props.trackingState[property] = "";
        }
        props.changeTrackingState(props.trackingState);

        props.changeCreateSuccess(true);
        setTimeout(function () {
          props.changeCreateSuccess(false);
        }, 5000);

        getTrackingTables(props, props.predictive);
      } else {
        // Show error message
        if (result) {
          let message = result.message;
          if (!message) {
            message = "Algo ocurrió mal, inténtalo nuevamente.";
          }
          props.changeValidationMessage(message);
          props.changeCreateError(true);
        }
      }
    } else {
      let message =
      idiomDefault === "es-ES"
          ? "La fecha de seguimiento debe estar en el rango de fechas de Mci"
          : "Tracking date must be in Mci date range";
      props.changeValidationMessage(message);
      props.changeCreateError(true);
    }




  } else {
    let nuevoObjeto = {
      ...props.tracking,
      typeTracking: props.predictive.consistencyTwo,
      index
    }
    let valueRango;
    if (idiomDefault === "es-ES") {
      const startDate_Obj = moment(nuevoObjeto.day1, "DD MMMM, YYYY").format('YYYY-MM-DD');
      const startDate_Obj2 = moment(nuevoObjeto.day2, "DD MMMM, YYYY").format('YYYY-MM-DD');

      if (nuevoObjeto.typeTracking == "daily" || nuevoObjeto.typeTracking == "diario") {
        let fechaInit = moment(props.predictive.StartDate)
        let fechaEnd = moment(props.predictive.EndDate)
        let fechaBusqueda = moment(startDate_Obj2).isBetween(fechaInit, fechaEnd)
        valueRango = fechaBusqueda

      }

      let updateFecha = {
        ...nuevoObjeto,
        day1: startDate_Obj,
        day2: startDate_Obj2
      }
      nuevoObjeto = updateFecha
    } else {
      const startDate_Obj = moment(nuevoObjeto.day1, "MMMM DD, YYYY").format('YYYY-MM-DD');
      const startDate_Obj2 = moment(nuevoObjeto.day2, "MMMM DD, YYYY").format('YYYY-MM-DD');



      if (nuevoObjeto.typeTracking === "daily" || nuevoObjeto.typeTracking === "diario") {
        let fechaInit = moment(props.predictive.StartDate)
        let fechaEnd = moment(props.predictive.EndDate)
        let fechaBusqueda = moment(startDate_Obj2).isBetween(fechaInit, fechaEnd)
        valueRango = fechaBusqueda
      }

      let updateFecha = {
        ...nuevoObjeto,
        day1: startDate_Obj,
        day2: startDate_Obj2
      }
      nuevoObjeto = updateFecha
    }
    if (valueRango || nuevoObjeto.typeTracking == "weekly" || nuevoObjeto.typeTracking == "semanal") {
      let result = await createPredictiveTrackingAxi(
        nuevoObjeto,
        props.predictiveId
      );
      // Show confirmation message

      if (result && result.success) {
        let property;
        // Clear state
        // for (property in props.tracking) {
        //   if (!staticProps.includes(property)) {
        //     props.tracking[property] = "";
        //   }
        // }
        props.changeTracking(resetInfoTraking);

        for (property in props.trackingState) {
          props.trackingState[property] = "";
        }
        props.changeTrackingState(props.trackingState);

        props.changeCreateSuccess(true);
        setTimeout(function () {
          props.changeCreateSuccess(false);
        }, 5000);

        getTrackingTables(props, props.predictive);
      } else {
        // Show error message
        if (result) {
          let message = result.message;
          if (!message) {
            message = "Algo ocurrió mal, inténtalo nuevamente.";
          }
          props.changeValidationMessage(message);
          props.changeCreateError(true);
        }
      }
    } else {
      let message = idiomDefault === "es-ES"? "La fecha de seguimiento debe estar en el rango de fechas de Mci" : "Tracking date must be in Mci date range";
      props.changeValidationMessage(message);
      props.changeCreateError(true);
    }



  }

}

async function getPredictive(props) {
  let result = await getPredictiveById(predictiveId);
  getWeekWig(props, result.data.wigId);
  if (result.success) {
    if (_isMounted) {
      result.data.axesNumber = result.data.x2 === null ? 1 : 2;
      newTracking.axesNumber = result.data.axesNumber;
      props.changeTracking(newTracking);
      props.changePredictive(result.data);

      getTrackingTables(props, result.data);
    }
  }
}

async function getTrackingTables(props, predictive) {
  let index;
  for (index of [1, 2]) {
    if (predictive[`axisId${index}`] !== null) {
      let trackingResult = await getTrackingListByAxisId(
        predictive[`axisId${index}`]
      );
      if (trackingResult.success) {
        props[`changeTrackingList${index}`](trackingResult.data);
      }
    }
  }
}

async function getWeekWig(props, wigID) {
  weeks = [];
  let resultWig = await getWIGById(wigID, 0);

  if (resultWig.success) {


    const fecha = moment(resultWig.data.endDate); // Supongamos que esta es la fecha que quieres verificar

    const inicio = moment().month(11).date(28); // 28 de diciembre
    const fin = moment().month(11).date(31); // 31 de diciembre

    if (fecha.isBetween(inicio, fin)) {


      wigStartDate = moment(resultWig.data.startDate).week();
      props.changewigStartWeek(wigStartDate);
      props.changewwigEndWeek(52);


      for (var i = wigStartDate; i <= 52; i++) {
        weeks.push(`${translate("week")} ${i}`);
      }

      props.changewigWeekArray(weeks);
    } else {
      wigEndDate = moment(resultWig.data.endDate).week();
      wigStartDate = moment(resultWig.data.startDate).week();
      props.changewigStartWeek(wigStartDate);
      props.changewwigEndWeek(wigEndDate);

      for (var i = wigStartDate; i <= wigEndDate; i++) {
        weeks.push(`${translate("week")} ${i}`);
      }

      props.changewigWeekArray(weeks);
    }


  }


}

export default compose(
  connect(
    (state) => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
    }),
    {}
  ),
  withState("predictiveId", "changePredictiveId", undefined),
  withState("predictive", "changePredictive", undefined),
  withState("wigStartWeek", "changewigStartWeek", undefined),
  withState("wigEndWeek", "changewwigEndWeek", undefined),
  withState("weeks", "changewigWeekArray", undefined),
  withState("tracking", "changeTracking", {
    goalAchived1: "",
    commentary1: "",
    goalAchived2: "",
    commentary2: "",
    week: getWeek(),
  }),
  withState("trackingState", "changeTrackingState", newTrackingState),
  withState("trackingList1", "changeTrackingList1", emptyDataTable),
  withState("trackingList2", "changeTrackingList2", emptyDataTable),
  withState("createSuccess", "changeCreateSuccess", false),
  withState("createError", "changeCreateError", false),
  withState("validationMessage", "changeValidationMessage", ""),
  withState("isLoadingSave", "changeIsLoadingSave", false),
  withHandlers({
    onFieldChange: (props) => (field, event) => {
      let idiom = getUserLocale();
      if (field === "day1" || field === "day2") {
        if (idiom === "es-ES") {
          let formatoCorrespondiente = moment(event).format("DD MMMM, YYYY");
          props.tracking[field] = formatoCorrespondiente;

          let isValid = validateProperty(
            predictiveTrackingSchema,
            props.tracking,
            field
          ).isValid.isValid;
          if (isValid) {
            props.trackingState[field] = "success";
          } else {
            props.trackingState[field] = "error";
          }
          props.changeTrackingState(props.trackingState);
          props.changeTracking(props.tracking);
        } else {
          let formatoCorrespondiente = moment(event).format("MMMM DD, YYYY");
          props.tracking[field] = formatoCorrespondiente;

          let isValid = validateProperty(
            predictiveTrackingSchema,
            props.tracking,
            field
          ).isValid.isValid;
          if (isValid) {
            props.trackingState[field] = "success";
          } else {
            props.trackingState[field] = "error";
          }
          props.changeTrackingState(props.trackingState);
          props.changeTracking(props.tracking);
        }
      } else if (field === "week1" || field === "week2") {
        var division = event.target.value.split(" ");
        props.tracking[field] = parseInt(division[1]);

        let isValid = validateProperty(
          predictiveTrackingSchema,
          props.tracking,
          field
        ).isValid;
        if (isValid) {
          props.trackingState[field] = "success";
        } else {
          props.trackingState[field] = "error";
        }
        props.changeTrackingState(props.trackingState);
        props.changeTracking(props.tracking);
      } else {

        props.tracking[field] = event.target.value;
        let isValid = validateProperty(
          predictiveTrackingSchema,
          props.tracking,
          field
        ).isValid;
        if (isValid) {
          props.trackingState[field] = "success";
        } else {
          props.trackingState[field] = "error";
        }
        props.changeTrackingState(props.trackingState);
        props.changeTracking(props.tracking);
      }
    },
    onTrackingSave: (props) => async (index) => {
      props.changeIsLoadingSave(true);
      if (index == 1) {
        let validation = validateObject(predictiveTrackingSchema1, props.tracking);
        if (validation.isValid) {
          await onSubmit(props, index);
        } else {
          let field;
          for (field in props.tracking) {
            if (validation.errors[field]) {
              props.trackingState[field] = "error";
            }
          }
          props.changeTrackingState(props.trackingState);
        }
      } else {
        let validation = validateObject(predictiveTrackingSchema2, props.tracking);
        if (validation.isValid) {
          await onSubmit(props, index);
        } else {
          let field;
          for (field in props.tracking) {
            if (validation.errors[field]) {
              props.trackingState[field] = "error";
            }
          }
          props.changeTrackingState(props.trackingState);
        }
      }

      props.changeIsLoadingSave(false);
    },
  }),
  lifecycle({
    componentDidMount() {
      predictiveId = this.props.match.params.id;
      wigStartDate = wigStartDate;
      wigEndDate = wigEndDate;
      weeks = weeks;
      this.props.changePredictiveId(predictiveId);
      newTracking.goalAchived1 = "";
      newTracking.commentary1 = "";
      newTracking.goalAchived2 = "";
      newTracking.commentary2 = "";
      this.props.changeTracking({ ...newTracking });
      this.props.changeTrackingState({ ...newTrackingState });
      _isMounted = true;
      getPredictive(this.props);
    },
  })
)(TrackingView);
