import { grayColor } from "assets/jss/material-dashboard-pro-react.js";

const dashboardStyle = {
  heading: {
    marginBottom: "30px"
  },
  centerTextAlign: {
    textAlign: "center"
  },
  title: {
    marginTop: "10px",
    color: grayColor[2],
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: "4.2rem"
  },
  category: {
    margin: "0 0 10px"
  },
  verticalCenter: {
    marginTop: "20%"
  },
  createBtn: {
    float: "center",
    textTransform: "none"
  },
};

export default dashboardStyle;
  