import getISOWeek from 'date-fns/getISOWeek'
import permissions from 'utils/permissions.json'
import { RolesId } from "components/Constants"
import moment from 'moment';
import { getUserLocale } from "utils/translate";

// Returns the ISO week of the date.
const getWeek = function () {
    let date = new Date(new Date().getTime());
    let result = getISOWeek(date)

    return result
}

// Returns the ISO week of the date.
const getWeekByDate = function (date) {
    let result = getISOWeek(date)

    return result
}

// Returns the number of ISO weeks of a year.
const getWeeksNumberByYear = function (year) {
    /*
    let result = getISOWeeksInYear(new Date(year, 1, 1))

    return result
    */
    return 53 //? Constantly return 53 as the number of weeks per year. Commented code returned number of Weeks depending on current year, 
    //? but was wrong if tracking predictives of year different than current year
}

const getMonthsByYear = function (year) {
    let array = [
        `All`,
        `January`,
        `February`,
        `March`,
        `April`,
        `May`,
        `June`,
        `July`,
        `August`,
        `September`,
        `October`,
        `November`,
        `December`
    ]

    for (let index = 0; index < array.length; index++) {
        console.log(array[index]);
    }
    return array

    /*
    let result = getISOWeeksInYear(new Date(year, 1, 1))

    return result
    */
     //? Constantly return 53 as the number of weeks per year. Commented code returned number of Weeks depending on current year, 
    //? but was wrong if tracking predictives of year different than current year
}

// Remove empty fields
const removeEmptyFields = function (body, fields) {
    let field
    for (field of fields) {
        if (body[field] === "") {
            delete body[field]
        }
    }
    return body
}

// format Date to day, month and year
const formatDate = function (dateString,locale) {
 
    let date = new Date(dateString)
    
    if(date == "Invalid Date"){
        return dateString;
    }else{
        if(locale === "en-US"){
            
            return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute:'numeric', second:'numeric'})
        }
        else{
            return moment(date).format("DD/MM/YYYY h:mm:ss A")
            // return date.toLocaleDateString('es-GT', { year: 'numeric', month: '2-digit', day: '2-digit', hours: 'numeric', minute:'numeric', second:'numeric'})
        }
       
    }
  
   
}

Date.prototype.valid = function() {
    return isFinite(this);
}

// Get the number of weeks between 2 dates
const getWeeksNumberBetweenDates = function (date1, date2) {
    return Math.round((date2 - date1) / (7 * 24 * 60 * 60 * 1000));
}

const verifyPermission = function (roleId, dataRoleId, resource, permission) {
    let hasPermission = false

    //Verify role
    if (roleId && permissions.roles[`${roleId}`] && permissions.roles[`${roleId}`][resource]) {
        let rolePermissions = permissions.roles[`${roleId}`][resource]

        if (rolePermissions.includes("read.write.all") || rolePermissions.includes(permission)) {
            hasPermission = true
            return hasPermission
        }
    }

    //Verify data role
    if (dataRoleId && permissions.dataRoles[`${dataRoleId}`] && permissions.dataRoles[`${dataRoleId}`][resource]) {
        let dataRolePermissions = permissions.dataRoles[`${dataRoleId}`][resource]

        if (dataRolePermissions.includes("read.write.all") || dataRolePermissions.includes(permission)) {
            hasPermission = true
        }
    }
    return hasPermission
}

// Get the number of weeks between 2 dates
const getUserRole = function () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let role = userInfo["https://alliedits/roles"][0]
    if (!role) {
        role = "User"
    }
    return RolesId[role]
}

function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined ?
            String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
            :
            true
    );
}

function addDays(date, days) {
    let newDate = new Date(date.valueOf())
    newDate.setDate(newDate.getDate() + days)
    return newDate;
}

function getInputFormatDate(today){
	//get current date with format YYYY-MM-DDTHH.MM
	let currentYear = today.getFullYear()
	let currentMonth = today.getMonth()+1
	let currentDay = today.getDate()	
    let date = currentYear + '-' + (currentMonth < 10 ? '0' + currentMonth : currentMonth) + '-' + (currentDay < 10 ? '0' + currentDay : currentDay) + 'T'+(today.getHours() < 10 ? '0' + today.getHours(): today.getHours() ) + ":" + (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes())
 
	return date
}

function getInputFormatDateY(today){
	//get current date with format YYYY-MM-DD HH:MM:SS
	today.setDate(today.getDate() - 5);
    let currentYear = today.getFullYear()
	let currentMonth = today.getMonth()+1
    
	let currentDay = today.getDate()

    let date = currentYear + '-' + (currentMonth < 10 ? '0' + currentMonth : currentMonth) + '-' + (currentDay < 10 ? '0' + currentDay : currentDay) + ' '+(today.getHours() < 10 ? '0' + today.getHours(): today.getHours() ) + ":" + (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()) + ":00"
 
	return date
}
function getReadableFormatDate(today,locale){
	//get current date with format YYYY-MM-DDTHH.MM
	let currentYear = today.getFullYear()
	let currentMonth = today.getMonth()+1
	let currentDay = today.getDate();
    let date;
    if(locale === "en-US"){
        date = (currentMonth < 10 ? '0' + currentMonth : currentMonth) + '/' +(currentDay < 10 ? '0' + currentDay : currentDay) + '/' + currentYear + ' '+(today.getHours() < 10 ? '0' + today.getHours(): today.getHours()) + ":" + (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes())
    }else{
         date = (currentDay < 10 ? '0' + currentDay : currentDay) + '/' + (currentMonth < 10 ? '0' + currentMonth : currentMonth) + '/' + currentYear + ' '+(today.getHours() < 10 ? '0' + today.getHours(): today.getHours()) + ":" + (today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes())
    }
	
    return date
}

function getFormatDateY(today, today_hours,locale){
	//get current date with format YYYY-MM-DDTHH.MM
    
    if(today_hours.getHours() >= 0 && today_hours.getHours() <6){
        today.setDate(today.getDate());
    }else{
        today.setHours(today.getHours() + 6)
    }
    
   
	let currentYear = today.getFullYear()
	let currentMonth = today.getMonth()+1
	let currentDay = today.getDate()
    today_hours.setHours(today_hours.getHours() - 6);
    let hours = today_hours.getHours()
    let date;
    if(locale === "en-US"){
         date =  (currentMonth < 10 ? '0' + currentMonth : currentMonth) + '-' + (currentDay < 10 ? '0' + currentDay : currentDay) + '-' + currentYear+ ' '+(hours < 10 ? '0' + hours: hours ) + ":" + (today_hours.getMinutes() < 10 ? '0' +today_hours.getMinutes() : today_hours.getMinutes() ) + ":"+ (today_hours.getSeconds() < 10 ? '0' +today_hours.getSeconds() : today_hours.getSeconds() )
    }else{
        date =  (currentDay < 10 ? '0' + currentDay : currentDay) + '-' + (currentMonth < 10 ? '0' + currentMonth : currentMonth) + '-' + currentYear+ ' '+(hours < 10 ? '0' + hours: hours ) + ":" + (today_hours.getMinutes() < 10 ? '0' +today_hours.getMinutes() : today_hours.getMinutes() ) + ":"+ (today_hours.getSeconds() < 10 ? '0' +today_hours.getSeconds() : today_hours.getSeconds() )
    }
	


    return date
}

function getFormatDate(date,locale){
    let longDate = Date.parse(date)
	if (longDate){
        let formatDate = getReadableFormatDate(new Date(longDate),locale)
        return formatDate
	}
	else
		return date
}

function currentWeekDates(date){
    const today = new Date()
    let currentYear = today.getFullYear()
    let currentWeek = getISOWeek(today)
    let dateYear = date.getFullYear()
    let dateWeek = getISOWeek(date)
    if (currentYear === dateYear && currentWeek === dateWeek)
        return true
    return false
}

const getDaysOfTheWeek = function(){
    let arrayDays = []
    if(getUserLocale() === "en-US"){
        arrayDays = [
            `All`,
            `Monday`,
            `Tuesday`,
            `Wednesday`,
            `Thursday`,
            `Friday`,
            `Saturday`,
            `Sunday`
        ]
    } else{
        arrayDays = [
            `Todos`,
            `Lunes`,
            `Martes`,
            `Miércoles`,
            `Jueves`,
            `Viernes`,
            `Sabado`,
            `Domingo`
        ]
    }
    return arrayDays
}

const getMonthOfYear = function(){
    let arrayDaysM = []
    if(getUserLocale() === "en-US"){
        arrayDaysM = [
            `All`,
            `January`,
            `February`,
            `March`,
            `April`,
            `May`,
            `June`,
            `July`,
            `August`,
            `September`,
            `October`,
            `November`,
            `December`
        ]
    } else{
        arrayDaysM = [
            `Todos`,
            `Enero`,
            `Febrero`,
            `Marzo`,
            `Abril`,
            `Mayo`,
            `Junio`,
            `Julio`,
            `Agosto`,
            `Septiembre`,
            `Octubre`,
            `Noviembre`,
            `Diciembre`
        ]
    }
    return arrayDaysM
}

export { addDays, getWeek, getWeeksNumberByYear, removeEmptyFields, formatDate, getWeeksNumberBetweenDates, verifyPermission, getUserRole, filterCaseInsensitive,
        getWeekByDate,
        getInputFormatDate,
        getFormatDate,
        getFormatDateY,
        currentWeekDates,
        getInputFormatDateY, 
        getDaysOfTheWeek, 
        getMonthOfYear,
        getMonthsByYear}