import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import AdminLayout from "layouts/Admin";
import Login from "views/Login";
import { overrides } from "themes";

const theme = createMuiTheme({ overrides });

const PrivateRoute = ({ component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props =>
				localStorage.getItem("id_token") ? (
					React.createElement(component, props)
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: props.location }
						}}
					/>
				)
			}
		/>
	);
};

const PublicRoute = ({ component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props =>
				localStorage.getItem("id_token") ? (
					<Redirect
						to={{
							pathname: "/"
						}}
					/>
				) : (
					React.createElement(component, props)
				)
			}
		/>
	);
};

const App = () => (
	<MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/admin/dashboard" />} />
        <Route exact path="/app" render={() => <Redirect to="/admin/dashboard" />} />
        <PrivateRoute path="/admin" component={AdminLayout} />
        <PublicRoute path="/login" component={Login} />
      </Switch>
    </BrowserRouter>
	</MuiThemeProvider>
);

export default App;
