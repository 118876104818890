const ApiUser = {
    EndPoint: process.env.REACT_APP_API_USER_URL
}

const ApiStats = {
    EndPoint: process.env.REACT_APP_API_STAT_URL
}

const ApiAdmin = {
    EndPoint: process.env.REACT_APP_API_ADMIN_URL
}

const Web = {
    EndPoint: process.env.REACT_APP_WEB_APP_URL
}

const ApiTeam = {
    EndPoint: process.env.REACT_APP_API_TEAM_URL
}

const DataTypes = {
    1: {
        decimalScale: 2,
        allowNegative: true,
        allowLeadingZeros: false,
        displayName: "Numérico"
    },
    2: {
        decimalScale: 2,
        suffix: "%",
        allowNegative: true,
        allowLeadingZeros: false,
        displayName: "Porcentaje"
    },
    3: {
        decimalScale: 2,
        suffix: "$",
        allowNegative: true,
        allowLeadingZeros: false,
        displayName: "Dólar"
    },
    4: {
        decimalScale: 2,
        suffix: "Q",
        allowNegative: true,
        allowLeadingZeros: false,
        displayName: "Quetzal"
    },
    5: {
        decimalScale: 0,
        suffix: "Hrs",
        allowNegative: true,
        allowLeadingZeros: false,
        displayName: "Horas"
    }
}

const Months = {
    1: `Enero`,
    2: `Febrero`,
    3: `Marzo`,
    4: `Abril`,
    5: `Mayo`,
    6: `Junio`,
    7: `Julio`,
    8: `Agosto`,
    9: `Septiembre`,
    10: `Octubre`,
    11: `Noviembre`,
    12: `Diciembre`,
}

const MonthsE = {
    1: `January`,
    2: `February`,
    3: `March`,
    4: `April`,
    5: `May`,
    6: `June`,
    7: `July`,
    8: `August`,
    9: `September`,
    10: `October`,
    11: `November`,
    12: `December`,
}
const Days = {
    1: `Lunes`,
    2: `Martes`,
    3: `Miércoles`,
    4: `Jueves`,
    5: `Viernes`
}

const DaysE = {
    1: `Monday`,
    2: `Tuesday`,
    3: `Wednesday`,
    4: `Thursday`,
    5: `Friday`
}

const LoginUser = {
    RedirectEndPoint: `${Web.EndPoint}login`,
    LogoutEndPoint: `${Web.EndPoint}`,
    ExpiredEndPoint: `${Web.EndPoint}error/sessionexpired`,
    InsertUserUrl: `${ApiUser.EndPoint}/v1/login`,
    UserByMail: `${ApiUser.EndPoint}/v1/usermail`,
    StressEndPoint: `${ApiUser.EndPoint}/v1/login/stress`,
}

const History = {
    GetLogsEndpoint: `${ApiTeam.EndPoint}/v1/history`
}

const Commitment = {
    GetEndPoint: `${ApiAdmin.EndPoint}/v1/team`,
    GetUserCommitmentEndPoint: `${ApiAdmin.EndPoint}/v1/user/commitment`,
    GetCommitmentListbyMinuteEndPoint: `${ApiAdmin.EndPoint}/v1/team/minute`,
    GetCommitmentByIDEndPoint: `${ApiAdmin.EndPoint}/v1/team/commitment/commitmentId`,
    GetClearPathtByIDEndPoint: `${ApiAdmin.EndPoint}/v1/team/commitment/clearpath`,
    GetTrackingByIDEndPoint: `${ApiStats.EndPoint}/v1/team/commitment/tracking`,
    GetCommitmentReminder: `${ApiAdmin.EndPoint}/v1/user/reminder`,
    GetCommitmentAv: `${ApiStats.EndPoint}/v1/assigned/commitments`
}

const WIG = {
    CreateEndPoint: `${ApiAdmin.EndPoint}/v1/team`,
    GetEndPoint: `${ApiAdmin.EndPoint}/v1/wig`,
    GetEndPointCopy :  `${ApiAdmin.EndPoint}/v1/wig/copy`,
    GetEndPointChangeStatus: `${ApiAdmin.EndPoint}/v1/wig/status`,
    GetEndPointunasigned: `${ApiAdmin.EndPoint}/v1/wig/unasigned`,
    GetEndPointParentWig: `${ApiAdmin.EndPoint}/v1/wig/inactivate/parent`,
    GetEndPointWigInactivate: `${ApiAdmin.EndPoint}/v1/wig/inactivate/wigs`,
    GetEndPointLast: `${ApiAdmin.EndPoint}/v1/team/lastwig`,
    GetEndPointUpdateParentWIG: `${ApiAdmin.EndPoint}/v1/wig/update/parent`,
    GetEndPointCopyWIGAxis: `${ApiAdmin.EndPoint}/v1/axis/WIG`,
    GetWIGsFromTeam: `${ApiAdmin.EndPoint}/v1/wig/user/team`,
    GetWIgAxisID: `${ApiAdmin.EndPoint}/v1/wig/axis`,
    GetEndPointWigList: `${ApiAdmin.EndPoint}/v1/wig/leader`,
    GetEndPointStat : `${ApiStats.EndPoint}/v1/wig`
}

const Team = {
    GetEndPoint: `${ApiTeam.EndPoint}/v1/team`,
    GetUserTeamEndPoint: `${ApiTeam.EndPoint}/v1/user/team`,
    GetUserTeamLeaderEndPoint: `${ApiTeam.EndPoint}/v1/user/teamleader`,
    GetDataRolebyUserIDEndPoint: `${ApiTeam.EndPoint}/v1/team/datarole`,
    GetAccountsEndPoint: `${ApiTeam.EndPoint}/v1/accounts/team`,
    GetCountriesEndPoint: `${ApiTeam.EndPoint}/v1/countries/team`,
    GetDepartmentsEndPoint: `${ApiTeam.EndPoint}/v1/departments/team`,
    GetImage: `${ApiAdmin.EndPoint}/v1/user/image`,
    SetImage: `${ApiAdmin.EndPoint}/v1/image`,
    GetTeamsForParent: `${ApiTeam.EndPoint}/v1/parent/team`
}

const Accountability = {
    GetEndPoint: `${ApiAdmin.EndPoint}/v1/accountability`,
    GetQuestionsEndPoint: `${ApiStats.EndPoint}/v1/accountability/questions`,
    GetRecordsByTeamEndPoint: `${ApiStats.EndPoint}/v1/accountability/records/team`,
    GetRecordEndPoint: `${ApiStats.EndPoint}/v1/accountability/record`,
    PostAccountabilityRecordEndPoint: `${ApiStats.EndPoint}/v1/accountabilityrecord/team`,
    PostAccountabilityMultipleRecordEndPoint: `${ApiStats.EndPoint}/v1/accountabilitymultiplerecord/team`,
    GetRecordByTeamEndPoint: `${ApiStats.EndPoint}/v1/accountability/lastrecord/team`,
    PostMinuteTeam: `${ApiStats.EndPoint}/v1/accountabilitycreateminute/team`,
    GetMinutesByTeamEndPoint: `${ApiStats.EndPoint}/v1/accountability/minuteslist/team`,
    GetMultipleRecordById: `${ApiStats.EndPoint}/v1/accountability/multiplerecord`,
    GetMultipleRecordParticipantsById: `${ApiStats.EndPoint}/v1/accountability/multiplerecorparticipants`,
    GetRecordById: `${ApiStats.EndPoint}/v1/accountability/record/`,
    GetLastMinuteTeamEndPoint: `${ApiStats.EndPoint}/v1/accountability/lastminute/team`,
    GetRecordList: `${ApiStats.EndPoint}/v1/user/records`
}

const Predictive = {
    GetEndPoint: `${ApiAdmin.EndPoint}/v1/predictive`,
    GetEndPointCOPY: `${ApiAdmin.EndPoint}/v1/wig/predictive/copy`,
    GetEndPointInactivate: `${ApiAdmin.EndPoint}/v1/wig/predictive/status`,
    GetEndPointCOPYTEAM: `${ApiAdmin.EndPoint}/v1/team/predictive/copy`,
    GetEndPointUnasigned: `${ApiAdmin.EndPoint}/v1/wig/predictives/update/unasigned`,
    GetEndPointUnasignedP: `${ApiAdmin.EndPoint}/v1/wig/predictive/unasigned`,
    GetEndPointUpdateWigID: `${ApiAdmin.EndPoint}/v1/wig/predictive/update/wig`,
    GetEndPointCopyPredictiveAxis: `${ApiAdmin.EndPoint}/v1/axis/predictive`,
    GetEndPointUpdateActive: `${ApiAdmin.EndPoint}/v1/predictive/status/update`,
    GetEndPointToParent: `${ApiAdmin.EndPoint}/v1/predictives/parent/team`,
    GetEndPointGetPredictivesID: `${ApiAdmin.EndPoint}/v1/predictives/user/team`,
    GetEndPointGet: `${ApiAdmin.EndPoint}/v1/predictives/user/team/wig`,
    GetEndPointStat: `${ApiStats.EndPoint}/v1/predictive`,
    GetEndPointStatAxis: `${ApiStats.EndPoint}/v1/predictiveAxi`,
    GetEndPointGet: `${ApiAdmin.EndPoint}/v1/predictives/user/team/wig`
}

const Axis = {
    GetEndPoint: `${ApiStats.EndPoint}/v1/axis`,
    
}

const User = {
    GetEndPoint: `${ApiUser.EndPoint}/v2/user`,
    SaveImage: `${ApiUser.EndPoint}/v1/image`,
   
}

const Stat = {
    Dataset0: `${ApiStats.EndPoint}/v1/dataset0/`,
    GetReportByUser: `${ApiStats.EndPoint}/v1/reportbyuser/`
}

const Auth0 = {
    Domain: process.env.REACT_APP_AUTH0_DOMAIN,
    ClientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    Audience: process.env.REACT_APP_AUTH0_AUDIENCE
}

const Translations = {
    createdAt: "Fecha",
    month: "Mes",
    y: "Meta trazada",
    goalAchived: "Meta alcanzada",
    difference: "Diferencia",
    commentary: "Comentario",
    loading: "Cargando"
}

const ResponseMessages = {
    'INVALID_TOKEN': 'INVALID_TOKEN',
    'ROLE_REQUIRED': 'ROLE_REQUIRED'
}

const Roles = {
    1: "Super Administrador",
    2: "Administrador",
    3: "Usuario",
    4: "Invitado"
}

const RolesId = {
    "SuperAdmin": 1,
    "Admin": 2,
    "User": 3,
    "Guest": 4
}

const DataRoles = {
    1: "Líder",
    2: "Co-líder",
    3: "Participante"
}

const CommitmentStatus = {
    0: "Eliminado",
    1: "Trabajando",
    2: "Completado",
    3: "Cerrado"
}

export {
    Axis,
    ApiUser,
    ApiStats,
    ApiAdmin,
    Auth0,
    History,
    Commitment,
    CommitmentStatus,
    DataRoles,
    DataTypes,
    Days,
    DaysE,
    LoginUser,
    Months,
    MonthsE,
    Predictive,
    ResponseMessages,
    Roles,
    RolesId,
    Stat,
    Team,
    Translations,
    User,
    WIG,
    Accountability
};
