import React from "react";

//Color
//import { primaryColor, successColor, barColor, warningColor, dangerColor } from "assets/jss/material-dashboard-pro-react.js";
import { barColor } from "assets/jss/material-dashboard-pro-react.js";

//import { ComposedChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip as RechartTooltip, Legend, ResponsiveContainer, Line } from 'recharts';

import {
  getBarColor,
  formatValue,
  getDataTypeDisplayName,
} from "utils/dataTypeFormatter.js";
import { translate } from "utils/translate";

import { Chart, registerables } from "chart.js";
import { Chart as ChartJS2 } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables, ChartDataLabels);

export default function TrackingChart(props) {

  let dataType = getDataTypeDisplayName(props.dataTypeId);
  let config = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          fontStyle: "normal",
          fontSize: 13,
          display: (props.type == "predictive" && (props.detailsConsistency == "diario" || props.detailsConsistency == "daily") ? false : true)
        },
        stacked: isDescendentWIG(),
      },
      y: {
        display: true,
        title: {
          display: true,
          text: translate(dataType),
        },
        stacked: false,
      },
    },
    layout: {
      padding: {
        top: 30,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {},
      },
    },
    spanGaps: true,
  };

  function formatChartValue(number) {
    let value = formatValue(number, props.dataTypeId);
    return value;
  }

  function maxValue(data) {
    let max = Math.max.apply(
      Math,
      data.map(function (o) {
        return o.goalAchived;
      })
    );
    return Math.round(max);
  }

  function getUpperDomainValue(max) {
    let upper = max * 1.05;
    return Math.round(upper);
  }

  function formatAverageDataset(dataset) {
    let monthlyValue = 0; //Variable to save the current value of the month during the sumatory of all
    let averageValue = 0; //Variable to save the average goalAchieved of each month
    let counter = 1;

    const newDataset = dataset.map((x) => {
      if (x.goalAchived > 0) {
        monthlyValue = x.goalAchived + monthlyValue;
        averageValue = monthlyValue / counter;
        averageValue = averageValue.toFixed(2);
        counter++;
      }

      // return ({ name: x.name, x: x.x, goalAchived: x.goalAchived !== null ? averageValue : null, level3: x.level3 })
      return x.goalAchived !== null ? averageValue : null;
    });
    return newDataset;
  }

  function formatCumulativeDatasetAscendent(dataset) {
    let monthlyValue = 0; //Variable to save the current value of the month during the sumatory of all

    /*
    const newDataset = dataset.map(x => {
      monthlyValue = x.goalAchived + monthlyValue
      //monthlyValue = monthlyValue.toFixed(2)
      return ({ name: x.name, x: x.x, goalAchived: x.goalAchived !== null ? monthlyValue : null })
    });
    */
    const newDataset = dataset.map((x) => {
      let goalAchived_casted = x.goalAchived == null ? 0 : x.goalAchived;
      monthlyValue = Number(goalAchived_casted) + Number(monthlyValue);
      monthlyValue = Number(monthlyValue).toFixed(2);
      return x.goalAchived !== null ? monthlyValue : null;
    });

    return newDataset;
  }

  function formatCumulativeDatasetDescendent(dataset) {
    /*
    const newDataset = dataset.map(x => {
      return ({ name: x.name, x: x.x, goalAchived: x.goalAchived, level3: x.level3, cumulativeSaving: x.goalAchived !== null ? x.x - x.goalAchived : null })
    });
    */
    const x = dataset.map((x) => {
      return x.x;
    });
    const minRange = dataset.map((x) => {
      return x.goalAchived;
    });
    const maxRange = dataset.map((x) => {
      return x.goalAchived !== null
        ? [x.goalAchived, x.goalAchived + (x.x - x.goalAchived)]
        : null;
    });

    return [minRange, maxRange, x];
  }

  function showPeriodGoal(data) {
    //? Check if all period goals are 0, if so hide the corresponding trend line
    let show = false;
    data.forEach((monthValue) => {
      if (
        monthValue.level1 ||
        monthValue.level2 ||
        monthValue.level3 
      ) {
        show = true;
      }
      return show;
    });
    return show;
  }

  function isDescendentWIG() {
    if (props.wig !== undefined) {
      if (config) {
        // variable config may not be initialized in time. This ensures it is

        // Chart tooltip configuration
        config.plugins.tooltip.callbacks["label"] = function (context) {
          if (context.raw !== null) {
            if (context.raw.length == 2) {
              let value = context.raw[1] - context.raw[0];
              // Modify your final tooltip here
              return context.dataset.label + ": " + value;
            } else {
              return context.dataset.label + ": " + context.raw;
            }
          }
        };
      }

      return props.wig.x1 > props.wig.y1;
    }
    return false;
  }

  // Adds commas and dots to numeric values
  function formatChartValues(value, context) {
    let DIGIT_PATTERN = /(^|\s)\d+(?=\.?\d*($|\s))/g;
    let MILI_PATTERN = /(?=(?!\b)(\d{3})+\.?\b)/g;
    if (value) {
      return value
        .toString()
        .replace(DIGIT_PATTERN, (m) => m.replace(MILI_PATTERN, ","));
    } else {
      return "0";
    }
  }

  function setGraphLabels(dataset) {
    let labels = dataset.map(x => {
      if (props.type === "wig") {
        x.name = x.name
      } else if (props.type === "predictive" && (props.detailsConsistency === "daily" || props.detailsConsistency === "diario")) {
        //x.name = x.day != null ? x.day : ""
        x.name = `Día: ${x.dia}`
      }

      return x.name
    })
    return labels;
  }

  function setGoalAchivedDataset(dataset) {
    let data = dataset.map((x) => {
      return x.goalAchived;
    });
    return data;
  }

  function setExpectedGoalsDataset(dataset) {
    let data = dataset.map((x) => {
      return x.level3;
    });
    return data;
  }

  function setAcceptableGoalsDataset(dataset) {
    let data = dataset.map((x) => {
      return x.level2;
    });
    return data;
  }

  function setFailedGoalsDataset(dataset) {
    let data = dataset.map((x) => {
      return x.level1;
    });
    return data;
  }

  function setBarColors(dataset) {
    let colors = dataset.map((x) => {
      return getBarColor(x);
    });
    return colors;
  }

  function getData() {
    let data =
      props.graphType === "cumulative"
        ? isDescendentWIG()
          ? formatCumulativeDatasetDescendent(props.data)
          : formatCumulativeDatasetAscendent(props.data)
        : props.graphType === "average" //? Is a cumulative average chart
          ? formatAverageDataset(props.data)
          : setGoalAchivedDataset(props.data);

    return data;
  }

  function getDatasets() {
    let dataSet = [];
    let data = getData();
    let showPeriodGoals = showPeriodGoal(props.data);

    if (isDescendentWIG()) {
      dataSet = [
        {
          type: "bar",
          label: props.name,
          data: data[0],
          borderColor: "white",
          backgroundColor:
            props.graphType === "cumulative" || props.graphType === "average"
              ? barColor
              : setBarColors(props.data),
          order: 1,
          datalabels: {
            color: "black",
            anchor: "end",
            align: "top",
            formatter: function (value, context) {
              return formatChartValues(value, context);
            },
          },
        },
        {
          type: "bar",
          label: translate("cumulative_saving"),
          data: data[1],
          borderColor: "white",
          backgroundColor: "#000",
          order: 0,
          datalabels: {
            display: false,
          },
        },
        {
          type: "line",
          label: "x",
          data: data[2],
          borderColor: "#d32f2f",
          tension: 0.4,
          cubicInterpolationMode: "monotone",
          fill: false,
          datalabels: {
            display: false,
          },
        },
      ];
      if (props.graphType === "average") {
        dataSet = [
          {
            type: "bar",
            label: props.name,
            data: data,
            borderColor: "white",
            backgroundColor:
              props.graphType === "cumulative" || props.graphType === "average"
                ? barColor
                : setBarColors(props.data),
            order: 1,
            datalabels: {
              color: "black",
              anchor: "end",
              align: "top",
              formatter: function (value, context) {
                return formatChartValues(value, context);
              },
            },
          },
        ];
      }
    } else {
      dataSet = [
        {
          type: "bar",
          label: props.name,
          data: data,
          borderColor: "white",
          backgroundColor:
            props.graphType === "cumulative" || props.graphType === "average"
              ? barColor
              : setBarColors(props.data),
          order: 1,
          datalabels: {
            color: "black",
            anchor: "end",
            align: "top",
            formatter: function (value, context) {
              return formatChartValues(value, context);
            },
          },
        },
      ];
      if (props.type !== "wig" && (props.consistency === "daily" || props.consistency === "diario") && props.axesNumber === 1) {
          dataSet = [
            {
              type: 'line',
              label: props.name,
              data: data,
              borderColor: "#919191",
              backgroundColor: (props.graphType === "cumulative" || props.graphType === "average") ? barColor : setBarColors(props.data),
              tension: 0.4,
              cubicInterpolationMode: 'monotone',
              fill: false,
              order: 1,
              datalabels: {
                display: false
              }
            }
          ]
      } else if(props.type !== "wig" && (props.consistency === "weekly" || props.consistency === "semanal") && props.axesNumber === 1) {
        dataSet = [
          {
            type: "bar",
            label: props.name,
            data: data,
            borderColor: "white",
            backgroundColor:
              props.graphType === "cumulative" || props.graphType === "average"
                ? barColor
                : setBarColors(props.data),
            order: 1,
            datalabels: {
              color: "black",
              anchor: "end",
              align: "top",
              formatter: function (value, context) {
                return formatChartValues(value, context);
              },
            },
          },
        ];
      } else{
        if (props.axesNumber === 2) {
          if (props.type !== "wig" && (props.detailsConsistency === "daily" || props.detailsConsistency === "diario")) {
            dataSet = [
              {
                type: 'line',
                label: props.name,
                data: data,
                borderColor: "#919191",
                backgroundColor: (props.graphType === "cumulative" || props.graphType === "average") ? barColor : setBarColors(props.data),
                tension: 0.4,
                cubicInterpolationMode: 'monotone',
                fill: false,
                order: 1,
                datalabels: {
                  display: false
                }
              }
            ]
          }
          
          if (props.type !== "wig" && (props.detailsConsistency === "weekly" || props.detailsConsistency === "semanal")) {
            dataSet = [
              {
                type: "bar",
                label: props.name,
                data: data,
                borderColor: "white",
                backgroundColor:
                  props.graphType === "cumulative" || props.graphType === "average"
                    ? barColor
                    : setBarColors(props.data),
                order: 1,
                datalabels: {
                  color: "black",
                  anchor: "end",
                  align: "top",
                  formatter: function (value, context) {
                    return formatChartValues(value, context);
                  },
                },
              },
            ];
          }
        }
      } 
    }

    if (
      showPeriodGoals &&
      (props.graphType !== "cumulative" && props.graphType !== "average")
    ) {
      dataSet.push(
        {
          label: translate("const_goalexpected"),
          data: setExpectedGoalsDataset(props.data),
          borderColor: "#388e3c",
          type: "line",
          tension: 0.4,
          cubicInterpolationMode: "monotone",
          fill: false,
          datalabels: {
            display: false,
          },
        },
        {
          label: translate("const_goalacceptable"),
          data: setAcceptableGoalsDataset(props.data),
          borderColor: "#f57c00",
          type: "line",
          tension: 0.4,
          cubicInterpolationMode: "monotone",
          fill: false,
          datalabels: {
            display: false,
          },
        },
        {
          label: translate("const_goalfailed"),
          data: setFailedGoalsDataset(props.data),
          borderColor: "#d32f2f",
          type: "line",
          tension: 0.4,
          cubicInterpolationMode: "monotone",
          fill: false,
          datalabels: {
            display: false,
          },
        }
      );
    }

    return dataSet;
  }

  return (
    <>
      {props.type === "wig" ? (
        <ChartJS2
          data={{
            labels: setGraphLabels(props.data),
            datasets: getDatasets(),
          }}
          options={config}
        />
      ) : (
        <ChartJS2
          data={{
            labels: setGraphLabels(props.data),
            datasets: getDatasets(),
          }}
          options={config}
        />
      )}
    </>
  );
}
