import SchemaValidator from "./SchemaValidator"

  /**
 * Validates an object according to the model provided
 */
function validateObject(model, data){
  let validationResult = {}
  // Validate output
  let validator = new SchemaValidator(model)
  let check = validator.validate(data)

  validationResult.isValid = check.isValid
  validationResult.errors = check.messages
  return validationResult
}

/**
 * Validates an object property according to the model provided
 */
function validateProperty(model, data, property){
  let validationResult = {}
  // Validate output
  let validator = new SchemaValidator(model)
  let check = validator.validateProperty(property, data)

  validationResult.isValid = check.isValid
  validationResult.errors = check.messages
  return validationResult
}

/**
 * Validates an object property according to the model provided
 */
function validateArrayProperty(model, data){
  let validationResult = {}
  // Validate output
  let validator = new SchemaValidator(model)
  let check = validator.validateArrayElement(model, data)

  validationResult.isValid = check.isValid
  validationResult.errors = check.messages
  return validationResult
}
  
  export { validateObject, validateProperty, validateArrayProperty }