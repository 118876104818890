import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { teamAccountabilitySchema } from "models/teamModel";
import { addDays } from "utils/functions"
import { getTeamById, updateTeamAccountability, getAccountabilitySchedule, getUsersByTeam } from "services/apiAdmin/teamApi";
// questions Api
import { getAllQuestions, saveAccountabilityGrades, saveMultipleAccountabilityGrades } from "services/apiAdmin/accountabilityApi";
import { setTeamMembersList } from "store/AdminState";
import { translate} from "utils/translate";

import { verifyPermission } from "utils/functions";

import AccountabilityView from "./recordView";

const colorsArray = [
  "default",
  "green",
  "red",
  "azure",
  "orange",
  "rose",
]

const newTeam = {
  title: "",
  parentTeamId: "",
  day: "",
  time: "7:00:00"
}

const gradesData = {
  'acGrade_0': [],
  'acGrade_1': [],
  'acGrade_2': [],
  'acGrade_3': [],
  'acGrade_4': [],
  'acGrade_5': [],
  'acGrade_6': [],
  'acGrade_7': [],
  'acGrade_8': []
}

const multipleGradesData = {
  acGrade_0: 0,
  acGrade_1: 0,
  acGrade_2: 0,
  acGrade_3: 0,
  acGrade_4: 0,
  acGrade_5: 0,
  acGrade_6: 0,
  acGrade_7: 0,
  acGrade_8: 0
}

const multipleNotesData = {
  acNote_0: "",
  acNote_1: "",
  acNote_2: "",
  acNote_3: "",
  acNote_4: "",
  acNote_5: "",
  acNote_6: "",
  acNote_7: "",
  acNote_8: ""
}

const gradesState = {
  'acGrade_0': "success",
  'acGrade_1': "success",
  'acGrade_2': "success",
  'acGrade_3': "success",
  'acGrade_4': "success",
  'acGrade_5': "success",
  'acGrade_6': "success",
  'acGrade_7': "success",
  'acGrade_8': "success"
}

const participantsData = [];

const newTeamState = {
  title: "",
  parentTeamId: "",
  day: "",
  time: ""
}

let _isMounted = false

async function onSubmit(props) {
  let result = await updateTeamAccountability(props.teamId, props.team)
  // Show confirmation message
  if (result && result.success && _isMounted) {
    props.setCreateSuccess(true)
    setTimeout(function () {
      if (_isMounted) {
        props.setCreateSuccess(false)
        //props.history.push('/admin/dashboard')
      }
    }, 1500);
  } else {
    // Show error message
    if (result) {
      let message = result.message
      if (!message) {
        message = "Algo ocurrió mal, inténtalo nuevamente."
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

async function getTeam(props, teamId) {
  let result = await getTeamById(teamId)

  if (result.success) {
    if (_isMounted) {
      props.setTeam(result.data)
    }

  }
}

async function getTeamUsers(props, teamId) {
  if (!teamId) {
    return
  }
  let result = await getUsersByTeam(teamId);
  if (_isMounted && result.success) {
    props.setTeamMembersList(result.data)
  }
}

let questionsList;
let message;
async function getQuestions() {

  let result = await getAllQuestions();
  if (_isMounted && result.success) {
    questionsList = result.data;
  }
}



async function getSchedule(props, filter) {
  let result = await getAccountabilitySchedule(filter)

  if (result.success) {
    if (_isMounted) {
      const today = new Date();
      const y = today.getFullYear()
      const m = today.getMonth() + 1
      const wd = today.getDay()

      let events = result.data.map((team) => {
        let eventDate = addDays(today, team.day - wd)
        eventDate = new Date(`${y}-${m}-${String(eventDate.getDate()).padStart(2, '0')} ${team.time}`)
        return {
          teamId: team.teamId,
          title: team.title,
          allDay: false,
          start: eventDate,
          end: new Date(eventDate.getTime() + 30 * 60000),
          color: colorsArray[Math.floor(Math.random() * 6)]
        }
      })
      props.setEvents(events)
    }

  }
}

async function onGradesSubmit(props) {

  // defining data object
  let data = {};

  let questions = props.questionsList.map(question => {
    return question.Id
  })
  data['grades'] = props.multipleGrades;
  data['notes'] = props.multipleNotes;
  data['notes'][0][8] = props.observation
  data['participants'] = props.participants;
  data['questions'] = questions;
  let result = await saveMultipleAccountabilityGrades(data, props.teamId);

  if (result.success === true) {
    window.location.href = `/admin/accountability`;
  } else {
    return { success: false }
  }

}

function isEmptyGrade(arrayGrade){
  if (arrayGrade.length === 0)
    return true

  arrayGrade.forEach((grade) => {
    if (grade !== "")
      return false
  });
  return true
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      teamId: state.admin.teamId,
      roleId: state.admin.roleId,
      team: state.admin.team,
      membersList: state.admin.membersList,
      questionsList: questionsList
    }),
    { setTeamMembersList }
  ),
  withState("team", "setTeam", { ...newTeam }),
  withState("teamState", "setTeamState", { ...newTeamState }),
  withState("events", "setEvents", []),
  withState("viewAllTeams", "setViewAllTeams", true),
  withState("createSuccess", "setCreateSuccess", false),
  withState("createError", "setCreateError", false),
  withState("validationMessage", "setValidationMessage", ""),
  withState("isLoadingSave", "setIsLoadingSave", false),
  withState("grades", "changeGrades", { ...gradesData }),
  withState("multipleGrades", "setMultipleGrades", []),
  withState("multipleNotes", "setMultipleNotes", []),
  withState("gradesState", "changeGradesState", { ...gradesState }),
  withState("participants", "changeParticipants", participantsData),
  withState("selectedMembers", "setSelectedMembers", []),
  withState("selectedTab", "setSelectedTab", 0),
  withState("observation", "setObservation", "N/A"),
  withState("errorMessage", "setErrorMessage", ""),
  withHandlers({
    onChangeTab: (props) => (element, value) =>{
      props.setSelectedTab(value)
    },
    onFieldChange: (props) => (field, value) => {
      props.team[field] = value
      let isValid = validateProperty(teamAccountabilitySchema, props.team, field).isValid
      if (isValid) {
        props.teamState[field] = "success"
      } else {
        props.teamState[field] = "error"
      }
      props.setTeamState(props.teamState)
      props.setTeam(props.team)
    },
    onParticipantsChange: (props) => (value) => {
      props.participants.length = 0;
      if (props.selectedTab > value.length - 1)
        props.setSelectedTab(0)

      let members = []

      for (let i = 0, l = value.length; i < l; i += 1) {
        props.participants[i] = value[i];
        let participant = props.membersList.find(element => element.userId === value[i])
        if (participant){
          let findMember = props.selectedMembers.find(member => member.userId === value[i])
          let memberGrade = ["10","10","10","10","10","10","10","10",""]
          let memberNote = ["N/A","N/A","N/A","N/A","N/A","N/A","N/A","N/A",""]
          if (findMember){
            memberGrade = findMember.memberGrade
            memberNote = findMember.memberNote
          }
          participant.memberGrade = memberGrade
          participant.memberNote = memberNote
          members.push(participant)
        }
          
      };

    let multipleGrades = []
    let multipleNotes = []
    members.map((member, index) =>{
      //let gradeReference = multipleGradesData
      //let noteReference = multipleNotesData
      let gradeReference = member.memberGrade
      let noteReference = member.memberNote
      multipleGrades.push(gradeReference)
      multipleNotes.push(noteReference)
    })

    props.setMultipleGrades(multipleGrades)
    props.setMultipleNotes(multipleNotes)

      props.changeParticipants(props.participants);
      props.setSelectedMembers(members)
    },
    onGradeChange: (props) => (field, value) => {
      props.grades[field][0] = value;
      props.changeGrades(props.grades);
    },
    onMultipleGradesChange: (props) => (index, field, value) => {
      props.multipleGrades[index][field] = value;
      props.setMultipleGrades(props.multipleGrades);
      props.selectedMembers[index].memberGrade[field] = value;
      props.setSelectedMembers(props.selectedMembers)
    },
    onNotesChange: (props) => (field, value) => {
      props.grades[field][1] = value;
      if (field === `acGrade_8`) {
        props.grades[field][0] = 'N/A';
      }
      if (value.length < 1) {
        // un comment this if notes are required. 
        // props.gradesState[field] = 'error';
        props.gradesState[field] = 'success';
      } else {
        props.gradesState[field] = 'success';
      }
      props.changeGrades(props.grades);
      props.changeGradesState(props.gradesState);
    },
    onMultipleNotesChange: (props) => (index, field, value) => {
      props.multipleNotes[index][field] = value;
      props.setMultipleNotes(props.multipleNotes);
      props.selectedMembers[index].memberNote[field] = value;
      props.setSelectedMembers(props.selectedMembers)

      if (value.length > 60 || value.length == 0) {
        props.gradesState["acGrade_"+field] = 'error';
      } else {
        props.gradesState["acGrade_"+field] = 'success';
      }
      props.changeGradesState(props.gradesState);
    },
    onFilterChange: (props) => async (field, value) => {
      props.setViewAllTeams(value)

      getSchedule(props, value)
    },
    gradesSave: (props) => async () => {
      props.setIsLoadingSave(true)

      let canSave = false;
      if (props.participants.length > 0 && props.questionsList.length >0 && props.selectedMembers.length > 0 && props.multipleGrades.length>0 && props.multipleNotes.length>0 && props.observation !== ""){
        canSave = true;
        if(props.observation.length > 512){
          canSave = false
          message =translate("accountability_fields_length3")
        }
        props.multipleGrades.forEach((grade, index) => {
          grade.forEach((element, i) => {
            if (i === 8){
            }
            else
              if (element === "")
                canSave = false
          })
        });
        props.multipleNotes.forEach((note, index) => {
          note.forEach((element, i) => {
            if (i === 8){
              
            }
            else
              if (element === ""){
                canSave = false
                message= translate("accountability_fill_fields")
              }
              if( element.length > 60){
                canSave = false
                 message =translate("accountability_fields_length") + " " + (i+1) + " " + translate("accountability_fields_length1")
              
                
              }
               

          })
        });

      }else if( props.multipleGrades.length=0 || props.multipleNotes.length>0 || props.observation == ""){
        message = translate ("accountability_fill")
       
      }

      setTimeout(async function () {
        props.setIsLoadingSave(false)
        if (canSave) {
          let result = await onGradesSubmit(props);
          if (result.success) {
            props.setCreateSuccess(true);
          } else {
            props.setValidationMessage('Algo ocurrió mal, inténtalo nuevamente.')
            props.setCreateError(true)
          }
          setTimeout(function () {
            if (_isMounted) {
              props.setCreateSuccess(false)
              //props.history.push('/admin/dashboard')
            }
          }, 2000);
        } else {
          props.setValidationMessage(message)
          props.setCreateError(true)
          setTimeout(function() {
            props.setCreateError(false)
          },2000)
        }

      }, 1000);

    },
    onSave: (props) => async () => {
      props.setIsLoadingSave(true)

      let validation = validateObject(teamAccountabilitySchema, props.team)
      if (validation.isValid) {
        await onSubmit(props)
      } else {
        let field
        for (field in props.team) {
          if (validation.errors[field]) {
            props.teamState[field] = "error"
          }
        }
        props.setTeamState(props.teamState)
      }
      props.setIsLoadingSave(false)

      if (verifyPermission(props.roleId, props.team.dataRoleId, "calendar", "read.all")) {
        getSchedule(props, props.viewAllTeams)
      }
    }
  }),
  lifecycle({
    componentWillMount() {
      getQuestions();
    },
    componentDidMount() {
      _isMounted = true

      getTeam(this.props, this.props.teamId)
      getTeamUsers(this.props, this.props.teamId)


      if (verifyPermission(this.props.roleId, this.props.team.dataRoleId, "calendar", "read.all")) {
        getSchedule(this.props, true)
      }

      this.props.setTeamState({ ...newTeamState })
      this.props.changeParticipants(participantsData)
      this.props.setCreateSuccess(false)
      this.props.setViewAllTeams(true)
      this.props.setSelectedMembers([])
      this.props.changeParticipants([])
    },
    UNSAFE_componentWillUpdate(nextState) {
      
      // Check team changes
      if (nextState.teamId !== this.props.teamId) {
        getTeam(this.props, nextState.teamId)
        getTeamUsers(this.props, this.props.teamId)
        this.props.setSelectedMembers([])
        this.props.changeParticipants([])
      }
    },
    componentWillUnmount() {
      _isMounted = false
    }
  })
)(AccountabilityView);
