import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { teamAccountabilitySchema } from "models/teamModel";
import { addDays } from "utils/functions"
import { getTeamById, updateTeamAccountability, getAccountabilitySchedule } from "services/apiAdmin/teamApi";
import { verifyPermission } from "utils/functions";

import AccountabilityView from "./AccountabilityView";

const colorsArray = [
	"default",
	"green",
	"red",
	"azure",
	"orange",
	"rose"
]

const newTeam = {
	title: "",
	parentTeamId: "",
	day: "",
	time: "7:00:00"
}

const newTeamState = {
	title: "",
	parentTeamId: "",
	day: "",
	time: ""
}

let _isMounted = false

async function onSubmit(props) {
	let result = await updateTeamAccountability(props.teamId, props.team)
	// Show confirmation message
	if (result && result.success && _isMounted) {
		props.setCreateSuccess(true)
		setTimeout(function () {
			if (_isMounted) {
				props.setCreateSuccess(false)
				//props.history.push('/admin/dashboard')
			}
		}, 1500);
	} else {
		// Show error message
		if (result) {
			let message = result.message
			if (!message) {
				message = "Algo ocurrió mal, inténtalo nuevamente."
			}
			props.setValidationMessage(message)
			props.setCreateError(true)
		}
	}
}

async function getTeam(props, teamId) {
	let result = await getTeamById(teamId)

	if (result.success) {
		if (_isMounted) {
			props.setTeam(result.data)
		}

	}
}

async function getSchedule(props, filter) {
	let result = await getAccountabilitySchedule(filter)

	if (result.success) {
		if (_isMounted) {
			const today = new Date();
		
			const y = today.getFullYear()
			const m = today.getMonth() + 1
			const wd = today.getDay()

			let events = result.data.map((team)=>{
				let eventDate = addDays(today, team.day - wd)
				
				eventDate = new Date(`${y}-${eventDate.getMonth()+1}-${String(eventDate.getDate()).padStart(2, '0')} ${team.time}`)
				
				
				return{
					teamId: team.teamId,
					title: team.title,
					allDay: false,
					start: eventDate,
					end:  new Date(eventDate.getTime() + 30*60000),
					color: colorsArray[Math.floor(Math.random() * 6)]
				  }
			})
			props.setEvents(events)
		}

	}
}


export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
			teamId: state.admin.teamId,
			roleId: state.admin.roleId,
			team: state.admin.team
		}),
		{}
	),
	withState("team", "setTeam", { ...newTeam }),
	withState("teamState", "setTeamState", { ...newTeamState }),
	withState("events", "setEvents", []),
	withState("viewAllTeams", "setViewAllTeams", true),
	withState("createSuccess", "setCreateSuccess", false),
	withState("createError", "setCreateError", false),
	withState("validationMessage", "setValidationMessage", ""),
	withState("isLoadingSave", "setIsLoadingSave", false),
	withHandlers({
		onFieldChange: (props) => (field, value) => {
			props.team[field] = value
			let isValid = validateProperty(teamAccountabilitySchema, props.team, field).isValid
			
			if (isValid) {
				props.teamState[field] = "success"
			} else {
				props.teamState[field] = "error"
			}
			if( field == `time`){
				let TimeParts = value.split(':')
				if (parseInt(TimeParts[0]) >= 7 && parseInt(TimeParts[0]) <= 17) {
					props.teamState[field] = "success"
				} else {
					props.teamState[field] = "error"
				}
			}
			


			props.setTeamState(props.teamState)
			props.setTeam(props.team)
		},
		onFilterChange: (props) => async (field, value) => {
			props.setViewAllTeams(value)

			getSchedule(props, value)
		},
		onSave: (props) => async () => {
			props.setIsLoadingSave(true)
			let validation = validateObject(teamAccountabilitySchema, props.team)
			if (validation.isValid) {
				await onSubmit(props)
			} else {
				let field
				for (field in props.team) {
					if (validation.errors[field]) {
						props.teamState[field] = "error"
					}
				}
				props.setTeamState(props.teamState)
			}
			props.setIsLoadingSave(false)

			if(verifyPermission(props.roleId, props.team.dataRoleId, "calendar", "read.all")){
				getSchedule(props, props.viewAllTeams)
			}
		}
	}),
	lifecycle({
		componentDidMount() {
			_isMounted = true

			getTeam(this.props, this.props.teamId)

			if(verifyPermission(this.props.roleId, this.props.team.dataRoleId, "calendar", "read.all")){
				getSchedule(this.props, true)
			}

			this.props.setTeamState({ ...newTeamState })
			this.props.setCreateSuccess(false)
			this.props.setViewAllTeams(true)
		},
		UNSAFE_componentWillUpdate(nextState) {
			// Check team changes
			if (nextState.teamId !== this.props.teamId) {
				getTeam(this.props, nextState.teamId)
			}
		},
		componentWillUnmount() {
			_isMounted = false
		}
	})
)(AccountabilityView);
