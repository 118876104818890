import { compose, withState, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { validateProperty, validateObject } from "utils/validator";
import { addTeamMemberSchema } from "models/teamModel";
import { setTeamMembersList } from "store/AdminState";
import { getAllUsers } from "services/apiUser/userApi";
import { addMemberToTeam, getTeamsLeaderByUser,getDataRolebyIdUser, getUsersByTeam } from "services/apiAdmin/teamApi";
import { getWIGByTeamIdYearAndUser , getLastWIG,copyWIG, inactivateWIG,getWIGSParentWIG,getWIGSInactivates, CopyWIGAxis} from "services/apiAdmin/wigApi";
import {CopyPredictive,InactivatePredictive, CopyPredictiveTeam,UnassignedPredictive,ActivatePredictive  } from "services/apiAdmin/predictiveApi";

import AddMemberView from "./AddMemberView";

const newMember = {
	userId: "",
	teamId: "",
	dataRoleId: 3
}

const newMemberState = {
	userId: "",
	teamId: "",
	dataRoleId: ""
}

const newWIG = {
	verb: "",
	what: "",
	axesNumber: 1,
	x1: "",
	y1: "",
	level1_1: "",
	level2_1: "",
	level3_1: "",
	displayName1: "",
	dataTypeId1: 1,
	x2: "",
	y2: "",
	level1_2: "",
	level2_2: "",
	level3_2: "",
	displayName2: "",
	dataTypeId2: 1,
	description: "",
	year: new Date().getFullYear(),
	parentWig: 0
}

let newPredictive = {
	wigId: "-1",
	verb: "",
	what: "gen",
	focus: "",
	quality: "",
	consistency: "",
	description: "",
	axesNumber: 1,
	displayName1: "",
	dataTypeId1: 1,
	dir1: 1,
	x1: "",
	y1: "",
	dataTypeId2: 1,
	dir2: 1,
	x2: "",
	y2: "",
	teamId: -1
}
let _isMounted = false

async function getUsers(props, member) {
	let result = await getAllUsers();
	if (_isMounted && result.success) {
		props.setUserList(result.data)
	}
	props.changeLoadingUserList(false)
}

async function getTeamsLeader(props,userId) {
	let result = await getTeamsLeaderByUser(userId);
	if (_isMounted && result.success) {
		props.setTeamsLeaderOptions(result.data)
		if(result.data.length >0){
			props.SetIsLeader(false)
			props.SetSelectFromTeam(true)
		}
	}

}
//case my team change leader and i need reactivate MCI´s and predictives
async function CASE3(props,statuswig){
	
	let currentYear = new Date().getFullYear();
	// GET WIGS STATE TEAM
	//OBTENER LÍDER DEL TEAM ACTUAL
	let result = await getWIGSInactivates(statuswig,props.fromTeam, currentYear)
	
	if(result.success){
		
		// GET WIG´S OTHER TEAMS WHERE MY WIG ARE PARENTWIG AND change status 50
		for(let i =0; i< result.data.length; i++){
			console.log(result.data[i].Id)
			//await UnassignedWhereParentWig(props,result.data[i].Id);
			//UNASSIGNED PREDICTIVES
			await UnassignedPredictives(result.data[i].Id,result.data[i].CreatedBy);
		}
	

	}
}
async function UnassignedWhereParentWig(props,wigId){
	
	let result = await getWIGSParentWIG(wigId);
	if(result.success){
		//SUCCESS
		//UNASSIGNED PREDICTIVES
		await UnassignedPredictives(wigId,props.member.userId);
	}
}
async function UnassignedPredictives(wigId,createdBy){
	let result = await UnassignedPredictive(wigId,createdBy);
	if(result.success){
		//SUCCESS
	}
}
// case change to rol  (participant co-lider)
async function CASE2(props){
	let currentYear=new Date().getFullYear();
	//COPY WIGS (DESACTIVATE) AND WIG´S PREDICTIVES (DESACTIVATE)
	let result = await getWIGByTeamIdYearAndUser(props.fromTeam, currentYear,props.member.userId)
	if(result.success){
		for (let i =0; i < result.data.length; i ++){
			let resultcopywig = await copyWIG(result.data[i].id,props.fromTeam);
			if(resultcopywig.success){
				//INACTIVATE WIG FOR WIGID
				await InactivateWIG(props,result.data[i].id,currentYear, props.fromTeam);
				// GET LAST WIG
				let resultlastwig= await getLastWIG(props.fromTeam);
				if(resultlastwig.success){
					//COPY AXIS WIG
					await CopyWIGAXIS2(props,result.data[i].id,resultlastwig.data.Id)
					if(props.copyPredictive == true){
						//COPY PREDICTIVES FOR MCI
						await Copy_Predictives(result.data[i].id,resultlastwig.data.Id,props.fromTeam,70,props.member.userId);
					}
				}
				
			}
		}
	}
	//COPY OTHER PREDICTIVES AND DESACTIVATE
	if(props.copyPredictive){
	//	console.log("Solamente ha copiado las de sus propias MCIS")
		await copyOtherPredictives(props,props.fromTeam);
		await ActivatePredictivesTeam(props.fromTeam);
	}



}

//case change líder to other team(líder) COPY WIGS, WIG´S PREDICTIVES AND OTHER PREDICTIVES
async function CASE1(props){
	let currentYear=new Date().getFullYear();
	//COPY WIGS (DESACTIVATE) AND WIG´S PREDICTIVES (DESACTIVATE)

	let result = await getWIGByTeamIdYearAndUser(props.fromTeam, currentYear,props.member.userId)
	if(_isMounted && result.success){
		for (let i =0; i < result.data.length; i ++){
		
			let resultcopywig = await copyWIG(result.data[i].id,props.member.teamId);
			
			if(resultcopywig.success){
				//INACTIVATE WIG FOR WIGID
				await InactivateWIG(props,result.data[i].id,currentYear, props.member.teamId);
				

				// GET LAST WIG
				let resultlastwig= await getLastWIG(props.member.teamId);
				if(resultlastwig.success){
					//COPY AXIS WIG
					
					await CopyWIGAXIS(props,result.data[i].id,resultlastwig.data.Id)

					if(props.copyPredictive == true){
						//COPY PREDICTIVES FOR MCI
						await Copy_Predictives(result.data[i].id,resultlastwig.data.Id,props.member.teamId,1,props.member.userId);
					}
					UnassignedPredictives(result.data[i].Id,props.member.userId);
				}
				

			}
		}
	}
	//COPY OTHER PREDICTIVES AND DESACTIVATE
	if(props.copyPredictive){
	
		await copyOtherPredictives(props, props.member.teamId);
	}

}
//COPY AND DESACTIVATE PREDICTIVES TEAM( PARENT WIG OTHER TEAM)
async function copyOtherPredictives(props,newTeamId){
	let currentYear=new Date().getFullYear();
	let result = await CopyPredictiveTeam(props.fromTeam, newTeamId, currentYear);
	if(result.success){
		
		//SUCCESS
	}
}

//INACTIVATE WIG FOR WIGID
async function InactivateWIG(props,wigId, currentYear,newTeamId){
	
	let resultinactivatewig = await inactivateWIG(wigId);
	if(resultinactivatewig.success){
		//IF COPY PREDICTIVES TRUE
	
	
	
	}
}


async function Copy_Predictives(wigId,lastwig,newTeamId,status,userId){
	let resultcopypredictives = await CopyPredictive(wigId,lastwig,newTeamId,status,userId);
	if(resultcopypredictives.success){
		//INACTIVATE PREDICTIVES FOR OLDWIG
		//await inactivatePredictives(wigId,userId);
	}
}
//INACTIVATE PREDICTIVES FOR WIGID
async function inactivatePredictives(wigId,userId){
	let resultinactivatepredictive = await InactivatePredictive(wigId,userId)
	if(resultinactivatepredictive.success){
		//SUCCESS
		
	}

}


async function CopyWIGAXIS(props,lastwigId,wigId){
	let resultcopywigaxis = await CopyWIGAxis(lastwigId,wigId);
	if(resultcopywigaxis.success){
		console.log("SE COPIARON AXIS WIG");
	}
}

async function CopyWIGAXIS2(props,lastwigId,wigId){
	let resultcopywigaxis = await CopyWIGAxis(lastwigId,wigId);
	if(resultcopywigaxis.success){
		
	}
}

async function ActivatePredictivesTeam(teamId){

	let result = await ActivatePredictive(teamId);
	if(result.success){
		//SUCCESS
	
	}
}
async function onSubmit(props) {
	 /*if(props.member.dataRoleId ==1){
		// NEW MEMBER TO BE LEADER
		if(props.copyWIG){
			//LEADER CHANGE TO NEW LEADER AND COPY WIGS
			await CASE1(props);
				// CHANGE STATUS TO WIGS WHERE MY WIG ARE PARENTID
			await CASE3(props,60);
			
		}else{
			if(props.copyPredictive){
				await copyOtherPredictives(props, props.member.teamId);
			}
			// CHANGE STATUS TO WIGS WHERE MY WIG ARE PARENTID
			await CASE3(props,1)
		}
	
	
	}else{
		
		if(props.copyWIG){
			await CASE2(props);
			if(props.copyPredictive){
				// copyOtherPredictives(props,props.fromTeam);
				//ActivatePredictivesTeam(props.fromTeam);
			}
			
		}
		else{
			if(props.copyPredictive){
				await copyOtherPredictives(props, props.fromTeam);
			}
	
		}
		
	}
	
	*/
	let result = await addMemberToTeam(props.member.teamId, props.member)
	// Show confirmation message
	if (result.success && _isMounted) {
		props.setCreateSuccess(true)
		await getUsersSave(props)
		setTimeout(function () {
			if (_isMounted) {
			//	props.history.push('/admin/dashboard')
			}
		}, 500);
	} else {
		// Show error message
		if (result) {
			let message = result.message
			if (!message) {
				message = "Algo salió mal, inténtelo nuévamente."
			}
			props.setValidationMessage(message)
			props.setCreateError(true)
		}
	}
}

async function getDataRoleIdUser(props){
	
	let result = await getDataRolebyIdUser(props.teamId)
	if(result.success){
		if(result.data.length!=0){
			props.setDataRoleId(result.data[0].dataRoleId)
		}
	}
	
}

async function getUsersSave(props){
	let result = await getUsersByTeam(props.teamId)
	if(result.success){
		props.setTeamMembersList(result.data)
	}
}

export default compose(
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name,
			teamList: state.admin.teamList,
			initialTeamId: state.admin.teamId,
			teamId: state.admin.teamId,
			roleId: state.admin.roleId,
		}),
		{setTeamMembersList}
	),
	withState("member", "setMember", { ...newMember }),
	withState("memberState", "setMemberState", { ...newMemberState }),
	withState("userList", "setUserList", []),
	withState("teamOptions", "setTeamsOptions", []),
	withState("createSuccess", "setCreateSuccess", false),
	withState("createError", "setCreateError", false),
	withState("loadingUserList", "changeLoadingUserList", false),
	withState("validationMessage", "setValidationMessage", ""),
	withState("isLoadingSave", "setIsLoadingSave", false),
	withState("isLeader", "SetIsLeader", true),
	withState("teamLeaderOptions", "setTeamsLeaderOptions", []),
	withState("fromTeam", "SetFromTeam", ""),
	withState("selectFromTeam", "SetSelectFromTeam", true),
	withState("copyWIG", "SetCopyWIG", false),
	withState("copyPredictive", "SetCopyPredictive", false),
	withState("wig", "changeWIG", { ...newWIG }),
	withState("predictive", "changePredictive", {...newPredictive}),
	withState("dataRoleId","setDataRoleId",""),
	withHandlers({
		onFieldChange: (props) => (field, value) => {
			props.member[field] = value
			let isValid = validateProperty(addTeamMemberSchema, props.member, field).isValid
			if (isValid) {
				props.memberState[field] = "success"
			} else {
				props.memberState[field] = "error"
			}
			props.setMember(props.member)
			props.setMemberState(props.memberState)
			getTeamsLeader(props,props.member.userId);

			if(field ==`dataRoleId` && value == 1){
				
			}else{
				if(props.member.dataRoleId==1){
					getTeamsLeader(props,props.member.userId)
					props.SetIsLeader(true)
				}else{
					 props.SetIsLeader(false)
				}
			
				props.SetSelectFromTeam(true)
				props.SetCopyWIG(false)
				props.SetCopyPredictive(false)
			}
		},
		onFieldChangeTeam: (props) => (value) => {
			props.SetFromTeam(value);
			props.SetSelectFromTeam(false)
		
			
		},
		onFieldChangeWIG: (props) => (value) => {
			props.SetCopyWIG(value);
			
		},
		onFieldChangePredictive: (props) => (value) => {
			props.SetCopyPredictive(value);
			
		},
		onMemberSave: (props) => async () => {
			props.setIsLoadingSave(true)
			
			let validation = validateObject(addTeamMemberSchema, props.member)
			if (validation.isValid) {
				await onSubmit(props)
			} else {
				let field
				for (field in props.member) {
					if (validation.errors[field]) {
						props.memberState[field] = "error"
					}
				}
				props.setMemberState(props.memberState)
			}
			props.setIsLoadingSave(false)
		}
	}),
	lifecycle({
		async componentDidMount() {
			_isMounted = true
			let member = { ...newMember }
			member.teamId = this.props.initialTeamId

			this.props.setMember(member)
			this.props.setMemberState({ ...newMemberState })
			this.props.setCreateSuccess(false)
			await getUsersSave(this.props)

			//getDataRoleIdUser(this.props)
			
	
			getUsers(this.props, member)
		

			let teams = this.props.teamList.filter(function (team) {
				return (team.dataRoleId === 1 || team.dataRoleId === 2 || team.roleId === 1 || team.roleId === 2)
			})
			this.props.setTeamsOptions(teams)
		},
		componentWillUnmount() {
			_isMounted = false
		},
		UNSAFE_componentWillUpdate(nextProps) {
			//If loading changes
			if (this.props.loadingUserList !== nextProps.loadingUserList && nextProps.loadingUserList) {
				getUsers(this.props)
			}
		}
	})
)(AddMemberView);
