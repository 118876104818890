import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Explore from "@material-ui/icons/Explore";

import DashboardIcon from "@material-ui/icons/Dashboard";
// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {
    CircularProgress,
    Select,
    MenuItem
  } from "@material-ui/core";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import Tooltip from '@material-ui/core/Tooltip';
import Button from "components/CustomButtons/Button.js";
import TrackChanges from "@material-ui/icons/TrackChanges";

//functions
import { formatDate, filterCaseInsensitive } from "utils/functions";
import { CommitmentStatus } from "components/Constants";
import { getCommitmentColor } from "utils/dataTypeFormatter";
import { translate } from "utils/translate"


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function ReactTables(props) {
  const classes = useStyles();

  let dataTableInfo = props.WigList.map((prop) => {
    return {
      id: prop.id,
      name: prop.Name,
      parent: prop.Parent,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          <Tooltip title={translate("table_header_parent_WIG")}>
          <FormControl
            fullWidth
            className={classes.selectFormControl}
          >
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
            >
              Lead Wig
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              
              onChange={e => props.onWigIDSelect(e)}
              inputProps={{
                name: "parentWig",
                id: "parentWig"
              }}
            >
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={""}
                disabled
              >
                {translate("commitment_wig_input")}
              </MenuItem>
              {props.parentWIGList.map((wig, index) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  style={{ overflowX: "auto" }}
                  value={wig.id}
                  key={index}
                >
                  {wig.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Tooltip>
        </div>
      ),
      buttons: (
        // we've added some custom button actions
        <div className="actions-right">
          <Tooltip title={translate("assign_button")}>
          <Button color="primary" onClick={() => props.onWIGSave(prop.Id)}>
                        {translate("button_save")}
                      </Button>
          </Tooltip>
         
        </div>
      )
    }
  })

  
  let dataTableInfo1 = props.PredictiveList.map((prop) => {
    return {
      id: prop.Id,
      name: prop.Name,
      parent: prop.WIGName,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          <Tooltip title={translate("table_header_parent_WIG")}>
          <FormControl
            fullWidth
            className={classes.selectFormControl}
          >
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
            >
              Lead Wig
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              
              onChange={e => props.onWigIDSelect(e)}
              inputProps={{
                name: "parentWig",
                id: "parentWig"
              }}
            >
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={""}
                disabled
              >
                {translate("commitment_wig_input")}
              </MenuItem>
              {props.parentWIGList.map((wig, index) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  style={{ overflowX: "auto" }}
                  value={wig.id}
                  key={index}
                >
                  {wig.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Tooltip>
        </div>
      ),
      buttons: (
        // we've added some custom button actions
        <div className="actions-right">
          <Tooltip title={translate("assign_button")}>
          <Button color="primary"  onClick={() => props.onPredictiveSave(prop.Id)}>
            {translate("button_save")}
          </Button>
          </Tooltip>
        </div>
      )
    }
  })
  let breadcrumbViews = [
    {
      name: translate("routes_home_label"),
      url: "/admin/dashboard",
      icon: DashboardIcon
    }
  ]

  return (
    <>
      <Breadcrumbs views={breadcrumbViews} />
      <Heading
        textAlign="center"
        title={translate("assigment_title")}
      />
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Explore />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{translate("assigment_title_wigs")}</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={dataTableInfo}
                filterable
                defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                columns={[
                  {
                    Header: translate("table_header_name"),
                    accessor: "name"
                  },
                  {
                    Header: translate("table_header_parent_WIG"),
                    accessor: "parent",
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: translate("table_header_new_parent_WIG"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  },
                 
                  {
                    Header: "",
                    accessor: "buttons",
                    sortable: false,
                    filterable: false
                  },
                  {sortable: false,
                    filterable: false}
                ]}
                defaultPageSize={10}
                showPaginationTop
                minRows={0}
                showPaginationBottom={false}
                className="-striped -highlight noActions"
                previousText={translate("table_previous_text")}
                nextText={translate("table_next_text")}
                loadingText={translate("table_loading_text")}
                noDataText={translate("table_not_found_text")}
                pageText={translate("table_page_text")}
                ofText={translate("table_of_text")}
                rowsText={translate("table_rows_text")}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Explore />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{translate("assigment_title_predictives")}</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={dataTableInfo1}
                filterable
                defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                columns={[
                  {
                    Header: translate("table_header_name"),
                    accessor: "name"
                  },
                  
                  {
                    Header: translate("table_header_parent_WIG"),
                    accessor: "parent",
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: translate("table_header_new_parent_WIG"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  },
                 
                  {
                    Header: "",
                    accessor: "buttons",
                    sortable: false,
                    filterable: false
                  },
                  {sortable: false,
                    filterable: false}
                ]}
                defaultPageSize={10}
                showPaginationTop
                minRows={0}
                showPaginationBottom={false}
                className="-striped -highlight noActions"
                previousText={translate("table_previous_text")}
                nextText={translate("table_next_text")}
                loadingText={translate("table_loading_text")}
                noDataText={translate("table_not_found_text")}
                pageText={translate("table_page_text")}
                ofText={translate("table_of_text")}
                rowsText={translate("table_rows_text")}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
