import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

import { filterCaseInsensitive } from "utils/functions";

import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import { CircularProgress, Grid } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

// @material-ui/icons
import GroupAdd from "@material-ui/icons/GroupAdd";
import Edit from "@material-ui/icons/Edit";
import Group from "@material-ui/icons/Group";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Close from "@material-ui/icons/Close";
// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { NavLink } from "react-router-dom";

import internalStyles from "assets/jss/material-dashboard-pro-react/views/Team/internalStyle.js";

// Functions
import { translate } from "utils/translate"

const styles = {
  ...internalStyles,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  addButton: {
    marginTop: "-25px",
    float: "right"
  }
};

const useStyles = makeStyles(styles);

export default function ReactTables(props) {
  const classes = useStyles();

  const getTeam = (id) => {
    let team = props.teamList.find(function (team) {
      return team.id === id
    })
    return team.title
  }

  const alertWithInput = (id) => {
    props.changeAlertId(id)
    props.changeAlertVisible(true)
  }

  const alert = () => {
    let alertMessage = (null)
    if (props.alertVisible) {
      alertMessage = (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title={translate("alert_team_delete_title")}
          onConfirm={() => props.onTeamDelete(props.alertId)}
          onCancel={() => props.onModalClosed()}
          confirmBtnCssClass={(props.alertLoading ? classes.disabled : "") + " " + classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={translate("alert_team_delete_confirm")}
          cancelBtnText={translate("alert_team_delete_cancel")}
          showCancel
          disabled={props.alertLoading}
        >
          {
            getTeam(props.alertId)
          }
          {props.alertLoading ?
            <GridContainer justify="center">
              <Grid>
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
            </GridContainer>
            :
            null
          }
        </SweetAlert>
      )
    }
    return (alertMessage)
  }

  let dataTableInfo = props.teamList.map((prop) => {
    return {
      id: prop.id,
      name: prop.title,
      parentTeam: prop.parentTeamTitle?prop.parentTeamTitle:'',
      allowEdition: (
        <div className="actions-center">
          <Switch
            checked={prop.allowLeaderEdition?true:false}
            onChange={event => props.onTeamChange("allowLeaderEdition", event.target.checked, prop.id)}
            value="Active"
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
              thumb: classes.switchIcon,
              track: classes.switchBar
            }}
          />
        </div>
      ),
      companyTeam: (
        <div className="actions-center">
          <Switch
            checked={prop.isCompanyTeam?true:false}
            onChange={event => {                
                if (event.target.checked){
                  let obj = props.teamList.find(o => o.isCompanyTeam)
                  if (obj)
                    props.onTeamChange("isCompanyTeam", false, obj.id)    
                }
                props.onTeamChange("isCompanyTeam", event.target.checked, prop.id)
              } 
            }
            value="Active"
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
              thumb: classes.switchIcon,
              track: classes.switchBar
            }}
          />
        </div>
      ),
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          <NavLink
            to={`/admin/team/edit/${prop.id}`}
          >
            <Tooltip title={translate("team_index_edit_tooltip")}>
              <Button
                color={"warning"}
                simple
                className={classes.actionButton}
              >
                <Edit className={classes.icon} />
              </Button>
            </Tooltip>
          </NavLink>
          <Tooltip title={translate("team_index_delete_tooltip")}>
            <Button
              color={"danger"}
              simple
              disabled={props.status === 1}
              className={classes.actionButton}
              //onClick={() => { if (prop.status === 1) { alertWithInput(prop.id) } }}
              onClick={() => alertWithInput(prop.id)}
            >
              <Close className={classes.icon} />
            </Button>
          </Tooltip>
        </div>
      )
    }
  })

  let breadcrumbViews = [
    {
      name: translate("routes_home_label"),
      url: "/admin/dashboard",
      icon: DashboardIcon
    },
    {
      name: translate("routes_team_label"),
      url: "/admin/team/index",
      icon: Group
    }
  ]

  return (
    <>
      {alert()}
      <Breadcrumbs views={breadcrumbViews} />
      <Heading
        textAlign="center"
        title={translate("team_index_header")}
      />
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Group />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{translate("routes_team_label")}</h4>
              <NavLink
                to={`/admin/team/create`}>
                <Tooltip title={translate("team_index_team_create_tooltip")}>
                  <Button color="primary" className={classes.addButton}>
                    <GroupAdd className={classes.icons} />
                  </Button>
                </Tooltip>
              </NavLink>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={dataTableInfo}
                filterable
                defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                columns={[
                  {
                    Header: translate("table_header_name"),
                    accessor: "name"
                  },
                  {
                    Header: translate("table_header_parent"),
                    accessor: "parentTeam"
                  },
                  {
                    Header: translate("table_header_allow_team_edit"),
                    accessor: "allowEdition",
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: translate("table_header_company_team"),
                    accessor: "companyTeam",
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: translate("table_header_actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                minRows={0}
                showPaginationBottom={false}
                className="-striped -highlight"
                previousText={translate("table_previous_text")}
                nextText={translate("table_next_text")}
                loadingText={translate("table_loading_text")}
                noDataText={translate("table_not_found_text")}
                pageText={translate("table_page_text")}
                ofText={translate("table_of_text")}
                rowsText={translate("table_rows_text")}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
