import auth0 from "auth0-js";
import { LoginUser, Auth0 } from "components/Constants";

export default class Auth {
	auth0 = new auth0.WebAuth({
		domain: Auth0.Domain,
		clientID: Auth0.ClientID,
		redirectUri: LoginUser.RedirectEndPoint,
		audience: Auth0.Audience,
		responseType: "token id_token",
		scope: "openid profile email"
	});

	login() {
		this.auth0.authorize();
	}

	handleAuthentication(callback) {
		this.auth0.parseHash((errParseHash, authResults) => {
			if (!callback) throw new Error("Callback undefined");
			else if (errParseHash) callback(errParseHash);
			else {
				if (authResults && authResults.accessToken && authResults.idToken) {
					this.setSession(authResults, res => {
						callback(res);
					});
				} else {
					callback(new Error("No access token returned"));
				}
			}
		});
	}

	setSession(authResults, callback) {
		//Assign local storage items
		let expiresAt = JSON.stringify(
			authResults.expiresIn * 1000 + new Date().getTime() + 4800000
		);
		localStorage.setItem("access_token", authResults.accessToken);
		localStorage.setItem("id_token", authResults.idToken);
		localStorage.setItem("expires_At", expiresAt);
	
	
		//Trying to get the user info
		this.auth0.client.userInfo(authResults.accessToken, (err, user) => {
			if (!callback) throw new Error("Callback undefined");
			else if (err) callback(err);
			else {
				localStorage.setItem("userInfo", JSON.stringify(user));
				//this.logout()
				callback(undefined);
			}
		});
	}

	renewSession() {
		this.auth0.checkSession({}, (err, authResults) => {
			if (authResults && authResults.accessToken && authResults.idToken) {
				this.setSession(authResults);
			} else if (err) {
				this.logout();
			}
		});
	}

	isAuthenticated() {
		let expiresAt = JSON.parse(localStorage.getItem("expires_At"));
		return new Date().getTime() < expiresAt;
	}

	logout() {
		// Remove tokens and expiry time
		localStorage.removeItem("access_token");
		localStorage.removeItem("id_token");
		localStorage.removeItem("expires_At");
		localStorage.removeItem("userInfo");
		localStorage.removeItem("userName");
		localStorage.removeItem("userId");
		localStorage.removeItem("nonce");
		localStorage.removeItem("picture");
		this.auth0.logout({
			returnTo: LoginUser.LogoutEndPoint
		});
	}

	sessionExpired() {
		// Remove tokens and expiry time
		localStorage.removeItem("access_token");
		localStorage.removeItem("id_token");
		localStorage.removeItem("expires_At");
		localStorage.removeItem("userInfo");
		localStorage.removeItem("userName");
		localStorage.removeItem("userId");
		localStorage.removeItem("nonce");
		localStorage.removeItem("picture");
		this.auth0.logout({
			returnTo: LoginUser.ExpiredEndPoint
		});
	}
}
