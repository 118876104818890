/*eslint-disable*/
import React from "react";

// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer, Views } from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
//import globalize from 'globalize';

// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
    CircularProgress,
    Select,
    MenuItem
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// material ui icons
import AddAlert from "@material-ui/icons/AddAlert";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ScheduleIcon from '@material-ui/icons/Schedule';
import Edit from "@material-ui/icons/Edit";
import GradingIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import { Days } from "components/Constants";
// table components
import Table from "components/Table/Table.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/Accountability/indexStyle.js";

// Functions
import { verifyPermission } from "utils/functions";
import { translate } from "utils/translate"

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(styles);


function weekDayHeader(props) {
    return (<div style={{ textAlign: "center" }}>{Days[`${props.date.getDay()}`]}</div>)
}

const CustomToolbar = (toolbar) => {
    const goToBack = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
        toolbar.onNavigate('prev');
    };

    const goToNext = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
        toolbar.onNavigate('next');
    };

    const goToCurrent = () => {
        const now = new Date();
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        toolbar.onNavigate('current');
    };

    const label = () => {
        const date = moment(toolbar.date);
        return (
            <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
        );
    };
    const sCalendar = {}
    return (
        <div className={sCalendar['toolbar-container']}>
            <label className={sCalendar['label-date']}>{label()}</label>

            <div className={sCalendar['back-next-buttons']}>
                <button className={sCalendar['btn-back']} onClick={goToBack}>&#8249;</button>
                <button className={sCalendar['btn-current']} onClick={goToCurrent}>today</button>
                <button className={sCalendar['btn-next']} onClick={goToNext}>&#8250;</button>
            </div>
        </div >
    );
};

export default function createView(props) {
    const classes = useStyles();

    const selectedEvent = event => {
        window.location.href = `/admin/accountability`;
    };

    let breadcrumbViews = [
        {
            name: translate("routes_home_label"),
            url: "/admin/dashboard",
            icon: DashboardIcon
        },
        {
            name: translate("routes_wig_session_label"),
            url: `/admin/accountability`,
            icon: ScheduleIcon
        },
        {
            name: translate("routes_wig_sessions_label"),
            url: `/admin/accountabilityrecords/team/${props.teamId}`,
            icon: FormatListNumberedIcon
        },
        {
            name: translate("routes_wig_session_record"),
            url: `/admin/accountabilityrecord/record/`,
            icon: AssignmentIcon
        }
    ]

    const eventColors = event => {
        var backgroundColor = "event-";
        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");
        return {
            className: backgroundColor
        };
    };

    const [multipleSelect, setMultipleSelect] = React.useState([]);

    const handleMultiple = event => {
        setMultipleSelect(event.target.value);
    };

    let recordDate = '';
    let recordTime = '';
    if (props.accountabilityRecord) {
        recordDate = props.accountabilityRecord[0].ACDate;
        recordTime = props.accountabilityRecord[0].ACTime;
    }


    let questionsRows = [];
    if (props.questionsList && props.accountabilityRecord) {
        props.questionsList.map((question, index) => (
            question.questionType == 1 ? questionsRows.push(
                [
                    `${question.Id}`,
                    `${translate("accountability_record_create_question_label_" + question.Id)}`,
                    <p mx={3}>{`${props.accountabilityRecord[index].AQScore}`}</p>,
                    <p mx={3}>{`${props.accountabilityRecord[index].AQObs} `}</p>
                ]
            ) : null

        ));
    }
    let generalObs = '';
    if (props.accountabilityRecord) {
        if (props.accountabilityRecord[8].AQObs.length > 1) {
            generalObs = props.accountabilityRecord[8].AQObs;
        } else {
            generalObs = translate("accountability_record_view_general_obs_message");
        }

    }

    return (
        <>
            <Breadcrumbs views={breadcrumbViews} />
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <AssignmentIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{translate("routes_wig_session_record")}</h4>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={6}>
                                            <GridContainer>
                                                <CustomInput
                                                    labelText={translate("accountability_record_create_team_label")}
                                                    id="inputTitle"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        disabled: true,
                                                        type: "text",
                                                        value: props.team.title
                                                    }}
                                                />
                                                <FormControl fullWidth className={classes.selectFormControl}>
                                                    <InputLabel
                                                        htmlFor="multiple-select"
                                                        className={classes.selectLabel}
                                                    >
                                                        {translate("accountability_record_create_participants_label")}
                                                    </InputLabel>
                                                    <Select
                                                        multiple
                                                        value={props.participants}
                                                        onChange={e => props.onParticipantsChange(e.target.value)}
                                                        MenuProps={{
                                                            className: classes.selectMenu,
                                                            classes: { paper: classes.selectPaper }
                                                        }}
                                                        classes={{ select: classes.select }}
                                                        inputProps={{
                                                            name: "multipleSelect",
                                                            id: "multiple-select"
                                                        }}
                                                    >
                                                        <MenuItem
                                                            disabled
                                                            classes={{
                                                                root: classes.selectMenuItem
                                                            }}
                                                        >
                                                            {translate("accountability_record_create_participants_label")}
                                                        </MenuItem>
                                                        {props.membersList.map((user, index) => (
                                                            <MenuItem
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                    selected: classes.selectMenuItemSelectedMultiple
                                                                }}
                                                                value={user.userId}
                                                            >
                                                                {`${user.firstName} ${user.lastName}`}
                                                            </MenuItem>
                                                        ))}


                                                    </Select>
                                                </FormControl>

                                            </GridContainer>

                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <GridContainer justify="center">
                                                <GridItem xs={12} sm={12} md={8}>
                                                    <h4>{translate("accountability_record_view_date_time_label")}</h4>
                                                    <br></br>
                                                    <h4
                                                        style={{ textAlign: "center" }}
                                                    >

                                                        {`${recordDate.substring(0, 10)} ${recordTime.substring(0, 5)} hrs`}

                                                    </h4>

                                                    <br />
                                                </GridItem>

                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer justify="center">
                                        <GridItem>
                                            <Table
                                                striped
                                                tableHead={[
                                                    <p style={{ fontWeight: 500 }}>No.</p>,
                                                    <GridItem xs={12} sm={12} md={12} style={{ fontWeight: 500 }}>
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <p
                                                                    style={{ textAlign: "center" }}
                                                                >{translate("accountability_record_create_aspect_label")}</p>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={12}>
                                                                <p
                                                                    style={{ textAlign: "center" }}
                                                                >{translate("accountability_record_create_methodology_label")}</p>
                                                            </GridItem>
                                                        </GridContainer>
                                                    </GridItem>,
                                                    <p style={{ fontWeight: 500 }}>{translate("accountability_record_create_score_label")}</p>,
                                                    <p style={{ fontWeight: 500 }}>{translate("accountability_record_create_observations_label")}</p>
                                                ]}
                                                tableData={
                                                    questionsRows
                                                }
                                                style={{ width: "100%" }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <h5>{translate("accountability_record_create_score_guide_label")}</h5>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <h5
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        <span style={{ marginRight: "10px" }}>{translate("accountability_record_create_score_guide_label_A")}</span>
                                                        <span style={{ marginRight: "10px" }}>{translate("accountability_record_create_score_guide_label_B")}</span>
                                                        <span style={{ marginRight: "10px" }}>{translate("accountability_record_create_score_guide_label_C")}</span>
                                                        <span> {translate("accountability_record_create_score_guide_label_NA")}</span>
                                                    </h5>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} >
                                    <h4>{translate("accountability_record_view_comments_label")}</h4>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} >
                                    <h5 mx={3}>{` ${generalObs} `}</h5>
                                </GridItem>
                                <div className={classes.center}>
                                    {props.isLoadingSave ?
                                        <CircularProgress color="inherit" /> :
                                        <Button color="primary" onClick={props.goBack}>
                                            {translate("button_back")}
                                        </Button>
                                    }
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}
