import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";

import { getHistoryLogsByTeam } from "services/apiAdmin/historyApi";

import { getAllUsers, getReportByUser } from "services/apiStat/statApi";
import { getInputFormatDate} from "utils/functions"
import HistoryView from "./DownloadView"

//library to export to excel
import writeXlsxFile from 'write-excel-file'


let _isMounted = false

async function getHistoryLogs(params) {	

	let result = await getReportByUser(params)

	if (_isMounted && result.success) {	
   
		return result
	}
}


  

export default compose(
	
	connect(
		state => ({
			isAuthenticated: state.login.isAuthenticated,
			name: state.login.name
		}),
		{}
	),
	withState("historyLogs", "setHistoryLogs", []),
	withState("valueOption", "setValueOption", []),	
	withState("selectedMembers", "setSelectedMembers", []),	
  withState("isLoadingSave","setIsLoadingSave",false),
	withHandlers({		
		onFieldChange: (props) => (field, value) => {			
			props.setValueOption(value)			
		},
		onSave: (props) => async () => {
      // console.log("props.valueOption", props.valueOption)
			if (_isMounted && props.valueOption > 0) {

				let params = {
					year:props.valueOption,			
				}
        props.setIsLoadingSave(true)
				let result = await getHistoryLogs(params)				

				const HEADER_ROW = [      									

          {value: 'Email', fontWeight: 'bold'},
          {value: 'Codigo Empleado', fontWeight: 'bold'},
          
          {value: 'MCIs', fontWeight: 'bold'},             
          {value: 'MPs', fontWeight: 'bold'},             
          {value: 'Especialista', fontWeight: 'bold'},             
          {value: 'Nombre', fontWeight: 'bold'},             
          
          {value: 'Nombre Equipo', fontWeight: 'bold'},             
          {value: 'Equipo Padre', fontWeight: 'bold'},   
          {value: 'Role', fontWeight: 'bold'},              
                  
          {value: 'Dia Rendicion Cuentas', fontWeight: 'bold'},             
          {value: 'Hora Rendicion de cuentas', fontWeight: 'bold'},  
          
          {value: 'Eje MCI', fontWeight: 'bold'},             
          {value: 'Nombre MCI', fontWeight: 'bold'},             
          {value: 'Nombre Completo MCI', fontWeight: 'bold'},                                        
          
          {value: 'Enero', fontWeight: 'bold'},             
          {value: 'Febrero', fontWeight: 'bold'},             
          {value: 'Marzo', fontWeight: 'bold'},             
          {value: 'Abril', fontWeight: 'bold'},             
          {value: 'Mayo', fontWeight: 'bold'},             
          {value: 'Junio', fontWeight: 'bold'},             
          {value: 'Julio', fontWeight: 'bold'},             
          {value: 'Agosto', fontWeight: 'bold'},             
          {value: 'Septiembre', fontWeight: 'bold'},             
          {value: 'Octubre', fontWeight: 'bold'},             
          {value: 'Noviembre', fontWeight: 'bold'},             
          {value: 'Diciembre', fontWeight: 'bold'},             
                    
          {value: 'Eje Predictiva', fontWeight: 'bold'},             
          {value: 'Nombre Predictiva', fontWeight: 'bold'},             
          {value: 'Nombre Completo Predictiva', fontWeight: 'bold'},             
          
          {value: 'Semana-1', fontWeight: 'bold'},             
          {value: 'Semana-2', fontWeight: 'bold'},             
          {value: 'Semana-3', fontWeight: 'bold'},             
          {value: 'Semana-4', fontWeight: 'bold'},             
          {value: 'Semana-5', fontWeight: 'bold'},             
          {value: 'Semana-6', fontWeight: 'bold'},             
          {value: 'Semana-7', fontWeight: 'bold'},             
          {value: 'Semana-8', fontWeight: 'bold'},             
          {value: 'Semana-9', fontWeight: 'bold'},             
          {value: 'Semana-10', fontWeight: 'bold'},             
          {value: 'Semana-11', fontWeight: 'bold'},             
          {value: 'Semana-12', fontWeight: 'bold'},             
          {value: 'Semana-13', fontWeight: 'bold'},             
          {value: 'Semana-14', fontWeight: 'bold'},             
          {value: 'Semana-15', fontWeight: 'bold'},             
          {value: 'Semana-16', fontWeight: 'bold'},             
          {value: 'Semana-17', fontWeight: 'bold'},             
          {value: 'Semana-18', fontWeight: 'bold'},             
          {value: 'Semana-19', fontWeight: 'bold'},             
          {value: 'Semana-20', fontWeight: 'bold'},             
          {value: 'Semana-21', fontWeight: 'bold'},             
          {value: 'Semana-22', fontWeight: 'bold'},             
          {value: 'Semana-23', fontWeight: 'bold'},             
          {value: 'Semana-24', fontWeight: 'bold'},             
          {value: 'Semana-25', fontWeight: 'bold'},             
          {value: 'Semana-26', fontWeight: 'bold'},             
          {value: 'Semana-27', fontWeight: 'bold'},             
          {value: 'Semana-28', fontWeight: 'bold'},             
          {value: 'Semana-29', fontWeight: 'bold'},             
          {value: 'Semana-30', fontWeight: 'bold'},             
          {value: 'Semana-31', fontWeight: 'bold'},                                     
          {value: 'Semana-32', fontWeight: 'bold'},             
          {value: 'Semana-33', fontWeight: 'bold'},             
          {value: 'Semana-34', fontWeight: 'bold'},             
          {value: 'Semana-35', fontWeight: 'bold'},             
          {value: 'Semana-36', fontWeight: 'bold'},             
          {value: 'Semana-37', fontWeight: 'bold'},             
          {value: 'Semana-38', fontWeight: 'bold'},             
          {value: 'Semana-39', fontWeight: 'bold'},                                     
          {value: 'Semana-40', fontWeight: 'bold'},             
          {value: 'Semana-41', fontWeight: 'bold'},             
          {value: 'Semana-42', fontWeight: 'bold'},             
          {value: 'Semana-43', fontWeight: 'bold'},             
          {value: 'Semana-44', fontWeight: 'bold'},             
          {value: 'Semana-45', fontWeight: 'bold'},             
          {value: 'Semana-46', fontWeight: 'bold'},             
          {value: 'Semana-47', fontWeight: 'bold'},             
          {value: 'Semana-48', fontWeight: 'bold'},             
          {value: 'Semana-49', fontWeight: 'bold'},             
          {value: 'Semana-50', fontWeight: 'bold'},             
          {value: 'Semana-51', fontWeight: 'bold'},             
          {value: 'Semana-52', fontWeight: 'bold'},             
          
				]
					
				const data = [
				  HEADER_ROW
				]

				result.data.map((d,i)=>{					
					data.push(d)								  
				})	
				
				if(result.data.length > 0){
					// wrhite xls file
          let today= new Date();
          let dia =  getInputFormatDate(today)
        
					await writeXlsxFile(data, {
						columns:"", // (optional) column widths, etc.
						fileName: '4DX_' + dia + '.xlsx'
					})

				}else{
					alert("No Data")
				}
			
								
			}
      props.setIsLoadingSave(false)
		}
	
	}),
	lifecycle({
		componentDidMount(props) {

			_isMounted = true
      
			//getHistoryLogs(this.props)					

		}
	})
)(HistoryView);
