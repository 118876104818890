import { WIG, Predictive, ResponseMessages } from "components/Constants";
import axios from "axios";
import { signOut } from "store/LoginState";
import store from "store";

/*
Used to create Predictive:
Retuns the id of the response
*/
async function createPredictive(newPredictive) {
  try {
    let url = `${WIG.GetEndPoint}/${newPredictive.wigId}/predictive`;
    let requestHeaders = createHeaders();
    let response = await axios({
      method: "post",
      url: url,
      headers: requestHeaders,
      data: newPredictive,
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `createPredictive returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for createPredictive`);

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
Used to update Predictive
*/
async function editPredictive(predictive, predictiveId) {
  try {
    let url = `${Predictive.GetEndPoint}/${predictiveId}`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "put",
      url: url,
      headers: requestHeaders,
      data: predictive,
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            console.debug(error.response);
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `createPredictive returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      console.debug(response);
      // Check response status code
      console.info(`statusCode: ${response.status} for createPredictive`);

      // If it's a success response
      if (response.status === 200) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
Get the list of predictives by WIG Id
*/
async function getPredictiveByWIGId(wigId) {
  try {
    let url = `${WIG.GetEndPoint}/${wigId}/predictive`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "get",
      url: url,
      headers: requestHeaders,
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `createPredictive returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
Get the Predictive with Id
*/
async function getPredictiveById(idPredictive) {
  try {
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "get",
      url: `${Predictive.GetEndPoint}/${idPredictive}`,
      headers: requestHeaders,
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          console.error(error.response);
          // Throw error if it's not a success response
          throw new Error(
            `createPredictive returned a status code ${error.response.status}`
          );
        }
      }
    });
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
Used to create tracking information for Predictive:
Retuns the id of the response
*/
async function createPredictiveTracking(tracking, predictiveId) {
  try {
    let url = `${Predictive.GetEndPointStat}/${predictiveId}/tracking`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "post",
      url: url,
      headers: requestHeaders,
      data: tracking,
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `createPredictiveTracking returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for createWIGTracking`);

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

async function createPredictiveTrackingAxi(tracking, predictiveId) {
  try {
    let url = `${Predictive.GetEndPointStatAxis}/${predictiveId}/tracking`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "post",
      url: url,
      headers: requestHeaders,
      data: tracking,
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `createPredictiveTracking returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for createWIGTracking`);

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}
/*
Deletes a Predictive with it's Id
*/
async function deletePredictiveById(idPredictive) {
  try {
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "delete",
      url: `${Predictive.GetEndPoint}/${idPredictive}`,
      headers: requestHeaders,
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          console.error(error.response);
          // Throw error if it's not a success response
          throw new Error(
            `createPredictive returned a status code ${error.response.status}`
          );
        }
      }
    });
    if (response) {
      // If it's a success response
      if (response.status === 204) {
        return { success: true, data: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
Get the list of predictives by WIG Id And User
*/
async function getPredictiveByWIGIdAndUser(wigId) {
  try {
    let url = `${WIG.GetEndPoint}/predictive/${wigId}/predictiveuser`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "get",
      url: url,
      headers: requestHeaders,
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `createPredictive returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
Get the list of predictives by WIG Id And User
*/
async function getPredictiveByWIGIdAndUserSET(wigId, userId) {
  try {
    let url = `${WIG.GetEndPoint}/predictivelist/${wigId}/${userId}`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "get",
      url: url,
      headers: requestHeaders,
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `createPredictive returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
Used to create Predictive:
Retuns the id of the response
*/
async function createPredictiveUser(newPredictive, userId, wigId) {
  try {
    let url = `${WIG.GetEndPoint}/save/predictive/${wigId}/${userId}`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "post",
      url: url,
      headers: requestHeaders,
      data: newPredictive,
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `createPredictive returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for createPredictive`);

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
COPY PREDICTIVE FOR LASTWIGID ASSIGNED NEW WIGID AND NEW TEAMID
*/
async function CopyPredictive(lastWigId, newWigId, teamId, status, userId) {
  try {
    let url = `${Predictive.GetEndPointCOPY}`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "post",
      url: url,
      headers: requestHeaders,
      data: {
        lastWigId: lastWigId,
        newWigId: newWigId,
        teamId: teamId,
        status: status,
        createdBy: userId,
      },
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `CopyPredictive returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for CopyPredictive`);

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}
/*
INACTIVATE PREDICTIVE
*/
async function InactivatePredictive(lastWigId, userId) {
  try {
    let url = `${Predictive.GetEndPointInactivate}`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "post",
      url: url,
      headers: requestHeaders,
      data: {
        lastWigId: lastWigId,
        createdBy: userId,
      },
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `InactivatePredictive returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for InactivatePredictive`);

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
COPY TEAM´S PREDICTIVE (OTHER TEAM´S MCIS PARENT)
*/
async function CopyPredictiveTeam(teamId, newTeamId, year) {
  try {
    let url = `${Predictive.GetEndPointCOPYTEAM}`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "post",
      url: url,
      headers: requestHeaders,
      data: {
        teamId: teamId,
        newTeamId: newTeamId,
        status: 50,
        year: year,
      },
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `CopyPredictiveTeam returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for CopyPredictiveTeam`);

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
UNASIGNED PREDICTIVES (WHERE WIGID ARE MYWIG)
*/
async function UnassignedPredictive(wigId, createdBy) {
  try {
    let url = `${Predictive.GetEndPointUnasigned}`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "post",
      url: url,
      headers: requestHeaders,
      data: {
        wigId: wigId,
        createdBy: createdBy,
      },
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `UnassignedPredictive returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for UnassignedPredictive`);

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
GET UNASIGNED PREDICTIVES (WHERE TEAMID AND CREATEDBY)
*/
async function GetUnassignedPredictives(teamId) {
  try {
    let url = `${Predictive.GetEndPointUnasignedP}`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "post",
      url: url,
      headers: requestHeaders,
      data: {
        teamId: teamId,
        status: 50,
      },
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `GetUnassignedPredictives returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // Check response status code
      console.info(
        `statusCode: ${response.status} for GetUnassignedPredictives`
      );

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
UPDATE PREDICTIVE WIGID AND CHANGE TO STATUS 1
*/
async function UpdatePredictiveWigID(wigId, predictiveId) {
  try {
    let url = `${Predictive.GetEndPointUpdateWigID}`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "post",
      url: url,
      headers: requestHeaders,
      data: {
        wigId: wigId,
        predictiveId: predictiveId,
      },
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `UpdatePredictiveWigID returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for UpdatePredictiveWigID`);

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
ACTIVATE PREDICTIVE
*/
async function ActivatePredictive(teamId) {
  try {
    let url = `${Predictive.GetEndPointUpdateActive}`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "post",
      url: url,
      headers: requestHeaders,
      data: {
        teamId: teamId,
      },
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `ActivatePredictive returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for ActivatePredictive`);

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
GET Predictives by UserId, TeamId and WIGId
*/
async function GetPredictivesByWIGTeamAndUser(teamId, wigId) {
  try {
    let url = `${Predictive.GetEndPointGet}`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "get",
      url: url,
      headers: requestHeaders,
      params: {
        teamId: teamId,
        wigId: wigId,
      },
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `GetPredictivesByWIGTeamAndUser returned a status code ${error.response.status}`
          );
        }
      }
    });

    if (response) {
      // Check response status code
      console.info(
        `statusCode: ${response.status} for GetPredictivesByWIGTeamAndUser`
      );

      // If it's a success response
      if (response.status === 200) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}

/*
GET PREDICTIVES TO PARENT
*/
async function GetPredictivesParent(userId, teamId, wigId) {
  try {
    let url = `${Predictive.GetEndPointToParent}`;
    let requestHeaders = createHeaders();

    let response = await axios({
      method: "post",
      url: url,
      headers: requestHeaders,
      data: {
        userId: userId,
        teamId: teamId,
        wigId: wigId,
      },
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.message === ResponseMessages.INVALID_TOKEN) {
            store().store.dispatch(signOut());
          } else {
            return error.response;
          }
        } else if (error.response.data) {
          return error.response;
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `GetPredictivesParent returned a status code ${error.response.status}`
          );
        }
      }
    });
    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for GetPredictivesParent`);
      // If it's a success response
      if (response.status === 200) {
        return { success: true, message: response.data.payload };
      } else {
        return { success: false, message: response.data.message };
      }
    } else {
      return {
        success: false,
        message: "Algo ocurrió mal, intentélo nuevamente",
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: "Algo ocurrió mal, intentélo nuevamente",
    };
  }
}
function createHeaders() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    nonce: localStorage.getItem("nonce"),
  };

  return headers;
}

export {
  createPredictive,
  editPredictive,
  deletePredictiveById,
  getPredictiveByWIGId,
  getPredictiveById,
  createPredictiveTracking,
  getPredictiveByWIGIdAndUser,
  getPredictiveByWIGIdAndUserSET,
  createPredictiveUser,
  CopyPredictive,
  InactivatePredictive,
  CopyPredictiveTeam,
  UnassignedPredictive,
  GetUnassignedPredictives,
  UpdatePredictiveWigID,
  ActivatePredictive,
  GetPredictivesByWIGTeamAndUser,
  GetPredictivesParent,
  createPredictiveTrackingAxi
};
